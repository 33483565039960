import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"

export default function Switch({ value, setValue, data }){

    const [blockStyles, setBlockStyles] = useState({
        width: 0,
        left: 0,
    })

    const handleClick = (value, ref) => {
        console.log(ref)
        if(ref){
        setValue(value)
        setBlockStyles({
            width: ref.current.clientWidth - 2,
            left: ref.current.offsetLeft + 1
        })
    }
    }

    useEffect(() => {

    }, [])

    return (
        <label className="switch">
            <span className="slider">
                <div className="slider-block" style={{transform: 'translate(-50%, -50%)', height: '100%'}, blockStyles} />
                {
                    data.map((item, key) => {
                        return (
                            <SlideItem 
                                key={key}
                                active={value}
                                handleClick={handleClick}
                                {...item}
                            />
                        )
                    })
                }
            </span>
        </label>
    )
}

function SlideItem({ value, label, icon, handleClick, active}){

    const ref = useRef()

    useEffect(() => {
        if(active === value) handleClick(value, ref)
    }, [])
    return (
        <div ref={ref} className={"slider-item" + (active === value ? " active" : "")} onClick={() => handleClick(value, ref)}>
            { icon ? <FontAwesomeIcon style={{marginRight: 10}} icon={icon} /> : null}
            <span>{label}</span>
        </div>
    )
}