import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import BoxLoading from "../../../components/BoxLoading"
import Drawer, { DrawerContext } from "../../../components/Drawer"
import { formatDate, getDeadlineType, getNextDeadline } from "../../../components/Functions"
import ImageStack from "../../../components/ImageStack"
import Table from "../../../components/Table"
import Tooltip from "../../../components/Tooltip"
import { AuthContext } from "../../../providers/AuthProvider"
import { DomainContext } from "../../../providers/DomainProvider"
import RadioGroup from "../../../components/RadioGroup"


const Context = createContext({
    tasks: null,
    activeTasks: null,
    archivedTasks: null,
})

const Provider = ({children}) => {

    const { apiCall } = useContext(AuthContext)

    const [tasks, setTasks] = useState(null)
    const [activeTasks, setActiveTasks] = useState(null)
    const [archivedTasks, setArchivedTasks] = useState(null)
    const [taskSubmissions, setTaskSubmissions] = useState(null)
    

    const getTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-tasks'})
        
        
        var tempArray = [];
        if(data.status === 1){
            let allTasks = data.data
            const originalTasks = data.data

            allTasks.forEach((item, index, object) => {
                if(item.parent) return

                if(item.batch){
                    if(object.filter(i => i.batch === item.batch).length > 1){
                        if(tempArray.filter(o => o.batch === item.batch).length){

                        }else{
                            tempArray = [...tempArray, {
                                batch: item.batch,
                                name: item.name,
                                subtasks: object.filter(i => i.parent === item.id),
                                deadline: item.deadline,
                                form: item.form,
                                count: object.filter(i => i.batch === item.batch).length,
                                responsibles: item.responsibles
                            }]
                        }
                    }else{
                        tempArray = [...tempArray, { ...item }]
                    }
                }else{
                    tempArray = [...tempArray, item]
                }
            })
            setTasks(allTasks)
            
        }
    }

    useEffect(() => {
        let active = []
        let archived = []
        let submissions = []
        if(tasks && tasks.length){
            tasks.forEach((item, index, object) => {
                
                console.log(item)
                
                    
                
                if(item.archivedon){
                    if(item.batch){
                        if(object.filter(i => i.batch === item.batch).length > 1){
                            if(archived.filter(o => o.batch === item.batch).length){
    
                            }else{
                                archived.push({
                                    batch: item.batch,
                                    name: item.name,
                                    subtasks: object.filter(i => i.parent === item.id),
                                    deadline: item.deadline,
                                    form: item.form,
                                    count: object.filter(i => i.batch === item.batch).length,
                                    responsibles: item.responsibles
                                })
                            }
                        }else{
                            archived.push(item)
                        }
                    }else{
                        archived.push(item)
                    }
                }else{

                    if(item.batch){
                        if(object.filter(i => i.batch === item.batch).length > 1){
                            if(active.filter(o => o.batch === item.batch).length){
    
                            }else{
                                active.push({
                                    batch: item.batch,
                                    name: item.name,
                                    subtasks: object.filter(i => i.parent === item.id),
                                    deadline: item.deadline,
                                    form: item.form,
                                    count: object.filter(i => i.batch === item.batch).length,
                                    responsibles: item.responsibles
                                })
                            }
                        }else{
                            active.push(item)
                        }
                    }else{
                        active.push(item)
                    }

                    
                }

                if(item.submissions.length){
                    item.isSubmission = true
                    submissions.push(item)
                }

                if(item.subtasks.length){
                    item.subtasks.map(subtask => {
                        if(subtask.submissions.length){
                            subtask.isSubmission = true
                            subtask.parent = item
                            subtask.companies = item.companies
                            submissions.push(subtask)
                        }
                    })
                }
                

            })
            setActiveTasks(active)
            setArchivedTasks(archived)
            setTaskSubmissions(submissions)
        }

        
    }, [tasks])

    useEffect(() => {
        getTasks()
    }, [])

    return <Context.Provider value={{
        tasks,
        activeTasks,
        archivedTasks,
        taskSubmissions
    }}> 
        {children}
    </Context.Provider>
}

/* eslint-disable */
export default function DomainTasks(){
    
    const { hasPermission } = useContext(DomainContext)
    
    return (
        <Provider>
            <Navigation />
        </Provider>
    )
}

export function ActiveDomainTasks(){

    const { activeTasks } = useContext(Context)
    const { domain, user } = useContext(AuthContext)
    //console.log(activeTasks)
    const navigate = useNavigate()
    return (
        <Table
            id="active-domain-tasks"
            gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "2fr fit-content(8ch) 2fr fit-content(8ch)"]}
            gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
            perPage={10}
            rows={activeTasks}
            showResult
            unit="oppgaver"
            onRowClick={(e) => {
                
                if(e.count && e.count > 1){
                    navigate(`samling/${e.batch}`)
                }else{
                    if(e.companies && e.companies.length === 1){
                        navigate(`/${domain.hash}/${e.companies[0].hash}/oppgaver/${e.id}`)
                    }else{
                        navigate(`${e.id}`)
                    }
                    
                }
                
            }}
            customSort={(rows) => {
                if(!rows) return false
                return rows.sort((a, b) => {
                    const a1 =  getNextDeadline({deadline: a.deadline, subtasks: a.subtasks, lastSubmission: a.lastSubmission})
                    const b1 = getNextDeadline({deadline: b.deadline, subtasks: b.subtasks, lastSubmission: b.lastSubmission})
                    
                    return a1.timestamp - b1.timestamp
                })
            }}
            columns={[
                {
                    grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                    keys: ['name', 'companies', 'count'],
                    display: (e) => {
                        
                        return (
                            <div>
                                <div className="row gx-2">
                                    { e.count > 1 ? 
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['fal', 'layer-group']} />
                                        </div> 
                                    : null }
                                    <div className="col">
                                    <span className="bold d-block taskname">{e.name}</span>
                                    </div>
                                </div>
                                
                                {
                                    e.count > 1 ? 
                                    <span className="hint-text">{`${e.count} bedrifter`}</span> 
                                : null 
                                }
                                {
                                    e.companies && e.companies.length && e.companies.map((company) => {
                                        return <span className="hint-text">{company.name}</span>
                                    })
                                }
                            </div>
                        )
                    }
                },
                {
                    grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                    keys: ['deadline', 'subtasks', 'lastSubmission', 'submissions'],
                    display: (e) => {
                        let deadlineClass = ''
                        const deadline = getNextDeadline({deadline: e.deadline, subtasks: e.subtasks, lastSubmission: e.lastSubmission, submissions: e.submissions})
                        
                        if(deadline.daysUntil !== null){
                            switch(true){
                                case deadline.daysUntil < 0:
                                    deadlineClass = 'text-danger'
                                    break
                                case deadline.daysUntil <= 7:
                                    deadlineClass = 'text-warning'
                                    break;
                            }
                        }
                        return (
                            <div style={{lineHeight: 1}}>
                                <span className={"d-block mb-1 " + deadlineClass}>{deadline.text}</span>
                                <small>
                                    <FontAwesomeIcon size="sm" icon={['fal', 'repeat']} style={{marginRight: 5}} />
                                    <i>{deadline.type}</i>
                                </small>   
                            </div>
                        ) 
                    }
                },
                {
                    grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                    keys: ['priority', 'form', 'subtasks', 'submission'],
                    display: (e) => {
                        return (
                            <div className="row gx-1">
                                {
                                    e.priority ? (
                                        <div className="col-auto">
                                            <Tooltip content="Prioritert oppgave" placement="bottom">
                                                <div className="bordered-color danger py-1 px-2">
                                                    <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : null
                                }
                                {
                                    e.form ? (
                                        <div className="col-auto">
                                            <Tooltip content="Skjema" placement="bottom">
                                                <div className="bordered-color success py-1 px-2">
                                                    <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : null
                                }
                                {
                                    e.subtasks && e.subtasks.length > 0 ? (
                                        <div className="col-auto">
                                            <Tooltip content="Underoppgaver" placement="bottom">
                                                <div className="bordered-color success py-1 px-2">
                                                    <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : null
                                }
                                {
                                    e.submission ? 
                                    <div className="col-auto">
                                            <Tooltip content="Påbegynt" placement="bottom">
                                                <div className="bordered-color warning py-1 px-2">
                                                    <FontAwesomeIcon fixedWidth icon={['fas', 'pencil']} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    : null
                                }
                                {
                                    e.submission?.signatures.length && e.submission?.signatures.filter(signature => signature.requiredBy === user.id && !signature.signed).length ? 
                                    <div className="col-auto">
                                            <Tooltip content="Må signeres av deg" placement="bottom">
                                                <div className="bordered-color danger py-1 px-2">
                                                    <FontAwesomeIcon fixedWidth icon={['fas', 'signature']} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    : null
                                }
                
                            </div>
                        )
                    }
                },
                {
                    grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                    keys: ['responsibles', 'subtasks'],
                    display: (e) => {
                        var responsibles = []
                        if(e.subtasks && e.subtasks.length){
                            e.subtasks.map((item) => {
                                item.responsibles && item.responsibles.map((i) => {
                                    if(!responsibles.filter(r => r.id === i.id).length) responsibles.push(i)
                                })
                                
                            })
                        }
                        if(e.responsibles && e.responsibles.length){
                            e.responsibles.map((i) => {
                                if(!responsibles.filter(r => r.id === i.id).length) responsibles.push(i)
                            })
                            
                        }
                        
                        
                        return (responsibles.length ? <ImageStack users={responsibles} /> : <Tooltip content="Mangler ansvarlig" placement="left"><FontAwesomeIcon size="2x" icon={['fas', 'circle-exclamation']} style={{color: 'var(--danger-tint)'}} /></Tooltip>)
                    }
                },
                {
                    grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                    keys: ['pinned'],
                    display: (e) => {
                        return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                    }
                }      
            ]}
        />
    )
}

export function ArchivedDomainTasks(){
    const { archivedTasks, taskSubmissions } = useContext(Context)
    const { domain, user } = useContext(AuthContext)
    console.log('taskSubmissions', taskSubmissions)
    
    const navigate = useNavigate()
    const [type, setType] = useState(1)
    return (
        <>
            <RadioGroup
                selected={type}
                select={(e) => setType(e)}
                items={[

                    {label: 'Ny ogikk', value: 1},
                    {label: 'Gammel logikk', value: 2}
                ]}
            />
            <Table
                id="archived-domain-tasks"
                grid="2fr 1fr 1fr 1fr 0fr"
                gridTemplateColumns={["2fr 1fr 1fr 1fr 1fr", "2fr 1fr 1fr 1fr 1fr", "repeat(5, 1fr"]}
                gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                perPage={15}
                rows={type === 1 ? taskSubmissions : archivedTasks}
                showResult
                unit="oppgaver"
                onRowClick={(e) => {
                    
                    if(e.count && e.count > 1){
                        navigate(`../samling/${e.batch}`)
                    }else{
                        if(e.companies && e.companies.length === 1){
                            

                            if(e.parent){
                                navigate(`/${domain.hash}/${e.companies[0].hash}/oppgaver/${e.parent.id}/${e.id}`)
                            }else{
                                navigate(`/${domain.hash}/${e.companies[0].hash}/oppgaver/${e.id}`)
                            }
                        }else{
                            if(e.parent){
                                navigate(`../${e.parent.id}/${e.id}`)
                            }else{
                                navigate(`../${e.id}`)
                            }
                        }
                        
                    }
                    
                }}
                customSort={(rows) => {
                    if(!rows) return false
                    return rows.sort((a, b) => {
                        return moment(b.archivedon).format('X') - moment(a.archivedon).format('X')
                    })
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                        keys: ['name', 'companies', 'parent'],
                        display: (e) => {
                            
                            return (
                                <div>
                                    {
                                        e.parent ? 
                                        <>
                                            <span className="d-block">{e.parent.name}</span>
                                            <div className="row gx-2 ps-2">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'turn-down-right']} />
                                                </div>
                                                <div className="col-auto">
                                                    <span className="bold d-block">{e.name}</span>
                                                </div>
                                            </div>
                                        </>
                                        : <span className="bold d-block">{e.name}</span>
                                    }
                                    {
                                        e.companies && e.companies.length && e.companies.map((company) => {
                                            return <span className="hint-text">{company.name}</span>
                                        })
                                    }
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                        keys: ['deadline', 'subtasks', 'lastSubmission', 'archivedon', 'isSubmission', 'submissions'],
                        display: (e) => {
                            if(e.submissions && e.submissions.length === 1 && e.archivedon){
                                return (
                                    <div>Oppgaven er utført og arkivert</div>
                                )
                            }
                            if(e.isSubmission){
                                return (
                                    <div className="row gx-1">
                                        <div className="col-auto">
                                            {e.submissions.length} utførte oppgaver
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <FontAwesomeIcon icon={['fas', 'check']} className="text-primary" />
                                    </div>
                                    <div className="col-auto">
                                        <span className={"bold"}>Utført og arkivert {formatDate(moment(e.archivedon))}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                        keys: ['priority', 'form', 'subtasks'],
                        display: (e) => {
                            return (
                                <div className="row gx-1">
                                    {
                                        e.priority ? (
                                            <div className="col-auto">
                                                <Tooltip content="Prioritert oppgave" placement="bottom">
                                                    <div className="bordered-color danger py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        e.form ? (
                                            <div className="col-auto">
                                                <Tooltip content="Skjema" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        e.subtasks && e.subtasks.length > 0 ? (
                                            <div className="col-auto">
                                                <Tooltip content="Underoppgaver" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                    
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                        keys: ['responsibles', 'subtasks', 'archivedon'],
                        display: (e) => {                                    
                            return e.archivedon ? <div>Arkivkert {formatDate(moment(e.archivedon), 'diff', true)}</div> : ''
                        }
                    },
                    {
                        grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                        keys: ['pinned'],
                        display: (e) => {
                            return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                        }
                    }      
                ]}
            />
        </>
    )
}

function Navigation(){
    const { activeTasks, archivedTasks } = useContext(Context)
    const { hasPermission } = useContext(DomainContext)

    const [drawerOpen, setDrawerOpen] = useState(false)

    const NavItem = ({ children, link}) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        
        return (
            <li><Link to={link} className={match ? 'active' : ''}>{children}</Link></li>
        )
    }

    return (
        <div className="p-md-3 p-2">
            <div className="p-md-4 p-3 box">
                <div className="row">
                    <div className="col">
                        <h3 className="bold mb-4">Alle oppgaver</h3>
                    </div>
                    { hasPermission(['createTask', 'adminTask']) ?
                        <div className="col-auto">
                            <button type="button" className="btn btn-primary" onClick={() => setDrawerOpen(true)}><FontAwesomeIcon icon={['fal', 'plus']} /> Ny oppgave</button>
                        </div>
                    : null }
                </div>
                <div className="row">
                    <div className="col">
                        <div className="sub-nav-container">
                            <ul className="sub-nav">
                                <NavItem link="">Aktive ({activeTasks ? activeTasks.length : 0})</NavItem>
                                <NavItem link="arkiv">Utførte ({archivedTasks ? archivedTasks.length : 0})</NavItem>
                            </ul>
                        </div>
                    </div>
                </div>
                <Outlet />
            </div>
            <Drawer open={drawerOpen} setOpen={setDrawerOpen}>
                <NewTask />
            </Drawer>
        </div>
    )
}

function NewTask(){

    const { setOpen } = useContext(DrawerContext)
    
    const [useTemplate, setUseTemplate] = useState(false)

    if(useTemplate){
        return <TaskTemplates back={setUseTemplate} />
    }

    return (
        <div className="p-3">
            <div className="row justify-content-between mb-4">
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <div className="row gx-2">
                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'arrow-left']} /></div>
                            <div className="col-auto">Oppgaver</div>
                        </div>
                    </button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'xmark']} size="lg" />
                    </button>
                </div>
            </div>
            <div className="">
                <h3 className="bold mb-3">Ny oppgave</h3>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="h-100 bg-ultra-light w-100 justify-content-center d-flex">
                            <Link to="ny" className="text-center d-flex flex-column align-items-center py-5 w-100 box mega-btn  px-5">
                                <div className="mb-3 circle-icon" style={{width: 70, height: 70, backgroundColor: 'var(--success-tint)', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={['far', 'pencil']} className="text-primary" size="lg" />
                                </div>
                                <h5 className="bold mb-2">Lag selv</h5>
                                <p>Lag en oppgavebeskrivelse og velg ansvarlige selv.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="h-100 bg-ultra-light w-100 justify-content-center d-flex">
                            <button type="button" onClick={() => setUseTemplate(true)} className="text-center d-flex flex-column align-items-center py-5 w-100 box mega-btn  px-5">
                                <div className="mb-3 circle-icon" style={{width: 70, height: 70, backgroundColor: 'var(--success-tint)', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={['far', 'file-check']} className="text-primary" size="lg" />
                                </div>
                                <h5 className="bold mb-2">Bruk en mal</h5>
                                <p>Bruk en av de predefinerte oppgavemalene. Du vil få mulighet til å redigere detaljene før du lagrer og sender oppgaven.</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TaskTemplates({ back }){
    const { setOpen } = useContext(DrawerContext)
    const { apiCall } = useContext(AuthContext)

    const [templates, setTemplates] = useState(null)
    useEffect(() => {
        getTemplateTasks()
    }, [])

    const getTemplateTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-template-tasks'});
        console.log(data)
        if(data.status === 1){
            setTemplates(data.data)
        }
    }
    
    const TemplateItem = ({}) => {
        return (
            <p>Mal</p>
        )
    }

    return (
        <div className="p-3">
            <div className="row justify-content-between mb-4">
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => back(false)}>
                        <div className="row gx-2">
                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'arrow-left']} /></div>
                            <div className="col-auto">Ny oppgave</div>
                        </div>
                    </button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'xmark']} size="lg" />
                    </button>
                </div>
            </div>
            <div className="p-3">
                {
                    !templates ? <p>Laster</p> : 
                     
                    <Table
                        gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "2fr 1fr 2fr 1fr"]}
                        gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                        perPage={15}
                        rows={templates}
                        showResult
                        unit="oppgaver"
                        onRowClick={(e) => {
                            
                            
                        }}
                        columns={[
                            {
                                grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                                keys: ['name'],
                                display: (e) => {
                                    return <span className="bold d-block taskname">{e.name}</span>
                                }
                            },
                            {
                                grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                                keys: ['deadline', 'subtasks', 'lastSubmission'],
                                display: (e) => {
                                    
                                    return (
                                        <div style={{lineHeight: 1}}>
                                            
                                            <small>
                                                <FontAwesomeIcon size="sm" icon={['fal', 'repeat']} style={{marginRight: 5}} />
                                                <i>{getDeadlineType({deadline: e.deadline})}</i>
                                            </small>   
                                        </div>
                                    ) 
                                }
                            },
                            {
                                grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                                keys: ['priority', 'form', 'subtasks'],
                                display: (e) => {
                                    return (
                                        <div className="row gx-1">
                                            {
                                                e.priority ? (
                                                    <div className="col-auto">
                                                        <Tooltip content="Prioritert oppgave" placement="bottom">
                                                            <div className="bordered-color danger py-1 px-2">
                                                                <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                e.form ? (
                                                    <div className="col-auto">
                                                        <Tooltip content="Skjema" placement="bottom">
                                                            <div className="bordered-color success py-1 px-2">
                                                                <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                e.subtasks && e.subtasks.length > 0 ? (
                                                    <div className="col-auto">
                                                        <Tooltip content="Underoppgaver" placement="bottom">
                                                            <div className="bordered-color success py-1 px-2">
                                                                <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                ) : null
                                            }
                            
                                        </div>
                                    )
                                }
                            },
                            {
                                grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                                keys: ['id'],
                                display: (e) => {
                                    return (<Link to={`ny?mal=${e.id}`} className="btn btn-primary">Bruk mal</Link>)
                                }
                            }      
                        ]}
                        ifEmpty={
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex flex-column align-items-center py-5">
                                    <FontAwesomeIcon  size="5x" icon={['fad', 'list-check']} className="text-primary" />
                                    <div className="row py-3">
                                        <div className="col-auto">
                                            <h5>Du har ingen oppgaver enda</h5>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        }
                    />
                    
                }
            </div>
        </div>
    )
}
