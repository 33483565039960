import React from "react"
export default function TaskSubmissionDetails(){
    return (
        <div className="px-3">
            <div className="box p-4">
                <h4 className="bold">Oppgaveutførelse</h4>
                <p>Kommer..</p>
            </div>
        </div>
        
    )
}