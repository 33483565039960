import React from 'react'
import { useState } from "react"
import { usePopper } from 'react-popper';
import { CSSTransition } from "react-transition-group"
import { Portal } from "./Portal";
/* eslint-disabled*/
export default function Tooltip({ content, placement, children}){

    const [show, setShow] = useState(false)

    const [referenceElement, setReferenceElement] = useState()
    const [popperElement, setPopperElement] = useState()

    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                  },
            }
        ]
    })

    return (
        
        <div 
            className="tooltip-container position-relative" 
            onTouchMove={() => setShow(true)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <Portal className="person-picker-portal">
                <CSSTransition in={show} timeout={250} classNames="tooltip" unmountOnExit>
                    <div 
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                        className="popper"
                    >
                    <div className={"tooltipp"}>{content}</div>
                    </div>
                </CSSTransition>
            </Portal>
            <div ref={setReferenceElement}>
                {children}
            </div>
        </div>
        
    )
}