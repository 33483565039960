/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState, useContext, useMemo, useCallback, useRef, useEffect, createContext, createRef } from "react";
import { Link, Outlet, useLocation, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import ContextMenu, { ContextMenuItem } from "../../../components/ContextMenu";
import FileUpload from "../../../components/FileUpload";
import Modal from "../../../components/Modal";
import PersonPicker from "../../../components/PersonPicker";
import { AuthContext } from "../../../providers/AuthProvider";
import { CompanyContext } from "../../../providers/CompanyProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import * as yup from 'yup'
import { useFormik } from "formik";
import Input from "../../../components/Input";
import DraftWysiwyg from "../../../components/DraftWysiwyg";
import { formatDate, rteMarkup } from "../../../components/Functions";
import CheckBox from "../../../components/CheckBox";
import BoxLoading from "../../../components/BoxLoading";
import UserPhoto from "../../../components/UserPhoto";
import Drawer from "../../../components/Drawer";
import ReactCodeInput from "react-code-input";

const DocumentContext = createContext({
    folder: null,
    areas: null,
    data: null,
})

const DocumentProvider = ({children}) => {

    const { apiCall, user, domain, company } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const [areas, setAreas] = useState(null)
    const [folder, setFolder] = useState(null)
    const [currentFolder, setCurrentFolder] = useState(null)
    const [path, setPath] = useState([])
    const [crumb, setCrumb] = useState([])

    const params = useParams()
    const navigate = useNavigate()

    const loadAreas = async () => {
        const { data } = await apiCall({action: 'load-company-documents'})
        
        if(data.status === 1){
            setAreas(data.data)
        }
    }

    const deleteDocument = async () => {

        
        const { data } = await apiCall({
            action: 'delete-company-document',
            document: folder.id
        })


        notify(data.status, data.title, data.message)
        if(data.status === 1){
            await loadAreas()
            navigate(`/${domain.hash}/${company.hash}/dokumenter`)
        }
        
    }

    useEffect(() => {
        loadAreas()
    }, [])

    useEffect(() => {
        
        if(areas && params.folder){
            const folder = areas.filter(t => t.alias === params.folder)[0]
            if(folder){
                setFolder(folder)
            }
            
        }
    }, [params, areas])

    useEffect(() => {
        if(folder){
            changeFolder()
        }

        if(!params.folder) setFolder(null)
    }, [params, folder])

    useEffect(() => {
        console.log('Folder changed', folder)
    }, [folder])

    const newDocument = async (name, members, teams) => {
        
        const { data } = await apiCall({
            action: 'new-company-document',
            name: name,
            members: members,
            teams: teams
        })

        notify(data.status, data.title, data.message)
        if(data.status === 1){
            await loadAreas()
            navigate(data.data.alias)
        }
    }

    const newArticle = async (values) => {
        
        const { data } = await apiCall({
            action: 'new-company-article',
            folder: folder,
            currentFolder: currentFolder,
            ...values
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            const temp = folder.documents
            const article = {
                article: data.data.id,
                name: data.data.title,
                alias: data.data.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase(),
                type: 'article',
                createdby: {
                    id: user.id,
                    name: user.fullname,
                    ptoto: user.photo
                },
                createdon: moment().format('YYYY-MM-DD HH:MM')
            }

            if(path.length === 0){
                temp.items.push(article)
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }

            if(path.length === 1){
                const index = temp.items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].items.push(article)
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }

            if(path.length === 2){
                const index = temp.items.findIndex(a => a.alias === path[0])
                const secondIndex = temp.items[index].items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].items[secondIndex].items.push(article)
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }

            if(path.length === 3){
                const index =       temp.items.findIndex(a => a.alias === path[0])
                const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
                const thirdIndex =  temp.items[index].items[secondIndex].items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].items[secondIndex].items[thirdIndex].items.push(article)
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }

        }
    }

    const editArticle = async (values) => {
        const { data } = await apiCall({
            action: 'edit-company-article',
            folder: folder,
            currentFolder: currentFolder,
            ...values
        })

        if(data.status === 1){

            const temp = folder.documents

            if(path.length === 1){
                const index = temp.items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].name = values.title
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                
                return true
            }

            if(path.length === 2){
                const index = temp.items.findIndex(a => a.alias === path[0])
                const secondIndex = temp.items[index].items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].items[secondIndex].name = values.title
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }

            if(path.length === 3){
                const index =       temp.items.findIndex(a => a.alias === path[0])
                const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
                const thirdIndex =  temp.items[index].items[secondIndex].items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].items[secondIndex].items[thirdIndex].name = values.title
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }

            if(path.length === 4){
                const index =       temp.items.findIndex(a => a.alias === path[0])
                const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
                const thirdIndex =  temp.items[index].items[secondIndex].items.findIndex(a => a.alias === path[2])
                const fourthIndex = temp.items[index].items[secondIndex].items[thirdIndex].items.findIndex(a => a.alias === currentFolder.alias)
                temp.items[index].items[secondIndex].items[thirdIndex].items[fourthIndex].name = values.title
                setFolder({
                    ...folder,
                    items: temp
                })
                save()
                return true
            }
        }

        notify(data.status, data.title, data.message)
    }

    const changeFolder = () => {
        let temp = folder.documents
        let paths = []
        let crumbs = []

        if(params.folder1){
            const f = temp.items.filter(f => f.alias === params.folder1)
            if(f.length){
                paths.push(f[0].alias)
                crumbs.push(f[0].name)
                temp = f[0]
            }
        }

        if(params.folder2){
            const f = temp.items.filter(f => f.alias === params.folder2)
            if(f.length){
                paths.push(f[0].alias)
                crumbs.push(f[0].name)
                temp = f[0]
            }else{
                //navigate(-1)
            }
        }

        if(params.folder3){
            const f = temp.items.filter(f => f.alias === params.folder3)
            if(f.length){
                paths.push(f[0].alias)
                crumbs.push(f[0].name)
                temp = f[0]
            }else{
                //navigate(-1)
            }
        }

        if(params.folder4){
            const f = temp.items.filter(f => f.alias === params.folder4)
            if(f.length){
                paths.push(f[0].alias)
                crumbs.push(f[0].name)
                temp = f[0]
            }
        }

        setCrumb(crumbs)
        setPath(paths)
        setCurrentFolder(temp)
    }

    const removeMember = async (id) => {
        
        const { data } = await apiCall({
            action: 'remove-document-member',
            user: id,
            document: folder.id
        })

        if(data.status === 1){
            await loadAreas()
            return true
        } 
        return false
    }

    const addMember = async (id) => {
        
        const { data } = await apiCall({
            action: 'add-document-member',
            user: id,
            document: folder.id
        })

        if(data.status === 1){
            await loadAreas()
            return true
        } 
        return false
    }

    const addRemoveTeam = async (id) => {
        const { data } = await apiCall({
            action: 'update-document-team',
            team: id,
            document: folder.id
        })
        if(data.status === 1){
            await loadAreas()
        } 
        return data
    } 

    const createFolder = async (name) => {
        if(name.length === 0){
            notify(2, 'Oops!', 'Mappen må ha et navn')
            return false
        }

        const temp = folder.documents
        const newFolder = {
            name: name,
            alias: name.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase(),
            type: 'folder',
            items: [],
            createdby: {
                id: user.id,
                name: user.fullname,
                ptoto: user.photo
            },
            createdon: moment().format('YYYY-MM-DD HH:MM')
        }

        if(path.length === 0){
            temp.items.push(newFolder)
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return true
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === currentFolder.alias)
            temp.items[index].items.push(newFolder)
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return true
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === currentFolder.alias)
            temp.items[index].items[secondIndex].items.push(newFolder)
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return true
        }
        
        
    }

    const createFiles = (files) => {
        let fileArray = []
        files.map((file) => {
            fileArray.push({
                name: file.file_name,
                type: 'file',
                file_type: file.file_type,
                attachment: file.id,
                createdby: {
                    id: user.id,
                    name: user.fullname,
                    ptoto: user.photo
                },
                createdon: moment().format('YYYY-MM-DD HH:MM')
            })
        })

        const temp = folder.documents

        if(path.length === 0){
            fileArray.map((f) => {
                temp.items = [...temp.items, f]
            })
            
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === currentFolder.alias)
            fileArray.map((f) => {
                temp.items[index].items  = [...temp.items[index].items, f]
            })
            
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === currentFolder.alias)
            //temp.items[index].items[secondIndex].items = [...temp.items[index].items[secondIndex].items, fileArray]

            fileArray.map((f) => {
                temp.items[index].items[secondIndex].items  = [...temp.items[index].items[secondIndex].items, f]
            })
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return
        }

        if(path.length === 3){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex =  temp.items[index].items[secondIndex].items.findIndex(a => a.alias === currentFolder.alias)
            //temp.items[index].items[secondIndex].items = [...temp.items[index].items[secondIndex].items, fileArray]

            fileArray.map((f) => {
                temp.items[index].items[secondIndex].items[thirdIndex].items  = [...temp.items[index].items[secondIndex].items[thirdIndex].items, f]
            })
            setFolder({
                ...folder,
                items: temp
            })
            changeFolder()
            save()
            return
        }
    }

    const editName = async (newName, item) => {
        
        const temp = folder.documents

        if(path.length === 0){
            const index = temp.items.findIndex(a => a.alias === item.alias)
            if(temp.items[index]){
                temp.items[index].name = newName
                temp.items[index].alias = newName.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setFolder({
                    ...folder,
                    temp
                })
            }
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === item.alias)
            if(temp.items[index].items[secondIndex]){
                temp.items[index].items[secondIndex].name = newName
                temp.items[index].items[secondIndex].alias = newName.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setFolder({
                    ...folder,
                    temp
                })
            }
            
            
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === item.alias)
            
            if(temp.items[index].items[secondIndex].items[thirdIndex]){
                temp.items[index].items[secondIndex].items[thirdIndex].name = newName
                temp.items[index].items[secondIndex].items[thirdIndex].alias = newName.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setFolder({
                    ...folder,
                    temp
                })
            }
        }

        changeFolder()
        const status = await save()
        return status

        
    }

    const deleteFile = async (item) => {

        
        
        const temp = folder.documents

        console.log(temp.items)
        if(path.length === 0){
            const index = temp.items.findIndex(a => a.alias === item.alias)
            
            if(temp.items[index]){
                temp.items.splice(index, 1)
                setFolder({
                    ...folder,
                    temp
                })
            }
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === item.alias)
            if(temp.items[index].items[secondIndex]){
                temp.items[index].items.splice(secondIndex, 1)
                setFolder({
                    ...folder,
                    temp
                    
                })
            }
            
            
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === item.alias)
            
            if(temp.items[index].items[secondIndex].items[thirdIndex]){
                temp.items[index].items[secondIndex].items.splice(thirdIndex, 1)
                setFolder({
                    ...folder,
                    temp
                })
            }
        }

        changeFolder()
        const status = await save()
        return status

        
    }


    const save = async () => {
        const { data } = await apiCall({
            action: 'save-company-document',
            data: folder
        })

        return data.status === 1
    }

    return (
        <DocumentContext.Provider value={{
            folder,
            areas,
            currentFolder,
            path,
            crumb,
            removeMember,
            addMember,
            createFolder,
            createFiles,
            editName,
            deleteFile,
            newDocument,
            newArticle,
            editArticle,
            addRemoveTeam,
            deleteDocument
        }}>
            {children}
        </DocumentContext.Provider>
    )
}

export default function CompanyDocuments(){
    return (
        <DocumentProvider>
            <DocumentContainer />
        </DocumentProvider>
    )
}

function DocumentContainer(){
    moment.locale('nb')
    const { areas, folder } = useContext(DocumentContext)
    const { hasCompanyPermission } = useContext(CompanyContext)
    const [modalOpen, setModalOpen] = useState(false)


    return (
        <div className="p-md-3 p-2 d-flex flex-column flex-grow-1 h-100">
            <div className="box flex-grow-1 d-flex flex-column">
                <div className="flex-grow-1 main-content">
                    <div className="row gx-0 h-100">
                        <div className={`col-xl-3 col-lg-4 h-100 ${!folder ? "channel-sidebar-open" : "channel-sidebar-closed"}`}>
                            <div className={`channel-sidebar w-100 h-100 ${folder ? "channel-sidebar-closed" : "channel-sidebar-open"}`}>
                                
                                <div className="channel-header p-3 my-1">
                                    <h6 className="bold mb-0">Dokumentområder</h6>
                                </div>
                                <div className="channel-sidebar-items scrollinside">
                                {
                                    areas && areas.map((item, key) => {
                                        return (
                                            <DocumentsNav 
                                                key={key} 
                                                index={key}
                                                {...item} />
                                        )
                                    })
                                }
                                
                                </div>
                                {
                                    hasCompanyPermission('adminDocument') ? 
                                
                                    <div className="channel-header px-2 pt-3 pb-3 border-top">
                                        <button className="btn btn-link text-primary" onClick={() => setModalOpen(true)}>
                                            <div className="row gx-0 align-items-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon fixedWidth icon={['fal', 'plus']}  />
                                                </div>
                                                <div className="col">
                                                    Legg til område
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                : null }
                            </div>
                        </div>
                        <div className="col">
                            {folder ? <Outlet /> : 
                                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                    <FontAwesomeIcon size="3x" className="mb-3 text-primary"  icon={['fad', 'folder']} />
                                    <div className="text-center">
                                        <h2 className="bold">Velg et dokumentområde</h2>
                                        <p>Du må velge et dokumentområde i venstre kolonne</p>
                                        {
                                        hasCompanyPermission('adminDocument') ? 
                                        <button className="btn btn-primary"  onClick={() => setModalOpen(true)}>+ Legg til nytt område</button>
                                        : null }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={modalOpen} setOpen={setModalOpen} containerStyle={{width: 400}}>
                <CreateNewDirectory setOpen={setModalOpen} />
            </Modal>
        </div>
    )
}

export function CompanyDocumentFolder(){
    const { folder, currentFolder, crumb, path, addMember, removeMember, deleteDocument } = useContext(DocumentContext)
    const { companyUsers, consultants, companyTeams, hasCompanyPermission } = useContext(CompanyContext)
    const { screenSize } = useContext(ConfigContext)
    const { domain, company } = useContext(AuthContext)

    const navigate = useNavigate()

    const [ responsibles, setResponsibles ] = useState(folder.members)
    const [teams, setTeams] = useState(folder.teams)
    const [folderModalOpen, setFolderModalOpen] = useState(false)
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const deleteDocumentClick = async () => {
        deleteDocument()
    }

    useEffect(() => {
        setResponsibles(folder.members)
    }, [folder])

    const updateResponsible = async (item) => {
        if(responsibles.filter(e => e.id === item.id).length > 0){
            const removed = responsibles.filter(e => e.id !== item.id)
            const status = await removeMember(item.id)
            if(status){
                setResponsibles(removed)
            }
        }else{
            
            const added = await addMember(item.id)
            if(added){
                setResponsibles([...responsibles, item])
            }
        }
    }

    const updateTeam = async (item) => {
        const status = await addRemoveTeam(item.id)

        if(status.status === 1){
            if(status.data === 'added'){
                setTeams([...teams, item])
            }

            if(status.data === 'removed'){
                const removed = teams.filter(e => e.id !== item.id)
                setTeams(removed)
            }
        }
    }

    const Crumb = ({ name }) => {
        return (
            <>
                <div className="col-auto px-2"><i><FontAwesomeIcon icon={["fal", "chevron-right"]} /></i></div>
                <div className="col-auto"><i>{name}</i></div>
            </>
        )
    }

    const renderDocuments = () => {

        const folders = currentFolder.items.filter(f => f.type === 'folder').sort((a,b) => {return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1})
        const files = currentFolder.items.filter(f => f.type === 'file').sort((a,b) => {return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1})
        const articles = currentFolder.items.filter(f => f.type === 'article').sort((a,b) => {return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1})

        let folderMarkup = folders.map((item,key) => {
            return <Row key={key} {...item} />
        })
        let fileMarkup = files.map((item,key) => {
            return <Row key={key} {...item} />
        })
        
        let articleMarkup = articles.map((item,key) => {
            return <Row key={key} {...item} />
        })

        return (
            <>
                {folderMarkup}
                {articleMarkup}
                {fileMarkup}
            </>
        )
    }

    if(currentFolder && currentFolder.type === 'article'){
        return (
            <ViewArticle />
        )
    }


    return (
        <div className="p-md-4 p-0 d-flex flex-column h-100">
            <div className="col-auto p-md-0 p-3">
                {
                    screenSize === 2 ? 
                    <div className="mb-2">
                        <Link to={`/${domain.hash}/${company.hash}/dokumenter`} className="btn btn-link">
                            <div className="row gx-2">
                                <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                                <div className="col-auto">Tilbake</div>
                            </div>
                        </Link>
                    </div> : null
                }
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <h3 className="bold">{folder.name}</h3>
                    </div>
                    <div className="col-auto">
                        <PersonPicker
                            items={[...companyUsers, ...consultants]}
                            teams={companyTeams}
                            selected={responsibles}
                            setSelected={(val) => updateResponsible(val)}
                            selectedTeams={teams}
                            setSelectedTeams={(val) => updateTeam(val)}
                            handleManually
                            disableSearch
                            disabled={!hasCompanyPermission('adminDocument')}
                        /> 
                    </div>
                </div>

                {
                    hasCompanyPermission('adminDocument') ? 
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <div className="row py-md-3 py-2 gx-md-2 gx-1">
                                    <div className="col-md-auto col-12 mb-1">
                                        <button className="btn btn-success w-100" onClick={() => setFileUploadModalOpen(true) }>
                                            <div className="row gx-2 justify-content-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'upload']} />
                                                </div>
                                                <div className="col-auto">
                                                    Last opp
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="col-md-auto col-6 mb-1">
                                        <button className="btn btn-border w-100" onClick={() => setFolderModalOpen(true)}>
                                            <div className="row gx-2 justify-content-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'folder-plus']} />
                                                </div>
                                                <div className="col-auto">
                                                    Ny mappe
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="col-md-auto col-6 mb-1">
                                        <button className="btn btn-border w-100" onClick={() => navigate(`../${folder.alias}/${path.length >= 1 ? path.join('/') + '/' : ''}ny-artikkel`)}>
                                            <div className="row gx-2 justify-content-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'file-plus']} />
                                                </div>
                                                <div className="col-auto">
                                                    Opprett artikkel
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <button className="btn text-danger btn-border" onClick={() => setDeleteModalOpen(true)}>
                                            <div className="row gx-2">
                                                <div className="col-auto">Slett</div>
                                                <div className="col-auto"><FontAwesomeIcon icon={['far', 'trash']} /></div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : null
                 }
                
                

                <div className="row gx-1 mb-3">
                    <div className="col-auto"><i>Hovedmappe</i></div>
                    {
                        crumb.map((name, key) => {
                            return <Crumb key={key} name={name} />
                        })
                    }
                </div>
                {currentFolder && currentFolder.alias && currentFolder.alias !== "root" ?
                    <div className="row">
                        <div className="col">
                            <button className="btn-up w-100" onClick={() => navigate(-1)}>
                                <FontAwesomeIcon className="me-2" icon={['fal', 'arrow-turn-up']} size="lg" /> <span>Gå opp et nivå</span>
                            </button>
                        </div>
                    </div> : null
                }
            </div>
            <div className="files-container flex-grow-1">
            {
                    
                    currentFolder && currentFolder.items && currentFolder.items.length? 
                        renderDocuments()
                    : <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                        <FontAwesomeIcon size="3x" className="mb-3 text-primary"  icon={['fad', 'folder-plus']} />
                        <div className="text-center">
                            <h2 className="bold">Her var det tomt!</h2>
                            <p>Denne mappen er tom. Her kan du laste opp filer{path.length < 3 ? 'eller lage nye mapper' : null}.</p>
                            {
                                hasCompanyPermission('adminDocument') ? 

                                <div className="row py-3 justify-content-center">
                                    <div className="col-auto">
                                        <button className="btn btn-success" onClick={() => setFileUploadModalOpen(true)}>
                                            <div className="row gx-2">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'upload']} />
                                                </div>
                                                <div className="col-auto">
                                                    Last opp fil
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    {path.length < 3 ? 
                                    <div className="col-auto">
                                        <button className="btn btn-border" onClick={() => setFolderModalOpen(true)}>
                                            <div className="row gx-2">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'folder-plus']} />
                                                </div>
                                                <div className="col-auto">
                                                    Lag ny mappe
                                                </div>
                                            </div>
                                        </button>
                                    </div> : null }
                                </div>

                            : null
                            }
                            
                        </div>
                    </div>
            }
                
                
            </div>
            <Modal open={folderModalOpen} setOpen={setFolderModalOpen} containerStyle={{width: 400}}>
                <CreateNewFolder setOpen={setFolderModalOpen} />
            </Modal>
            <Modal open={fileUploadModalOpen} setOpen={setFileUploadModalOpen} containerStyle={{width: 500}}>
                <FileUploadModal setOpen={setFileUploadModalOpen} />
            </Modal>
            <Modal setOpen={setDeleteModalOpen} open={deleteModalOpen}>
                <div className="p-4 bg-white">
                    <h3>Slette?</h3>
                    <p>Er du sikker på at du vil slette dokumentområdet? Alle filer og artikler vil forsvinne.</p>
                    <div className="row justify-content-end">
                        <div className="col-auto"><button className="btn btn-danger" onClick={() => deleteDocumentClick()}>Slett dokumentområde</button></div>
                        <div className="col-auto"><button className="btn btn-border" onClick={() => setDeleteModalOpen(false)}>Nei</button></div>
                    </div>
                </div>
            </Modal>
        </div>
        
    )
}

function Row( props ){
    
    const { name, type, alias, createdby, createdon, attachment } = props

    const { path, folder, editName, deleteFile } = useContext(DocumentContext)
    const { downloadAttachment } = useContext(AuthContext)
    const { hasCompanyPermission } = useContext(CompanyContext)

    const [modalOpen, setModalOpen] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    
    const download = async () => {
        downloadAttachment(attachment, name)
    }

    const File = () => {
        const [newName, setNewName] = useState(name)
        const [editMode, setEditMode] = useState(false)
        
        const ref = createRef()

        useEffect(() => {
            if(ref.current){
                ref.current.focus()
            }
        }, [ref, editMode])

        const saveNewName = async () => {
            editName(newName, props)
            setEditMode(false)
        }

        return (
            <div className="file hover-primary border-bottom pointer">
            <div className="row align-items-center gx-0">
                <div className="col-auto">
                    <div className="p-md-3 p-2">
                        <FontAwesomeIcon className="text-primary" icon={['fad', 'file']} size="2x" fixedWidth />
                    </div>
                </div>
                {
                    editMode ? 
                    <div className="col">
                        <div className="py-md-3 py-2">
                            <div className="row align-items-center gx-1">
                                <div className="col-auto">
                                    <input ref={ref} type="text" value={newName} onChange={(e) => setNewName(e.target.value)} className="form-control  py-1 py-md-2" />
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-primary px-md-3 px-2" onClick={() => saveNewName()}>
                                        <FontAwesomeIcon icon={['fal', 'check']} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col">
                        <div className="py-md-3 py-2">
                            {name}
                        </div>
                    </div>
                }
                {
                    createdby ? 
                        <div className="col-auto d-none d-md-block">
                            <i>{createdby.name}</i>
                        </div>
                : null }
                {
                    createdon ? 
                        <div className="col-auto">
                            <div className="p-md-3 p-2">
                                <i>{moment(createdon).format('D.m.y')}</i>
                            </div>
                        </div>
                : null }
                <div className="col-auto">
                        <ContextMenu
                            buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                        >
                            <ContextMenuItem 
                                label="Last ned"
                                icon={['fas', 'arrow-down-to-line']}
                                onClick={() => download()}
                            />
                            {
                                hasCompanyPermission('adminDocument') ? 
                                    <>
                                        <ContextMenuItem 
                                            label="Rediger navn"
                                            icon={['far', 'pencil']}
                                            onClick={() => setEditMode(true)}
                                        />
                                    
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Slett"
                                            icon={['far', 'trash']}
                                            onClick={() => setModalOpen(true)}
                                            colorClass="text-danger"
                                        />
                                    </>
                            : null }
                        </ContextMenu>
                    </div>
            </div>
            <Modal open={modalOpen} setOpen={setModalOpen}>
                <div className="p-3">
                    <h3 className="bold">Slette fil</h3>
                    <p>Er du sikker på at du vil slette <i>{name}</i>?</p>
                    <div className="row">
                        <div className="col">
                            <button onClick={() => deleteFile(props)} className="btn btn-danger w-100">Ja, slett</button>
                        </div>
                        <div className="col">
                            <button onClick={() => setModalOpen(false)} className="btn btn-border w-100">Nei</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
        )
    }

    const Folder = () => {

        const goToFolder = () => {
            if(path.length){
                navigate(`../${folder.alias}/${path.join('/')}/${alias}`)
            }else{
                navigate(alias)
            }
        }

        const [newName, setNewName] = useState(name)
        const [editMode, setEditMode] = useState(false)
        const ref = createRef()

        useEffect(() => {
            if(ref.current){
                ref.current.focus()
            }
        }, [ref, editMode])

        const saveNewName = async () => {
            editName(newName, props)
            setEditMode(false)
        }

        return (
            <div className="file hover-primary border-bottom pointer">
                <div className="row align-items-center gx-0">
                    <div className="col-auto" onClick={() => goToFolder()}>
                        <div className="p-md-3 p-2">
                            <FontAwesomeIcon className="text-primary" icon={['fas', 'folder']} size="2x" fixedWidth />
                        </div>
                    </div>
                    {
                        editMode ? 
                        <div className="col">
                            <div className="py-md-3 py-2">
                                <div className="row align-items-center gx-1">
                                    <div className="col-auto">
                                        <input ref={ref} type="text" value={newName} onChange={(e) => setNewName(e.target.value)} className="form-control py-1 py-md-2" />
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-primary px-md-3 px-2" onClick={() => saveNewName()}>
                                            <FontAwesomeIcon icon={['fal', 'check']} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col" onClick={() => goToFolder()}>
                            <div className="py-3">
                                {name}
                            </div>
                        </div>
                    }
                    {
                        createdby ? 
                            <div className="col-auto d-none d-md-block">
                                 <i>{createdby.name}</i>
                            </div>
                    : null }
                    {
                        createdon ? 
                            <div className="col-auto">
                                <div className="p-md-3 p-2"><i>{moment(createdon).format('D. MMMM y')}</i></div>
                            </div>
                    : null }
                    <div className="col-auto">
                        <ContextMenu
                            buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                        >
                            <ContextMenuItem 
                                label="Åpne"
                                icon={['far', 'folder']}
                                onClick={() => goToFolder()}
                            />
                            <ContextMenuItem 
                                label="Kopier lenke"
                                icon={['far', 'link']}
                                onClick={() => navigator.clipboard.writeText(window.location.href + `/${alias}`)}
                            />
                            {
                                hasCompanyPermission('adminDocument') ? 
                                <>
                                    <ContextMenuItem 
                                        label="Rediger navn"
                                        icon={['far', 'pencil']}
                                        onClick={() => setEditMode(true)}
                                    />
                                    <hr style={{margin: 0}} />
                                    <ContextMenuItem 
                                        label="Slett"
                                        icon={['far', 'trash']}
                                        onClick={() => setModalOpen(true)}
                                        colorClass="text-danger"
                                    />
                                </>
                                : null
                            }
                            
                        </ContextMenu>
                    </div>
                    <div className="col-auto">
                        <div className="p-3">
                            <button className="btn-square-icon" onClick={() => goToFolder()}>
                                <FontAwesomeIcon  size="lg" icon={['far', 'chevron-right']} />
                            </button>
                        </div>
                    </div>
                    
                </div>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                    <div className="p-3">
                        <h3 className="bold">Slette mappe</h3>
                        <p>Er du sikker på at du vil slette <i>{name}</i>?</p>
                        <div className="row">
                            <div className="col">
                                <button onClick={() => deleteFile(props)} className="btn btn-danger w-100">Ja, slett</button>
                            </div>
                            <div className="col">
                                <button onClick={() => setModalOpen(false)} className="btn btn-border w-100">Nei</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    const Article = () => {

        const goToArticle = () => {
            if(path.length){
                navigate(`../${folder.alias}/${path.join('/')}/${alias}`)
            }else{
                navigate(alias)
            }
        }

        const goToSign = () => {
            if(path.length){
                navigate(`../${folder.alias}/${path.join('/')}/${alias}/signeringer`)
            }else{
                navigate(`${alias}/signeringer`)
            }
        }

        const goToArticleEdit = () => {
            if(path.length){
                navigate(`../${folder.alias}/${path.join('/')}/${alias}/rediger`)
            }else{
                navigate(`${alias}/rediger`)
            }
        }

        return (
            <div className="file hover-primary border-bottom pointer">
                <div className="row align-items-center gx-0">
                    <div className="col-auto" onClick={() => goToArticle()}>
                        <div className="p-md-3 p-2">
                            <FontAwesomeIcon className="text-primary" icon={['fad', 'file-lines']} size="2x" fixedWidth />
                        </div>
                    </div>

                    <div className="col" onClick={() => goToArticle()}>
                        <div className="py-md-3 py-2">
                            {name}
                        </div>
                    </div>
                    
                    {
                        createdby ? 
                            <div className="col-auto d-none d-md-block">
                                <i>{createdby.name}</i>
                            </div>
                    : null }
                    {
                        createdon ? 
                            <div className="col-auto">
                                <div className="p-md-3 p-2"><i>{moment(createdon).format('D.m.y')}</i></div>
                            </div>
                    : null }
                    <div className="col-auto">
                            <ContextMenu
                                buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                            >
                                <ContextMenuItem 
                                    label="Se artikkel"
                                    icon={['fas', 'arrow-down-to-line']}
                                    onClick={() => goToArticle()}
                                />
                                {
                                    hasCompanyPermission('adminDocument') ? 
                                    <>
                                        <ContextMenuItem 
                                            label="Signeringer"
                                            icon={['fas', 'file-signature']}
                                            onClick={() => goToSign()}
                                        />
                                        <ContextMenuItem 
                                            label="Rediger"
                                            icon={['far', 'pencil']}
                                            onClick={() => goToArticleEdit()}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Slett"
                                            icon={['far', 'trash']}
                                            onClick={() => setModalOpen(true)}
                                            colorClass="text-danger"
                                        />
                                    </>
                                    : null
                                }
                                
                            </ContextMenu>
                        </div>
                </div>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                    <div className="p-3">
                        <h3 className="bold">Slette fil</h3>
                        <p>Er du sikker på at du vil slette <i>{name}</i>?</p>
                        <div className="row">
                            <div className="col">
                                <button onClick={() => deleteFile(props)} className="btn btn-danger w-100">Ja, slett</button>
                            </div>
                            <div className="col">
                                <button onClick={() => setModalOpen(false)} className="btn btn-border w-100">Nei</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    return type === 'folder' ? <Folder /> : type === 'article' ? <Article /> : <File />
}

function DocumentsNav({ name, icon, index, alias }){
    const navigate = useNavigate()
    const params = useParams()

    let resolved = useResolvedPath(alias);
    let match = useMatch({ path: resolved.pathname, end: false });

    return (
        <div onClick={() => navigate(alias)} className={"channel-sidebar-item" + (match ? ' active' : '')}>
            <div className="row">
                <div className="col">
                    <span>{name}</span>
                </div>
                { icon ? 
                <div className="col-auto">
                    <FontAwesomeIcon fixedWidth icon={['fal', icon]} />
                </div>
                : null}
            </div>
            
        </div>
    )
}

function CreateNewDirectory({ setOpen }){
    const { newDocument } = useContext(DocumentContext)
    const { companyUsers, consultants, companyTeams, hasCompanyPermission } = useContext(CompanyContext)
    const { user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [members, setMembers] = useState([user])
    const [teams, setTeams] = useState([])

    const create = async () => {
        const status = await newDocument(name, members, teams)
        setOpen(false)
        if(status){
            setOpen(false)
        }
    }

    return (
        <div className="py-3 px-md-5">
            <h4 className='bold'>Opprett nytt dokumentområde</h4>
            <form onSubmit={(e) => {
                e.preventDefault()
                create()
            }}>
                <div className="form-group mb-3">
                    <label>Navn</label>
                    <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Navn på dokumentområde" type="text" className="form-control" />
                </div>
                <div className="form-group mb-3">
                    <label>Velg medlemmer</label>
                    <PersonPicker
                        items={[...consultants, ...companyUsers]}
                        teams={companyTeams}
                        selected={members}
                        selectedTeams={teams}
                        setSelected={setMembers}
                        setSelectedTeams={setTeams}
                        disableSearch
                    />
                </div>
                <div className="row gx-2">
                    <div className="col-6">
                        <button type="button" className='btn btn-block btn-border w-100' onClick={() => setOpen(false)}>Avbryt</button>
                    </div>
                    <div className="col-6">
                        <button type="submit" className='btn btn-block btn-success w-100' disabled={(members.length === 0 && teams.length === 0) || name.length === 0 ? 'disabled' : ''}>Lagre</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

function CreateNewFolder({ setOpen }){

    const { createFolder } = useContext(DocumentContext)
    const [name, setName] = useState('')

    const create = async () => {
        const status = await createFolder(name)
        
        if(status){
            setOpen(false)
        }
    }

    const ref = createRef()

    useEffect(() => {
        if(ref && ref.current){
            ref.current.focus()
        }
    }, [])

    return (
        <div className="py-3 px-md-5 px-2">
            <h4 className='bold'>Opprett mappe</h4>
            <form onSubmit={(e) => {
                e.preventDefault()
                create()
            }}>
                <div className="form-group mb-3">
                    <label>Navn på mappe</label>
                    <input ref={ref} value={name} onChange={(e) => setName(e.target.value)} placeholder="Mappenavn" type="text" className="form-control" />
                </div>
            </form>
            <div className="row gx-2">
                <div className="col-6">
                    <button className='btn btn-block btn-border w-100' onClick={() => setOpen(false)}>Avbryt</button>
                </div>
                <div className="col-6">
                    <button className='btn btn-block btn-success w-100' onClick={() => create()}>Lagre</button>
                </div>
            </div>
            
        </div>
    )
}

function FileUploadModal({ setOpen }){
    const { createFiles } = useContext(DocumentContext)
    const [files, setFiles] = useState([])

    const upload = () => {
        createFiles(files)
        setOpen(false)
    }

    return (
        <div className="py-3 px-md-5 px-0">
            <h4 className="bold mb-3">Last opp filer</h4>
            <FileUpload
                files={files}
                setFiles={setFiles}
            />
            <div className="row gx-2 mt-2">
                <div className="col-6">
                    <button className='btn btn-block btn-border w-100' onClick={() => setOpen(false)}>Avbryt</button>
                </div>
                <div className="col-6">
                    <button className='btn btn-block btn-success w-100' onClick={() => upload()}>Last opp</button>
                </div>
            </div>
        </div>
    )
}

export function NewCompanyDocumentFile(){

    const { newArticle } = useContext(DocumentContext)

    const navigate = useNavigate()

    let schema = yup.object().shape({
        title: yup.string().required("Navn på artikkel påkrevd"),
        signable: yup.bool(),
        content: yup.mixed(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            signable: false,
            content: null
        },
        validationSchema: schema,
        onSubmit: async (values) => {           
            
            saveArticle(values)
        },
    });

    const saveArticle = async (values) => {
        
        const status = await newArticle(values)
        if(status){
            
            navigate(-1)
        }
    }
    
    return (
        
            <div className="d-flex flex-column h-100">
                <div className="p-3 p-md-4 flex-grow-1 d-flex flex-column">
                    <button className="btn btn-link" onClick={() => navigate(-1)}>
                        <div className="row gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                            </div>
                            <div className="col-auto">
                                <span>Tilbake</span>
                            </div>
                        </div>
                    </button>
                <form onSubmit={formik.handleSubmit} className="d-flex flex-column flex-grow-1">
                    <div className="row my-4 align-items-center">
                        <div className="col">
                            <h3 className="bold">Opprett ny artikkel</h3>
                        </div>
                        <div className="col-md-auto col-12">
                            <div className="row gx-2">
                                <div className="col-md-auto col-6">
                                    <button type="submit" className="w-100 btn btn-primary px-lg-5">Lagre</button>
                                </div>
                                <div className="col-md-auto col-6">
                                    <button type="button" className="w-100 btn btn-border px-lg-5">Avbryt og lukk</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column">
                        
                        <div className="row align-items-start">
                            <div className="col-lg-7 col-12">
                                <Input
                                    name="title"
                                    label="Navn på artikkel"
                                    type="text"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    touched={formik.touched.title}
                                    helperText={formik.touched.title && formik.errors.title}
                                    placeholder={"Artikkelens navn"}
                                />
                            </div>
                            <div className="col-lg col-12">
                                <div className="form-group">
                                    <label>Må signeres</label>
                                    <CheckBox
                                        name="signable"
                                        label="Artikkelen kan signeres"
                                        value={formik.values.signable}
                                        classes="checkbox-success"
                                        onChange={(e) => formik.setFieldValue("signable", e)}
                                    />
                                </div>
                            </div>
                        </div>
                            <div className="form-group flex-grow-1 d-flex flex-column">
                                <label>Innhold</label>
                                <DraftWysiwyg
                                    value={formik.values.content}
                                    setValue={(e) => formik.setFieldValue("content", e)}
                                    containerClass="flex-grow-1 d-flex flex-column"
                                    includeImage={true}
                                />
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        
    )
}

export function EditCompanyArticle(){

    const { currentFolder, editArticle } = useContext(DocumentContext)
    const { hasCompanyPermission } = useContext(CompanyContext)
    const { apiCall } = useContext(AuthContext)

    const navigate = useNavigate()

    const [article, setArticle] = useState(null)

    useEffect(() => {
        if(currentFolder && currentFolder.article){
            loadArticle()
        }
    }, [currentFolder])

    const loadArticle = async () => {
        const { data } = await apiCall({
            action: 'load-article',
            id: currentFolder.article
        })

        if(data.status === 1){
            setArticle(data.data)
        }
    }
  

    const saveArticle = async (values) => {
        await editArticle(values)
        loadArticle()
        
    }

    const Form = () => {

        let schema = yup.object().shape({
            title: yup.string().required("Navn på artikkel påkrevd"),
            signable: yup.bool(),
            content: yup.mixed(),
        });
    
        const formik = useFormik({
            initialValues: {
                id: article.id,
                title: article.title,
                signable: article.properties?.signable ? article.properties.signable : false,
                content: article.content
            },
            validationSchema: schema,
            onSubmit: async (values) => {           
                saveArticle(values)
            },
        });

        return (
            <form onSubmit={formik.handleSubmit} className="d-flex flex-column flex-grow-1">
                <div className="row my-4 align-items-center">
                    <div className="col">
                        <h3 className="bold">Rediger artikkel</h3>
                    </div>
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary px-lg-5">Lagre</button>
                            </div>
                            <div className="col-auto">
                                <button onClick={() => navigate(-1)} type="button" className="btn btn-border px-lg-5">Avbryt og lukk</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="flex-grow-1 d-flex flex-column">
                    
                <div className="row align-items-start">
                            <div className="col-lg-7 col-12">
                                <Input
                                    name="title"
                                    label="Navn på artikkel"
                                    type="text"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    touched={formik.touched.title}
                                    helperText={formik.touched.title && formik.errors.title}
                                    placeholder={"Artikkelens navn"}
                                />
                            </div>
                            <div className="col-lg col-12">
                                <div className="form-group">
                                    <label>Må signeres</label>
                                    <CheckBox
                                        name="signable"
                                        label="Artikkelen kan signeres"
                                        value={formik.values.signable}
                                        classes="checkbox-success"
                                        onChange={(e) => formik.setFieldValue("signable", e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group flex-grow-1 d-flex flex-column">
                            <label>Innhold</label>
                            <DraftWysiwyg
                                value={formik.values.content}
                                setValue={(e) => formik.setFieldValue("content", e)}
                                containerClass="flex-grow-1 d-flex flex-column"
                                includeImage={true}
                            />
                        </div>
                        
                    </div>
                </form>
        )
    }


    
    return (
        
            <div className="d-flex flex-column h-100">
                <div className="p-3 p-md-4 flex-grow-1 d-flex flex-column">
                    <button className="btn btn-link" onClick={() => navigate(-1)}>
                        <div className="row gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                            </div>
                            <div className="col-auto">
                                <span>Tilbake</span>
                            </div>
                        </div>
                    </button>
                    {
                        article ? (hasCompanyPermission('adminDocument') ? <Form /> : <h3>Ingen tilgang</h3>) : <h3>Laster..</h3>
                    }
                </div>
            </div>
        
    )
}

function ViewArticle(){

    const { currentFolder, path, folder, crumb } = useContext(DocumentContext)
    const { apiCall, user } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { hasCompanyPermission } = useContext(CompanyContext)

    const navigate = useNavigate()

    const [article, setArticle] = useState(null)
    const [signatureDrawerOpen, setSignatureDrawerOpen] = useState(false)

    useEffect(() => {
        if(currentFolder && currentFolder.article){
            loadArticle()
        }
    }, [])

    const loadArticle = async () => {
        const { data } = await apiCall({
            action: 'load-article',
            id: currentFolder.article
        })

        if(data.status === 1){
            setArticle(data.data)
        }
    }

    if(!article) return (
        <h3>Laster..</h3>
    )

    const goToEdit = () => {
        if(path.length){
            navigate(`../${folder.alias}/${path.join('/')}/rediger`)
        }else{
            navigate(`${alias}/rediger`)
        }
    }

    const Crumb = ({ name }) => {
        return (
            <>
                <div className="col-auto px-2"><i><FontAwesomeIcon icon={["fal", "chevron-right"]} /></i></div>
                <div className="col-auto"><i>{name}</i></div>
            </>
        )
    }

    

    const ArticleFooter = () => {

        const [signed, setSigned] = useState(false)
        const [signModalOpen, setSignModalOpen] = useState(false)
        useEffect(() => {
            setSigned(Boolean(article.signatures.filter(signature => signature.requiredBy === user.id).length))
        }, [])

        

        const SignModal = () => {
            const [code, setCode] = useState('')
            const [disabled, setDisabled] = useState(false)

            useEffect(() => {
                if(code.length === 6){
                    signArticle()
                }
            }, [code])

            const signArticle = async () => {
                setDisabled(true)
                const { data } = await apiCall({
                    action: 'sign-article',
                    id: article.id,
                    code: code
                })
        
                notify(data.status, data.title, data.message)
                
                if(data.status === 1) loadArticle()
            }

            return (
                <div className="p-4" style={{maxWidth: 400}}>
                    <h4 className="bold">Signering av artikkel</h4>
                    <p>Ved å signere denne artikkelen bekrefter du at du har <strong>lest og forstått</strong> innholdet.</p>
                    <p>Vennligst verifiser din signatur med to-faktor koden fra din autentiserings-app under.</p>
                    <form className="text-center" onSubmit={(e) => {
                        e.preventDefault()
                        signArticle()
                    }}>
                        <ReactCodeInput
                            type='text' 
                            fields={6} 
                            value={code}
                            onChange={val => setCode(val)}
                            style={{textAlign: 'center'}}
                            autoFocus
                            inputMode="numeric"
                            inputStyle={{random: 0}}
                            className="mb-4"
                            disabled={disabled}
                        />
                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-black-outline w-100" onClick={() => setSignModalOpen(false)}>Avbryt og lukk</button>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="btn btn-primary w-100" disabled={disabled}>Signér</button>
                            </div>
                        </div>
                    
                    </form>
                    
                </div>
            )
        }

        return (
            <div className="article-footer border-top">
                <div className="p-md-3 p-0">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            {
                                article.edited && article.edited.by ? 
                                    <div className="row gx-2 align-items-center">
                                        <div className="col-auto">
                                            <UserPhoto photo={article.edited.by.photo} name={article.edited.by.fullname} size={[50, 40, 40]} />
                                        </div>
                                        <div className="col">
                                            <span className="d-block bold">{article.edited.by.fullname}</span>
                                            <small>Sist redigert {formatDate(article.edited.on)}</small>
                                        </div>
                                    </div>
                                    
                                : (
                                    article.created && article.created.by ?
                                    <div className="row gx-2">
                                        <div className="col-auto">
                                            <UserPhoto photo={article.created.by.photo} name={article.created.by.fullname} size={[50, 40, 40]} />
                                        </div>
                                        <div className="col">
                                            <span className="d-block bold">{article.created.by.fullname}</span>
                                            <small>Opprettet {formatDate(article.created.on)}</small>
                                        </div>
                                    </div> : null
                                )
                            }
                        </div>
                        {
                            article.properties?.signable ? 
                            <div className="col-auto">
                            {
                                signed ? <button className="btn btn-primary" disabled>Signert av deg</button> : null
                            }
                            {
                                folder?.members?.filter(member => member.id === user.id).length && !article.signatures.filter(signature => signature.requiredBy === user.id).length ? 
                                <button onClick={() => setSignModalOpen(true)} type="button" className="btn btn-primary px-5">Signér</button> : null
                            }
                            </div>
                            :null

                        }
                        

                        <Modal open={signModalOpen} setOpen={setSignModalOpen}>
                            <SignModal />
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }

    
    
    return (
        <div className="d-flex flex-column justify-content-between" style={{ minHeight: '100%', maxHeight: '100%', overflowY: 'auto'}}>
            <div className="article-header pt-md-4 px-md-4 p-0">
                <button className="btn btn-link" onClick={() => navigate(-1)}>
                    <div className="row gx-1">
                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                        <div className="col">Tilbake</div>
                    </div>
                </button>
                <div className="row mt-4">
                    <div className="col">
                        <h2 className="bold">{article.title}</h2>
                    </div>
                    <div className="col-auto">
                        { hasCompanyPermission('adminDocument') ?
                        <div className="row gx-1">
                            {
                                article.properties?.signable ? 
                                    <div className="col-auto">
                                        <button type="button" onClick={() => setSignatureDrawerOpen(true)} className="btn-square-icon"><FontAwesomeIcon icon={['far', 'file-signature']} /></button>
                                    </div>
                                : null
                            }
                            
                            <div className="col-auto">
                                <button onClick={() => goToEdit()} className="btn-square-icon text-warning"><FontAwesomeIcon icon={['far', 'pencil']} /></button>
                            </div>
                            <div className="col-auto">
                                <Link to="rediger" className="btn-square-icon text-danger"><FontAwesomeIcon icon={['far', 'trash']} /></Link>
                            </div>
                        </div>
                        : null }
                    </div>
                </div>
                <div className="row gx-1 mb-3">
                    <div className="col-auto"><i>Hovedmappe</i></div>
                    {
                        crumb.map((name, key) => {
                            return <Crumb key={key} name={name} />
                        })
                    }
                </div>
                <hr />
            </div>
            
            <div className="flex-grow-1 scrollinside" style={{overflowY: 'auto'}}>
                <div className="p-md-4 p-0 " dangerouslySetInnerHTML={{__html: rteMarkup(article.content)}}></div>
            </div>
            {
                article.properties?.signable || article.created || article.edited ? <ArticleFooter /> : null
            }
            <Drawer open={signatureDrawerOpen} setOpen={setSignatureDrawerOpen}>
                <CompanyArticleSignatures />
            </Drawer>
        </div>
    )
}

export function CompanyArticleSignatures(){

    const { currentFolder, folder } = useContext(DocumentContext)
    const { apiCall, user } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const navigate = useNavigate()

    const [article, setArticle] = useState(null)
    const [signatures, setSignatures] = useState([])

    useEffect(() => {
        if(currentFolder && currentFolder.article){
            loadArticle()

            
        }
    }, [currentFolder])

    useEffect(() => {
        console.log('Signatures', signatures)
    }, [signatures])

    const loadArticle = async () => {
        const { data } = await apiCall({
            action: 'load-article-signatures',
            id: currentFolder.article
        })
        
        if(data.status === 1){
            setArticle(data.article)
            setSignatures(data.signatures)
        }
    }

    const signArticle = async () => {
        const { data } = await apiCall({
            action: 'sign-article',
            id: currentFolder.article
        })

        notify(data.status, data.title, data.message)
        
        if(data.status === 1) loadArticle()
    }

    if(!article){
        return <BoxLoading />
    }

    if(!article.properties?.signable){
        return (
            <div className="d-flex flex-column h-100">
            <div className="p-3 p-md-4 flex-grow-1 d-flex flex-column">
                <button className="btn btn-link mb-3" onClick={() => navigate(-1)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>

                <h4 className="bold">{article.title}</h4>
                <p>Denne artikkelen kan ikke signeres</p>
                
            </div>
        </div>
        )
    } 

    const Person = ({ id, fullname, photo }) => {

        const [signed, setSigned] = useState(false)

        useEffect(() => {
            setSigned(Boolean(signatures.filter(signature => signature.requiredBy === id).length))
        }, [])

        return (
            <div className="row gx-3 align-items-center mb-2">
                <div className="col-auto">
                    <UserPhoto photo={photo} name={fullname} size={[40, 40, 40]} />
                </div>
                <div className="col">
                    <span>{fullname}</span>
                </div>
                <div className="col-auto">
                    {
                        signed ? <div className="px-5 py-2 bg-success text-white">Signert</div> :
                        <div className="px-5 py-2 bg-grey">Ikke signert</div>
                    }
                    
                </div>
            </div>
        )
    }
  
    return (
        <div className="d-flex flex-column h-100">
            <div className="p-3 p-md-4 flex-grow-1 d-flex flex-column">
                <button className="btn btn-link mb-3" onClick={() => navigate(-1)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>
                <div className="row mb-5">
                    <div className="col">
                        <h2 className="bold">Signering</h2>
                    </div>
                    <div className="col-auto">
                        {
                            folder?.members?.filter(member => member.id === user.id).length && !signatures.filter(signature => signature.requiredBy === user.id).length ? 
                            <button onClick={() => signArticle()} type="button" className="btn btn-primary px-5">Signér</button> : null
                        }
                        
                    </div>
                </div>

                <h4 className="bold">{article.title}</h4>
                {
                    folder?.members?.map((member, key) => {
                        return <Person {...member} key={key} />
                    })
                }
            </div>
        </div>
    )
}