import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BoxLoading({ customText }){
    return (
        <div className="p-2 h-100 d-flex flex-column">
        <div className="box  d-flex flex-column flex-grow-1 align-items-center justify-content-center">
        
            <div className="d-inline-block text-center">
                <FontAwesomeIcon icon={['fad', 'gear']} size="5x" spin className="mb-3" />
                {customText ? <h4 className="text-center fw-bold">{customText}</h4> : null}
            </div>
           
        
        </div>
        </div>
    )
}