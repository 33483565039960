import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"

import { useParams, useNavigate } from "react-router-dom"
import { formatDate, getDeadlineType, getNextDeadline } from "../../../components/Functions"
import ImageStack from "../../../components/ImageStack"
import Table from "../../../components/Table"
import Tooltip from "../../../components/Tooltip"
import { AuthContext } from "../../../providers/AuthProvider"

export default function TaskBatch(){
    const { apiCall, domain } = useContext(AuthContext)
    const params = useParams()
    const navigate = useNavigate()

    const [tasks, setTasks] = useState(null)

    useEffect(() => {
        console.log('Tasks', tasks)
    }, [tasks])

    useEffect(async () => {
        const status = await apiCall({action: 'get-tasks-by-batch', batch: params.batch})
        console.log(status)
        if(status.data.status === 1){
            setTasks(status.data.data)
        }else{
            setTasks([])
        }
    }, [])

    if(tasks === null){
        return (
            <div className="p-3">
                <div className="p-4 box">
                <h3 className="bold mb-4">...</h3>
                </div>
            </div>
        )
    }

    if(tasks.length === 0){
        return (
            <div className="p-3">
                <div className="p-4 box">
                    <h3 className="bold mb-4">Ingen oppgaver her?</h3>
                </div>
            </div>
        )
    }

    return (
        <div className="p-3">
            <div className="p-4 box">
                <h3 className="bold mb-4">{tasks[0].name}</h3>
                <Table
                    gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "repeat(2, 1fr)"]}
                    gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                    perPage={5}
                    rows={tasks}
                    showResult
                    unit="oppgaver"
                    onRowClick={(e) => {
                        console.log(e)
                        navigate(`/${domain.hash}/${e.companies[0].hash}/oppgaver/${e.id}`)
                        
                        
                    }}
                    columns={[
                        { 
                            grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                            keys: ['name', 'companies'],
                            display: (e) => {
                                
                                return (
                                    <div>
                                        <span className="bold d-block">{e.name}</span>
                                        {
                                            e.companies && e.companies.length && e.companies.map((company) => {
                                                return <span className="hint-text">{company.name}</span>
                                            })
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                            keys: ['deadline', 'subtasks', 'archivedon', 'submissions'],
                            display: (e) => {
                                let deadlineClass = ''
                                const deadline = getNextDeadline({deadline: e.deadline, subtasks: e.subtasks, lastSubmission: e.lastSubmission, submissions: e.submissions})
                                
                                if(deadline.daysUntil){
                                    switch(true){
                                        case deadline.daysUntil < 0:
                                            deadlineClass = 'text-danger'
                                            break
                                        case deadline.daysUntil <= 7:
                                            deadlineClass = 'text-warning'
                                            break;
                                    }
                                }

                                if(e.archivedon){
                                    return (
                                        <div style={{lineHeight: 1}}>
                                            <span className={"d-block mb-1 text-primary"}>Utført</span>
                                            <small>
                                                <FontAwesomeIcon size="sm" icon={['fal', 'calendar']} style={{marginRight: 5}} />
                                                <i>{formatDate(e.archivedon)}</i>
                                            </small>   
                                        </div>
                                    )
                                }
                                return (
                                    <div style={{lineHeight: 1}}>
                                        <span className={"d-block mb-1 " + deadlineClass}>{deadline.text}</span>
                                        <small>
                                            <FontAwesomeIcon size="sm" icon={['fal', 'repeat']} style={{marginRight: 5}} />
                                            <i>{getDeadlineType({deadline: e.deadline})}</i>
                                        </small>   
                                    </div>
                                ) 
                            }
                        },
                        {
                            grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                            keys: ['priority', 'form', 'subtasks'],
                            display: (e) => {
                                return (
                                    <div className="row gx-1">
                                        {
                                            e.priority ? (
                                                <div className="col-auto">
                                                    <Tooltip content="Prioritert oppgave" placement="bottom">
                                                        <div className="bordered-color danger py-1 px-2">
                                                            <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            e.form ? (
                                                <div className="col-auto">
                                                    <Tooltip content="Skjema" placement="bottom">
                                                        <div className="bordered-color success py-1 px-2">
                                                            <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            e.subtasks && e.subtasks.length > 0 ? (
                                                <div className="col-auto">
                                                    <Tooltip content="Underoppgaver" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                    </div>
                                                    </Tooltip>
                                                </div>
                                            ) : null
                                        }
                        
                                    </div>
                                )
                            }
                        },
                        {
                            grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                            keys: ['responsibles'],
                            display: (e) => {
                                return (e.responsibles && e.responsibles.length ? <ImageStack users={e.responsibles} /> : <FontAwesomeIcon icon={['fas', 'circle-exclamation']} className="text-danger" />)
                            }
                        },
                        {
                            grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                            keys: ['pinned'],
                            display: (e) => {
                                return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                            }
                        }      
                    ]}
                />
            </div>
        </div>
    )
    
}