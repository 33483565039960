import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AuthContext } from "../providers/AuthProvider";
import { formatDate, useOnClickOutside } from "./Functions";
import UserPhoto from "./UserPhoto";
import moment from 'moment'

export default function MessageCenter(){
    const ref = useRef()
    const [open, setOpen] = useState(false)
    const { messageCenter, toggleMessageCenter, contextMessages } = useContext(AuthContext)

    useEffect(() => {
        setTimeout(() => {
            setOpen(messageCenter)
        }, 50)
    }, [messageCenter])

    useOnClickOutside(ref, () => toggleMessageCenter(false))

    return (
        <CSSTransition in={messageCenter} timeout={250} classNames="notification-center-wrapper" unmountOnExit>
            <div className="notification-center-wrapper">
                <CSSTransition in={open} timeout={500} classNames="notification-center-container" unmountOnExit>
                    <div className="notification-center-container scrollinside p-md-5 p-4" ref={ref}>
                        <div  style={{borderBottom: '1px solid #000'}}>
                            <div className="row pb-2">
                                <div className="col">
                                    <h6 className="bold">Meldingsvarsler</h6>
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link" onClick={() => toggleMessageCenter(false)}>
                                        <FontAwesomeIcon icon={['fas','xmark']} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                contextMessages && contextMessages.length > 0 ? contextMessages.map((item, key) => {
                                    return <Notification key={key} {...item} />
                                }) : <NoNotifications /> 
                                
                            }
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </CSSTransition>
    )
}

function NoNotifications(){
    return (
        <div className="h-100 d-flex flex-column justify-content-center align-items-center pt-5">
            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <FontAwesomeIcon size="2x"   icon={['far', 'bell']} className="text-primary" />
            </div>
            <div className="text-center">
                <h6 className="bold text-primary">Du har ingen meldingsvarsler</h6>
                <p>Her kan du se alle meldingsvarsler du vil få.</p>
            </div>
        </div>
    )
}

function Notification({ id, actions, company, content, createdby, createdon, isRead, domain }){
    const { getNotifications, apiCall, toggleMessageCenter } = useContext(AuthContext)

    const navigate = useNavigate()

    const getContent = () => {
        if(createdby && createdby.fullname) {
            return content.replace('{createdby.fullname}', createdby.fullname)
        }
        return content.replace('{createdby.fullname}', 'Ukjent')
        
    }

    const actionClick = (index) => {
        setRead()
        const action = actions[index];
        let url
        switch(action.class){
            case 'SagaEmployee':
                url = `ansatte/${action.id}`
                break;
            case 'SagaNewTask':
                url = `oppgaver/${action.id}`
                break;
            case 'SagaChannel':
                url = `kanaler/${action.alias}/samtaler`
                break
            case 'SagaChannelConversationArchived':
                url = `kanaler/${action.alias}/arkiv`
        }

        if(company){
            navigate(`/${domain.hash}/${company.hash}/${url}`)
        }else{
            navigate(`/${domain.hash}/${url}`)
        }

        toggleMessageCenter(false)

    }

    const setRead = async () => {
        const status = await apiCall({action: 'set-notification-read', id: id})
        if(status.data.status === 1) getNotifications()
    }

    return (
        <div className="notification-center-item py-3 border-bottom">
            <small className="hint-text bold">{`${formatDate(createdon)}`}</small><small className="hint-text mx-2">kl. {moment(createdon).format('HH:mm')}</small>
            <div className="row gx-2 align-items-center mb-2">
                {createdby ? 
                    <div className="col-auto">
                        <UserPhoto photo={createdby.photo} name={createdby.fullname} size={[40, 40, 40]} />
                    </div>
                : null}
                <div className="col">
                    { company ? <small className="bordered-color success px-1">{company.name}</small> : null }
                    
                    <p className="m-0">{getContent()}</p>
                </div>
            </div>
            <div className="row gx-2">
                {
                    actions && actions.map((item, key) => {
                        return (
                            <div className="col-auto" key={key}>
                                <button className="btn btn-primary btn-sm" onClick={() => actionClick(key)}>{item.text}</button>
                            </div>
                        )
                    })
                }
                { !isRead ? 
                <div className="col-auto">
                    <button className="btn btn-border btn-sm" onClick={() => setRead()}>Marker som lest</button>
                </div>
                : null }
            </div>
        </div>
    )
}