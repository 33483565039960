/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, RichUtils, convertFromRaw, convertToRaw, AtomicBlockUtils, Modifier } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import Modal from './Modal';
import FileUpload from './FileUpload';
import ImageUpload from './ImageUpload';
import { ConfigContext } from '../providers/ConfigProvider';

export default function Draft(props){

	const { baseUrl } = useContext(ConfigContext)
    
    const { value, setValue, containerClass } = props
    const [editorState, setEditorState] = useState(
      value ? () => EditorState.createWithContent(convertFromRaw(value)) : () => EditorState.createEmpty(),
    )
	const [imageModalOpen, setImageModalOpen] = useState(false)

	const imagePlugin = createImagePlugin();
	const plugins = [imagePlugin];

    useEffect(() => {
      setValue(convertToRaw(editorState.getCurrentContent()))
    }, [editorState])
    
    var INLINE_STYLES = [
        {icon: ['fas', 'bold'], style: 'BOLD'},
        {icon: ['fal', 'italic'], style: 'ITALIC'},
        {icon: ['far', 'underline'], style: 'UNDERLINE'}
    ];

    const editor = useRef(null)
  

    // Focus on editor window
    const focusEditor = () => { editor.current.focus() }

    

    const InlineStyleControls = (props) => {
      
        var currentStyle = editorState.getCurrentInlineStyle();

	
        return (
			<div className="RichEditor-controls">
				<div className="row gx-2 align-items-center">
					{INLINE_STYLES.map(type =>
					<StyleButton
						key={type.icon}
						active={currentStyle.has(type.style)}
						icon={type.icon}
						style={type.style}
					/>
					)}
			</div>
		  </div>
        );
      };

      const setStyle = (style) => {
        console.log('TOGGLING STYLE', style)
        focusEditor()
        setEditorState(RichUtils.toggleInlineStyle(editorState, style))
		
		focusEditor()
        
    }

	const BLOCK_TYPES = [
		{label: 'Paragraf', style: 'unstyled'},
        {label: 'Overskrift 1', style: 'header-one'},
        {label: 'Overskrift 2', style: 'header-two'},
        {label: 'Overskrift 3', style: 'header-three'},
        {label: 'Overskrift 4', style: 'header-four'},
        {label: 'Overskrift 5', style: 'header-five'},
        {label: 'Overskrift 6', style: 'header-six'}
	];

	const toggleBlockType = (blockType) => {
		console.log('Toggling blocktype', blockType)
		setEditorState(
		  RichUtils.toggleBlockType(
			editorState,
			blockType
		  )
		);
	}

    const BlockStyleControl = () => {
		const selection = editorState.getSelection();
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

		
		const BlockOption = ({label, style}) => {
			return (
				<option value={style}>{label}</option>
			)
		}
		return (
			<div className="RichEditor-controls">
				<select value={blockType} onChange={(e) => toggleBlockType(e.target.value)}>
					{BLOCK_TYPES.map((type) =>
						<BlockOption
							key={type.label}
							active={type.style === blockType}
							label={type.label}
							style={type.style}
						/>
					)}
				</select>
			</div>
		)
    }

	const ListStyleControl = () => {
		const selection = editorState.getSelection();
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

		const TYPES = [
			{icon: ['fal', 'list-ol'], style: 'ordered-list-item'},
			{icon: ['fal', 'list-ul'], style: 'unordered-list-item'}
		]
		const Button = ({icon, style, active}) => {
			return (
				<div className="col-auto">
					<button type="button" className={`btn btn-link ${active ? 'style-button-active' : ''}`} onMouseDown={(e) => {
						e.preventDefault()
						toggleBlockType(style)
					}}>
						<FontAwesomeIcon size="lg" fixedWidth className={"draft-editor-button"} icon={icon} />
					</button>
				</div>
			)
		}
		return (
			<div className="RichEditor-controls">
				<div className="row gx-2 align-items-center">
					{TYPES.map((type) =>
						<Button
							key={type.icon}
							active={type.style === blockType}
							icon={type.icon}
							style={type.style}
						/>
					)}
				</div>
			</div>
		)
	}

	const addImage = (url) => {
		setEditorState(imagePlugin.addImage(editorState, url))
	}

	const addLink = () => {
		const selectionState = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
		  url: 'http://www.zombo.com',
		});
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const contentStateWithLink = Modifier.applyEntity(
		  contentStateWithEntity,
		  selectionState,
		  entityKey,
		);
		const newEditorState = EditorState.set(editorState, {
		  currentContent: contentStateWithLink,
		});

		setEditorState(newEditorState)
	}

	const MediaControls = () => {
		const TYPES = [
			{icon: ['far', 'image'], style: 'ordered-list-item', onClick: () => setImageModalOpen(true) },
			{icon: ['far', 'link'], style: 'unordered-list-item', onClick: () => addLink()}
		]
		const Button = ({icon, style, active, onClick}) => {
			return (
				<div className="col-auto">
					<button type="button" className="btn btn-link"  onMouseDown={(e) => {
						e.preventDefault()
						onClick()
					}}>
						<FontAwesomeIcon size="lg" fixedWidth className={"draft-editor-button"} icon={icon} />
					</button>
				</div>
			)
		}
		return (
			<div className="RichEditor-controls">
				<div className="row gx-2 align-items-center">
					{TYPES.map((type) =>
						<Button
							key={type.icon}
							icon={type.icon}
							style={type.style}
							onClick={type.onClick}
						/>
					)}
				</div>
			</div>
		)
	}
    

    const StyleButton = ({ active, icon, style}) => {

        return (
			<div className="col-auto">
				<button type="button" className={`btn btn-link ${active ? 'style-button-active' : ''}`} onMouseDown={(e) => {
					e.preventDefault()
					setStyle(style)
				}}>
					<FontAwesomeIcon fixedWidth className={"draft-editor-button"} icon={icon} />
				</button>
			</div>
        )
    }

    

    const handleKeyCommand = useCallback((command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command)
		if (newState) {
			setEditorState(newState)
			return "handled"
		}
		return "not-handled"
	})

	const UploadImage = ({ setOpen }) => {
	
		const [files, setFiles] = useState([])
		
		const upload = () => {
			files.map((item) => {
				addImage(`${baseUrl}/${item.file_path}`)
			})
			setOpen(false)
		}
	
		return (
			<div className="py-3 px-5">
				<h4 className="bold mb-3">Last opp filer</h4>
				<ImageUpload
					files={files}
					setFiles={setFiles}
				/>
				<div className="row my-3">
					<div className="col-md-6">
						<button type="button" className='btn btn-block btn-border w-100' onClick={() => setOpen(false)}>Avbryt</button>
					</div>
					<div className="col-md-6">
						<button type="button" className='btn btn-block btn-success w-100' onClick={() => upload()}>Legg til</button>
					</div>
				</div>
			</div>
		)
	}

	const handleReturn = () => {
		toggleBlockType('unstyled')
	}
	
	  

    return (
        <div className={`draft-editor-container ${containerClass}`}>
            <Editor 
				editorState={editorState} 
				onChange={setEditorState} 
				ref={editor} 
				handleKeyCommand={handleKeyCommand} 
				plugins={plugins}
				handleReturn={handleReturn}
			/>
            <div className="draft-editor-buttons">
              	<BlockStyleControl />
              	<InlineStyleControls />
				<ListStyleControl />
				<MediaControls />
            </div>
			<Modal open={imageModalOpen} setOpen={setImageModalOpen} containerStyle={{width: 750, maxWidth: '100%', overflow: 'visible'}}>
                <UploadImage setOpen={setImageModalOpen} />
            </Modal>
        </div>
    )
}
 
