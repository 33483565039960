/* eslint-disable */
import { Link, NavLink, useLocation, useMatch, useResolvedPath } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Accordion } from "react-bootstrap";

import React, { useContext, useEffect, useState } from "react";
import { AccessContext } from "../../providers/AccessProvider";
import { ConfigContext } from "../../providers/ConfigProvider";
import NavItem from "../../components/NavItem";
import { DomainContext } from "../../providers/DomainProvider";
import { AuthContext } from "../../providers/AuthProvider";
import ContextPicker from "../../components/ContextPicker";

export default function DomainSidebar(){

    const { domain, contextNotifications, contextMessages } = useContext(AuthContext)
    const { navOpen, baseUrl, screenSize } = useContext(ConfigContext)
    const { domainPermissions, hasPermission } = useContext(DomainContext)
    
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        setNotifications(contextNotifications.filter(n => !n.isRead && n.domain.id === domain.id && !n.company))
    }, [contextNotifications])

    
    let location = useLocation();

    const MENU = [
        {
            name: "Oversikt",
            icon: "grid-2",
            link: "oversikt",
            notifications: 0
        },
        {
            name: "Oppgaver",
            icon: "chart-simple",
            link: "oppgaver",
            notifications: notifications?.filter(n => {
                
                if(n?.actions?.length){
                    return n.actions.filter(a => a.class === 'SagaNewTask').length
                }
                return false
            }).length
        },
        {
            name: "Områder",
            icon: "address-book",
            link: "kunder",
            notifications: 0
        },
        {
            name: "Medlemmer",
            icon: "user-group",
            link: "medlemmer",
            notifications: 0
        },
        {
            name: "Kanaler",
            icon: "messages",
            link: "kanaler",
            notifications: contextMessages.filter(notification => {
                if(!notification.isRead && notification.company === null){
                    return true
                }
            }).length
        },
        {
            name: "Mine grupper",
            icon: "user-group",
            link: "grupper",
            notifications: 0
        },
        {
            name: "Dokumenter",
            icon: "file-lines",
            link: "dokumenter",
            notifications: 0
        },
        {
            name: "Maler",
            icon: "pen-to-square",
            link: "maler",
            notifications: 0
        }
    ]

    const drawLogo = () => {
        if(domain){
            if(domain.config && domain.config.logo){
                return <img src={`${baseUrl}/${domain.config.logo}`} className="main-logo mb-4 mt-3" style={{maxHeight: 70}} />
            }
        }
    }

    return (
        <div className={"col nav" + (navOpen ? ' nav-open' : '')}>
             <nav className="sidebar d-flex flex-column">
                <div className="px-4 py-md-4 pt-4 mb-md-3">
                    {drawLogo()}
                </div>
                <ul className="flex-grow-1">
                    {
                        MENU && MENU.map((el, index) => {
                            return (<NavItem
                                key={index}
                                index={index}
                                location={location}
                                item={el}
                            />)
                        })
                    }
                </ul>
                {
                    hasPermission(['adminDomain']) ? (
                        <ul className="pb-2">
                            <NavItem index={0} location={location} item={{name: 'Innstillinger', icon: 'gears', link: 'innstillinger', notifications: 0}} />
                                
                        </ul>
                    ) : null
                }
                
                
            </nav>
        </div>
    )
}





