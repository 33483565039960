import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import UserPhoto from "../../../components/UserPhoto"
import { NewCompanyContext } from "../../../providers/NewCompanyProvider"

export default function DomainNewCompanyUsers(){

    const { users, setUsers, save, formik, setUserDrawerOpen } = useContext(NewCompanyContext)


    const removeUser = (el) => {
        formik.setFieldValue("users", formik.values.users.filter(u => u !== el))
    }

    return (
        <div className="bg-white p-md-4 p-3">
            <div className="row justify-content-between mb-5">
                <div className="col-auto">
                    <h2 className="bold">Administrer brukere</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-4" type="submit">Lagre</button>
                </div>
            </div>
            
            <h4 className="bold">Opprett brukere</h4>
                
            <div className="border-lg mb-md-5 mb-2">
                <div className="p-md-4 p-3">
                    Opprett aktive brukere som representerer bedriften
                    <div className="pt-4">
                        <button type="button" className="btn btn-dashed" onClick={() => setUserDrawerOpen(true)}>
                            <div className="row gx-1">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['fal', 'plus']} />
                                </div>
                                <div className="col">
                                    <span>Legg til brukere</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                {
                    formik.values.users.map((el, key) => {
                        
                        return (
                            <div className="border-top px-3 py-2" key={key}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <UserPhoto name={el.firstname} size={[40, 40, 40]} />
                                    </div>
                                    <div className="col">
                                        <p className="m-0">{el.firstname} {el.lastname}</p>
                                    </div>
                                    <div className="col-auto">
                                        <p className="m-0">{el.permissionTemplate ? el.permissionTemplate.label : 'Ingen brukerrolle valgt'}</p>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-link text-danger" onClick={() => removeUser(el)}>Fjern</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            
        </div>
    )
}