/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, RichUtils, convertFromRaw, convertToRaw, Modifier } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import React, { useCallback, useEffect, useRef, useState, useContext, useMemo, createRef } from 'react';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention'
import { Entry, HashtagEntry } from './Functions';
import { AuthContext } from '../providers/AuthProvider';
import { useKeyPressEvent } from 'react-use';
import Tooltip from './Tooltip';

export default function ChannelEditor(props){
    
    const { 
        value, 
        setValue, 
        mentionableUsers, 
        attachments, 
        setAttachments,
        submit
    } = props

    const [editorState, setEditorState] = useState(
      value ? () => EditorState.createWithContent(convertFromRaw(value)) : () => EditorState.createEmpty(),
    )
   
    const { uploadFile, getTagableObjects } = useContext(AuthContext)

    const [mentionOpen, setMentionOpen] = useState(false);
    const [hashtagOpen, setHashtagOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(mentionableUsers)
    const [hashtags, setHashtags] = useState([])
    const [hashTagSuggestions, setHashTagSuggestions] = useState([])
    
    const containerRef = createRef()
    const editor = useRef(null)

    const getHashtags = async () => {
        const { data } = await getTagableObjects()
        setHashtags(data)
        setHashTagSuggestions(data)
    }
    
    useEffect(() => {
        getHashtags()
        
    }, [])

    useEffect(() => {
        console.log(mentionableUsers)
    }, [mentionableUsers])

    
    
	const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.addEventListener('change', (e) => {
        e.preventDefault();
        handleDrop(e.path ? e.path[0].files : e.target.files)
        
        
    })

    const handleDrop = async (fileList) => {
        console.log(fileList)
        let newAttachments = []
        for (var i = 0; i < fileList.length; i++) {
			if(!attachments.some(file => file.name === fileList[i].name)){
            
                const data = await uploadFile(fileList[i])
                if(data){
                    newAttachments = [...attachments, data]
                }
                
                
				
			}
		}


        setAttachments(newAttachments)
    }

    const removeFile = (name) => {
        setAttachments(attachments.filter(a => a.file_name !== name))
    }

    useEffect(() => {
        if(containerRef.current){
            containerRef.current.addEventListener('drop', (e) => {
                e.preventDefault()
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    handleDrop(e.dataTransfer.files)
                    e.dataTransfer.clearData()  
                }
                
            })
        }
    }, [containerRef])


    useEffect(() => {
      setValue(convertToRaw(editorState.getCurrentContent()))
    }, [editorState])
    
    var INLINE_STYLES = [
        {icon: ['fas', 'bold'], style: 'BOLD'},
        {icon: ['fal', 'italic'], style: 'ITALIC'},
        {icon: ['far', 'underline'], style: 'UNDERLINE'}
    ];


    // Focus on editor window
    const focusEditor = () => { editor.current.focus() }

    const { MentionSuggestions, HashtagSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionComponent(mentionProps) {
                
                return (
                    <span
                      className={mentionProps.className}
                      // eslint-disable-next-line no-alert
                    >
                      @{mentionProps.mention.fullname}
                    </span>
                )
            }
        });
        const hashtagPlugin = createMentionPlugin({
            mentionTrigger: ['#'],
            mentionComponent(mentionProps) {
                
                return (
                    <span
                      className={mentionProps.className}
                      // eslint-disable-next-line no-alert
                    >
                      #{mentionProps.mention.title}
                    </span>
                )
            }
        })
        const { MentionSuggestions } = mentionPlugin;
        const HashtagSuggestions = hashtagPlugin.MentionSuggestions
        const plugins = [mentionPlugin, hashtagPlugin];
        return { plugins, HashtagSuggestions, MentionSuggestions };
    }, []);

    const onOpenMentionChange = useCallback((_open) => setMentionOpen(_open), [])
    const onSearchMentionChange = useCallback(({ value }) =>  setSuggestions(customSuggestionsFilter(value, mentionableUsers)), [])

    const customSuggestionsFilter = (searchValue, suggestions) => {
        const size = (list) => (list.constructor.name === 'List'
            ? list.size
            : list.length);
    
        const get = (obj, attr) => (obj.get
            ? obj.get(attr)
            : obj[attr]);
    
        const value = searchValue.toLowerCase();
        const filteredSuggestions = suggestions.filter((suggestion) => (
          !value || get(suggestion, 'fullname').toLowerCase().indexOf(value) > -1
        ));
        const length = size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 10;
        return filteredSuggestions.slice(0, length);
      };

    const onOpenHashtagChange = useCallback((_open) => setHashtagOpen(_open), [])
    const onSearchHashtagChange = useCallback(({ value }) => {
        console.log('Hashtags', hashtags)
        /*
        setHashTagSuggestions(() => {
            if(value.length === 0) return hashtags
			var v = value.toLowerCase();

            console.log('V', v)
			var triggerSuggestions = hashtags;
            console.log('triggerSuggestions', triggerSuggestions)
			var filteredSuggestions = triggerSuggestions.filter((suggestion) => {
				return !v || suggestion.title.toLowerCase().indexOf(v) > -1;
			});
			var length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
			return filteredSuggestions.slice(0, length);
		})
        */
    }, [])
  
    const insertSymbol = (symbol) => {
        const newContentState = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            symbol,
        );
      
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-fragment',
        );
      
        setEditorState(newEditorState);

        setTimeout(() => {
            focusEditor()
        }, 100)
    }

    const InlineStyleControls = (props) => {
      
        var currentStyle = editorState.getCurrentInlineStyle();

	
        return (
			
				<div className="row gx-1 align-items-center">
					{INLINE_STYLES.map(type =>
					<StyleButton
						key={type.icon}
						active={currentStyle.has(type.style)}
						icon={type.icon}
						style={type.style}
					/>
					)}
		  </div>
        );
      };

    const setStyle = (style) => {
        console.log('TOGGLING STYLE', style)
        focusEditor()
        setEditorState(RichUtils.toggleInlineStyle(editorState, style))
		
		focusEditor()
        
    }

	const BLOCK_TYPES = [
		{label: 'Paragraf', style: 'unstyled'},
        {label: 'Overskrift 1', style: 'header-one'},
        {label: 'Overskrift 2', style: 'header-two'},
        {label: 'Overskrift 3', style: 'header-three'},
        {label: 'Overskrift 4', style: 'header-four'},
        {label: 'Overskrift 5', style: 'header-five'},
        {label: 'Overskrift 6', style: 'header-six'}
	];

	const toggleBlockType = (blockType) => {
		console.log('Toggling blocktype', blockType)
		setEditorState(
		  RichUtils.toggleBlockType(
			editorState,
			blockType
		  )
		);
	}

    const BlockStyleControl = () => {
		const selection = editorState.getSelection();
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

		
		const BlockOption = ({label, style}) => {
			return (
				<option value={style}>{label}</option>
			)
		}
		return (
			<div className="RichEditor-controls">
				<select value={blockType} onChange={(e) => toggleBlockType(e.target.value)}>
					{BLOCK_TYPES.map((type) =>
						<BlockOption
							key={type.label}
							active={type.style === blockType}
							label={type.label}
							style={type.style}
						/>
					)}
				</select>
			</div>
		)
    }

	const ListStyleControl = () => {
		const selection = editorState.getSelection();
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

		const TYPES = [
			{icon: ['fal', 'list-ol'], style: 'ordered-list-item'},
			{icon: ['fal', 'list-ul'], style: 'unordered-list-item'}
		]
		const Button = ({icon, style, active}) => {
			return (
				<div className="col-auto">
					<button className={`btn btn-link ${active ? 'style-button-active' : ''}`} onMouseDown={(e) => {
						e.preventDefault()
						toggleBlockType(style)
					}}>
						<FontAwesomeIcon size="lg" fixedWidth className={"draft-editor-button"} icon={icon} />
					</button>
				</div>
			)
		}
		return (
			<div className="RichEditor-controls">
				<div className="row gx-2 align-items-center">
					{TYPES.map((type) =>
						<Button
							key={type.icon}
							active={type.style === blockType}
							icon={type.icon}
							style={type.style}
						/>
					)}
				</div>
			</div>
		)
	}

    const StyleButton = ({ active, icon, style}) => {

        return (
			<div className="col-auto">
				<button className={`btn btn-link ${active ? 'style-button-active' : ''}`} onMouseDown={(e) => {
					e.preventDefault()
					setStyle(style)
				}}>
					<FontAwesomeIcon fixedWidth className={"draft-editor-button"} icon={icon} />
				</button>
			</div>
        )
    }

    const handleKeyCommand = useCallback((command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command)
		if (newState) {
			setEditorState(newState)
			return "handled"
		}
		return "not-handled"
	})

	const handleReturn = () => {
		toggleBlockType('unstyled')
	}
	
	  
    useKeyPressEvent("Enter", (e) => {
        
        
        if(document.activeElement === editor.current.editor.editor && e.shiftKey){
            submit()
        }
    });

    return (
        <div className="p-3">       
            <div className="border">
                <div className="px-2 bg-grey">
                    <div className="row gx-1 justify-content-between">
                        <div className="col-auto">
                            <InlineStyleControls />
                        </div>
                        <div className="col-auto">
                            <div className="row gx-1">
                                <div className="col-auto">
                                    <FontAwesomeIcon onClick={() => insertSymbol('#')} fixedWidth icon={['fal', 'hashtag']} />
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon onClick={() => insertSymbol('@')} fixedWidth icon={['fal', 'at']} />
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon className="pointer" onClick={() => fileSelector.click()} fixedWidth icon={['fal', 'paperclip']} />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className=" p-2">
                    <div className="row">
                        <div className="col">
                            
                            <Editor 
                                editorState={editorState} 
                                onChange={setEditorState} 
                                placeholder="Skriv en kommentar" 
                                ref={editor}
                                plugins={plugins}
                                entryComponent={Entry}
                            />
                            <MentionSuggestions
                                open={mentionOpen}
                                onOpenChange={onOpenMentionChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchMentionChange}
                                entryComponent={Entry}
                            />
                            <HashtagSuggestions 
                                open={hashtagOpen}
                                onOpenChange={onOpenHashtagChange}
                                suggestions={hashTagSuggestions}
                                onSearchChange={onSearchHashtagChange}
                                entryComponent={HashtagEntry}
                            />
                        </div>
                        <div className="col-auto">
                            {
                                attachments && attachments.length > 0 ? (
                                    <div className="d-flex flex-column">
                                        {
                                            attachments.map((file, key) => {
                                                return (
                                                    <div key={key} className="row gx-0 col-auto mb-1" style={{padding: '5px 10px', backgroundColor: 'var(--primary)', color: '#FFF', borderRadius: '30px'}}>
                                                        <div className="col">
                                                        {file.file_name}
                                                        </div>
                                                        <div className="col-auto">
                                                            <FontAwesomeIcon onClick={() => removeFile(file.file_name)} className="pointer" style={{marginLeft: 10}} icon={['fal', 'xmark']} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) 
                            : null }
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-link" onClick={() => submit()}>
                                <Tooltip content={"Send melding (Shift + Enter)"} placement="top">
                                    <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
