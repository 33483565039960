import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import { Link, Outlet, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom"
import BoxLoading from "../../components/BoxLoading"
import Drawer, { DrawerContext } from "../../components/Drawer"
import { formatDate, getNextDeadline } from "../../components/Functions"
import ImageStack from "../../components/ImageStack"
import PersonPicker from "../../components/PersonPicker"
import Table from "../../components/Table"
import Tooltip from "../../components/Tooltip"
import UserPhoto from "../../components/UserPhoto"
import { AuthContext } from "../../providers/AuthProvider"
import { ConfigContext } from "../../providers/ConfigProvider"
import { TeamContext } from "./CompanyTeams"


const Context = createContext({
    user: null,
    tasks: null,
})

const Provider = ({children}) => {
    const { currentTeam } = useContext(TeamContext)
    const [user, setUser] = useState(null)
    const [selectableUsers, setSelectableUsers] = useState([])
    const [tasks, setTasks] = useState(null)
    const [companies, setCompanies] = useState(null)
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const params = useParams()

    useEffect(() => {
        if(currentTeam && user){
            setSelectableUsers([...currentTeam.leaders, ...currentTeam.members].filter(u => u.id !== user.id))
        }
    }, [params, user])

    useEffect(() => {
        loadUser()
    }, [params])

    const loadUser = async () => {
        const { data } = await apiCall({
            action: 'get-company-team-user',
            id: params.consultant
        })

        if(data.status === 1){
            setUser(data.user)
            setTasks(data.tasks)
            loadRepresentatives()
        }
    }

    const loadRepresentatives = async () => {
        const { data } = await apiCall({
            action: 'get-representatives-by-user',
            user: params.consultant
        })

        if(data.status === 1){
            setCompanies(data.data)
        }
    }

    const updateRepresentative = async (company, representative) => {
        const { data } = await apiCall({
            action: 'edit-representative',
            user: user.id,
            company: company,
            representative: representative
        })

        notify(data.status, data.title, data.message)
        if(data.status === 1){
            loadRepresentatives()
        }
    }

    

    return <Context.Provider value={{
        user,
        tasks,
        companies,
        selectableUsers,
        updateRepresentative,
    }}>{params.consultant ? children : <SelectUser />}</Context.Provider>
}

function SelectUser(){
    return (
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <FontAwesomeIcon size="2x"   icon={['far', 'user-tie']} className="text-primary" />
            </div>
            <div className="text-center">
                <h6 className="bold text-primary">Velg et medlem for å se detaljer</h6>
                <p>Her kan du se både oppgaver og samtaler tilhørende medlemmer i ditt team.</p>
            </div>
        </div>
    )
}

export default function CompanyTeamUser(){
    

    return (
        <Provider>
            <Main />
        </Provider>
    )
}

function Main(){

    const { user } = useContext(Context)
    const Nav = ({ label, link }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <div className="col-auto">
                <Link to={link} className={match ? "bold text-primary" : ""}>{label}</Link>
            </div>
        )
    }

    return (
        <div className="d-flex flex-column h-100">
                <div className="border-bottom px-4 pt-4 pb-2">
                    <h3 className="bold">{user ? user.fullname : null}</h3>
                    <div className="row justify-content-between">

                        <div className="col-auto">
                            <div className="row">
                                <Nav link="oppgaver" label="Oppgaver" />
                                <Nav link="samtaler" label="Samtaler" />
                            </div>
                        </div>
                        <Nav link="innstillinger" label="Innstillinger" />

                    </div>
                    
                </div>
                <Outlet />
            </div>
    )

}

export function CompanyTeamUserTasks(){
    
    const { tasks } = useContext(Context)
    const { domain } = useContext(AuthContext)
    const navigate = useNavigate()

    

    
    if(!tasks){
        return (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon size="2x" spin icon={['fad', 'spinner-third']} className="text-primary" />
                </div>
                <div className="text-center">
                    <h6 className="bold text-primary">Henter oppgaver...</h6>
                </div>
            </div>
        )
    }

    if(tasks.length === 0){
        return (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon size="2x" icon={['fad', 'list-check']} className="text-primary" />
                </div>
                <div className="text-center">
                    <h6 className="bold text-primary">Ingen oppgaver</h6>
                </div>
            </div>
        )
    }

    return (
        <div className="p-4">
            <Table
                gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "2fr 1fr 2fr 1fr"]}
                gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                grid="2fr 1fr 1fr 1fr 0fr"
                perPage={5}
                rows={tasks}
                showResult
                unit="oppgaver"
                onRowClick={(e) => {
                    
                    if(e.count && e.count > 1){
                        navigate(`/oppgaver/samling/${e.batch}`)
                    }else{
                        navigate(`/${domain.hash}/oppgaver/${e.id}`)
                    }
                    
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                        keys: ['name', 'company'],
                        display: (e) => {
                            
                            return (
                                <div>
                                    <span className="bold d-block">{e.name}</span>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                        keys: ['deadline', 'subtasks'],
                        display: (e) => {
                            return getNextDeadline({deadline: e.deadline, subtasks: e.subtasks}).text
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                        keys: ['priority', 'form', 'subtasks'],
                        display: (e) => {
                            return (
                                <div className="row gx-1">
                                    {
                                        e.priority ? (
                                            <div className="col-auto">
                                                <Tooltip content="Prioritert oppgave" placement="bottom">
                                                    <div className="bordered-color danger py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        e.form ? (
                                            <div className="col-auto">
                                                <Tooltip content="Skjema" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        e.subtasks && e.subtasks.length > 0 ? (
                                            <div className="col-auto">
                                                <Tooltip content="Underoppgaver" placement="bottom">
                                                <div className="bordered-color success py-1 px-2">
                                                    <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                    
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                        keys: ['responsibles'],
                        display: (e) => {
                            return (e.responsibles && e.responsibles.length ? <ImageStack users={e.responsibles} /> : <FontAwesomeIcon icon={['fas', 'circle-exclamation']} className="text-danger" />)
                        }
                    },
                    {
                        grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                        keys: ['pinned'],
                        display: (e) => {
                            return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                        }
                    }      
                ]}
            />
        </div>
    )
}

export function CompanyTeamUserConversations(){
    const params = useParams()
    const [tasks, setTasks] = useState(null)
    const { apiCall, domain } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        setConversations(null)
        if(params.consultant){
            getConversations()
        }
    }, [params])

    const getConversations = async () => {
        const { data } = await apiCall({action: 'get-unread-conversation-messages-consultant', id: params.consultant})
        
        if(data.status === 1){
            setConversations(data.data)
        }else{
            setConversations([])
        }
    }
    const [conversations, setConversations] = useState(null)

    if(!conversations){
        return (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon size="2x" spin icon={['fad', 'spinner-third']} className="text-primary" />
                </div>
                <div className="text-center">
                    <h6 className="bold text-primary">Henter samtaler...</h6>
                </div>
            </div>
        )
    }

    

    if(conversations.length === 0){
        return (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon size="2x" icon={['fad', 'comments']} className="text-primary" />
                </div>
                <div className="text-center">
                    <h6 className="bold text-primary">Ingen samtaler</h6>
                    <p>Det er ingen samtaler å følge opp!</p>
                </div>
            </div>
        )
    }

    return (
        <div className="p-4">
            <Table
                gridTemplateColumns={["min-content 1fr 1fr min-content", "fit-content(8ch) 1fr 1fr min-content", "fit-content(8ch) 1fr min-content"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                grid="0fr 1fr 1fr 0fr"
                perPage={5}
                rows={conversations}
                showResult
                unit="samtaler"
                onRowClick={(e) => {
                    
                    if(e.company){
                        navigate(`/${domain.hash}/${e.company.hash}/kanaler/${e.alias}/samtaler`)
                    }else{
                        navigate(`/${domain.hash}/kanaler/${e. alias}/samtaler`)
                    }
                    
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                        keys: ['conversations'],
                        display: (e) => {
                            const lastMessage = e.conversations[e.conversations.length - 1]
                            
                            return (
                                <div style={{marginRight: '1rem'}}>
                                    <UserPhoto photo={lastMessage?.message.createdby.photo} name={lastMessage?.message.createdby.fullname} size={[50, 40, 30]} />
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 2", "1 / 2 / 2 / 2", "1 / 2 / 2 / 2"],
                        keys: ['message', 'conversations'],
                        display: (e) => {
                            
                            const lastMessage = e.conversations[e.conversations.length - 1]

                            let dateString = formatDate(lastMessage.message.createdon, 'diff')
                            

                            return (
                                <div>
                                    <h6 className="bold">{lastMessage?.message.createdby.fullname}</h6>
                                    <div className="row gx-3 hint-text align-items-center">
                                        <div className="col-auto">
                                            <span className="hint-text">{dateString}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row gx-1 align-items-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'message-lines']} />
                                                </div>
                                                <div className="col-auto">
                                                    {e.conversations.length}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 3 / 2 / 4"],
                        keys: ['company', 'title'],
                        display: (e) => {
                            return (
                                <div>
                                    <span className="d-block bold">{e.title}</span>
                                    <span>{e.company?.name}</span>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5"],
                        keys: ['channel', 'message'],
                        display: (e) => {
                            return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                        }
                    }      
                ]}
            />
        </div>
    )
}

export function CompanyTeamUserSettings(){

    const [repDrawerOpen, setRepDrawerOpen] = useState(false)
    const [hasRepresentatives, setHasRepresentatives] = useState(false)
    const { companies } = useContext(Context)

    useEffect(() => {
        if(companies){
            companies.map((company) => {
                if(company.representatives.length) setHasRepresentatives(true)
            })
        }
    }, [companies])
    return (
        <>
            <div className="p-4">
                <div className="mb-3">
                    <h4 className="bold">Fjern medlem fra gruppen</h4>
                    <small>Dersom medlemmet fjernes fra gruppen vil den også miste tilhørende tilganger</small>
                    <button className="btn btn-danger mt-3">
                        <div className="row">
                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'user-minus']} /></div>
                            <div className="col-auto">Fjern medlem fra gruppe</div>
                        </div>
                    </button>
                </div>
            </div>
            <Drawer open={repDrawerOpen} setOpen={setRepDrawerOpen}>
                <Representatives />
            </Drawer>
        </>
    )
}

function Representatives(){
    const { setOpen } = useContext(DrawerContext)
    const { user, companies } = useContext(Context)
    

    if(!user || !companies) return <BoxLoading/>

    

    
    return (
        <div className="p-3">
            <div className="row justify-content-between mb-3">
                <div className="col-auto">
                    
                    
                    <button className='btn btn-link mb-2' onClick={() => setOpen(false)}>
                        <div className="row gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                            </div>
                            <div className="col-auto">
                                <span className="bold">Mine grupper</span>
                            </div>
                        </div>
                    </button>
                    
                </div>
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['fas', 'xmark']} />
                    </button>
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col">
                    <h5 className="bold">Legg til stedsfortreder</h5>
                    <p>I listen under kan du enkeltvis velge stedsfortreder fra din gruppe per kunde</p>
                </div>
                <div className="col-auto">
                    <div className="row gx-2 align-items-center">
                        <div className="col">
                            <span>{user.fullname}</span>
                        </div>
                        <div className="col-auto">
                            <UserPhoto photo={user.photo} name={user.fullname} size={[50, 50, 50]} />
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="person-modal-person-wrapper">
                        {
                            companies.map((item, index) => {

                                return (
                                    <Company 
                                        key={index} 
                                        item={item}
                                    />
                                )
                            })
                        }
                    </div>
        </div> 
    )
}

function Company({ item }){
    const { id, name, image, unread_messages, active_tasks, representatives } = item
    const { selectableUsers, updateRepresentative } = useContext(Context)

    const updateResponsible = (val) => {
        updateRepresentative(id, val.id)
    }

    return (
        <div className="person-modal-person-item p-3 pointer">
            <div className="row align-items-center gx-3">
                
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-4">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <UserPhoto photo={image} name={name} size={[50, 50, 50]} />
                                </div>
                                <div className="col-auto">
                                    <h6 className="bold">{name}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            {active_tasks.length ? `${active_tasks.length} oppgaver` : `Ingen oppgaver`}
                        </div>
                        <div className="col-4">
                            {unread_messages.length ? `${unread_messages.length} uleste meldinger` : `Ingen uleste meldinger`}
                        </div>
                    </div>
                    
                    
                    
                </div>
                <div className="col-auto">
                    
                    <PersonPicker
                        items={selectableUsers}
                        selected={representatives}
                        setSelected={(val) => updateResponsible(val)}
                        handleManually
                    /> 
                </div>
            </div>
        </div>
        
    )
}