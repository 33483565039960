import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { CheckForm, CommentForm, ConfirmForm, RangeForm } from "./FormComponents"
import { canDoDask, debounceAsync, rteMarkup, styleText } from "./Functions"
import * as Yup from 'yup';
import { useFormik } from 'formik';

const Context = createContext({})

const Provider = ({children, TaskContext}) => {
    const { data, submission, updateForm, submitForm, updateSubmission, timeout, isSigned } = useContext(TaskContext)
    const { form } = data

    

    const createValidationSchemaTemp = () => {
        if(!form.items) return null
        const shapes = {}
        const DATA_OBJ_KEYS = Object.keys(form.items);
    
        DATA_OBJ_KEYS.forEach(((parameter) => {
            switch(form.items[parameter].type.value){
                case 1:
                    shapes[parameter] = Yup.object().shape({
                        value: Yup.string().required('Du må gjøre et valg'),
                        /*
                        comment: Yup.string().when('value', {
                            is: 'danger',
                            then: Yup.string().required('Kommentar må fylles ut'),
                            otherwise: Yup.string().nullable().notRequired()
                        })*/
                    })
                    break;
                case 2:
                    shapes[parameter] = Yup.object().shape({
                        comment: Yup.string().required('Kommentar er påkrevd')
                    })
                    break;
                case 3:
                    shapes[parameter] = Yup.object().shape({
                        value: Yup.string().oneOf(['success'], 'Denne må hukes av').required('Denne må hukes av')
                    })
                    break;
                case 4:
                    shapes[parameter] = Yup.object().shape({
                        value: Yup.number().required()
                    })
                    break;
                default:
            }
         
        }));

        //console.log(shapes)
    
        return Yup.object().shape(shapes);
    }
    const schema = createValidationSchemaTemp()

    const getInitialValues = () => {
        const values = {}
        if(!form.items) return values
        Object.keys(form.items).map((key) => {
            values[key] = submission && submission.payload?.form && submission?.payload?.form[key] ? submission.payload?.form[key] : null
        })

        return values
    }

    const initialValues = getInitialValues()

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        //onSubmit: onSubmitDebounced
        onSubmit: async (values) =>  submitForm(values),
        
    });
    
    

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorElement = document.getElementById(Object.keys(formik.errors).sort()[0])
            if(firstErrorElement) firstErrorElement.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        }
    }, [formik]);
    

    

    return <Context.Provider value={{
        data,
        form,
        formik,
        updateSubmission,
        updateForm,
        isSigned
    }}>{children}</Context.Provider>
}

export default function TaskForm({ TaskContext }){
    return (
        <Provider TaskContext={TaskContext}>
            <Form />
            <AutoSave />
        </Provider>
    )
}   

function AutoSave({ delay = 1000, onSubmit }){
        const { formik, updateForm } = useContext(Context)
        const { values, errors, initialValues } = formik
      
        const onFormSubmit = useCallback(async () => {
            updateForm(values)
        }, [values, initialValues, errors]);    
      
        // add delay of 300ms by default, or whatever delay prop is
        useEffect(() => {
          const timer = setTimeout(() => onFormSubmit(), delay);
          return () => clearTimeout(timer);
        }, [values, errors]);
      
        return null;
      
      
 
}

function Form(){
    const { data, form, formik, handleFormikChange, isSigned } = useContext(Context)
  

    if(!form) return (<div></div>)
    const { categoryOrder, categories } = form
    const drawSubmitButton = () => {

        if(!isSigned){
            return (
                <div className="bg-warning-tint rounded p-3">
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto"><FontAwesomeIcon icon={['fad', 'circle-exclamation']} size="lg" className="text-warning" /></div>
                        <div className="col-auto">
                            <p className="m-0">Oppgaven må signeres av alle</p>
                        </div>
                    </div>
                </div>
            )
        }
        
        if(!canDoDask({deadline: data.deadline, lastSubmission: data.lastSubmission})){
            return (<button className="btn btn-primary w-100" disabled="disabled">Utførelse av oppgaven åpner senere</button>)
        }

        if(data.signature === 2){
            return (<button type="submit" className="btn btn-success w-100">Send til signering</button>)
        }

        return (<button type="submit" className="btn btn-success w-100">Send inn og fullfør oppgave</button>)
    }

    
    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            {categoryOrder && categoryOrder.map(categoryId => {
                const category = categories[categoryId];
                
                const items = category.items.map(
                    itemId => form.items[itemId],
                );

                return <Category 
                    key={category.id} 
                    category={category} 
                    items={items} 
                    index={categoryOrder.findIndex(e => e === categoryId)}
                />;
            })}
            {drawSubmitButton()}
            
        </form>
    ) 
}

function Category({ category, items, index }){
    

    const [collapsed, setCollapsed] = useState(index === 0 ? true : false)
    return(
        <div className="border  mb-2 bg-white">
            <div className="p-3">
                <div className="row align-items-center">
                    <div className="col-auto pointer" onClick={() => setCollapsed(!collapsed)}>
                        <FontAwesomeIcon icon={['far', collapsed ? 'chevron-down' : 'chevron-up']} />
                    </div>
                    <div className="col">
                        <h5 className="mb-0 bold">{category.title}</h5>
                    </div>
                    <div className="col-auto">
                        <small>{Object.keys(items).length} spørsmål</small>
                    </div>
                </div>
            </div>
            <div style={{display: collapsed ? 'block' : 'none'}}>
                <div className="p-3 border-top">
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">{category.title}</h3>
                            <div dangerouslySetInnerHTML={{__html: rteMarkup(category.description)}}></div>
                            
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    {   
                        items.map((item, key) =>{
                            
                            return (
                                <div key={key} id={item.id} >
                                    <FormItem
                                        
                                        {...item}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function FormItem(props){
    const { formik, isSigned } = useContext(Context)
    const { id, type } = props
    const onChange = (value) => {
        formik.setFieldValue(id, value)
    }
    let switchType = ''
    if(type.value) switchType = type.value
    if(type.val) switchType = type.val
    switch(switchType){
        case 1:
            return <CheckForm
                error={formik.errors[id]} 
                value={formik.values[id]}
                touched={formik.touched[id]}
                onChange={(e) => onChange(e)}
                disabled={!isSigned}
                {...props} />
        case 2:
            return <CommentForm 
                error={formik.errors[id]} 
                value={formik.values[id]}
                touched={formik.touched[id]}
                onChange={(e) => onChange(e)}
                disabled={!isSigned}
                {...props} />
        case 3:
            return <ConfirmForm 
                error={formik.errors[id]} 
                value={formik.values[id]}
                touched={formik.touched[id]}
                onChange={(e) => onChange(e)}
                disabled={!isSigned}
            {...props} />
        case 4:
            return <RangeForm 
                error={formik.errors[id]} 
                value={formik.values[id]}
                touched={formik.touched[id]}
                onChange={(e) => onChange(e)}
                disabled={!isSigned}
                {...props} />
    }

    return (
        <div></div>
    )

}