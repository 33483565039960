import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { CompanyContext } from "../providers/CompanyProvider"
import { ConfigContext } from "../providers/ConfigProvider"
import { useOnClickOutside } from "./Functions"

export default function CompanyPicker(){
    const { company, companies, changeCompany } = useContext(CompanyContext)
    const { baseUrl } = useContext(ConfigContext)

    
    const [open, setOpen] = useState(true)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState(companies)

    useEffect(() => {
        setOpen(false)
    }, [company])

    useEffect(() => {
        if(open){
            if(searchRef.current){
                searchRef.current.focus()
            }
        }
    }, [open])

    useEffect(() => {
        setFilter(companies.filter(company => company.name.match(new RegExp(search, 'i'))))
    }, [search])

    const searchRef = useRef()
    const ref = useRef()
    useOnClickOutside(ref, () => setOpen(false))

    return (
        <div className="company-picker" ref={ref}>
            <div className="company-picker-container pointer"  onClick={() => setOpen(!open)}>
                { company ? (
                <div className="row align-items-center gx-3">
                    <div className="col-auto">
                        { company.image ? 
                            <img src={baseUrl + company.image} style={{height: 30, width: 30, borderRadius: '50%'}} /> : 
                            <div style={{height: 30, width: 30, borderRadius: '50%', background: 'var(--success-tint)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{company.name.substr(0, 1)}</div>
                        }
                    </div>
                    <div className="col">
                        <h6 className="m-0 pr-4">{company.name}</h6>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex flex-column">
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-up']} />
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-down']} />
                        </div>
                        
                    </div>
                </div>) : (
                    <div className="row align-items-center gx-3">
                    
                    <div className="col">
                        <h6 className="m-0 pr-4">Velg bedrift</h6>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex flex-column">
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-up']} />
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-down']} />
                        </div>
                        
                    </div>
                </div>
                )}
            </div>
            <CSSTransition in={open} timeout={250} classNames="notification-center-wrapper" unmountOnExit>
                <div className="company-picker-dropdown">
                    <div className="box p-3">
                        <input ref={searchRef} value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form-control mb-3" placeholder="Søk.."/>
                        <label className="caps hint-text">Bedrift</label>
                        {
                            filter && filter.map((item, key) => {
                                return (
                                    <div className="hover-primary p-2 px-3 pointer" style={{borderRadius: 5}} key={key} onClick={() => changeCompany(item.id)}>
                                        {item.name}
                                    </div>
                                )
                            })
                        }

                    </div>
                    
                </div>
            </CSSTransition>
        </div>
    )
}