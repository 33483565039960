import React, { useContext, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import Input from '../../../components/Input';
import ImageCrop from '../../../components/ImageCrop';
import { AuthContext } from '../../../providers/AuthProvider';
import { ConfigContext } from '../../../providers/ConfigProvider';
import DropDown from '../../../components/DropDown';

export default function DomainEdit(){
    
    const { domain, uploadFile, apiCall, loadDomain } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    console.log(domain)
    let schema = yup.object().shape({
        orgnr: yup.string().matches(/^[0-9]{9}$/, 'Org.nr. må være 9 siffer').required("Org.nr. er påkrevd"),
        name: yup.string().required("Bedriftsnavn er påkrevd"),
        country: yup.object().typeError("Land er påkrevd").required(),
        address: yup.string().required("Adresse er påkrevd"),
        zip: yup.string().matches(/^[0-9]{4}$/, 'Postnummer må være 4 siffer').required("Postnummer er påkrevd"),
        city: yup.string().required("Poststed er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            orgnr: domain.orgnr ? domain.orgnr : '',
            name: domain.name ? domain.name : '',
            country: domain.config?.country ? domain.config?.country : null,
            address: domain.config?.address?.address ? domain.config.address.address : '',
            zip: domain.config?.address?.zip ? domain.config?.address.zip : '',
            city: domain.config?.address?.city ? domain.config?.address.city : '',
            logo: domain.config?.logo ? domain.config.logo : null,
            logoSmall: domain.config?.logoSmall ? domain.config.logoSmall : null,
            type: null,
            tags: [],
        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            save(values)
        },
    });

    const save = async (values) => {
        const logoData = values.logo instanceof File ? await uploadFile(values.logo) : null
        const logoSmallData = values.logoSmall instanceof File ? await uploadFile(values.logoSmall) : null

        //console.log(imageData)

        const { data } = await apiCall({
            action: 'edit-domain-info',
            orgnr: values.orgnr,
            name: values.name,
            country: values.country,
            address: values.address,
            zip: values.zip,
            city: values.city,
            logo: logoData,
            logoSmall: logoSmallData
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            loadDomain()
        }

    }
    return (
        <div className="p-md-3 p-2">
            <div className="box p-md-4 p-3">
                <div className="row">
                    <div className="col">
                        <h3 className="bold">Rediger domene</h3>
                    </div>                    
                </div>
                
                <hr className="my-4" />

                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">Rediger bedrift</h3>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-success px-5">Lagre endringer</button>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-6">
                            <div className="py-3">
                                <ImageCrop 
                                    img={formik.values.logo}
                                    setImg={(logo) => formik.setFieldValue("logo", logo)}
                                    height={150}
                                    aspect={2}
                                    unit={'logo'}
                                    error={formik.touched.logo && Boolean(formik.errors.logo)}
                                    helperText={formik.touched.logo && formik.errors.logo}
                                />
                                
                                <div className="mt-3">
                                    <p className="hint-text">Denne logoen vil vises i sin helhet i menyen for alle områder under dette domenet. Et horisontalt utsnitt med hvit bakgrunn anbefales</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="py-3">
                                <ImageCrop 
                                    img={formik.values.logoSmall}
                                    setImg={(logo) => formik.setFieldValue("logoSmall", logo)}
                                    height={150}
                                    aspect={1}
                                    unit={'logo'}
                                    error={formik.touched.logoSmall && Boolean(formik.errors.logoSmall)}
                                    helperText={formik.touched.logoSmall && formik.errors.logoSmall}
                                />
                                <div className="mt-3">
                                    <p className="hint-text">Denne logoen vil vises i et sirkulært utsnitt. Utsnittet er fast. Bruk gjerne et logosymbol og ikke tekst til dette formatet. Brukes der hvor det henvises til området.</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    
                    <h4 className="bold">Bedriftsopplysninger</h4>
                    <p>Bedriftens opplysninger</p>
                    <div className="row gx-3 align-items-center">
                        <div className="col-4">
                            <Input
                                name="orgnr"
                                label="Org.nr."
                                type="text"
                                value={formik.values.orgnr}
                                onChange={formik.handleChange}
                                error={formik.touched.orgnr && Boolean(formik.errors.orgnr)}
                                touched={formik.touched.orgnr}
                                helperText={formik.touched.orgnr && formik.errors.orgnr}
                                placeholder={"Bedriftens organisasjonsnummer"}
                            />
                        </div>
                           
                        <div className="col-xl col-lg-12 mb-2 mb-md-0">
                            <Input
                                name="name"
                                label="Navn"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Bedriftens navn"}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-12 col-12">
                            <div className={"form-group mb-3"}>
                                <label>Land</label>
                                <DropDown
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    value={formik.values.country}
                                    onChange={(e) => formik.setFieldValue("country", e)}
                                    touched={formik.touched.country}
                                    helperText={formik.touched.country && formik.errors.country}
                                    options={[
                                        {label: 'Norge', value: 'NO'},
                                        {label: 'Sverige', value: 'SE'},
                                        {label: 'Danmark', value: 'DK'},
                                        {label: 'Polen', value: 'PL'},
                                    ]}
                                    placeholder="Velg land"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end mb-3 gx-2 gx-md-3">
                        <div className="col-12 col-md mb-2 mb-md-0">
                            <Input
                                name="address"
                                label="Gateadresse"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                touched={formik.touched.address}
                                helperText={formik.touched.address && formik.errors.address}
                                placeholder={"Gateadresse"}
                            />
                        </div>
                        <div className="col-md-2 col-4">
                            <Input
                                name="zip"
                                label="Postnr"
                                type="text"
                                value={formik.values.zip}
                                onChange={formik.handleChange}
                                error={formik.touched.zip && Boolean(formik.errors.zip)}
                                touched={formik.touched.zip}
                                helperText={formik.touched.zip && formik.errors.zip}
                                placeholder={"Postnr."}
                            />
                        </div>
                        <div className="col-md-3 col">
                            <Input
                                name="city"
                                label="Poststed"
                                type="text"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.zip)}
                                touched={formik.touched.city}
                                helperText={formik.touched.city && formik.errors.city}
                                placeholder={"Poststed"}
                            />
                        </div>
                    </div>
                
                </form>
            </div>
        </div>
    )
}
