import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage } from "formik"
import React, { createRef, useContext, useEffect, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { ConfigContext } from "../providers/ConfigProvider"
import Range from "./Range"

export function CheckForm(props){
    
    const { title, description, onChange, value, error,  disabled } = props
    const initialState = {
        value: value ? value.value : '',
        comment: value ? value.comment : '',
        attachments: value && value.attachments ? value.attachments : []
    }

    const [selected, setSelected] = useState(initialState.value)
    const [comment, setComment] = useState(initialState.comment)
    const [files, setFiles] = useState(initialState.attachments)
    const attachmentRef = createRef()

    useEffect(() => {
        if({value: value, comment: comment} !== initialState){
            //if(error && error.error) validateForm()
            if(onChange){
                onChange({
                    value: selected,
                    comment: comment,
                    attachments: files
                })
            }
        }
    }, [selected, comment, files])
    

    const CheckBox = ({ icon, value, text }) => {
        const random = Math.floor(Math.random() * 10000) + 1
        return (
            <div className={"col-auto custom-form-check" + (value ? ' ' + value : '') }>
                <input  type="radio" checked={selected === value ? 'checked' : ''} id={'custom-form-check-' + random} onChange={(e) => !disabled ? setSelected(value) : null} />
                <label htmlFor={'custom-form-check-' + random}>
                    <span>
                        <span>
                            { text ? text : null}
                            { icon ? <FontAwesomeIcon fixedWidth icon={['fal', icon]} /> : null}
                        </span>
                    </span>
                </label>
            </div>
        )
    }

    return (
        <div ref={attachmentRef} className={"box p-3 mb-3" + (error  ? " box-error" : "")}>
            <div className="row mb-2">
                <div className="col">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    {error ? <small className="text-danger">{error.comment}</small> : null}
                </div>
                <div className="col-auto">
                    <div className="row gx-1">
                        <CheckBox 
                            icon={'check'}
                            value={'success'}
                        />
                        
                        <CheckBox 
                            icon={'xmark'}
                            value={'danger'}
                        />
                    </div>
                    
                </div>
                
                <div className="col-12">
                    {
                        !disabled ? 
                            <textarea rows={2} className="form-control" placeholder="Fyll inn en kommentar" value={comment ? comment : ''} onChange={(e) => setComment(e.target.value)}></textarea> :
                            <>
                                {
                                    comment ? <div className="border p-3"><p className="m-0">{comment}</p></div> : null
                                }
                            </>
                    }
                </div>
            </div>
            {!disabled ? 
                <FileUpload files={files} setFiles={setFiles} containerRef={attachmentRef} /> : 
                    
                files.length > 0 ? 
                    (<div className="file-wrapper mt-2">
                        {
                            files.map((file, key) => {
                                return <FileDownload key={key} {...file} />
                            })
                        }
                    </div>)
                : null
            }
        </div>
    )
}

export function CommentForm(props){
    const { title, description, onChange, value, error,  disabled } = props
    

    const initialState = {
        comment: value ? value.comment : '',
        attachments: value && value.attachments ? value.attachments : []
    }

    const [comment, setComment] = useState(initialState.comment)
    const [files, setFiles] = useState(initialState.attachments)
    const attachmentRef = createRef()
    

    useEffect(() => {
        if(comment !== initialState){
            //if(error && error.error) validateForm()
            if(onChange){
                onChange({
                    comment: comment,
                    attachments: files
                })
            }
        }
    }, [comment, files])

    return (
        <div ref={attachmentRef}  className={"box p-3 mb-3" + (error ? " box-error" : "")}>
            <div className="row mb-2">
                <div className="col-12">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    {error ? <small className="text-danger">{error.comment}</small> : null}
                </div>
                <div className="col-12">
                    {
                        !disabled ? <textarea value={comment ? comment : ''} onChange={(e) => setComment(e.target.value)} rows={5} className="form-control" placeholder="Skriv eventuell kommentar her.."></textarea> : <div className="border p-3"><p className="m-0">{comment}</p></div>
                    }
                    
                </div>
            </div>
            {!disabled ? 
                <FileUpload files={files} setFiles={setFiles} containerRef={attachmentRef} /> : 
                    
                files.length > 0 ? 
                    (<div className="file-wrapper mt-2">
                        {
                            files.map((file, key) => {
                                return <FileDownload key={key} {...file} />
                            })
                        }
                    </div>)
                : null
            }
            
        </div>
    )
}

export function ConfirmForm(props){
    const { title, description, onChange, value, error,  disabled } = props
    const initialState = {
        value: value ? value.value : '',
        comment: value && value.comment ? value.comment : '',
        attachments: value && value.attachments ? value.attachments : []
    }
    const [selected, setSelected] = useState(initialState.value)
    const [comment, setComment] = useState(initialState.comment)
    const [files, setFiles] = useState(initialState.attachments)
    const attachmentRef = createRef()

    useEffect(() => {
        if({value: value, comment: comment} !== initialState){
            //if(error && error.error) validateForm()
            if(onChange){
            onChange({
                value: selected,
                comment: comment,
                attachments: files
            })
            }
        }
    }, [selected, comment, files])
    

    const CheckBox = ({ icon, value, text }) => {
        const random = Math.floor(Math.random() * 10000) + 1
        return (
            <div className={"col-auto custom-form-check" + (value ? ' ' + value : '') }>
                <input  type="radio" checked={selected === value ? 'checked' : ''} id={'custom-form-check-' + random} onChange={(e) => !disabled ? setSelected(value) : null} />
                <label htmlFor={'custom-form-check-' + random}>
                    <span>
                        <span>
                            { text ? text : null}
                            { icon ? <FontAwesomeIcon fixedWidth icon={['fal', icon]} /> : null}
                        </span>
                    </span>
                </label>
            </div>
        )
    }

    return (
        <div ref={attachmentRef} className={"box p-3 mb-3" + (error  ? " box-error" : "")}>
            <div className="row mb-2">
                <div className="col">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    {error ? <small className="text-danger">{error.value}</small> : null}
                </div>
                <div className="col-auto">
                    <div className="row gx-1">
                        <CheckBox 
                            icon={'check'}
                            value={'success'}
                        />
                    </div>
                </div>
                
                <div className="col-12">
                    
                    {
                        !disabled ? 
                            <textarea value={comment ? comment : ''} onChange={(e) => setComment(e.target.value)} rows={2} className="form-control" placeholder="Fyll inn en kommentar"></textarea> :
                            <>
                                {
                                    comment ? <div className="border p-3"><p className="m-0">{comment}</p></div> : null
                                }
                            </>
                    }
                </div>
            </div>
            {!disabled ? 
                <FileUpload files={files} setFiles={setFiles} containerRef={attachmentRef} /> : 
                    
                files.length > 0 ? 
                    (<div className="file-wrapper mt-2">
                        {
                            files.map((file, key) => {
                                return <FileDownload key={key} {...file} />
                            })
                        }
                    </div>)
                : null
            }
        </div>
    )
}

export function RangeForm({ title, description, onChange, value, disabled, helperText }){
    
    const initialState = {
        value: value ? value.value : false,
        comment: value ? value.comment : null,
        attachments: value && value.attachments ? value.attachments : []
    }
    const [selected, setSelected] = useState(initialState.value)
    const [comment, setComment] = useState(initialState.comment)
    const [files, setFiles] = useState(initialState.attachments)
    const attachmentRef = createRef()

    useEffect(() => {
        if({value: value, comment: comment} !== initialState){
            if(onChange){
            onChange({
                value: selected,
                comment: comment,
                attachments: files
            })
        }
        }
    }, [selected, comment, files])

    return (
        <div ref={attachmentRef}  className="box p-3 mb-3">
            <div className="row mb-2">
                <div className="col">
                    <h4>{title}</h4>
                    <p>{description}</p>
                </div>
                <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                    <Range min={1} max={10} value={selected} setValue={setSelected} disabled={disabled} />
                </div>
                <div className="col-12">
                    
                    {
                        !disabled ? 
                            <textarea value={comment ? comment : ''} onChange={(e) => setComment(e.target.value)} rows={2} className="form-control" placeholder="Skriv eventuell kommentar her.."></textarea> :
                            <>
                                {
                                    comment ? <div className="border p-3"><p className="m-0">{comment}</p></div> : null
                                }
                            </>
                    }
                </div>
            </div>
            {!disabled ? 
                <FileUpload files={files} setFiles={setFiles} containerRef={attachmentRef} /> : 
                    
                files.length > 0 ? 
                    (<div className="file-wrapper mt-2">
                        {
                            files.map((file, key) => {
                                return <FileDownload key={key} {...file} />
                            })
                        }
                    </div>)
                : null
            }
        </div>
    )
}

function FileUpload(props){

    const { uploadFile } = useContext(AuthContext)
    
    const { files, setFiles, containerRef } = props

    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.addEventListener('change', (e) => {
        e.preventDefault();
        handleDrop(e.path ? e.path[0].files : e.target.files)
    })
    useEffect(() => {

    }, [files])
    
    useEffect(() => {
        if(containerRef.current){
            ['dragenter', 'dragover'].forEach(eventName => {
                
                containerRef.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }, false)
                
            });

            ['dragleave'].forEach(eventName => {
                
                containerRef.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }, false)
                
            })

        
            containerRef.current.addEventListener('drop', (e) => {
                e.preventDefault()
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    handleDrop(e.dataTransfer.files)
                    e.dataTransfer.clearData()  
                }
                
            })
        }

        return () => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                if(containerRef.current){
                    containerRef.current.removeEventlistener(eventName)
                }
            })
        }
    }, [containerRef])

    const handleDrop = async (fileList) => {
        let newFiles = []
        for (var i = 0; i < fileList.length; i++) {
			if(!files.some(file => file.name === fileList[i].name)){
                
                const data = await uploadFile(fileList[i])

                if(data){
                    newFiles = [...files, data]
                }
                
                
				
			}
		}


        setFiles(newFiles)
    }

    const removeFile = (id) => {
        setFiles(files.filter(f => f.id !== id))
    }

    const FileIcon = ({type}) => {
        switch(type){
            case 'image/jpeg':
                return <FontAwesomeIcon icon={['fal', 'file-image']} size="1x" fixedWidth />

            default:
                return <FontAwesomeIcon icon={['fal', 'file-lines']} size="1x" fixedWidth />

        }
    }

    const FileItem = ({ id, file_name, file_type, file_size }) => {
                
        return (
            
                <div className="file-item p-1">
                    <div className=" row align-items-center gx-1">
                        <div className="col-auto">
                            <FileIcon type={file_type} />
                        </div>
                        <div className="col">
                            <p className="m-0 fw-bold">{file_name}</p>
                        </div>
                        <div className="col-auto">
                            <p className="text-danger pointer mb-0"  onClick={() => removeFile(id)}>Fjern</p>
                        </div>
                    </div>
                </div>
            
        )
    }

    return (
        <div>
            <div className="row gx-2 text-primary pointer" onClick={() => fileSelector.click()}>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fad', 'file']} />
                </div>
                <div className="col">
                    Legg til vedlegg eller dra og slipp
                </div>
            </div>
            { files.length > 0 ? 
                (<div className="file-wrapper mt-2">
                    {
                        files.map((el, index) => {
                            return (
                                <FileItem 
                                    key={index}
                                    {...el}
                                />)
                            })  
                    }
            
                </div>)
            : null }
        </div>
    )
}

function FileDownload({ id, file_name, file_type, file_size}){

    const { downloadAttachment } = useContext(AuthContext)
    const FileIcon = () => {
        switch(file_type){
            case 'image/jpeg':
                return <FontAwesomeIcon icon={['fal', 'file-image']} size="1x" fixedWidth />

            default:
                return <FontAwesomeIcon icon={['fal', 'file-lines']} size="1x" fixedWidth />

        }
    }

    return (
        
            <div className="file-item p-1 pointer" onClick={() => downloadAttachment(id, file_name)}>
                <div className=" row align-items-center gx-1">
                    <div className="col-auto">
                        <FileIcon />
                    </div>
                    <div className="col">
                        <p className="m-0 fw-bold">{file_name}</p>
                    </div>
                    <div className="col-auto">
                        <p className="text-primary pointer mb-0">Last ned</p>
                    </div>
                </div>
            </div>
        
    )
    
}
