/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Dropdown } from "react-bootstrap";
import moment from 'moment'
import React, { useEffect, useContext, useState } from "react";
//import GridLayout from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { Link, Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import Tooltip from "../../../components/Tooltip";
import ImageStack from "../../../components/ImageStack";
import Table from "../../../components/Table";
import UserPhoto from "../../../components/UserPhoto";
import { ConfigContext } from "../../../providers/ConfigProvider";
import { CompanyContext } from "../../../providers/CompanyProvider";
import { formatDate, getDeadlineType, getNextDeadline } from "../../../components/Functions";
import { AccessContext } from "../../../providers/AccessProvider";
import CompanyConsultantDashboard from "./CompanyConsultantDashboard";
import { DomainContext } from "../../../providers/DomainProvider";



export default function CompanyDashboard(){
    moment.locale('no')
    
    const { domainAccess } = useContext(DomainContext)
    const { company } = useContext(AuthContext)


    return domainAccess ? (company.companyType === 1 ? <CompanyConsultantDashboard />  : <GridDashboard />) : <GridDashboard />
    
}

function GridDashboard(){
    const { user } = useContext(AuthContext)

    

    return (
        <div>
            <div>
                <div className="p-md-3 p-2 p-lg-4">
                    <h2 className="bold">Velkommen tilbake {user.fullname}</h2>
                    <p>Du har to oppgaver og ingen samtaler</p>
                </div>
                <div className="row px-md-3 px-2">
                    <div className="col-12">
                        <DashboardTasks />
                    </div>
                    <div className="col-lg-6 col-12">
                        <Conversations />
                    </div>
                    <Shortcuts />
                </div>
            </div>
        </div>
    )
}

export function ApiTest(){
    const { apiCall } = useContext(AuthContext)

    return (
        <div className="py-5">
            <h1>Testing av API</h1>
            <button className="btn btn-primary" onClick={() => apiCall({action: 'api-unit-testing', username: 'fail'})}>Lorem ipsum</button>
        </div>
    )
}



export function DashboardTasks(){

    const { apiCall, mustRelog, domain, company } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const [tasks, setTasks] = useState(null)


    useEffect(() => {
        getTasks()
    }, [company])
    useEffect(() => {
        if(!mustRelog){
            getTasks();
        }
    }, [mustRelog])

    const getTasks = async () => {
        const status = await apiCall({action: 'get-my-tasks-on-company', company: company.id})

        
        var tempArray = [];
        
        if(status.data.status === 1){
            
            
            let allTasks = status.data.data
            setTasks(allTasks)
            
            
        }else{
            setTasks([])
            //notify(status.data.status, status.data.title, status.data.message)
        }
    }

    const navigate = useNavigate()
  


    return (
        <div className="p-4 box mb-3">
            <div>
                <div className="row">
                    <div className="col">
                        <h3 className="bold mb-4">Dine oppgaver</h3>
                    </div>
                    <div className="col-auto">
                        <Link to="../oppgaver/ny" className="btn btn-primary"><FontAwesomeIcon icon={['fal', 'plus']} /> Ny oppgave</Link>
                    </div>
                </div>
                

                <Table
                    gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "2fr 1fr 2fr 1fr"]}
                    gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                    perPage={5}
                    rows={tasks}
                    showResult
                    unit="oppgaver"
                    onRowClick={(e) => {
                        navigate(`/${domain.hash}/${company.hash}/oppgaver/${e.id}`)
                        
                        
                    }}
                    customSort={(rows) => {
                        if(!rows) return false
                        return rows.sort((a, b) => {
                            const a1 =  getNextDeadline({deadline: a.deadline, subtasks: a.subtasks, lastSubmission: a.lastSubmission})
                            const b1 = getNextDeadline({deadline: b.deadline, subtasks: b.subtasks, lastSubmission: b.lastSubmission})
                            return a1.timestamp - b1.timestamp
                        })

                    
                    }}
                    columns={[
                        { 
                            grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                            keys: ['name', 'companies'],
                            display: (e) => {
                                
                                return (
                                    <div>
                                        <span className="bold d-block">{e.name}</span>
                                        {
                                            e.companies && e.companies.length && e.companies.map((company) => {
                                                return <span className="bordered-color success px-1">{company.name}</span>
                                            })
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                            keys: ['deadline', 'subtasks'],
                            display: (e) => {
                                let deadlineClass = ''
                                const deadline = getNextDeadline({deadline: e.deadline, subtasks: e.subtasks, lastSubmission: e.lastSubmission})
                                if(deadline.daysUntil){
                                    switch(true){
                                        case deadline.daysUntil < 0:
                                            deadlineClass = 'text-danger'
                                            break
                                        case deadline.daysUntil <= 7:
                                            deadlineClass = 'text-warning'
                                            break;
                                    }
                                }
                                return (
                                    <div style={{lineHeight: 1}}>
                                        <span className={"d-block mb-1 " + deadlineClass}>{deadline.text}</span>
                                        <small>
                                            <FontAwesomeIcon size="sm" icon={['fal', 'repeat']} style={{marginRight: 5}} />
                                            <i>{getDeadlineType({deadline: e.deadline})}</i>
                                        </small>   
                                    </div>
                                ) 
                            }
                        },
                        {
                            grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                            keys: ['priority', 'form', 'subtasks'],
                            display: (e) => {
                                return (
                                    <div className="row gx-1">
                                        {
                                            e.priority ? (
                                                <div className="col-auto">
                                                    <Tooltip content="Prioritert oppgave" placement="bottom">
                                                        <div className="bordered-color danger py-1 px-2">
                                                            <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            e.form ? (
                                                <div className="col-auto">
                                                    <Tooltip content="Skjema" placement="bottom">
                                                        <div className="bordered-color success py-1 px-2">
                                                            <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            e.subtasks && e.subtasks.length > 0 ? (
                                                <div className="col-auto">
                                                    <Tooltip content="Underoppgaver" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                    </div>
                                                    </Tooltip>
                                                </div>
                                            ) : null
                                        }
                        
                                    </div>
                                )
                            }
                        },
                        {
                            grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                            keys: ['responsibles'],
                            display: (e) => {
                                return (e.responsibles && e.responsibles.length ? <ImageStack users={e.responsibles} /> : <FontAwesomeIcon icon={['fas', 'circle-exclamation']} className="text-danger" />)
                            }
                        },
                        {
                            grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                            keys: ['pinned'],
                            display: (e) => {
                                return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                            }
                        }      
                    ]}
                />
               
            </div>
        </div>
    )
}

export function DashboardTeams(){
    return (
        <h1>Teams</h1>
    )
}

function Conversations(){
    const { unreadMessages } = useContext(CompanyContext)
    const { domain, company } = useContext(AuthContext)
    const navigate = useNavigate()
    if(unreadMessages.length === 0){
        
            return (
                <div className="p-4 h-100 d-flex flex-column box">
                    <div className="row align-items-center mb-4 px-1">
                        <div className="col">
                            <h2 className="bold">Samtaler</h2>
                        </div>
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                        </div>
                    </div>
                    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                        <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <FontAwesomeIcon size="2x" icon={['fad', 'comments']} className="text-primary" />
                        </div>
                        <div className="text-center">
                            <h6 className="bold text-primary">Ingen samtaler</h6>
                            <p>Det er ingen samtaler å følge opp!</p>
                        </div>
                    </div>
                </div>
            )
        
    }
    return (
        <div className="p-4 box">
            <div className="row align-items-center mb-4 px-1">
                <div className="col">
                    <h2 className="bold">Samtaler</h2>
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
                </div>
            </div>
        
            <Table
                id="company-dashboard-tasks"
                gridTemplateColumns={["min-content 1fr fit-content(8ch)", "fit-content(8ch) 1fr fit-content(8ch)", "fit-content(8ch) 1fr fit-content(8ch)"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={5}
                rows={unreadMessages}
                showResult
                unit="samtaler"
                onRowClick={(e) => {
                    
                    navigate(`/${domain.hash}/${company.hash}/kanaler/${e.alias}/samtaler`)
                    
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                        keys: ['conversations'],
                        display: (e) => {
                            const lastMessage = e.conversations[e.conversations.length - 1]
                            
                            return (
                                <div style={{marginRight: '1rem'}}>
                                    <UserPhoto photo={lastMessage?.message.createdby.photo} name={lastMessage?.message.createdby.fullname} size={[50, 40, 30]} />
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 2", "1 / 2 / 2 / 2", "1 / 2 / 2 / 2"],
                        keys: ['message', 'conversations'],
                        display: (e) => {
                            
                            const lastMessage = e.conversations[e.conversations.length - 1]

                            let dateString = formatDate(lastMessage.message.createdon, 'diff')
                            

                            return (
                                <div>
                                    <h6 className="bold">{lastMessage?.message.createdby.fullname}</h6>
                                    <div className="row gx-3 hint-text align-items-center">
                                        <div className="col-auto">
                                            <span className="hint-text">{dateString}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row gx-1 align-items-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'message-lines']} />
                                                </div>
                                                <div className="col-auto">
                                                    {e.conversations.length}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 3 / 2 / 4"],
                        keys: ['company', 'title'],
                        display: (e) => {
                            return (
                                <div>
                                    <span className="d-block bold">{e.title}</span>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5"],
                        keys: ['channel', 'message'],
                        display: (e) => {
                            return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                        }
                    }      
                ]}
            />
            
        </div>
    )
}

function Shortcuts(){
    const { company, domain } = useContext(AuthContext)
    const { companyShortcuts } = useContext(CompanyContext)
    const navigate = useNavigate()

    function Shortcut({ type, externalLink, internalLink, title }){

        const drawShortcutIcon = () => {
            if(type === 'external') return ['far', 'arrow-up-right-from-square']
            if(internalLink && internalLink.icon) return internalLink.icon
            return ['far', 'file']
        }

        const onClick = () => {
            if(type === 'external'){
                window.open(externalLink)
            }else{
                let url = ''
                switch(internalLink.value.type){
                    case 'SagaNewTask':
                        url = `oppgaver/${internalLink.value.id}`
                        break;
                    case 'SagaChannel':
                        url = `kanaler/${internalLink.value.alias}/samtaler`
                        break
                    case 'SagaDocument':
                        url = `dokumenter/${internalLink.value.alias}`
                        break
                }
        
                
                navigate(`/${domain.hash}/${company.hash}/${url}`)
                
            }

        }

        return (
            
            <div className="col-md-2 col-6  mb-md-3 mb-2">
                <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary h-100" onClick={onClick}>
                    <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold">{title}</h6>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                companyShortcuts.map((item, key) => <Shortcut key={key} {...item} />)
            }
        </>
    )
    return (
        <div className="p-md-4 p-3 box">
            <div className="row align-items-center mb-md-4 mb-2 px-md-1">
                <div className="col">
                    <h2 className="bold">Snarveier</h2>
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                </div>
            </div>
        </div>
    )
}
