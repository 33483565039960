import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import ContextMenu, { ContextMenuItem } from "../../../components/ContextMenu"
import DropDown from "../../../components/DropDown"
import Modal from "../../../components/Modal"
import RadioGroup from "../../../components/RadioGroup"
import Table from "../../../components/Table"
import UserPhoto from "../../../components/UserPhoto"
import { AuthContext } from "../../../providers/AuthProvider"
import { ConfigContext } from "../../../providers/ConfigProvider"
import { DomainContext } from "../../../providers/DomainProvider"
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../../components/Input"


export default function DomainSettings(){

    const { domain, apiCall, reloadPaths, getTagableObjects } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { hasPermission, domainShortcuts, reloadDomainProperties, reloadDomainCompanies } = useContext(DomainContext)

    const navigate = useNavigate()

    const [areas, setAreas] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [confirmDomainName, setConfirmDomainName] = useState('')
    const [canDeleteDomain, setCanDeleteDomain] = useState(false)

    const [shortcuts, setShortcuts] = useState(domainShortcuts)
    const [shortcutModalOpen, setShortcutModalOpen] = useState(false)
    const [tagable, setTagable] = useState(null)

    useEffect(async () => {
        const { data, status } = await getTagableObjects()

        if(status === 1){
            const tags = data.map(item => {
                return {
                    label: `${item.title}`,
                    icon: findIcon(item.type),
                    value: item,
                    description: `${getType(item.type)}${item.company ? `, ${item.company?.name}` : ''} `
                }
            })
            setTagable(tags)
        }
    }, [])

    useEffect(() => {
        setShortcuts(domainShortcuts)
    }, [domainShortcuts])

    useEffect(() => {
        reloadDomainProperties()
    }, [])

    useEffect(() => {
        console.log('shortcuts updated', shortcuts)
        //if(shortcuts.length) saveShortcuts()
    }, [shortcuts])

    const saveShortcuts = async (shortcuts) => {
        const { data } = await apiCall({action: 'save-domain-shortcuts', shortcuts: shortcuts})

        console.log(data)
        if(data.status === 1) reloadDomainProperties()
    }

    const findIcon = (t) => {
        switch(t){
            case 'SagaNewTask':
                return ['far', 'chart-simple']
            case 'SagaDocument':
                return ['far', 'file-lines']
            case 'SagaChannel':
                return ['far', 'messages']
            default:
                return ['far', 'file']
        }
    }

    const getType = (t) => {
        switch(t){
            case 'SagaNewTask':
                return 'Oppgave'
            case 'SagaDocument':
                return 'Dokumentområde'
            case 'SagaChannel':
                return 'Kanal'
            default:
                return null
        }
    }



    useEffect(() => {
        setConfirmDomainName('')
    }, [deleteModalOpen])

    useEffect(() => {
        setCanDeleteDomain(confirmDomainName === domain.name)
    }, [confirmDomainName])

    useEffect(() => {
        loadAreas()
        setShortcuts(domain.properties?.shortcuts ? domain.properties?.shortcuts : [])
    }, [])

    const loadAreas = async () => {
        const { data } = await apiCall({
            action: 'loadDomainAreas'
        })

        if(data.status === 1){
            setAreas(data.data)
        }else{
            notify(data.status, data.title, data.message)
            setAreas([])
        }

    }

    const deleteDomain = async () => {
        const { data } = await apiCall({action: 'deleteDomain'})
       
        notify(data.status, data.title, data.message)
        if(data.status === 1){
            await reloadPaths()
            navigate('/')
        }
        
    }

    const deleteShortcut = (shortcut) => {
        const newShortcuts = shortcuts.filter(s => s !== shortcut)
        setShortcuts(newShortcuts)
        saveShortcuts(newShortcuts)
    }

    const archiveCompany = async (id) => {
        const { data } = await apiCall({
            action: 'archive-domain-company-relation',
            id: id,
        })

        notify(data.status, data.title, data.message)
        if(data.status === 1){
            loadAreas()
        }

    }

    


    return (
        <div className="p-md-3 p-2">
            <div className="p-md-4 p-3 box">
                <div className="row">
                    <div className="col">
                        <h3 className="bold">Innstillinger</h3>
                        <p>Her kan du redigere innstillinger for områder og brukere. Som standard i feltet under brukes domenet....</p>
                    </div>
                    {
                        hasPermission('superAdmin') ? 
                        <div className="col-auto">
                        <Link to="nytt-domene" className="btn btn-primary">
                            <div className="row gx-2">
                                <div className="col-auto"><FontAwesomeIcon icon={['fal', 'plus']} /></div>
                                <div className="col-auto">Legg til domene</div>
                            </div>
                        </Link>
                    </div>
                        : null
                    }
                    
                </div>
                
                <hr className="my-4" />
                <div className="row mb-5">
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <UserPhoto photo={domain.config.logoSmall} name="Saga" size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                <h4 className="m-0 bold">{domain.name}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <Link to="rediger" className="btn btn-border">
                            <div className="row gx-2">
                                <div className="col-auto"><FontAwesomeIcon icon={['fal', 'pencil']} /></div>
                                <div className="col-auto">Rediger domene</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-danger" onClick={() => setDeleteModalOpen(true)}>
                            <div className="row gx-2">
                                <div className="col-auto"><FontAwesomeIcon icon={['fal', 'trash']} /></div>
                                <div className="col-auto">Slett domene</div>
                            </div>
                        </button>
                    </div>
                </div>

                <div className="mb-5">
                <Table
                    rows={areas}
                    perPage={5}
                    gridTemplateColumns={["repeat(4, 1fr)", "repeat(4, 1fr)", "repeat(2, 1fr)"]}
                    gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                    headers={[
                        { title: 'Navn', key: 'name', sort: 'string'},
                        { title: 'Antall aktive brukere', key: 'users', sort: 'array'},
                        { title: 'Type', key: 'type', sort: 'string'},
                        { title: null }
                    ]}
                    columns={[
                        { 
                            grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                            keys: ['name', 'image', 'alias', 'hash', 'relationType'],
                            display: (e) => {
                                return (
                                    <div className="row align-items-center gx-2">
                                        
                                        <div className="col-auto">
                                            <Link to={`../${e.hash}/oversikt`} className="medium">{e.name}</Link>
                                        </div>
                                        {
                                            e.relationType === 'Archived' ? 
                                            <div className="col-auto ">
                                            <small className="bg-danger px-3 py-1 rounded text-white caps">Arkivert</small>
                                        </div>
                                        : null
                                        }
                                        
                                    </div>
                                )
                            }
                        },
                        {
                            grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "2 / 1 / 3 / 2"],
                            keys: ['users'],
                            display: (e) => {
                                return(<span>{e.users > 0 ? <span className="text-success medium">{e.users} aktive brukere</span> : <span>Ingen brukere</span>}</span>)
                            }
                        },
                        {
                            grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 2 / 2 / 3"],
                            keys: ['type'],
                            display: (e) => {
                                return(
                                    <div className="d-flex justify-content-md-start justify-content-end">
                                        <span>{e.type == 1 ? 'Bedrift' : 'Rom'}</span>
                                    </div>
                                )
                            }
                        },
                        {
                            grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 2 / 3 / 3"],
                            keys: ['consultants', 'hash', 'relationType', 'id'],
                            display: (e) => {
                                
                                return (
                                    <div className="d-flex justify-content-end">
                                    <ContextMenu
                                        buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                    >
                                        <ContextMenuItem
                                            label="Rediger"
                                            icon={['far', 'pencil']}
                                            onClick={() => navigate(`/${domain.hash}/${e.hash}/innstillinger`)}
                                        />
                                        <ContextMenuItem 
                                            label={e.relationType === 'Archived' ? "Reaktivér område" : "Arkiver område"}
                                            icon={['far', 'box-archive']}
                                            onClick={() => archiveCompany(e.id)}
                                        />
                                    </ContextMenu>
                                    </div>
                                    
                                )
                                
                            }
                        }    
                    ]}
                    ifEmpty={(
                        <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
                            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FontAwesomeIcon size="2x"   icon={['far', 'building']} className="text-primary" />
                            </div>
                            <div className="text-center">
                                <h6 className="bold text-primary">Ingen områder i domenet</h6>
                                <p>Her vil du se alle områder i ditt domene</p>
                                <Link to="nytt-omrade" className="btn btn-primary px-4">Opprett område</Link>
                            </div>
                        </div>
                    )}
                ></Table>
                </div>
                <div className="row">
                    <div className="col">
                        <h3 className="bold">Snarveier</h3>
                        <p>Her kan du opprette og redigere snarveier som vises på oversikten til alle medlemmer av domenet.</p>
                    </div>
                    
                </div>
                <div className="row gx-2">
                    {
                        shortcuts.length ? shortcuts.map((item, key) => <Shortcut key={key} item={item} deleteShortcut={deleteShortcut} />) : null
                    }
                    <div className="col-md-2" onClick={() => setShortcutModalOpen(true)}>
                        <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary">
                            <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FontAwesomeIcon fixedWidth size="3x" icon={['far', 'plus']} className="text-primary" />
                            </div>
                            <div className="text-center">
                                <h6 className="bold">Legg til ny snarvei</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={shortcutModalOpen} setOpen={setShortcutModalOpen}>
                <NewShortcut setOpen={setShortcutModalOpen} tagable={tagable} setShortcuts={setShortcuts} shortcuts={shortcuts} saveShortcuts={saveShortcuts} />
            </Modal>
            <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
                <div className="p-3">
                    <h3 className="bold">Vil du slette domenet?</h3>
                    <p>Er du helt sikker på at du vil slette dette domenet?</p>
                    <div className="form-group mb-4">
                        <label>Skriv inn navnet på domenet for å bekrefte sletting</label>
                        <input value={confirmDomainName} onChange={(e) => setConfirmDomainName(e.target.value)} type="text" className="form-control" placeholder="Navn på domenet" />
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <button className="btn btn-danger" onClick={() => deleteDomain()} disabled={!canDeleteDomain}>
                                <div className="row gx-2">
                                    <div className="col-auto"><FontAwesomeIcon icon={['fal', 'trash']} /></div>
                                    <div className="col-auto">Ja, slett</div>
                                </div>
                            </button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-border" onClick={() => setDeleteModalOpen(false)}>
                                <div className="row gx-2">
                                    <div className="col-auto"><FontAwesomeIcon icon={['fal', 'xmark']} /></div>
                                    <div className="col-auto">Nei, la meg tenke på det</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

function Shortcut({ item, deleteShortcut }){
    const { type, externalLink, internalLink, title } = item
    const drawShortcutIcon = () => {
        if(type === 'external') return ['far', 'arrow-up-right-from-square']
        if(internalLink && internalLink.icon) return internalLink.icon
        return ['far', 'file']
    }

    return (
        
        <div className="col-md-2 mb-2 h-100">
            <div className="box hover-primary py-2 px-3 h-100">
                <div className="row justify-content-end gx-0"><div className="col-auto"><FontAwesomeIcon icon={['fas', 'xmark']} size="sm" className="pointer" onClick={() => deleteShortcut(item)} /></div></div>
                <div className="py-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold">{title}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NewShortcut({ setOpen, tagable, setShortcuts, shortcuts, saveShortcuts }){
    
    let schema = yup.object().shape({
        title: yup.string().required("Tittel er påkrevd"),
        type: yup.string(),
        internalLink: yup.object().nullable().when('type', {
            is: 'internal',
            then: yup.object().typeError("Snarvei er påkrevd").required(),
        }),
        externalLink: yup.string().when('type', {
            is: 'external',
            then: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Skriv inn en korrekt lenke'
            )
            .required('Lenke er påkrevd'),
        }),
    });


    const formik = useFormik({
        initialValues: {
            title: '',
            type: 'internal',
            internalLink: null,
            externalLink: ''

        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            setShortcuts([...shortcuts, values])
            saveShortcuts([...shortcuts, values])
            setOpen(false)
        },
    });
    
    const drawShortcutIcon = () => {
        if(formik.values.type === 'external') return ['far', 'arrow-up-right-from-square']
        if(formik.values.internalLink && formik.values.internalLink.icon) return formik.values.internalLink.icon
        return ['far', 'file']
    }

    return (
        <div className="p-3 d-flex" style={{minWidth: 600, maxWidth: '90vw', minHeight: '50vh', overflow: 'visible'}}>
            <form className="d-flex flex-column flex-grow-1" onSubmit={formik.handleSubmit}>
                <div className="flex-grow-1">
                    <h3 className="bold">Lag ny snarvei</h3>
                    <div className="form-group mb-2">
                        
                        <RadioGroup
                            items={[
                                {label: 'Intern snarvei', value: 'internal'},
                                {label: 'Ekstern snarvei', value: 'external'}
                            ]}
                            selected={formik.values.type}
                            select={(value) => formik.setFieldValue('type', value)}
                            large
                        />
                    </div>
                    {
                        formik.values.type === 'internal' ? 
                            <div className="form-group mb-3">
                                <label>Snarvei til</label>
                                {
                                    tagable ? 
                                    <DropDown
                                        options={tagable}
                                        placeholder="Velg snarvei"
                                        error={formik.touched.internalLink && Boolean(formik.errors.internalLink)}
                                        touched={formik.touched.internalLink}
                                        helperText={formik.touched.internalLink && formik.errors.internalLink}
                                        value={formik.values.internalLink}
                                        onChange={(value) => {
                                            formik.setFieldValue('internalLink', value)
                                            formik.setFieldValue('title', value.label)
                                        }}
                                    />
                                    : null
                                }
                            </div>

                        :
                        <div className="form-group mb-3">
                            <Input
                                name="externalLink"
                                label="Lenke til ekstern adresse"
                                type="text"
                                value={formik.values.externalLink}
                                onChange={formik.handleChange}
                                error={formik.touched.externalLink && Boolean(formik.errors.externalLink)}
                                touched={formik.touched.externalLink}
                                helperText={formik.touched.externalLink && formik.errors.externalLink}
                                placeholder={"Eks. https://www.vg.no/"}
                            />
                        </div>     
                    }
                    
                    <div className="form-group mb-3">
                        <Input
                            name="title"
                            label="Tittel på snarvei"
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            touched={formik.touched.title}
                            helperText={formik.touched.title && formik.errors.title}
                            placeholder={"Tittel på snarvei"}
                        />
                    </div>

                    <div className="row justify-content-center mt-3">
                        <div className="col-6">
                            <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary">
                                <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                                </div>
                                <div className="text-center">
                                    <h6 className="bold">{formik.values.title}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" className="btn btn-border w-100" onClick={() => setOpen(false)}>Avbryt</button>
                    </div>
                    <div className="col">
                        <button type="submit" className="btn btn-success w-100">Lagre snarvei</button>
                    </div>
                </div>
            </form>
        </div>
    )
}