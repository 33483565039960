
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { useOnClickOutside } from "./Functions"
import Modal from "./Modal"
import { useKeyPressEvent } from "react-use";

export const DrawerContext = createContext({
    isOpen: false,
    setOpen: () => {}
})

const DrawerProvider = ({children, setOpen, open}) => {
    const [isOpen, setIsOpen] = useState(open)
    const [dirty, setDirty] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        if(open !== isOpen){
            setIsOpen(open)
        }
    }, [open])

    useEffect(() => {
        if(isOpen !== open){
            setOpen(isOpen)
        }
    }, [isOpen])

    return <DrawerContext.Provider value={{
        isOpen,
        setOpen: (bool) => {
            console.log('Drawer triggered', dirty)
            if(dirty){
                setModalOpen(true)
            }else{
                setIsOpen(bool)
            }
        },
        forceClose: () => {
            console.log('Force close drawer')
            setDirty(false)
            setModalOpen(false)
            setOpen(false)
        },
        setDirty,
        modalOpen,
        setModalOpen
    }}>{children}</DrawerContext.Provider>
}

export default function Drawer({ open, children, classNames, setOpen}){

    
    
    return (
        <DrawerProvider setOpen={setOpen} open={open}>
            <DrawerComponent classNames={classNames}>
                {children}
            </DrawerComponent>
        </DrawerProvider>
    )
}

function DrawerComponent({ children, classNames  }){
    const ref = useRef()
    const { isOpen, setOpen, modalOpen, setModalOpen, forceClose } = useContext(DrawerContext)

    
    useOnClickOutside(ref, () => setOpen(false))

    useKeyPressEvent("Escape", () => setOpen(false));
    return(
        <CSSTransition in={isOpen} timeout={500} classNames="drawer-container" unmountOnExit>
            <div className="drawer-wrapper">
                <CSSTransition in={isOpen} timeout={100} classNames="drawer-container" unmountOnExit>
                    <div className={`drawer-container ${classNames}`} ref={ref}>
                        <div className="container">
                            {children}
                            <Modal open={modalOpen} setOpen={setModalOpen}>
                                <div className="p-2">
                                <h3 className="bold">Vil du lukke?</h3>
                                <p>Du har gjort endringer som ikke vil bli lagret, er du sikker på at du vil lukke?</p>
                                <div className="row">
                                    <div className="col">
                                        <button type="button" className="btn btn-warning w-100" onClick={() => forceClose()}>Ja, lukk uten å lagre</button>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn btn-success w-100" onClick={() => setModalOpen(false)}>Nei</button>
                                    </div>
                                </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            
        </CSSTransition>
    )
}