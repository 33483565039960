import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { createContext, useContext, useEffect, useState } from "react"
import { ConfigContext } from "../providers/ConfigProvider"
import Draft from "./Draft"
import DraftWysiwyg from './DraftWysiwyg';
import Drawer, { DrawerContext } from "./Drawer"
import FileUpload from "./FileUpload"
import FormBuilderProvider from "./FormBuilder"
import IconCheck from "./IconCheck"
import RadioGroup from "./RadioGroup"
import Tooltip from "./Tooltip"
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "./Input"
import TaskRepetition from "./TaskRepetition"
import { getTaskRepetitionType } from "./Functions"

const SubtaskContext = createContext({
    form: {},
    activeSubtask: null,
    setActiveSubtask: () => {},
    setForm: () => {}
})

export default function SubtaskProvider({ TaskContext }){

    const { subtasks, addSubTask, templateForms } = useContext(TaskContext)
    const [form, setForm] = useState({})
    const [activeSubtask, setActiveSubtask] = useState(null)
    

    return <SubtaskContext.Provider value={{
        subtasks,
        form,
        activeSubtask,
        templateForms,
        setActiveSubtask: (task) => {
            console.log('setActiveSubtask', task)
            if(task) setForm(task.form ? task.form : {})
            
            setActiveSubtask(task)
        },
        setForm,
        addSubTask
    }}><Main /></SubtaskContext.Provider>
}

function Main(){
    
    const { activeSubtask, subtasks, setForm } = useContext(SubtaskContext)
    const [showTaskList, setShowTaskList] = useState(false)

    useEffect(() => {
        if(subtasks.length > 0){
            setShowTaskList(true)
        }
    }, [subtasks])

    useEffect(() => {
        setForm({})
    }, [showTaskList])
    
   
    if(showTaskList && !activeSubtask){
        return (
            <SubTaskList setShowTaskList={setShowTaskList} />
        )
    }

    return (
        <NewSubTask  />
    )
}

function SubTaskList({ close, setShowTaskList }){
    const { subtasks } = useContext(SubtaskContext)
    const { setOpen } = useContext(DrawerContext)
    
    return (
        <div className="p-3">
            <div className="row justify-content-end">
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['fas', 'xmark']} />
                    </button>
                </div>
            </div>
            <h3 className="bold mb-5">Underoppgaver</h3>
            <hr />
            {
                subtasks.map((item, key) => {
                    return <SubTaskItem key={key} item={item} />
                })
            }
            <div className="icon-check-container mt-4" >
                <div className="row gx-2 align-items-center">
                    <div className="col-auto">
                        <button className={"icon-check-button"} onClick={() => setShowTaskList(false)}>
                            <FontAwesomeIcon icon={['fal', 'plus']} />
                        </button>
                    </div>
                    <div className="col">
                        <span className='hint-text'>Legg til underoppgave</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SubTaskItem({ item }){
    const { name, startDate, endDate, repetition, responsibleCompanies, responsibleUsers, form, interval, deadline } = item
    const { baseUrl } = useContext(ConfigContext)
    const { setActiveSubtask } = useContext(SubtaskContext)

    const intervalDescription = getTaskRepetitionType(interval ? interval : deadline)
    
    return (
        <div className="box p-3 mb-3 pointer"  onClick={() => setActiveSubtask(item)}>
            <div className="row align-items-center">
                <div className="col">
                    <h5 className="bold m-0">{name}</h5>
                </div>
                <div className="col-auto">
                    <div style={{lineHeight: 1}}>
                        <span className="d-block mb-1">{intervalDescription.description}</span>
                        <small>
                            <FontAwesomeIcon size="sm" icon={['fal', 'repeat']} style={{marginRight: 5}} />
                            <i>{intervalDescription.type}</i>
                        </small>   
                    </div>
                </div>
                <div className="col-auto">
                    <div className="image-stack">
                        {
                            responsibleUsers && responsibleUsers.length > 0 ? responsibleUsers.map((item, key) => {
                                return (
                                    <Tooltip content={item.fullname} key={key} placement={"top"}>
                                        { item.photo ? <img src={baseUrl + item.photo} />
                                            : <div className="profile-image-replacement" style={{width: 40, height: 40, fontSize: '1.8rem'}}><span>{item.fullname.substr(0, 1)}</span></div>
                                        }
                                    </Tooltip>
                                )
                            }) : 
                            <div className="icon-check-container" >
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <button className={"icon-check-button"}>
                                            <small style={{fontSize: '.7rem'}}>Åpen</small>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-auto">
                    <button className="btn btn-link">
                        <FontAwesomeIcon size='lg' icon={['far', 'arrow-right']} />
                    </button>
                </div>
            </div>
        </div>
    )
}

function NewSubTask(){
    
    const { form, setForm, activeSubtask, setActiveSubtask, addSubTask, subtasks } = useContext(SubtaskContext)
    const { setOpen, setDirty } = useContext(DrawerContext)

    let schema = yup.object().shape({
        name: yup.string().required("Oppgavenavn er påkrevd"),
    });

    

    const formik = useFormik({
        initialValues: {
            id: activeSubtask && activeSubtask.id ? activeSubtask.id : null, 
            name: activeSubtask ? activeSubtask.name : '',
            priority: activeSubtask ? activeSubtask.priority : false,
            signature: activeSubtask ? activeSubtask.signature : 1,
            interval: activeSubtask ? activeSubtask.interval : {
                repetition: 1,
                startDate: null,
                endDate: null
            },
            description: activeSubtask ? activeSubtask.description : null,
            files: activeSubtask ? activeSubtask.files : []
        },
        validationSchema: schema,
        onSubmit: async (values) => { 
            saveTask(values)
        },
    });


    const [formDrawerOpen, setFormDrawerOpen] = useState(false)

    const  handleDirty = () => {
        setDirty(formik.dirty)
    }

    const saveTask = (data) => {
       
        const index = subtasks.findIndex(t => t === activeSubtask);
        
        addSubTask({
            id: data && data.id ? data.id : null,
            name: data.name,
            files: data.files,
            description: data.description,
            interval: data.interval,
            priority: data.priority,
            signature: data.signature,
            form: form
        }, index)
        setDirty(false)
        setForm(null)
        
        setActiveSubtask(null)
    }

    return (
        <div className="p-3">
            <div className="row justify-content-between">
                <div className="col-auto">
                    {
                        subtasks && subtasks.length > 0 ? 
                    
                    <button className='btn btn-link mb-2' onClick={() => setActiveSubtask(null)}>
                        <div className="row gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                            </div>
                            <div className="col-auto">
                                <span className="bold">Underoppgaver</span>
                            </div>
                        </div>
                    </button>
                    : null}
                </div>
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['fas', 'xmark']} />
                    </button>
                </div>
            </div>
            <h3 className="bold">Ny underoppgave</h3>
            <hr />

            <div className="row mb-3">
                    <div className="col-auto">
                        <div onClick={() => setFormDrawerOpen(true)} className="border p-3 pointer" style={{borderRadius: '5px'}}>
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <FontAwesomeIcon className={form && Object.keys(form).length ? "text-primary" : ""} size='2x' icon={['fal', 'clipboard']} />
                                </div>
                                <div className="col">
                                    <h6 className='bold m-0'>Skjema</h6>
                                    
                                    {
                                        form && Object.keys(form).length ? <i>Rediger skjema</i>
                                        : <i className="hint-text">Legg til skjema</i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} onChange={() => handleDirty()}>
                    <div className="row">
                        <div className="col-lg-8">
                            
                            <Input
                                name="name"
                                label="Navn"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Navn på oppgave"}
                            />
                            <div className="form-group mb-3">
                                <label>Beskrivelse</label>
                                
                                <DraftWysiwyg
                                    value={formik.values.description}
                                    setValue={(e) => formik.setFieldValue('description', e)}
                                />
                                
                            </div>
                            <div className="form-group">
                                <label>Vedlegg</label>
                                <FileUpload
                                    files={formik.values.files}
                                    setFiles={(e) => formik.setFieldValue('files', e)}
                                />
                            </div>
                        </div>
                        
                        <div className="col">
                            
                            <div className="form-group mb-3">
                                <label>Signering</label>
                                <RadioGroup
                                    items={[
                                        {label: 'Ingen signering', value: 1},
                                        {label: 'Krev signatur av ansvarlige', value: 2},
                                    ]}
                                    selected={formik.values.signature}
                                    select={(e) => formik.setFieldValue('signature', e)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Prioritet</label>
                                <IconCheck
                                    checked={formik.values.priority}
                                    setChecked={(e) => formik.setFieldValue('priority', e)}
                                    icon="flag"
                                    color="danger"
                                    classes="mb-2"
                                />
                                <p><i>{ formik.values.priority ? 'Oppgaven er merket som prioritert' : 'Oppgaven har ingen spesiell prioritet'} </i></p>
                            </div>
                            <TaskRepetition
                                value={formik.values.interval}
                                setValue={(e) => formik.setFieldValue('interval', e)}
                            />
                        </div>
                    </div>
                
                    <div className="row mt-5 justify-content-center">
                        
                        <div className="col-5">
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary d-block">Lagre oppgave</button>
                            </div>
                        </div>
                    </div>
                </form>
                <Drawer open={formDrawerOpen} setOpen={setFormDrawerOpen}>
                    <FormBuilderProvider currentContext={SubtaskContext} />          
                </Drawer>

        </div>
    )
}
