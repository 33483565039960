/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext, useEffect, createContext } from "react";
import { Link, Outlet, useLocation, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import '@draft-js-plugins/mention/lib/plugin.css'
import { AuthContext } from "../../../providers/AuthProvider";
import Tooltip from "../../../components/Tooltip";
import { ConsultantContext } from "../../../providers/ConsultantProvider";
import Modal from "../../../components/Modal";
import UserPhoto from "../../../components/UserPhoto";
import { ConfigContext } from "../../../providers/ConfigProvider";
import DropDown from "../../../components/DropDown";
import PersonPicker from "../../../components/PersonPicker";
import { DomainContext } from "../../../providers/DomainProvider";




export const ChannelContext = createContext({
    channels: null,
    channelIndex: null,
    currentChannel: null,
    mentionable: [],
    changeChannel: () => {},
    loadChannels: () => {},
    addMessage: () => {},
    getChannel: () => {},
    getMentionable: () => {},
    archiveConversation: () => {}
})


const ChannelProvider = ({children}) => {
    const [channels, setChannels] = useState(null)
    const [channelIndex, setChannelIndex] = useState(null)
    const [currentChannel, setCurrentChannel] = useState(null)
    const [mentionable, setMentionable] = useState([])

    const { notify } = useContext(ConfigContext)
    const { user, apiCall, uploadFile } = useContext(AuthContext)
    const { companies } = useContext(ConsultantContext)

    const params = useParams()

    useEffect(() => {
        if(!params.id){
            setCurrentChannel(null)
        }
    }, [params])

    useEffect(() => {
        if(channels){
            if(channels[channelIndex]){
                setCurrentChannel(channels[channelIndex])
            }
        }
    }, [channelIndex])

    useEffect(() => {
        loadChannels()
    }, [])

    useEffect(() => {
        
        if(currentChannel && currentChannel.members && currentChannel.members.length){
            setMentionable(currentChannel.members)
        }else{
            setMentionable([])
        }
    }, [currentChannel])


    const getMentionable = () => {
        if(currentChannel && currentChannel.members.length){
            return currentChannel.members
        }
    }

    const loadChannels = async () => {
        const status = await apiCall({action: 'get-domain-channels'})  
        if(status){
            setChannels(status.data.data)
        }
    }

    const deleteChannel = async () => {
        const { data } = await apiCall({
            action: 'delete-channel', 
            channel: currentChannel.id,
        })
        notify(data.status, data.title, data.message)
        return data
    }

    const saveMessage = async (message, conversation, attachments) => {

        const convo = currentChannel.conversations[conversation]
        const status = await apiCall({
            action: 'api-new-channel-message', 
            conversation: convo.id,
            message: message,
            attachments: attachments
        })
        
        //TODO: Sette meldinga til .5 opacity og vente til server returnerer 
    }

    const newConversation = async (message, attachments) => {

        const status = await apiCall({
            action: 'api-new-channel-conversation', 
            channel: currentChannel.id,
            message: message,
            attachments: attachments
        })
    }

    const reloadChannel = async () => {
        const status = await apiCall({action: 'get-channel-conversations', channel: currentChannel.id})  
        console.log(status)
        if(status.data.status === 1){
            setCurrentChannel(status.data.data)
        }

        return true
    }

    const addMember = async (user) => {
        
        const { data } = await apiCall({
            action: 'add-channel-member',
            channel: currentChannel.id,
            userId: user
        })
        

        notify(data.status, data.title, data.message)
        reloadChannel()
    }

    const removeMember = async (user) => {
        const { data } = await apiCall({
            action: 'remove-channel-member',
            channel: currentChannel.id,
            userId: user
        })
        

        notify(data.status, data.title, data.message)
        reloadChannel()
    }

    const addTeam = async (team) => {
        
        const { data } = await apiCall({
            action: 'add-channel-team',
            channel: currentChannel.id,
            team: team
        })
        

        notify(data.status, data.title, data.message)
        reloadChannel()
    }

    const removeTeam = async (team) => {
        const { data } = await apiCall({
            action: 'remove-channel-team',
            channel: currentChannel.id,
            team: team
        })
        

        notify(data.status, data.title, data.message)
        reloadChannel()
    }

    return <ChannelContext.Provider value={{
        channels,
        channelIndex,
        currentChannel,
        mentionable,
        deleteChannel,
        loadChannels,
        changeChannel: (index) => {
            setChannelIndex(index)
            //Her må vi så hente inn tags og brukere basert på kanalen vi er i
        },
        addMessage: async (rawMessage, conversation, attachments) => {
            
            if(conversation !== null){
                await saveMessage(rawMessage, conversation, attachments)
            }else{
                await newConversation(rawMessage, attachments)
            }
            await reloadChannel()
            return true
        },
        deleteMessage: async (index, conversation) => {
            
            const newMessages = currentChannel?.conversations[conversation]?.messages.filter((item, i) => i !== index)
            const id = currentChannel?.conversations[conversation].id
            if(newMessages){
                const { data } = await apiCall({
                    action: 'delete-conversation-message',
                    conversation: id,
                    newMessages: newMessages
                })

                if(data.status === 1){
                    await reloadChannel()
                }else{
                    notify(data.status, data.title, data.message)
                }
            }
        },
        getChannel: async (id) => {
            const status = await apiCall({action: 'get-channel-conversations', channel: id})  
            
            if(status.data.status === 1){
                setCurrentChannel(status.data.data)
            }
        },
        addChannel: async (name, members, teams) => {
            const { data } = await apiCall({
                action: 'create-domain-channel', 
                name: name, 
                members: members,
                teams: teams
            })  
            
            console.log(data)
            
            if(data.status === 1){
                loadChannels()
            }

            notify(data.status, data.title, data.message)

            return data
        },
        getMentionable: () => {
            return getMentionable()
        },
        archiveConversation: async (index) => {
            if(currentChannel.conversations[index]){
                const status = await apiCall({action: 'archive-conversation', id: currentChannel.conversations[index].id})
                notify(status.data.status, status.data.title, status.data.message)
                if(status.data.status === 1) reloadChannel()
            }   
            console.log(currentChannel.conversations[index])
        },
        addMember,
        removeMember,
        addTeam,
        removeTeam,
    }}>{children}</ChannelContext.Provider>
}
export default function Channels(){
    return (
        <ChannelProvider>
            <ChannelsContainer />
        </ChannelProvider>
    )
}
function ChannelsContainer(){
    const { domain } = useContext(AuthContext)
    const { loadChannels, currentChannel } = useContext(ChannelContext)

    const [modalOpen, setModalOpen] = useState(false)

    let match = useMatch(`${domain.hash}/kanaler`);
    return (
        <div className="p-md-3 p-2 d-flex flex-column flex-grow-1 h-100">
            <div className="box flex-grow-1 d-flex flex-column">
                <div className="flex-grow-1 main-content">
                <div className="row gx-0 h-100">
                    <div className={`col-xl-3 col-lg-4 h-100 ${!currentChannel ? "channel-sidebar-open" : "channel-sidebar-closed"}`}>
                        <div className={`channel-sidebar w-100 ${!currentChannel ? "channel-sidebar-open" : "channel-sidebar-closed"} h-100`}>
                            <div className="channel-header p-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="bold mb-0">Alle kanaler</h6>
                                    </div>
                                    <div className="col-auto">
                                        <Tooltip content="Last inn på nytt" placement="top">
                                            <button className="btn btn-link" onClick={() => loadChannels()}>
                                                <FontAwesomeIcon icon={['fal', 'arrows-rotate']} />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                
                            </div>
                            <ChannelNav />
                            <div className="channel-header px-3 pt-3 pb-3 border-top">
                                <button className="btn btn-link text-primary" onClick={() => setModalOpen(true)}>
                                    <div className="row gx-0 align-items-center">
                                        <div className="col-auto">
                                            <FontAwesomeIcon fixedWidth icon={['fal', 'plus']}  />
                                        </div>
                                        <div className="col">
                                            Administrer kanaler
                                        </div>
                                    </div>
                                    
                                    
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    <div className={`col ${currentChannel ? "channel-content-open" : "channel-content-closed"}`}>
                        {
                            match ? (
                                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                    <FontAwesomeIcon size="3x" className="mb-3"  icon={['fad', 'messages']} />
                                    <div className="text-center">
                                        <h3 className="bold">Velg en kanal</h3>
                                        <p>Du må velge en kanal i venstre kolonne</p>
                                    </div>
                                </div>
                            ) : <Outlet />
                        }
                       
                    </div>
                </div>
            </div>
            </div>
            <Modal open={modalOpen} setOpen={setModalOpen} containerStyle={{minWidth: 300, overflow: 'visible'}}>
                <AddChannelModal setOpen={setModalOpen} />
            </Modal>
        </div>
    )
}
function AddChannelModal({ setOpen }){
    const { addChannel } = useContext(ChannelContext)
    const { domainUsers, domainTeams } = useContext(DomainContext)
    const { user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [members, setMembers] = useState([])
    const [teams, setTeams] = useState([])
    const [types, setTypes] = useState([])
    const [type, setType] = useState(null)
    const [users, setUsers] = useState([])
    const navigate = useNavigate()

    const saveChannel = async () => {
        const status = await addChannel(name, members, teams)
        if(status.status === 1){
            navigate({to: status.alias})
            setOpen(false)
        }
    }

    

    return (
        <div className="p-2">
            <h5>Legg til ny kanal</h5>
            <form onSubmit={(e) => {
                e.preventDefault()
                saveChannel()
            }}>
                <div className="form-group mb-3">
                    <label>Navn på kanal</label>
                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" placeholder="Gi kanalen et navn" />
                </div>
            </form>
            <div className="form-group mb-3">
                <label>Velg medlemmer</label>

                <PersonPicker
                    items={domainUsers}
                    teams={domainTeams}
                    selected={members}
                    selectedTeams={teams}
                    setSelected={setMembers}
                    setSelectedTeams={setTeams}
                    disableSearch
                />
            </div>
            <button className="btn btn-primary w-100" disabled={(members.length === 0 && teams.length === 0) || name.length === 0 ? 'disabled' : ''} onClick={() => saveChannel()}>Legg til</button>
        </div>
    )
}

function ChannelNav(){
    const { channels } = useContext(ChannelContext)
    if(!channels){
        return (
            <div className="channel-sidebar-items d-flex justify-content-center align-items-center">
                <div className="text-center">
                    <FontAwesomeIcon size="2x" spin icon={['fal', 'spinner-third']} className="mb-2" />
                    <p>Laster...</p>
                </div>
            </div>
        )
    }

  

    return (
        <div className="channel-sidebar-items scrollinside">
            {
                channels && channels.map((item, key) => {
                    return (
                        <ChannelNavItem
                            key={key} 
                            index={key}
                            {...item} />
                    )
                })
            }
        </div>
    )
}

function ChannelCompanyItem({ name, alias, image, channels }){
    let resolved = useResolvedPath(alias);
    let match = useMatch({ path: resolved.pathname, end: false });
    
    
    
    const [collapsed, setCollapsed] = useState(match ? true : false)
    
    return (
        <div className={"d-block channel-sidebar-item"}>
            <div className="row align-items-center gx-3"  onClick={() => setCollapsed(!collapsed)}>
                
                <div className="col-auto">
                    <UserPhoto photo={image} name={name} size={[40, 30, 30]} />
                </div>
                
                <div className="col" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    <span>{name}</span>
                </div>
                <div className="col-auto">
                    {channels && channels.length > 0 ? <FontAwesomeIcon icon={['fas', collapsed ? 'angle-down' : 'angle-up']} /> : null}
                    
                </div>
            </div>
            
                
                {
                    collapsed && channels && channels.map((item, key) => {
                        return (
                            <ChannelNavItem
                                key={key} 
                                index={key}
                                companyAlias={alias}
                                {...item} />
                        )
                    })
                }
                
            
        </div>
    )
}
function ChannelNavItem({ title, alias, id, companyAlias }){
    let resolved = useResolvedPath(`${alias}`);
    let match = useMatch({ path: resolved.pathname, end: false });
    const { contextMessages } = useContext(AuthContext)

    let notifications = 0;
    
    contextMessages.map(notification => {
        if(!notification.isRead){
            if(notification.actions[0].id === id){
                notifications++;
            }
        }
    })

    return (
        <Link to={`${alias}/samtaler`} className={"d-flex channel-sidebar-item" + (match ? ' active' : '')}>
            <span>{title}</span>
            {
                notifications >= 1 ? <div className="notification-bubble"><FontAwesomeIcon icon={['fas', 'circle']} size="lg" className="text-danger" /><span>{notifications}</span></div> : null
            }
        </Link>
    )
}


