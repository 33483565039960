
/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useState, useEffect, useContext, createRef } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';
import Modal from '../../../components/Modal';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import CheckBox from '../../../components/CheckBox';
import FileUpload from '../../../components/FileUpload';
import { ConfigContext } from '../../../providers/ConfigProvider';
import { useFormik } from 'formik';
import * as yup from 'yup'
import Input from '../../../components/Input';
import DraftWysiwyg from '../../../components/DraftWysiwyg';
import ContextMenu, { ContextMenuItem } from '../../../components/ContextMenu';
import { rteMarkup } from '../../../components/Functions';

const Context = createContext({
    data: null,
    currentFolder: null,
    path: null,
    name: null,
    displayName: null,
    isPublic: null,
    newFolder: () => {},
    newFiles: () => {},
    save: () => {},
    editName: () => {},
    deleteFile: () => {}
})

const Provider = ({children}) => {

    const params = useParams()
    const navigate = useNavigate()
    const { apiCall, domain } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    
    const [id, setId] = useState(null)
    const [data, setData] = useState({})
    const [currentFolder, setCurrentFolder] = useState(data)
    const [path, setPath] = useState([])
    const [isPublic, setIsPublic] = useState(false)
    const [name, setName] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [crumb, setCrumb] = useState([])

    const [folderModalOpen, setFolderModalOpen] = useState(false)
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false)

    useEffect(() => {
        loadTemplate()
    }, [])

    const changeFolder = () => {
        let folder = data
        let paths = []
        let crumbs = []


        if(params.folder){
            if(folder.items){
                const f = folder.items.filter(f => f.alias === params.folder)
                folder = f[0]
                console.log('f', f)
                if(f.length && f[0].type === 'folder'){
                    paths.push(f[0].alias)
                    crumbs.push(f[0].name)
                    
                }
            }
        }

        if(params.folder1){
            if(folder.items){
                const f = folder.items.filter(f => f.alias === params.folder1)
                folder = f[0]
                console.log('f1', f)
                if(f.length && f[0].type === 'folder'){
                    paths.push(f[0].alias)
                    crumbs.push(f[0].name)
                    
                }
                //navigate(-1)
            }
        }

        if(params.folder2){
            if(folder.items){
                const f = folder.items.filter(f => f.alias === params.folder2)
                folder = f[0]
                console.log('f2', f)
                if(f.length && f[0].type === 'folder'){
                    paths.push(f[0].alias)
                    crumbs.push(f[0].name)
                    
                }
                
            }
        }

        if(params.folder3){
            if(folder.items){
                const f = folder.items.filter(f => f.alias === params.folder3)
                folder = f[0]
                console.log('f3', f)
                if(f.length && f[0].type === 'folder'){
                    paths.push(f[0].alias)
                    crumbs.push(f[0].name)
                    
                }
                
            }
        }

        if(params.folder4){
            if(folder.items){
                const f = folder.items.filter(f => f.alias === params.folder4)
                folder = f[0]
                console.log('f4', f)
                if(f.length && f[0].type === 'folder'){
                    paths.push(f[0].alias)
                    crumbs.push(f[0].name)
                    
                }
                
            }
        }

       
        setCrumb(crumbs)
        setPath(paths)
        setCurrentFolder(folder)

    }



    useEffect(() => {
        changeFolder()
    }, [params, data])

    const createFolder = (name) => {
        if(name.length === 0){
            notify(2, 'Oops!', 'Mappen må ha et navn')
            return false
        }
        const temp = data
        const folder = {
            name: name,
            alias: name.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase(),
            type: 'folder',
            items: []
        }

        console.log(path)

        if(path.length === 0){
            temp.items.push(folder)
            setData(temp)
            changeFolder()
            return true
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === path[0])
            temp.items[index].items.push(folder)
            setData(temp)
            changeFolder()
            return true
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            temp.items[index].items[secondIndex].items.push(folder)
            setData(temp)
            changeFolder()
            return true
        }

        
    }

    const createFiles = (files) => {
        let fileArray = []
        files.map((file) => {
            fileArray.push({
                name: file.file_name,
                type: 'file',
                file_type: file.file_type,
                attachment: file.id
            })
        })

        const temp = data

        if(path.length === 0){
            fileArray.map((f) => {
                temp.items = [...temp.items, f]
            })
            
            setData(temp)
            changeFolder()
            return
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === currentFolder.alias)
            fileArray.map((f) => {
                temp.items[index].items  = [...temp.items[index].items, f]
            })
            
            setData(temp)
            changeFolder()
            return
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === currentFolder.alias)
            temp.items[index].items[secondIndex].items = [...temp.items[index].items[secondIndex].items, fileArray]
            setData(temp)
            changeFolder()
            return
        }
    }

    const newArticle = async (values) => {
        
        const temp = data
        const article = {
            name: values.title,
            alias: values.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase(),
            content: values.content,
            type: 'article'
        }
        
       
        if(path.length === 0){
            temp.items.push(article)
            setData(temp)
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === currentFolder.alias)
            temp.items[index].items.push(article)
            setData(temp)
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === currentFolder.alias)
            temp.items[index].items[secondIndex].items.push(article)
            setData(temp)
        }

        if(path.length === 3){
            const index = temp.items.findIndex(a => a.alias === params.folder)
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === params.folder1)
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === params.folder2)

            temp.items[index].items[secondIndex].items[thirdIndex].items.push(article)
            setData(temp)
        }

        navigate(-1)

        
        
    }

    const editArticle = (values) => {
        const temp = data
       
        if(path.length === 0){
            const index = temp.items.findIndex(a => a.alias === params.folder)
            if(temp.items[index]){
                temp.items[index].name = values.title
                temp.items[index].alias = values.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                temp.items[index].content = values.content
                setData(temp)
            }
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === params.folder1)
            if(temp.items[index].items[secondIndex]){
                temp.items[index].items[secondIndex].name = values.title
                temp.items[index].items[secondIndex].alias = values.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                temp.items[index].items[secondIndex].content = values.content
                setData(temp)
            }
            
            
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === params.folder2)
            
            if(temp.items[index].items[secondIndex].items[thirdIndex]){
                temp.items[index].items[secondIndex].items[thirdIndex].name = values.title
                temp.items[index].items[secondIndex].items[thirdIndex].content = values.content
                temp.items[index].items[secondIndex].items[thirdIndex].alias = values.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setData(temp)
            }
        }

        if(path.length === 3){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === params.folder2)
            const fourthIndex = temp.items[index].items[secondIndex].items[thirdIndex].items.findIndex(a => a.alias === params.folder3)
            if(temp.items[index].items[secondIndex].items[thirdIndex].items[fourthIndex]){
                temp.items[index].items[secondIndex].items[thirdIndex].items[fourthIndex].name = values.title
                temp.items[index].items[secondIndex].items[thirdIndex].items[fourthIndex].content = values.content
                temp.items[index].items[secondIndex].items[thirdIndex].items[fourthIndex].alias = values.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setData(temp)
            }
        }

        changeFolder()
    }

    const editName = async (newName, item) => {
        
        const temp = data

        if(path.length === 0){
            const index = temp.items.findIndex(a => a.alias === item.alias)
            if(temp.items[index]){
                temp.items[index].name = newName
                temp.items[index].alias = newName.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setData(temp)
            }
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === item.alias)
            if(temp.items[index].items[secondIndex]){
                temp.items[index].items[secondIndex].name = newName
                temp.items[index].items[secondIndex].alias = newName.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setData(temp)
            }
            
            
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === item.alias)
            
            if(temp.items[index].items[secondIndex].items[thirdIndex]){
                temp.items[index].items[secondIndex].items[thirdIndex].name = newName
                temp.items[index].items[secondIndex].items[thirdIndex].alias = newName.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase()
                setData(temp)
            }
        }

        changeFolder()

        
    }

    const deleteFile = async (item) => {
        
        const temp = data

        if(path.length === 0){
            const index = temp.items.findIndex(a => a.alias === item.alias)
            
            if(temp.items[index]){
                temp.items.splice(index, 1)
                setData(temp)
            }
        }

        if(path.length === 1){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === item.alias)
            if(temp.items[index].items[secondIndex]){
                temp.items[index].items.splice(secondIndex, 1)
                setData(temp)
            }
            
            
        }

        if(path.length === 2){
            const index = temp.items.findIndex(a => a.alias === path[0])
            const secondIndex = temp.items[index].items.findIndex(a => a.alias === path[1])
            const thirdIndex = temp.items[index].items[secondIndex].items.findIndex(a => a.alias === item.alias)
            
            if(temp.items[index].items[secondIndex].items[thirdIndex]){
                temp.items[index].items[secondIndex].items.splice(thirdIndex, 1)
                setData(temp)
            }
        }

        changeFolder()

        
    }

    const loadTemplate = async () => {
        const { data } = await apiCall({
            action: 'load-domain-template-document',
            id: params.templateId
        })

        if(data.status === 1){
            const { id, name, display_name, documents, isPublic } = data.data
            setName(name)
            setDisplayName(display_name)
            setData(documents)
            setIsPublic(isPublic)
            setId(id)
            
        } 

        
        
    }

    const saveNewTemplate = async () => {
        const status = await apiCall({
            action: 'edit-domain-template-document',
            id: id,
            name: name,
            displayName: displayName,
            public: isPublic,
            documents: data
        })

        console.log(status.data)

        notify(status.data.status, status.data.title, status.data.message)

        navigate(`/${domain.hash}/maler/dokumentmaler`)
    }


    return <Context.Provider value={{
        id,
        data,
        currentFolder,
        path,
        crumb,
        name,
        displayName,
        isPublic,
        setName,
        setDisplayName,
        setIsPublic,
        newFolder: (name) => {
            return createFolder(name)
        },
        newFiles: (files) => {
            createFiles(files)
        },
        save: (data) => {
            saveNewTemplate(data)
        },
        newArticle,
        editArticle,
        editName,
        deleteFile,
        folderModalOpen,
        setFolderModalOpen,
        fileUploadModalOpen,
        setFileUploadModalOpen
    }}>{children}</Context.Provider>
}



export default function DomainDocumentTemplateEdit(){
    return (
        <Provider>
            <CreateDocument />
        </Provider>
    )
}



function CreateDocument(){

    const { save, name, setName, displayName, setDisplayName, isPublic, setIsPublic, path, folderModalOpen, setFolderModalOpen, fileUploadModalOpen, setFileUploadModalOpen } = useContext(Context)
    const { domain } = useContext(AuthContext)
    

    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()

   

    return (
        <div className="p-3">
            <div className="box p-4">
                <button className="btn btn-link" onClick={() => navigate(`/${domain.hash}/maler/dokumentmaler`)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>

                <div className="row mt-4 align-items-center">
                    <div className="col">
                        <h3 className="bold">Opprett dokumentmal</h3>
                    </div>
                    <div className="col-auto">
                        <button type="button" onClick={() => save()} className="btn btn-primary d-block">Lagre mal</button>
                    </div>
                </div>
                
                <hr/>
                <CheckBox 
                    label="Offentlig mal - tilgjengelig for alle områder under"
                    value={isPublic}
                    onChange={(e) => setIsPublic(e)}
                    classes="mb-3"
                />
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Navn på mal</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Navn på mal" className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Visningsnavn</label>
                            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} placeholder="Visningsnavn" className="form-control" />
                        </div>
                    </div>
                </div>
                <div>
                <div className="row py-3">
                    <div className="col-auto">
                        <button className="btn btn-success" onClick={() => setFileUploadModalOpen(true) }>
                            <div className="row gx-2">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['fal', 'upload']} />
                                </div>
                                <div className="col-auto">
                                    Last opp
                                </div>
                            </div>

                        </button>
                    </div>
                    {
                        path.length < 3  ? (
                            <div className="col-auto">
                                <button className="btn btn-border" onClick={() => setFolderModalOpen(true)}>
                                    <div className="row gx-2">
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['fal', 'folder-plus']} />
                                        </div>
                                        <div className="col-auto">
                                            Lag ny mappe her
                                        </div>
                                    </div>

                                </button>
                            </div>
                        ) : null
                    }

                        <div className="col-auto">
                            <button className="btn btn-border" onClick={() => {
                                    navigate(`${path.length >= 1 ? path.join('/') + '/' : ''}ny-artikkel`)}
                                }>
                                <div className="row gx-2">
                                    <div className="col-auto">
                                        <FontAwesomeIcon icon={['far', 'file-plus']} />
                                    </div>
                                    <div className="col-auto">
                                        Opprett artikkel
                                    </div>
                                </div>
                            </button>
                        </div>
                    
                </div>
                <div className="row">
                    { path.length > 0 ?
                        <div className="row">
                            <div className="col">
                                <button className="btn-up w-100" onClick={() => navigate(-1)}>
                                    <FontAwesomeIcon className="me-2" icon={['fal', 'arrow-turn-up']} size="lg" /> <span>Gå opp et nivå</span>
                                </button>
                            </div>
                        </div> : null
                    }
                </div>
                <div className="files-container">
                    {
                        params.folder1 || params.folder2 || params.folder3 || location.pathname.split('/').pop() === 'ny-artikkel' ? <Outlet /> : <DomainTemplateDocumentFolderEdit />
                    }
                    </div>
                </div>
                <Modal open={folderModalOpen} setOpen={setFolderModalOpen} containerStyle={{minWidth: 400, overflow: 'visible'}}>
                    <CreateNewFolder setFolderModalOpen={setFolderModalOpen} />
                </Modal>
                <Modal open={fileUploadModalOpen} setOpen={setFileUploadModalOpen} containerStyle={{minWidth: 500, overflow: 'visible'}}>
                    <FileUploadModal setOpen={setFileUploadModalOpen} />
                </Modal>
            </div>
        </div>
    )
    
}

function FileUploadModal({ setOpen }){
    const { newFiles } = useContext(Context)
    const [files, setFiles] = useState([])

    const upload = () => {
        newFiles(files)
        setOpen(false)
    }

    return (
        <div className="py-3 px-5">
            <h4 className="bold">Last opp filer</h4>
            <FileUpload 
                files={files}
                setFiles={setFiles}
            />
            <div className="row">
                <div className="col-md-6">
                    <button className='btn btn-block btn-border w-100' onClick={() => setOpen(false)}>Avbryt</button>
                </div>
                <div className="col-md-6">
                    <button className='btn btn-block btn-success w-100' onClick={() => upload()}>Last opp</button>
                </div>
            </div>
        </div>
    )
}

function CreateNewFolder({ setFolderModalOpen }){

    const { newFolder } = useContext(Context)
    const [name, setName] = useState('')

    const create = async () => {
        const status = await newFolder(name)
        if(status){
            setFolderModalOpen(false)
        }
    }

    return (
        <div className="py-3 px-5">
            <h4 className='bold'>Opprett mappe</h4>
            <form onSubmit={(e) => {
                e.preventDefault()
                create()
            }}>
                <div className="form-group mb-3">
                    <label>Navn på mappe</label>
                    <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Mappenavn" type="text" className="form-control" />
                </div>
            </form>
            <div className="row">
                <div className="col-md-6">
                    <button className='btn btn-block btn-border w-100' onClick={() => setFolderModalOpen(false)}>Avbryt</button>
                </div>
                <div className="col-md-6">
                    <button className='btn btn-block btn-success w-100' onClick={() => create()}>Lagre</button>
                </div>
            </div>
            
        </div>
    )
}

export function DomainTemplateDocumentFolderEdit(){

    const { currentFolder, path, data, crumb, setFileUploadModalOpen, setFolderModalOpen } = useContext(Context)
    const navigate = useNavigate()
    const location = useLocation()

    const Crumb = ({ name }) => {
        return (
            <>
                <div className="col-auto px-2"><i><FontAwesomeIcon icon={["fal", "chevron-right"]} /></i></div>
                <div className="col-auto"><i>{name}</i></div>
            </>
        )
    }

    const renderDocuments = () => {

        const folders = currentFolder.items.filter(f => f.type === 'folder').sort((a,b) => {return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1})
        const files = currentFolder.items.filter(f => f.type === 'file').sort((a,b) => {return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1})
        const articles = currentFolder.items.filter(f => f.type === 'article').sort((a,b) => {return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1})
        
        let folderMarkup = folders.map((item,key) => {
            return <Row key={key} {...item} />
        })
        let fileMarkup = files.map((item,key) => {
            return <Row key={key} {...item} />
        })

        let articleMarkup = articles.map((item,key) => {
            return <Row key={key} {...item} />
        })

        return (
            <>
                {folderMarkup}
                {articleMarkup}
                {fileMarkup}
            </>
        )
    }

    if(currentFolder && currentFolder.type === 'article'){
        if(location.pathname.split('/').pop() === 'rediger') return <Outlet />
        return (
            <ViewArticle />
        )
    }

    return (
        <div>
            <div className="row gx-1 mb-3">
                <div className="col-auto"><i>Hovedmappe</i></div>
                {
                    crumb.map((name, key) => {
                        return <Crumb key={key} name={name} />
                    })
                }
            </div>
        
        <div className="files-container flex-grow-1">
            {
                    
                    currentFolder && currentFolder.items && currentFolder.items.length? 
                        renderDocuments()
                    : <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                        <FontAwesomeIcon size="3x" className="mb-3 text-primary"  icon={['fad', 'folder-plus']} />
                        <div className="text-center">
                            <h2 className="bold">Her var det tomt!</h2>
                            <p>Denne mappen er tom. Her kan du laste opp filer{path.length < 3 ? 'eller lage nye mapper' : null}.</p>
                            <div className="row py-3 justify-content-center">
                                <div className="col-auto">
                                    <button className="btn btn-success" onClick={() => setFileUploadModalOpen(true)}>
                                        <div className="row gx-2">
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={['far', 'upload']} />
                                            </div>
                                            <div className="col-auto">
                                                Last opp fil
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                {path.length < 3 ? 
                                <div className="col-auto">
                                    <button className="btn btn-border" onClick={() => setFolderModalOpen(true)}>
                                        <div className="row gx-2">
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={['far', 'folder-plus']} />
                                            </div>
                                            <div className="col-auto">
                                                Lag ny mappe
                                            </div>
                                        </div>
                                    </button>
                                </div> : null }
                            </div>
                        </div>
                    </div>
            }
                
                
            </div>
            </div>
    )
}

function Row( props ){
    
    const { name, type, alias, createdby, createdon, attachment } = props
    const { path, folder, editName, deleteFile, id } = useContext(Context)
    const { downloadAttachment, domain } = useContext(AuthContext)

    const navigate = useNavigate()
    const location = useLocation()

    const [modalOpen, setModalOpen] = useState(false)

    const download = async () => {
        downloadAttachment(attachment, name)
    }

    const File = () => {
        const [newName, setNewName] = useState(name)
        const [editMode, setEditMode] = useState(false)
        const ref = createRef()

        useEffect(() => {
            if(ref.current){
                ref.current.focus()
            }
        }, [ref, editMode])

        const saveNewName = async () => {
            editName(newName, props)
            setEditMode(false)
        }

        return (
            <div className="file hover-primary border-bottom pointer">
                <div className="row align-items-center gx-0">
                    <div className="col-auto">
                        <div className="p-md-3 p-2">
                            <FontAwesomeIcon className="text-primary" icon={['fad', 'file']} size="2x" fixedWidth />
                        </div>
                    </div>
                    {
                        editMode ? 
                        <div className="col">
                            <div className="py-md-3 py-2">
                                <div className="row align-items-center gx-2">
                                    <div className="col">
                                        <input ref={ref} type="text" value={newName} onChange={(e) => setNewName(e.target.value)} className="form-control py-1 py-md-2" />
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-primary px-md-3 px-2" onClick={() => saveNewName()}>
                                            <FontAwesomeIcon icon={['fal', 'check']} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col">
                            <div className="py-md-3 py-2">
                                {name}
                            </div>
                        </div>
                    }
                    {
                        createdby ? 
                            <div className="col-auto d-none d-md-block">
                                <i>{createdby.name}</i>
                            </div>
                    : null }
                    {
                        createdon ? 
                            <div className="col-auto">
                                <div className="p-md-3 p-2"><i>{moment(createdon).format('D.m.y')}</i></div>
                            </div>
                    : null }
                    <div className="col-auto">
                            <ContextMenu
                                buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                            >
                                <ContextMenuItem 
                                    label="Last ned"
                                    icon={['fas', 'arrow-down-to-line']}
                                    onClick={() => download()}
                                />
                                <ContextMenuItem 
                                    label="Kopier lenke"
                                    icon={['far', 'link']}
                                    onClick={() => navigator.clipboard.writeText(window.location.href + `/${alias}`)}
                                />
                                <ContextMenuItem 
                                    label="Rediger navn"
                                    icon={['far', 'pencil']}
                                    onClick={() => setEditMode(true)}
                                />
                                <hr style={{margin: 0}} />
                                <ContextMenuItem 
                                    label="Slett"
                                    icon={['far', 'trash']}
                                    onClick={() => setModalOpen(true)}
                                    colorClass="text-danger"
                                />
                            </ContextMenu>
                        </div>
                </div>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                    <div className="p-3">
                        <h3 className="bold">Slette fil</h3>
                        <p>Er du sikker på at du vil slette <i>{name}</i>?</p>
                        <div className="row">
                            <div className="col">
                                <button onClick={() => deleteFile(props)} className="btn btn-danger w-100">Ja, slett</button>
                            </div>
                            <div className="col">
                                <button onClick={() => setModalOpen(false)} className="btn btn-border w-100">Nei</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    const Folder = () => {
        
        const goToFolder = () => {
            if(path.length){
                navigate(`/${domain.hash}/maler/dokumentmaler/rediger/${id}/${path.join('/')}/${alias}`)
            }else{
                navigate(alias)
            }

        }

        const [newName, setNewName] = useState(name)
        const [editMode, setEditMode] = useState(false)
        const ref = createRef()

        useEffect(() => {
            if(ref.current){
                ref.current.focus()
            }
        }, [ref, editMode])

        const saveNewName = async () => {
            editName(newName, props)
            setEditMode(false)
        }

        return (
            <div className="file hover-primary border-bottom pointer">
                <div className="row align-items-center gx-0">
                    <div className="col-auto" onClick={() => goToFolder()}>
                        <div className="p-md-3 p-2">
                            <FontAwesomeIcon className="text-primary" icon={['fas', 'folder']} size="2x" fixedWidth />
                        </div>
                    </div>
                    {
                        editMode ? 
                        <div className="col">
                            <div className="py-md-3 py-2">
                                <div className="row align-items-center gx-1">
                                    <div className="col-auto">
                                        <input ref={ref} type="text" value={newName} onChange={(e) => setNewName(e.target.value)} className="form-control py-1 py-md-2" />
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-primary px-md-3 px-2" onClick={() => saveNewName()}>
                                            <FontAwesomeIcon icon={['fal', 'check']} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col" onClick={() => goToFolder()}>
                            <div className="py-md-3 py-2">
                                {name}
                            </div>
                        </div>
                    }
                    {
                        createdby ? 
                            <div className="col-auto d-none d-md-block">
                                 <i>{createdby.name}</i>
                            </div>
                    : null }
                    {
                        createdon ? 
                            <div className="col-auto">
                                <div className="p-md-3 p-2"><i>{moment(createdon).format('D. MMMM y')}</i></div>
                            </div>
                    : null }
                    <div className="col-auto">
                        <ContextMenu
                            buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                        >
                            <ContextMenuItem 
                                label="Åpne"
                                icon={['far', 'folder']}
                                onClick={() => goToFolder()}
                            />
                            <ContextMenuItem 
                                label="Kopier lenke"
                                icon={['far', 'link']}
                                onClick={() => navigator.clipboard.writeText(window.location.href + `/${alias}`)}
                            />
                            <ContextMenuItem 
                                label="Rediger navn"
                                icon={['far', 'pencil']}
                                onClick={() => setEditMode(true)}
                            />
                            <hr style={{margin: 0}} />
                            <ContextMenuItem 
                                label="Slett"
                                icon={['far', 'trash']}
                                onClick={() => setModalOpen(true)}
                                colorClass="text-danger"
                            />
                        </ContextMenu>
                    </div>
                    <div className="col-auto">
                        <div className="p-md-3 p-2">
                            <button className="btn-square-icon" onClick={() => goToFolder()}>
                                <FontAwesomeIcon  size="lg" icon={['far', 'chevron-right']} />
                            </button>
                        </div>
                    </div>
                    
                </div>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                    <div className="p-3">
                        <h3 className="bold">Slette mappe</h3>
                        <p>Er du sikker på at du vil slette <i>{name}</i>?</p>
                        <div className="row">
                            <div className="col">
                                <button onClick={() => deleteFile(props)} className="btn btn-danger w-100">Ja, slett</button>
                            </div>
                            <div className="col">
                                <button onClick={() => setModalOpen(false)} className="btn btn-border w-100">Nei</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    const Article = () => {

        const goToArticle = () => {
            
            if(path.length){
                navigate(`/${domain.hash}/maler/dokumentmaler/rediger/${id}/${path.join('/')}/${alias}`)
            }else{
                navigate(alias)
            }
        }

        const goToArticleEdit = () => {

            if(path.length){
                navigate(`/${domain.hash}/maler/dokumentmaler/rediger/${id}/${path.join('/')}/${alias}/rediger`)
            }else{
                navigate(`${alias}/rediger`)
            }
        }

        return (
            <div className="file hover-primary border-bottom pointer">
                <div className="row align-items-center gx-0">
                    <div className="col-auto" onClick={() => goToArticle()}>
                        <div className="p-md-3 p-2">
                            <FontAwesomeIcon className="text-primary" icon={['fad', 'file-lines']} size="2x" fixedWidth />
                        </div>
                    </div>

                    <div className="col" onClick={() => goToArticle()}>
                        <div className="py-md-3 py-2">
                            {name}
                        </div>
                    </div>
                    
                    {
                        createdby ? 
                            <div className="col-auto d-none d-md-block">
                                <i>{createdby.name}</i>
                            </div>
                    : null }
                    {
                        createdon ? 
                            <div className="col-auto">
                                <div className="p-md-3 p-2"><i>{moment(createdon).format('D.m.y')}</i></div>
                            </div>
                    : null }
                    <div className="col-auto">
                            <ContextMenu
                                buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                            >
                                <ContextMenuItem 
                                    label="Se artikkel"
                                    icon={['fas', 'arrow-down-to-line']}
                                    onClick={() => goToArticle()}
                                />
                                <ContextMenuItem 
                                    label="Rediger"
                                    icon={['far', 'pencil']}
                                    onClick={() => goToArticleEdit()}
                                />
                                <hr style={{margin: 0}} />
                                <ContextMenuItem 
                                    label="Slett"
                                    icon={['far', 'trash']}
                                    onClick={() => setModalOpen(true)}
                                    colorClass="text-danger"
                                />
                            </ContextMenu>
                        </div>
                </div>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                    <div className="p-3">
                        <h3 className="bold">Slette fil</h3>
                        <p>Er du sikker på at du vil slette <i>{name}</i>?</p>
                        <div className="row">
                            <div className="col">
                                <button onClick={() => deleteFile(props)} className="btn btn-danger w-100">Ja, slett</button>
                            </div>
                            <div className="col">
                                <button onClick={() => setModalOpen(false)} className="btn btn-border w-100">Nei</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    return type === 'folder' ? <Folder /> : type === 'article' ? <Article /> : <File />
}

function Empty(){
    return (
        <div className="p-5 border border-lg text-center">
            <FontAwesomeIcon icon={['fal', 'folder']} size="2x" />
            <h4 className='bold'>Denne mappen er tom</h4>
            <p>For å starte kan du enten laste opp en fil eller lage en ny mappe</p>
        </div>

    )
}

export function NewTemplateEditDocumentFile(){

    const { newArticle } = useContext(Context)

    const navigate = useNavigate()

    let schema = yup.object().shape({
        title: yup.string().required("Navn på artikkel påkrevd"),
        content: yup.mixed(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            content: null
        },
        validationSchema: schema,
        onSubmit: async (values) => {           
            
            saveArticle(values)
        },
    });

    const saveArticle = async (values) => {
        
        const status = await newArticle(values)
        if(status){
            
            navigate(-1)
        }
    }
    
    return (
        
            <div className="d-flex flex-column h-100">
                <div className="p-0 p-md-0 flex-grow-1 d-flex flex-column">
                    <button className="btn btn-link" onClick={() => navigate(-1)}>
                        <div className="row gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                            </div>
                            <div className="col-auto">
                                <span>Tilbake</span>
                            </div>
                        </div>
                    </button>
                <form onSubmit={formik.handleSubmit} className="d-flex flex-column flex-grow-1">
                    <div className="row my-4 align-items-center">
                        <div className="col">
                            <h3 className="bold">Opprett ny artikkel</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row">
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-primary px-lg-5">Lagre</button>
                                </div>
                                <div className="col-auto">
                                    <button onClick={() => navigate(-1)} type="button" className="btn btn-border px-lg-5">Avbryt og lukk</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column">
                        
                            <Input
                                name="title"
                                label="Navn på artikkel"
                                type="text"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                touched={formik.touched.title}
                                helperText={formik.touched.title && formik.errors.title}
                                placeholder={"Artikkelens navn"}
                            />
                            <div className="form-group flex-grow-1 d-flex flex-column">
                                <label>Innhold</label>
                                <DraftWysiwyg
                                    value={formik.values.content}
                                    setValue={(e) => formik.setFieldValue("content", e)}
                                    containerClass="flex-grow-1 d-flex flex-column"
                                    includeImage={true}
                                />
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        
    )
}

export function EditTemplateEditArticle(){

    const { currentFolder, editArticle } = useContext(Context)
    const navigate = useNavigate()
  

    const saveArticle = async (values) => {
        editArticle(values)
        navigate(-1)
        
    }

    const Form = () => {

        let schema = yup.object().shape({
            title: yup.string().required("Navn på artikkel påkrevd"),
            content: yup.mixed(),
        });
    
        const formik = useFormik({
            initialValues: {
                title: currentFolder.name,
                content: currentFolder.content
            },
            validationSchema: schema,
            onSubmit: async (values) => {           
                console.log('Submit?') 
                saveArticle(values)
            },
        });

        return (
            <form onSubmit={formik.handleSubmit} className="d-flex flex-column flex-grow-1">
                <div className="row my-4 align-items-center">
                    <div className="col">
                        <h3 className="bold">Rediger artikkel</h3>
                    </div>
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary px-lg-5">Lagre</button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-border px-lg-5">Avbryt og lukk</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="flex-grow-1 d-flex flex-column">
                    
                        <Input
                            name="title"
                            label="Navn på artikkel"
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            touched={formik.touched.title}
                            helperText={formik.touched.title && formik.errors.title}
                            placeholder={"Artikkelens navn"}
                        />
                        <div className="form-group flex-grow-1 d-flex flex-column">
                            <label>Innhold</label>
                            <DraftWysiwyg
                                value={formik.values.content}
                                setValue={(e) => formik.setFieldValue("content", e)}
                                containerClass="flex-grow-1 d-flex flex-column"
                                includeImage={true}
                            />
                        </div>
                        
                    </div>
                </form>
        )
    }
    
    return (
        
            <div className="d-flex flex-column h-100">
                <div className="p-3 p-md-4 flex-grow-1 d-flex flex-column">
                    <button className="btn btn-link" onClick={() => navigate(-1)}>
                        <div className="row gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                            </div>
                            <div className="col-auto">
                                <span>Tilbake</span>
                            </div>
                        </div>
                    </button>
                    {
                        currentFolder ? <Form /> : <h3>Laster..</h3>
                    }
                </div>
            </div>
        
    )
}

function ViewArticle(){

    const { currentFolder } = useContext(Context)
    
    return (
        <div className="p-md-4 p-0">
            
            <div className="row">
                <div className="col">
                    <h2 className="bold">{currentFolder.name}</h2>
                </div>
            </div>
            
            <hr />
            <div dangerouslySetInnerHTML={{__html: rteMarkup(currentFolder.content)}}>
            </div>
        </div>
    )
}
