import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TaskDetailsContext } from "../providers/TaskDetailsProvider";
import { CheckForm, CommentForm, ConfirmForm, RangeForm } from "./FormComponents";
import { styleText, formatDate, getNextDeadline } from "./Functions";
import moment from 'moment'
import TaskLog from "./TaskLog";
import ImageStack from "./ImageStack";
import { Link } from "react-router-dom";
import Signatures from "./Signatures";
import RightDrawer from "./RightDrawer";
import Drawer from "./Drawer";
import Attachments from "./Attachments";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import UserPhoto from "./UserPhoto";



export default function ArchivedTaskDetails({ Context }){

    const { data, changeSubmission, selectedSubmission } = useContext(Context)
  
    const [isSubtask, setIsSubtask] = useState(false)
    const [open, setOpen] = useState(false);
    const [subtaskDrawerOpen, setSubtaskDrawerOpen] = useState(false)
    const [submissionDrawerOpen, setSubmissionDrawerOpen] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(data.parent) setIsSubtask(true)
    }, [data])

    useEffect(() => {
        if(!selectedSubmission){
            console.log('Selected submission is null', data.submissions)
            if(data.submissions[0]) changeSubmission(data.submissions[0].id)
            
        }else{
            console.log('selectedSubmission', selectedSubmission)
        }

    }, [])

    useEffect(() => {
        if(params.subtask) setSubtaskDrawerOpen(true)
    }, [params])

    

    return (
        <div className="p-3">
            {
                selectedSubmission ? 
                <div className="box mb-3 px-3 py-2 bg-warning-tint">
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['far', 'box-archive']} />
                        </div>
                        <div className="col">
                            <p className="m-0">Denne oppgaven ble utført <span className="bold">{formatDate(moment(selectedSubmission.archivedon), 'DD. MMM YYYY')}</span> og er arkivert.</p>
                        </div>
                    </div>
                </div>
            : null}
            
            { data.submissions.length > 1 ? (
                <div className="box mb-3 px-3 py-2 pointer" onClick={() => setSubmissionDrawerOpen(true)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['far', 'repeat']} />
                        </div>
                        <div className="col">
                            <p className="m-0">Dette er en gjentagende oppgave. Det er registrert flere utførelser av oppgaven.</p>
                        </div>
                        <div className="col-auto">
                            <div className="row align-items-center gx-1">
                                <div className="col-auto"><button className="btn btn-link text-primary">Se alle utførelser</button></div>
                                <div className="col-auto"><FontAwesomeIcon className="text-primary" icon={['far', 'arrow-right']} /></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="row gx-2">
                <div className="col-lg-6 mb-3">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-2">
                            <div className="col">
                                <button className="btn btn-link" onClick={() => navigate(-1)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                                        <div className="col">Tilbake</div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-auto">
                                { selectedSubmission ? 
                                    <div className="row gx-1">
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['fas', 'check']} className="text-primary" />
                                        </div>
                                        <div className="col-auto">
                                            <p className="m-0 bold">Utført {formatDate(moment(selectedSubmission.archivedon), 'DD. MMM YYYY')}</p>
                                        </div>
                                    </div>
                                :

                                    <div className="row gx-1">
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['fas', 'xmark']} className="text-danger" />
                                        </div>
                                        <div className="col-auto">
                                            <p className="m-0 bold">Ikke utført</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                            { data.priority ? 
                                <div className="col-auto">
                                    <div className="icon-check-container checked checked-danger">
                                        <div className="icon-check-button ">
                                            <FontAwesomeIcon icon={['fas', 'flag']} />
                                        </div>
                                    </div>
                                </div>
                            : null }
                        </div>
                        <div className="mb-2">
                            <ImageStack users={data.responsibles} size={[40, 40, 40]} />
                        </div>
                        <h3 className="bold">{data.name}</h3>
                        { data.description ? <div dangerouslySetInnerHTML={{__html: styleText(data.description.blocks)}}></div> : null }
                        
                        { data.attachments && data.attachments.length > 0 ? (
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                <Attachments
                                    files={data.attachments}
                                />
                            </div>
                        ) : null}
                        { data.subtasks && data.subtasks.length > 0 ? (
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                {
                                    data.subtasks && data.subtasks.map((item, key) => {
                                       
                                        const nextDeadline = getNextDeadline({deadline: item.deadline})
                                        return (
                                            
                                            <div key={key} className={"p-3 bottom-border subtask-item" + (item.archivedon ? ' subtask-item-disabled' : '')} >
                                                <div className="row align-items-center gx-2">
                                                    {item.responsibles.length ? 
                                                        <div className="col-auto">
                                                            <ImageStack users={item.responsibles} />
                                                        </div>
                                                    : null }
                                                    <div className="col" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                        <Link to={`${item.id}`}><span className="bold">{item.name}</span></Link>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span>{nextDeadline.text}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        ): null}
                    </div>
                </div>
                <div className="col-lg-6 mb-3">
                    <div className="h-100 box d-flex flex-column py-2" >
                        <TaskLog Context={Context} />
                    </div>
                </div>
                
            </div>
            {
                data.signature === 2 ? (
                    <div className="signatures py-3">
                        <h5 className="bold">Signaturer</h5>
                        <Signatures users={data.responsibles} signatures={selectedSubmission?.signatures} />
                    </div>
                ) : null
            }
            { selectedSubmission ? (     
                <>
                    {
                        selectedSubmission.payload?.form ?
                            <ArchivedFormSubmission Context={Context} /> :
                            (
                                <div className="box p-3 mb-3">
                                    <h4 className="bold">Kommentar til utførelse (valgfritt)</h4>
                                    <div className="rte">
                                        {selectedSubmission.payload?.comment ? selectedSubmission.payload.comment : <i>Ingen kommentar utfylt</i>}
                                    </div>
                                    { selectedSubmission.payload?.attachments && selectedSubmission.payload?.attachments.length > 0 ? (
                                        <div className="form-group my-4">
                                            <label>Vedlegg</label>
                                            <Attachments
                                                files={selectedSubmission.payload?.attachments}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            )
                    }
                </>
            ) : <p className="text-center"><i>Denne oppgaven har blitt arkivert uten å fullføres.</i></p>}

            { !isSubtask ? 
                <Drawer open={subtaskDrawerOpen} setOpen={setSubtaskDrawerOpen} classNames={"bg-grey"}>
                    <Outlet />
                </Drawer>
            : null}
            
            <RightDrawer open={submissionDrawerOpen} setOpen={setSubmissionDrawerOpen}>
                <Submissions setOpen={setSubmissionDrawerOpen} Context={Context} />
            </RightDrawer>
        </div>
    )
}

function ArchivedFormSubmission({ Context }){

    const { selectedSubmission } = useContext(Context)

    console.log('selectedSubmission', selectedSubmission)

    const { categoryOrder, categories, items } = selectedSubmission.payload

    const Category = ({ category, items, index }) => {
        const [collapsed, setCollapsed] = useState(true)

        const FormItem = (props) => {
            
            const { id, type } = props

            
            let switchType = ''
            if(type.value) switchType = type.value
            if(type.val) switchType = type.val
            switch(switchType){
                case 1:
                    return <CheckForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} />
                case 2:
                    return <CommentForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} /> 
                case 3:
                    return <ConfirmForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} />
                case 4:
                    return <RangeForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} />
            }

            return (
                <div></div>
            )
        }

        return(
            <div className="border  mb-2 bg-white">
                <div className="p-3">
                    <div className="row align-items-center">
                        <div className="col-auto pointer" onClick={() => setCollapsed(!collapsed)}>
                            <FontAwesomeIcon icon={['far', collapsed ? 'chevron-down' : 'chevron-up']} />
                        </div>
                        <div className="col">
                            <h5 className="mb-0 bold">{category.title}</h5>
                        </div>
                        <div className="col-auto">
                            <small>{Object.keys(items).length} spørsmål</small>
                        </div>
                    </div>
                </div>
                <div style={{display: collapsed ? 'block' : 'none'}}>
                    <div className="p-3 border-top">
                        <div className="row">
                            <div className="col">
                                <h3 className="bold">{category.title}</h3>
                                <div dangerouslySetInnerHTML={{__html: styleText(category.description.blocks)}}></div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="p-3">
                    {   
                            items.map((item, key) =>{
                                return (
                                    <FormItem
                                        key={key}
                                        {...item}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="form">
            {categoryOrder && categoryOrder.map(categoryId => {
                const category = categories[categoryId];
                
                const items = category.items.map(
                    itemId => selectedSubmission.payload.items[itemId],
                );

                return <Category 
                    key={category.id} 
                    category={category} 
                    items={items} 
                    index={categoryOrder.findIndex(e => e === categoryId)}
                />;
            })}
        </div>
    ) 
}

function Submissions({ setOpen, Context }){

    const { data, changeSubmission } = useContext(Context)
    const { apiCall } = useContext(AuthContext)
    const [submissions, setSubmissions] = useState(null)
    const getSubmissions = async () => {
        
        const status = await apiCall({action: 'get-all-task-submissions', id: data.id})
        
        if(status.data.status === 1){
            setSubmissions(status.data.data)
        }
        
    }

    useEffect(() => {
        getSubmissions()
    }, [])

    const SubmissionItem = ({ id, archivedon, archivedby }) => {
        return (
            <div className="submission-drawer-item">
                <div className="row gx-2">
                    <div className="col-auto">
                        <div className="submission-drawer-dot">
                            <div className="dot"></div>
                        </div>
                    </div>
                    <div className="col">
                        <span className="hint-text mb-2 d-block">{formatDate(archivedon, 'DD. MMMM YYYY')}</span>
                        {archivedby ? (
                            <div className="row gx-2 align-items-center pb-4 mr-5">
                                <div className="col-auto">
                                    <UserPhoto photo={archivedby.photo} name={archivedby.fullname} size={[40, 40, 40]} />
                                </div>
                                <div className="col">
                                    Utført av {archivedby.fullname}
                                </div>
                            </div>
                        ) : null}
                        
                    </div>
                    <div className="col-auto align-self-center">
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => {
                            changeSubmission(id)
                            setOpen(false)
                        }}  style={{marginLeft: 30}}>Se oppgave</button>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div>
            <div style={{borderBottom: '1px solid #000'}}>
                <div className="row pb-2">
                    <div className="col">
                        <h6 className="bold">Utførelser av {data.name}</h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => setOpen(false)}>
                            <FontAwesomeIcon icon={['fas','xmark']} />
                        </button>
                    </div>
                </div>
            </div>
            {submissions === null ? 
                (
                    <div className="d-flex justify-content-center py-5">
                        <FontAwesomeIcon spin size="2x" icon={['fad', 'spinner-third']} />
                    </div>
                )
                :
                (
                    <div className="py-4">
                    {
                        submissions.length > 0 ? submissions.map((el, key) => {
                            console.log('SubmissionItem', el)
                            return <SubmissionItem key={key} {...el} />
                        }) : null
                    }
                    </div>
                )
            }
            
            
        </div>
    )
}

function Skeleton(){
    return (
        <div className="p-3">
            <div className="row gx-2 mb-3">
                <div className="col-lg-6">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-5">
                            <div className="col">
                                
                            </div>
                            <div className="col-auto">
                                <i>Frist: </i>
                            </div>
                            
                            <div className="col-auto">
                                <div className="icon-check-container checked-danger">
                                    <div className="icon-check-button ">
                                        <FontAwesomeIcon icon={['fas', 'flag']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="bold"></h3>
                        
                        
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                
                            </div>
                        
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="h-100 box  d-flex flex-column">
                        <div className="p-4 col">
                            
                        </div>
                        <div className="p-3 border-top">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link">
                                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}