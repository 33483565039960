import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { createContext, createRef, useContext, useEffect, useState } from "react";
import { Link, Outlet, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { DatePicker } from "../../../components/DatePicker";
import UserPhoto from "../../../components/UserPhoto";
import { AuthContext } from "../../../providers/AuthProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import axios from "axios";
import DropDown from "../../../components/DropDown";
import ImageCrop from "../../../components/ImageCrop";
import { CompanyContext } from "../../../providers/CompanyProvider";
import Modal from "../../../components/Modal";

const Context = createContext({
    data: null,
    permissionTemplates: null,
    reload: () => {}
})

const Prodivder = ({children}) => {
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const [data, setData] = useState(null) 
    const [permissionTemplates, setPermissionTemplates] = useState([])

    const params = useParams()

    useEffect(() => {
        if(params.userid){
            loadUserDetails()
            loadPermissionTemplates()
        }
    }, [])

    const loadUserDetails = async () => {
        const status = await apiCall({action: 'get-company-user-details', id: params.userid})
        
        if(status.data.status === 1){
            setData(status.data.data)
            console.log(status.data.data)
        }else{
            notify(status.data.status, status.data.title, status.data.message)
            setData(false)
        }
    }

    const loadPermissionTemplates = async () => {
        const status = await apiCall({action: 'get-company-permission-templates'})
        if(status.data.status === 1){
            setPermissionTemplates(status.data.data)
        }else{
            setPermissionTemplates(false)
        }
    }

    

    return (
        <Context.Provider value={{
            data,
            permissionTemplates,
            reload: () => {
                loadUserDetails()
            }
        }}>
            {data === null || permissionTemplates === null ? <Loading /> : data === false ? <NoAccess /> : children}
        </Context.Provider>
    )
}

function Loading(){
    return (
        <div className="p-md-3 p-2">
            <div className="box p-md-4 p-3">
                <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                    <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span className="fa-stack fa-lg">
                            
                            <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'user']} className="fa-stack-1x text-secondary" />
                            <FontAwesomeIcon fixedWidth spin size="2x" icon={['fad', 'spinner-third']} className="fa-stack-1x text-primary" />
                        </span>
                    </div>
                    <div className="text-center">
                        <h6 className="bold text-primary">Henter medlem</h6>
                        <p>Vent litt mens vi henter all informasjon om medlemmet.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NoAccess(){
    return (
        <div className="p-md-3 p-2">
            <div className="box p-4">
                <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                    <div className="bg-danger-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon fixedWidth size="lg" icon={['fas', 'xmark']} className="text-danger" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold text-danger">Fant ikke medlem</h6>
                        <p>Det oppstod en feil ved henting av medlem</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default function CompanyUser(){

    const { hasCompanyPermission } = useContext(CompanyContext)
    const TabItem = ({ link, children }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={"tab-item " + (match ? "tab-item-active" : "")}>{children}</Link>
        )
    }

    return (
        <Prodivder>
            <div className="p-md-3 p-2">
                <div className="transparent-box">
                    <div className="tab-container">
                        <div className="row gx-0 justify-content-between">
                            <div className="col-auto">
                                <TabItem link="detaljer">Detaljer</TabItem>
                            </div>
                            {
                                hasCompanyPermission('adminUser') ?
                                    <div className="col-auto align-self-end">
                                        <TabItem link="rediger">Rediger detaljer <FontAwesomeIcon style={{marginLeft: 10}} icon={['fal', 'pencil']} /></TabItem>
                                    </div>
                                : null
                            }
                            
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
        </Prodivder>
    )
}
export function CompanyUserDetails(){

    const { data, permissionTemplates } = useContext(Context)
    const { notify } = useContext(ConfigContext)
    const navigate = useNavigate()
    
    const currentPermissionTemplate = permissionTemplates.filter(p => p.id === data.permissionTemplate)[0]

    return (
        
            <div className="p-md-4 p-3 bg-white">
                <button className="btn btn-link" onClick={() => navigate(-1)}>
                    <div className="row">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>

                <div className="py-3">
                    <h2 className="bold mb-3">Brukerdetaljer</h2>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <UserPhoto photo={data.photo} name={data.fullname} size={[80, 70, 50]} />
                        </div>
                        <div className="col">
                            <h3 className="bold m-0">{data.fullname}</h3>
                            <h6 className="m-0">{data.role}</h6>
                        </div>
                    </div>
                </div>

                <div className="pb-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="bold">Personopplysninger</h4>
                            <p>Brukerens personopplysninger som oppholssted, navn kontaktopplysninger og mer.</p>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Fornavn</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.firstname}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Etternavn</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.lastname}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>e-postadresse</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Telefon</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.phone}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Fødselsdato</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.dob ? moment(data.dob).format('DD. MMM Y') : <i>Ikke satt</i>}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Land</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.country ? JSON.parse(data.country).label : <i>Ikke satt</i>}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Stillingstittel</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{data.role}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="bold">Brukerprofil</h4>
                            <p>Informasjon om brukerprofil, som siste endring og om brukeren er aktiv i systemet.</p>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Brukerrolle</label>

                                </div>
                                <div className="col-6">
                                    <label className="bold">{currentPermissionTemplate ? currentPermissionTemplate.name : 'Ikke satt'}</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Aktiv bruker</label>

                                </div>
                                <div className="col-6">
                                    <label className="bold">{data.active === 1 ? 'Ja' : 'Nei'}</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Sist endret</label>

                                </div>
                                <div className="col-6">
                                    <label className="bold">{data.lastEdited ? moment(data.lastEdited).format('DD. MMMM YYYY') : '-'}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        
    )
}
export function CompanyUserEdit(){
    const { data, permissionTemplates, reload } = useContext(Context)
    const { notify, baseUrl } = useContext(ConfigContext)
    const { apiCall, uploadFile, domain, company } = useContext(AuthContext)
    const { hasCompanyPermission, reloadCompanyUsers } = useContext(CompanyContext)

    const navigate = useNavigate()

    const [firstname, setFirstname] = useState(data ? data.firstname : '')
    const [lastname, setLastname] = useState(data ? data.lastname : '')
    const [image, setImage] = useState(data ? data.photo : '')
    const [dob, setDob] = useState(data ? new Date(data.dob) : null)
    const [email, setEmail] = useState(data.email)
    const [country, setCountry] = useState(data ? JSON.parse(data.country) : null)
    const [role, setRole] = useState(data ? data.role : '')
    const [phone, setPhone] = useState(data ? data.phone : '')
    const [permissionTemplate, setPermissionTemplate] = useState([])
    const [permissionOptions, setPermissionOptions] = useState([])

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    

    useEffect(() => {
        let temp = []
        permissionTemplates.map((e) => {
            temp.push({label: e.name, value: e.id})
        })
        setPermissionOptions(temp)

        if(data.permissionTemplate){
            setPermissionTemplate(temp.filter(v => v.id === data.permissionTemplate)[0])
        }
    }, [])

    useEffect(() => {
        if(permissionOptions.length && data.permissionTemplate){
            setPermissionTemplate(permissionOptions.filter(v => v.value === data.permissionTemplate)[0])
        }
    }, [permissionOptions])


    const countryList = [
        {label: 'Norge', value: 'NO'},
        {label: 'Sverige', value: 'SE'},
        {label: 'Danmark', value: 'DK'}
    ]

    const saveChanges = async () => {
        
        const imageData = image instanceof File ? await uploadFile(image) : null

        const status = await apiCall({
            action: 'edit-company-user',
            id: data.id,
            firstname: firstname,
            lastname: lastname,
            dob: moment(dob).format('YYYY-MM-DD 12:00:00'),
            email: email,
            country: JSON.stringify(country),
            role: role,
            phone: phone,
            permissionTemplate: permissionTemplate.value,
            image: imageData
        })

        notify(status.data.status, status.data.title, status.data.message)

        if(status.data.status === 1){
            reload()
        } 

    }

    const archiveUser = async () => {
        const status = await apiCall({
            action: 'archive-company-user',
            id: data.id
        })

        notify(status.data.status, status.data.title, status.data.message)

        if(status.data.status === 1){
            reload()
        } 
    }

    const activateUser = async () => {
        const status = await apiCall({
            action: 'activate-company-user',
            id: data.id
        })

        notify(status.data.status, status.data.title, status.data.message)

        if(status.data.status === 1){
            reload()
        } 
    }

    const removeUser = async () => {
        const status = await apiCall({
            action: 'remove-company-user',
            id: data.id
        })

        notify(status.data.status, status.data.title, status.data.message)

        if(status.data.status === 1){
            await reloadCompanyUsers()
            navigate(`/${domain.hash}/${company.hash}/medlemmer`)
        } 
    }

    const deleteUser = async () => {
        const status = await apiCall({
            action: 'delete-company-user',
            id: data.id
        })

        notify(status.data.status, status.data.title, status.data.message)

        if(status.data.status === 1){
            await reloadCompanyUsers()
            navigate(`/${domain.hash}/${company.hash}/medlemmer`)
        } 
    }


    return (
        
            <div className="p-md-4 p-3 bg-white">
                <button className="btn btn-link" onClick={() => navigate(-1)}>
                    <div className="row">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>

                

                <div className="py-3">
                    <div className="row">
                        <div className="col">
                            <h2 className="bold mb-3">Rediger bruker</h2>
                            <div className="py-3">
                                <ImageCrop 
                                    img={image}
                                    setImg={setImage}
                                    height={120}
                                    aspect={1}
                                    unit={'profilbilde'}
                                />
                            </div>
                        </div>
                        <div className="col-auto">
                            <button onClick={() => saveChanges()} className="btn btn-success px-5">Lagre endringer</button>
                        </div>
                    </div>
                    
                </div>

                <div className="pb-5">
                    <h4 className="bold">Personopplysninger</h4>
                    <p>Brukerens personopplysninger som oppholssted, navn kontaktopplysninger og mer.</p>
                    <div className="row gx-5">
                        <div className="col-md-8">
                        
                            <div className="row mb-3">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Fornavn</label>
                                        <input value={firstname} onChange={(e) => setFirstname(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Etternavn</label>
                                        <input value={lastname} onChange={(e) => setLastname(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>e-postadresse</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Telefonnummer</label>
                                        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Stillingstittel</label>
                                        <input value={role} onChange={(e) => setRole(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Land</label>
                                        <DropDown
                                            options={countryList}
                                            value={country}
                                            onChange={(e) => setCountry(e)}
                                            placeholder="Velg land"
                                            selectFirst
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Fødselsdato</label>
                                        <DatePicker
                                            selected={dob}
                                            onChange={setDob}
                                            maxDate={new Date()}
                                            showYearDropdown
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            
                        
                        </div>
                        <div className="col">
                            {
                                data.permissionTemplate ? 
                                    <div className="form-group mb-3">
                                        <label>Rolle</label>
                                        <DropDown 
                                            options={permissionOptions}
                                            value={permissionTemplate}
                                            placeholder="Velg en rolle"
                                            onChange={(e) => setPermissionTemplate(e)}

                                        />
                                    </div>
                                : null
                            }
                            
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Aktiv bruker</label>
                                </div>
                                <div className="col-6">
                                    <label className="bold">{data.active === 1 ? 'Ja' : 'Nei'}</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Sist endret</label>

                                </div>
                                <div className="col-6">
                                    <label className="bold">{data.lastEdited ? moment(data.lastEdited).format('DD. MMMM YYYY') : '-'}</label>
                                </div>
                            </div>
                            <div className="row">

                            {
                                hasCompanyPermission('adminUser') && data.active === 0 ? 
                                    <div className="col-12 mb-2">
                                        <button className="btn btn-success w-100" onClick={() => activateUser()}>
                                            <FontAwesomeIcon style={{marginRight: 10}} icon={['far', 'lock-open']} />Aktiver og send tilgang
                                        </button> 
                                    </div>
                                : null
                            }
                            {
                                hasCompanyPermission('adminUser') ? 
                                    <div className="col-12 mb-2">
                                        <button type="button" className="btn btn-border w-100" onClick={() => archiveUser()}>
                                            <FontAwesomeIcon style={{marginRight: 10}} icon={['far', 'box-archive']} />{data.permissionTemplate ? 'Arkiver medlem' : 'Reaktiver medlem'}
                                        </button>
                                    </div>
                                : null
                            }
                            {
                                hasCompanyPermission('adminUser') ? 
                                    <div className="col-12 mb-2">
                                        <button className="btn btn-danger w-100" onClick={() => setDeleteModalOpen(true)}>
                                            <FontAwesomeIcon style={{marginRight: 10}} icon={['far', 'person-to-door']} />Fjern medlem
                                        </button> 
                                    </div>
                                : null
                            }
                            </div>
                        </div>
                        {hasCompanyPermission('adminUser') ? 
                            <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
                                <div className="p-3" style={{maxWidth: '500px'}}>
                                    <h3 className="bold">Fjerne medlem fra bedriften?</h3>
                                    <p>Er du sikker på at du vil fjerne dette medlemmet? Medlemmet vil bli fjernet fra alle oppgaver, kanaler og dokumentområder.</p>
                                    <div className="row">
                                        <div className="col">
                                            <button type="button" className="btn btn-border w-100" onClick={() => setDeleteModalOpen(false)} >Nei</button>
                                        </div>
                                        <div className="col">
                                            <button type="button" onClick={() => removeUser()} className="btn btn-warning w-100">Ja, fjern medlem</button>
                                        </div>
                                    </div>
                                    {
                                        hasCompanyPermission('superAdmin') ? 
                                        <div className="mt-3">
                                            <button type="button" onClick={() => deleteUser()} className="btn btn-danger w-100">Slett bruker fra systemet</button>
                                        </div>
                                        : null
                                    }
                                </div>
                            </Modal>
                        : null}
                    </div>
                </div>
            </div>
        
    )
}

