import UserPhoto from "./UserPhoto"
import React from "react"
export default function ImageStack({ users, size }){

    if(!users) return <div></div>
    
    return (
        <div className="image-stack" style={{width: (users.length * 40) - ((users.length - 1) * 20)}}> 
            {users.map((item, key) => {
                return <UserPhoto key={key} photo={item.photo} name={item.fullname} consultant={item.consultant} size={size ? size : 40}/>
                
            })}
        </div>
    )
}