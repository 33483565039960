/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
export default function IconCheck({ checked, setChecked, icon, color, classes }){

    const ref = useRef()


    const [isChecked, setIsChecked] = useState(checked)



    return (
        <div className={"icon-check-container" + (checked ? ` checked checked-${color}` : '') + ` ${classes}`} >
            <div className="row gx-2">
                <div className="col-auto">
                    <button type="button" className={"icon-check-button"} onClick={() => setChecked(!checked)}>
                        <FontAwesomeIcon icon={['fal', icon]} />
                    </button>
                </div>
            </div>
        </div>
    )
}