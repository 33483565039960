import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../components/CheckBox";
import { AuthContext } from "../../../providers/AuthProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import FormBuilderProvider from "../../../components/FormBuilder";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from '../../../components/Input';
import Modal from "../../../components/Modal";

const Context = createContext({
    name: null,
    displayName: null,
    isPublic: null,
    formItems: {},
    setFormItems: () => {},
    saveForm: () => {},
    handleAddFormItem: () => {},
    editCategory: () => {},
    editFormItem: () => {},
    deleteFormItem: () => {}
})

const Provider = ({children}) => {
    
    const [form, setForm] = useState({})
    
    const { apiCall, domain } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const navigate = useNavigate()

    const save = async (values) => {

        let errors = false
        Object.keys(form.categories).forEach(category => {
            if(form.categories[category].items.length === 0){
                errors = true
            }
        })

        if(errors){
            alert('Hver kategori må ha minst ett spørsmål')
            return
        }

        const { data } = await apiCall({
            action: 'create-domain-template-form',
            name: values.name,
            displayName: values.displayName,
            isPublic: values.isPublic,
            form: form
        })
        notify(data.status, data.title, data.message)

        navigate(`/${domain.hash}/maler/skjemamaler`)
    }

    return <Context.Provider value={{
        form,
        setForm,
        save,
    }}>{children}</Context.Provider>
} 

export default function DomainFormTemplateNew(){
    return (
        <Provider>
            <NewTemplate />
        </Provider>
    )
}

function NewTemplate(){
    
    const navigate = useNavigate()

    const [modalOpen, setModalOpen] = useState(false)

    const {
        save
    } = useContext(Context)

    let schema = yup.object().shape({
        name: yup.string().required("Navn er påkrevd"),
        displayName: yup.string().required("Visningsnavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            displayName: '',
            isPublic: false,
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            save(values)
        },
    });



    return (
        <div className="p-3">
            <div className="box p-4">
                <button className="btn btn-link" onClick={() => {
                    if(formik.dirty){
                        setModalOpen(true)
                    }else{
                        navigate(-1)
                    }
                }}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                        <div className="p-2">
                        <h3 className="bold">Vil du lukke?</h3>
                        <p>Du har gjort endringer som ikke vil bli lagret, er du sikker på at du vil lukke?</p>
                        <div className="row">
                            <div className="col">
                                <button type="button" className="btn btn-warning w-100" onClick={() => navigate(-1)}>Ja, lukk uten å lagre</button>
                            </div>
                            <div className="col">
                                <button type="button" className="btn btn-success w-100" onClick={() => setModalOpen(false)}>Nei</button>
                            </div>
                        </div>
                        </div>
                    </Modal>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row mt-4 align-items-center">
                        <div className="col">
                            <h3 className="bold">Opprett skjemamal</h3>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary d-block">Lagre mal</button>
                        </div>
                    </div>
                    <hr/>
                    <CheckBox 
                        label="Offentlig mal - tilgjengelig for alle områder under"
                        value={formik.values.isPublic}
                        onChange={(e) => formik.setFieldValue('isPublic', e)}
                        classes="mb-3"
                    />
                    <div className="row mb-3">
                        <div className="col-lg-6">
                            <Input
                                name="name"
                                label="Navn på mal"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Navn på skjemamal"}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Input
                                name="displayName"
                                label="Visningnavn på skjema"
                                type="text"
                                value={formik.values.displayName}
                                onChange={formik.handleChange}
                                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                                touched={formik.touched.displayName}
                                helperText={formik.touched.displayName && formik.errors.displayName}
                                placeholder={"Visningnavn på skjema"}
                            />
                        </div>
                    </div>
                </form>
                <FormBuilderProvider currentContext={Context} template /> 
            </div>
        </div>
    )
}
