import React from 'react';
import ReactDOM from 'react-dom';

import './bootstrap.min.css'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import './App.css'
import App from './App';
import moment from 'moment'
import 'moment/locale/nb.js'
import reportWebVitals from './reportWebVitals';

import { ConfigProvider } from './providers/ConfigProvider';

moment.locale("nb")
ReactDOM.render(
  	<React.StrictMode>
    	<ConfigProvider>
      		<App />
    	</ConfigProvider>
  	</React.StrictMode>,
  	document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
