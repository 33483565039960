/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, RichUtils, convertFromRaw, convertToRaw, AtomicBlockUtils, Modifier } from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import createImagePlugin from '@draft-js-plugins/image';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import Modal from './Modal';
import FileUpload from './FileUpload';
import ImageUpload from './ImageUpload';
import { ConfigContext } from '../providers/ConfigProvider';

export default function DraftWysiwyg(props){
    
    const { value, setValue, containerClass, includeImage } = props
    const [editorState, setEditorState] = useState(
      value ? () => EditorState.createWithContent(convertFromRaw(value)) : () => EditorState.createEmpty(),
    )
    const [imageModalOpen, setImageModalOpen] = useState(false)
    const imagePlugin = createImagePlugin();
    const plugins = [imagePlugin];
    const editor = useRef(null);

    const handleKeyCommand = useCallback((command, editorState) => {
		  const newState = RichUtils.handleKeyCommand(editorState, command)
      if (newState) {
        setEditorState(newState)
        return "handled"
      }
      return "not-handled"
    })

    const addImage = (url) => {
      setEditorState(imagePlugin.addImage(editorState, url))
    }

    const toggleBlockType = (blockType) => {
      setEditorState(
        RichUtils.toggleBlockType(
        editorState,
        blockType
        )
      );
    }
    const handleReturn = () => {
		  toggleBlockType('unstyled')
	  }

    useEffect(() => {
        setValue(convertToRaw(editorState.getCurrentContent()))
      }, [editorState])

    const translationNo = {
        // Generic
        "generic.add": "Legg til",
        "generic.cancel": "Avbryt",
      
        // BlockType
        "components.controls.blocktype.h1": "Overskrift 1",
        "components.controls.blocktype.h2": "Overskrift 2",
        "components.controls.blocktype.h3": "Overskrift 3",
        "components.controls.blocktype.h4": "Overskrift 4",
        "components.controls.blocktype.h5": "Overskrift 5",
        "components.controls.blocktype.h6": "Overskrift 6",
        "components.controls.blocktype.blockquote": "Blockquote",
        "components.controls.blocktype.code": "Kode",
        "components.controls.blocktype.blocktype": "Block Type",
        "components.controls.blocktype.normal": "Normal",
      
        // Color Picker
        "components.controls.colorpicker.colorpicker": "Fargevelger",
        "components.controls.colorpicker.text": "Tekst",
        "components.controls.colorpicker.background": "Marker",
      
        // Embedded
        "components.controls.embedded.embedded": "Bygg inn",
        "components.controls.embedded.embeddedlink": "Innebygd lenke",
        "components.controls.embedded.enterlink": "Skriv inn lenke",
      
        // Emoji
        "components.controls.emoji.emoji": "Emoji",
      
        // FontFamily
        "components.controls.fontfamily.fontfamily": "Skrifttype",
      
        // FontSize
        "components.controls.fontsize.fontsize": "Fontstørrelse",
      
        // History
        "components.controls.history.history": "Historie",
        "components.controls.history.undo": "Angre",
        "components.controls.history.redo": "Tilbakestill",
      
        // Image
        "components.controls.image.image": "Bilde",
        "components.controls.image.fileUpload": "Filopplastning",
        "components.controls.image.byURL": "URL",
        "components.controls.image.dropFileText": "Slipp fil eller klikk for å laste opp",
      
        // Inline
        "components.controls.inline.bold": "Fet",
        "components.controls.inline.italic": "Kursiv",
        "components.controls.inline.underline": "Understreket",
        "components.controls.inline.strikethrough": "Gjennomstreket",
        "components.controls.inline.monospace": "Monospace",
        "components.controls.inline.superscript": "Superscript",
        "components.controls.inline.subscript": "Subscript",
      
        // Link
        "components.controls.link.linkTitle": "Lenketittel",
        "components.controls.link.linkTarget": "Lenkemål",
        "components.controls.link.linkTargetOption": "Åpne i nytt vindu",
        "components.controls.link.link": "Lenke",
        "components.controls.link.unlink": "Bryt lenke",
      
        // List
        "components.controls.list.list": "Liste",
        "components.controls.list.unordered": "Unummerert",
        "components.controls.list.ordered": "Nummerert",
        "components.controls.list.indent": "Innrykk",
        "components.controls.list.outdent": "Utrykk",
      
        // Remove
        "components.controls.remove.remove": "Fjern",
      
        // TextAlign
        "components.controls.textalign.textalign": "Tekstposisjon",
        "components.controls.textalign.left": "Venstre",
        "components.controls.textalign.center": "Midtstill",
        "components.controls.textalign.right": "Høyre",
        "components.controls.textalign.justify": "Fordel"
      };
      /**
       * Wysiwyg editor docs
       * Our toolbar options : ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'history']
       * More at https://jpuri.github.io/react-draft-wysiwyg/#/docs
       */

    return (
        <div className={`draft-editor-container ${containerClass && containerClass}`}>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            ref={editor}
            handleKeyCommand={handleKeyCommand} 
            plugins={plugins}
            handleReturn={handleReturn}
            localization={{
                translations: translationNo
            }} 
            toolbar={{
                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'history'],
                inline: { 
                    inDropdown: false,
                    options: ["bold", "italic", "underline"]
                },
                blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4']
                },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true }
            }}
            toolbarCustomButtons={[<ImageDraftButton enabled={includeImage} setOpen={setImageModalOpen} />]}
          />
          <Modal open={imageModalOpen} setOpen={setImageModalOpen} containerStyle={{width: 750, maxWidth: '100%', overflow: 'visible'}}>
                <UploadImage addImage={addImage} setOpen={setImageModalOpen} />
          </Modal>
        </div>
      
    )

    
}

const ImageDraftButton = ({enabled, setOpen}) => {
  if(enabled){
    return (
      <div onClick={() => setOpen(true)} className="rdw-block-image-wrapper" aria-label="rdw-block-control">
        <div className="rdw-popup-wrapper rdw-block" aria-label="rdw-block">
          <div className="rdw-imagemodal" title="Placeholders">
            <span><FontAwesomeIcon size="lg" fixedWidth className={"draft-image-button"} icon={['fal', 'file-image']} /></span> 
          </div>
        </div>
      </div>
    )
  }

  return <div></div>
}

const UploadImage = ({ addImage, setOpen }) => {
	
	const { baseUrl } = useContext(ConfigContext)
  const [files, setFiles] = useState([])

  const upload = () => {
    files.map((item) => {
      addImage(`${baseUrl}/${item.file_path}`)
    })
    setOpen(false)
  }

  return (
    <div className="py-3 px-5">
      <h4 className="bold mb-3">Last opp filer</h4>
      <ImageUpload
        files={files}
        setFiles={setFiles}
      />
      <div className="row my-3">
        <div className="col-md-6">
          <button type="button" className='btn btn-block btn-border w-100' onClick={() => setOpen(false)}>Avbryt</button>
        </div>
        <div className="col-md-6">
          <button type="button" className='btn btn-block btn-success w-100' onClick={() => upload()}>Legg til</button>
        </div>
      </div>
    </div>
  )
}
 
