import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckBox from "../../../components/CheckBox";
import { AuthContext } from "../../../providers/AuthProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from '../../../components/Input';
import BoxLoading from "../../../components/BoxLoading";
import FormBuilderProvider from "../../../components/FormBuilder";

const Context = createContext({
    loadedForm: null,
    form: {},
    setForm: () => {},
    save: () => {}
})

const Provider = ({children}) => {
    
    const [loadedForm, setLoadedForm] = useState(null)
    

    const [form, setForm] = useState({})
    
    const { apiCall, domain } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const params = useParams()
    const navigate = useNavigate()

    const save = async (values) => {
        const { data } = await apiCall({
            action: 'edit-domain-template-form',
            id: loadedForm.id,
            name: values.name,
            displayName: values.displayName,
            isPublic: values.isPublic,
            form: form
        })
        notify(data.status, data.title, data.message)

        navigate(`/${domain.hash}/maler/skjemamaler`)
    }

    const loadTemplate = async () => {
        const { data } = await apiCall({
            action: 'load-domain-template-form',
            id: params.formId
        })

        if(data.status === 1){
            setLoadedForm(data.data)
        } 
    }

    useEffect(() => {
        loadTemplate()
    }, [])

    useEffect(() => {
        if(loadedForm){
            setForm(loadedForm.form)
        }
    }, [loadedForm])

    return <Context.Provider value={{
        loadedForm,
        form,
        setForm,
        save
    }}>{loadedForm === null ? <BoxLoading /> : children}</Context.Provider>
} 

export default function DomainFormTemplateEdit(){
    return (
        <Provider>
            <NewTemplate />
        </Provider>
    )
}
function NewTemplate(){
    
    const navigate = useNavigate()

    const {
        loadedForm,
        save
    } = useContext(Context)

    let schema = yup.object().shape({
        name: yup.string().required("Navn er påkrevd"),
        displayName: yup.string().required("Visningsnavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            name: loadedForm ? loadedForm.name : '',
            displayName: loadedForm ? loadedForm.display_name : '',
            isPublic: loadedForm ? loadedForm.isPublic : '',
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            save(values)
        },
    });

    return (
        <div className="p-3">
            <div className="box p-4">
            <button className="btn btn-link" onClick={() => navigate(-1)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row mt-4 align-items-center">
                        <div className="col">
                            <h3 className="bold">Opprett skjemamal</h3>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary d-block">Lagre mal</button>
                        </div>
                    </div>
                    <hr/>
                    <CheckBox 
                        label="Offentlig mal - tilgjengelig for alle områder under"
                        value={formik.values.isPublic}
                        onChange={(e) => formik.setFieldValue('isPublic', e)}
                        classes="mb-3"
                    />
                    <div className="row mb-3">
                        <div className="col-lg-6">
                            <Input
                                name="name"
                                label="Navn på mal"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Navn på skjemamal"}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Input
                                name="displayName"
                                label="Visningnavn på skjema"
                                type="text"
                                value={formik.values.displayName}
                                onChange={formik.handleChange}
                                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                                touched={formik.touched.displayName}
                                helperText={formik.touched.displayName && formik.errors.displayName}
                                placeholder={"Visningnavn på skjema"}
                            />
                        </div>
                    </div>
                </form>
                <FormBuilderProvider currentContext={Context} template /> 
            </div>
        </div>
    )
}