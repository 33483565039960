/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState, createContext } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import DropDown from "../../../components/DropDown";
import Modal from "../../../components/Modal";
import PageHeader from "../../../components/PageHeader";
import PersonPicker from "../../../components/PersonPicker";
import UserPhoto from "../../../components/UserPhoto";
import { AuthContext } from "../../../providers/AuthProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import { ConsultantContext } from "../../../providers/ConsultantProvider";
import { DomainContext } from "../../../providers/DomainProvider";

export const TeamContext = createContext({
    teamOptions: [],
    selectedTeam: {},
    setSelectedTeam: () => {},
    currentTeam: {},
    setCurrentTeam: () => {}
}) 

export const TeamProvider = ({children}) => {
    const { domainTeams } = useContext(DomainContext)
    
    
    const [teamOptions, setTeamOptions] = useState([])
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [currentTeam, setCurrentTeam] = useState(null)

    

    useEffect(() => {
        
        if(domainTeams && domainTeams.length){
            let temp = []
            domainTeams.map((e, k) => {
                if(k === 0) setSelectedTeam({label: e.name, value: e.id})
                temp.push({
                    label: e.name,
                    value: e.id
                })
            })

            setTeamOptions(temp)
        }
    }, [domainTeams])

    useEffect(() => {
        console.log(selectedTeam)
        if(selectedTeam){
            setCurrentTeam(domainTeams.filter((t => t.id === selectedTeam.value))[0])
        }
    }, [selectedTeam])

    return (
        <TeamContext.Provider value={{
            teamOptions,
            selectedTeam,
            setSelectedTeam,
            currentTeam,
            setCurrentTeam
        }}>
            {children}
        </TeamContext.Provider>
    )
}

export default function DomainTeams(){
    return (
        <TeamProvider>
            <Teams />
        </TeamProvider>
    )
    
}

function Teams(){
    const { selectedTeam, teamOptions, currentTeam, setSelectedTeam } = useContext(TeamContext)
    const { hasPermission } = useContext(DomainContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const params = useParams()

    return (
        <div className="p-md-3 p-2 h-100" >
            <div className="box screenheight d-flex flex-column" style={{minHeight: 0}}>
                <div className="p-3 border-bottom col-auto">
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">Grupper</h3>
                        </div>
                        <div className="col-auto">
                            {
                                hasPermission('adminTeam') ? <button className="btn btn-primary" onClick={() => setModalOpen(true)}>+ Ny gruppe</button> : null
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="col" style={{minHeight: 0}}>
                    <div className="row no-gutters gx-0 h-100"  style={{minHeight: 0, maxHeight: '100%'}}>
                        <div className="col-xl-3 col-lg-4 border-right d-flex flex-column" style={{minHeight: 0, maxHeight: '100%'}}>
                            <div className="p-1 border-bottom col-auto">
                                <DropDown
                                    value={selectedTeam}
                                    options={teamOptions}
                                    onChange={(e) => setSelectedTeam(e)}
                                    containerClass="mb-0"
                                />
                            </div>
                            <div className="col overflow-auto">
                                
                                {
                                    currentTeam && currentTeam.leaders && currentTeam.leaders.length ?
                                        currentTeam.leaders.map((e, k) => {
                                            return <ConsultantItem key={k} index={k} {...e} />
                                        }) : null
                                }
                                {
                                    currentTeam && currentTeam.members && currentTeam.members.length ?
                                        currentTeam.members.map((e, k) => {
                                            return <ConsultantItem key={k} index={k} {...e} />
                                        }) : null
                                }
                            </div>
                            { currentTeam &&
                                hasPermission('adminTeam')
                            ? 
                                <div className="px-4 pt-3 pb-3 col-auto border-top">
                                    <button className="btn btn-link text-primary" onClick={() => setEditModalOpen(true)}>
                                        <FontAwesomeIcon  icon={['far', 'pen']} className="text-primary d-inline-block me-2" />
                                        Rediger gruppe
                                    </button>
                                </div>
                            : null }
                        </div>
                        <div className="col">
                            { params.consultant ? <Outlet /> : 
                            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon size="2x"   icon={['far', 'user-tie']} className="text-primary" />
                                </div>
                                <div className="text-center">
                                    <h6 className="bold text-primary">Velg et gruppemedlem for å se detaljer</h6>
                                    <p>Her kan du se både oppgaver og samtaler tilhørende medlemmer i din gruppe.</p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                
                </div>
            </div>
            <Modal open={modalOpen} setOpen={setModalOpen} containerStyle={{overflow: 'auto'}}>
                <NewTeam setOpen={setModalOpen}  />
            </Modal>
            <Modal open={editModalOpen} setOpen={setEditModalOpen} containerStyle={{overflow: 'auto'}}>
                <EditTeam setOpen={setEditModalOpen} selected={currentTeam} />
            </Modal>
        </div>
    )
}

function NewTeam({ setOpen }){

    const [name, setName] = useState('')
    const [leaders, setLeaders] = useState([])
    const [members, setMembers] = useState([])

    const { domainUsers, loadTeams } = useContext(DomainContext)
    const { notify } = useContext(ConfigContext)
    const { apiCall } = useContext(AuthContext)

    const save = async () => {
        const { data } = await apiCall({
            action: 'create-domain-team',
            name: name,
            leaders: leaders,
            members: members
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            
            loadTeams()
            setOpen(false)
        }
    }

    const updateLeaders = (item) => {
        if(leaders.filter(e => e.id === item.id).length > 0){
            const removed = leaders.filter(e => e.id !== item.id)
            setLeaders(removed)
        }else{
            setLeaders([...leaders, item])
        }
    }

    const updateMembers = (item) => {
        if(members.filter(e => e.id === item.id).length > 0){
            const removed = members.filter(e => e.id !== item.id)
            setMembers(removed)
        }else{
            setMembers([...members, item])
        }
    }

    const SelectedUser = ({ id, fullname, photo, onRemove }) => {
        return (
            <div className="p-3 border-bottom-not-last">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <UserPhoto name={fullname} photo={photo} size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                {fullname}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-danger" onClick={() => onRemove(id)}>Fjern</button>
                    </div>
                </div>
            </div>
        )
    }

    const removeLeader = (id) => {
        if(leaders.filter(e => e.id === id).length > 0){
            const removed = leaders.filter(e => e.id !== id)
            setLeaders(removed)
        }
    }

    const removeMember = (id) => {
        if(members.filter(e => e.id === id).length > 0){
            const removed = members.filter(e => e.id !== id)
            setMembers(removed)
        }
    }

    return (
        <div className="p-3" style={{minWidth: 600, maxWidth: '90vw', overflow: 'visible'}}>
            <h3 className="bold">Opprett gruppe</h3>
            <div className="form-group mb-3">
                <label>Gruppenavn</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="border-lg mb-2">
                <div className="px-3 py-2">
                    <label className="m-0 p-0 bold">Gruppeleder</label>
                    
                </div>
                <hr className="m-0" />
                {
                    leaders.length === 0 ?
                        <div className="p-3">
                            <p className="hint-text m-0">Legg til en eller flere gruppeledere</p>
                        </div>
                    : leaders.map((item, index) => {
                        return <SelectedUser key={index} {...item} onRemove={removeLeader} />
                    })
                }
                
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={domainUsers}
                    selected={leaders}
                    setSelected={(val) => updateLeaders(val)}
                    handleManually
                    hideOutput
                    text="Legg til en gruppeleder"
                    label="Brukere"
                    disableSearch
                />
            </div>

            <div className="border-lg mb-2">
                <div className="px-3 py-2">
                    <label className="m-0 p-0 bold">Gruppemedlemmer</label>
                    
                </div>
                <hr className="m-0" />
                {
                    members.length === 0 ?
                        <div className="p-3">
                            <p className="hint-text m-0">Legg til en eller flere gruppemedlemmer</p>
                        </div>
                    : members.map((item, index) => {
                        return <SelectedUser key={index} {...item} onRemove={removeMember} />
                    })
                }
                
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={domainUsers}
                    selected={members}
                    setSelected={(val) => updateMembers(val)}
                    handleManually
                    hideOutput
                    text="Legg til et gruppemedlem"
                    label="Brukere"
                    disableSearch
                /> 
                 
            </div>
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-border w-100" onClick={() => setOpen(false)}>Avbryt</button>
                </div>
                <div className="col">
                    <button className="btn btn-success w-100" onClick={() => save()}>Lagre gruppe</button>
                </div>
            </div>
        </div>
    )
}

function EditTeam({ setOpen, selected }){

    const [name, setName] = useState(selected ? selected.name : [])
    const [leaders, setLeaders] = useState(selected ? selected.leaders : [])
    const [members, setMembers] = useState(selected ? selected.members : [])

    const [modalOpen, setModalOpen] = useState(false)

    const { domainUsers, loadTeams } = useContext(DomainContext)
    const { notify } = useContext(ConfigContext)
    const { apiCall } = useContext(AuthContext)

    const save = async () => {
        const { data } = await apiCall({
            action: 'edit-domain-team',
            id: selected ? selected.id : null,
            name: name,
            leaders: leaders,
            members: members
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            
            loadTeams()
            setOpen(false)
        }
    }

    const updateLeaders = (item) => {
        if(leaders.filter(e => e.id === item.id).length > 0){
            const removed = leaders.filter(e => e.id !== item.id)
            setLeaders(removed)
        }else{
            setLeaders([...leaders, item])
        }
    }

    const updateMembers = (item) => {
        if(members.filter(e => e.id === item.id).length > 0){
            const removed = members.filter(e => e.id !== item.id)
            setMembers(removed)
        }else{
            setMembers([...members, item])
        }
    }

    const SelectedUser = ({ id, fullname, photo, onRemove }) => {
        return (
            <div className="p-3 border-bottom-not-last">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <UserPhoto name={fullname} photo={photo} size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                {fullname}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-danger" onClick={() => onRemove(id)}>Fjern</button>
                    </div>
                </div>
            </div>
        )
    }

    const removeLeader = (id) => {
        if(leaders.filter(e => e.id === id).length > 0){
            const removed = leaders.filter(e => e.id !== id)
            setLeaders(removed)
        }
    }

    const removeMember = (id) => {
        if(members.filter(e => e.id === id).length > 0){
            const removed = members.filter(e => e.id !== id)
            setMembers(removed)
        }
    }

    const deleteTeam = async () => {
        const { data } = await apiCall({action: 'delete-domain-team', id: selected ? selected.id : null})

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            setModalOpen(false)
            loadTeams()
            setOpen(false)
        }
    }   

    return (
        <div className="p-3" style={{minWidth: 600, maxWidth: '90vw', overflow: 'visible'}}>
            <h3 className="bold">Rediger gruppe</h3>
            <div className="form-group mb-3">
                <label>Gruppenavn</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="border-lg mb-2">
                <div className="px-3 py-2">
                    <label className="m-0 p-0 bold">Gruppeleder</label>
                    
                </div>
                <hr className="m-0" />
                {
                    leaders.length === 0 ?
                        <div className="p-3">
                            <p className="hint-text m-0">Legg til en eller flere gruppeledere</p>
                        </div>
                    : leaders.map((item, index) => {
                        return <SelectedUser key={index} {...item} onRemove={removeLeader} />
                    })
                }
                
            </div>
            <div className="pt-1">
            <PersonPicker
                    items={domainUsers}
                    selected={leaders}
                    setSelected={(val) => updateLeaders(val)}
                    handleManually
                    hideOutput
                    text="Legg til en gruppeleder"
                    label="Brukere"
                    disableSearch
                />
            </div>

            <div className="border-lg mb-2">
                <div className="px-3 py-2">
                    <label className="m-0 p-0 bold">Gruppemedlemmer</label>
                    
                </div>
                <hr className="m-0" />
                {
                    members.length === 0 ?
                        <div className="p-3">
                            <p className="hint-text m-0">Legg til en eller flere gruppemedlemmer</p>
                        </div>
                    : members.map((item, index) => {
                        return <SelectedUser key={index} {...item} onRemove={removeMember} />
                    })
                }
                
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={domainUsers}
                    selected={members}
                    setSelected={(val) => updateMembers(val)}
                    handleManually
                    hideOutput
                    text="Legg til et gruppemedlem"
                    label="Brukere"
                    disableSearch
                /> 
                 
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-border w-100" onClick={() => setOpen(false)}>Avbryt</button>
                </div>
                <div className="col">
                    <button className="btn btn-success w-100" onClick={() => save()}>Lagre gruppe</button>
                </div>
            </div>
            <div className="row mt-4 justify-content-center">
                <div className="col-auto">
                    <button className="btn btn-link text-danger" onClick={() => setModalOpen(true)}>
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['fal', 'trash']} />
                            </div>
                            <div className="col-auto">
                                Slett gruppe
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <Modal open={modalOpen} setOpen={setModalOpen}>
                <div className="p-3">
                    <h3 className="bold">Slette gruppe?</h3>
                    <p>Er du sikker på at du vil slette denne gruppen?</p>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-border w-100" onClick={() => setModalOpen(false)}>Avbryt</button>
                        </div>
                        <div className="col">
                            <button className="btn btn-danger w-100" onClick={() => deleteTeam()}>Slett gruppe</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

function ConsultantItem({ item, index, id, fullname, title, photo, role, notifications }){

    const params = useParams()
    return (
        <div style={{transition: 'all 250ms ease'}} className={"px-3" + ( params.consultant && parseInt(params.consultant) === id ? " bg-success-tint" : "") }>
            <Link to={`${id}/oppgaver`}>
                <div className="row align-items-center py-3">
                    <div className="col-auto">
                        <UserPhoto photo={photo} name={fullname} size={[50, 40, 30]} />
                    </div>
                    <div className="col">
                        <h6 className="mb-0">{fullname}</h6>
                        <p className="hint-text m-0">{role}</p>
                    </div>
                    {notifications ? 
                    <div className="col-auto">
                        <div className="notification-bubble">
                            <FontAwesomeIcon icon={['fas', 'circle']} size="2x" className="text-danger" />
                            <span>{notifications}</span>
                        </div>
                    </div>
                    : null }
                </div>
            </Link>
        </div>
    )
}