import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import CheckBox from "../../../components/CheckBox"
import Drawer from "../../../components/Drawer"
import DropDown from "../../../components/DropDown"
import Table from "../../../components/Table"
import UserPhoto from "../../../components/UserPhoto"
import { AuthContext } from "../../../providers/AuthProvider"
import { CompanyContext } from "../../../providers/CompanyProvider"
import { ConfigContext } from "../../../providers/ConfigProvider"
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../../components/Input"

const Context = createContext({
    users: null,
    permissionTemplates: [],
    createDomainUser: () => {}
})

const Provider = ({children}) => {
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { companyUsers, reloadCompanyUsers } = useContext(CompanyContext)

    const [users, setUsers] = useState(companyUsers.filter(u => !u.archived))
    const [archivedUsers, setArchivedUsers] = useState(companyUsers.filter(u => u.archived))
    const [permissionTemplates, setPermissionTemplates] = useState([])

    useEffect(() => {
        reloadCompanyUsers()
    }, [])
    useEffect(() => {
        setUsers(companyUsers.filter(u => !u.archived))
        setArchivedUsers(companyUsers.filter(u => u.archived))
    }, [companyUsers])

    const loadPermissionTemplates = async () => {
        const status = await apiCall({action: 'get-company-permission-templates'})
        if(status.data.status === 1){
            setPermissionTemplates(status.data.data)
        }else{
            setPermissionTemplates(false)
        }
    }

    const createUser = async (e) => {
        const { data } = await apiCall({
            action: 'create-company-user',
            firstname: e.firstname,
            lastname: e.lastname,
            phone: e.phone,
            role: e.role,
            email: e.email,
            permissionTemplate: e.permissionTemplate.value,
            active: e.active
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1) reloadCompanyUsers()

        return data.status === 1 ? true : false
    }

    useEffect(() => {
        //getUsers()
        loadPermissionTemplates()
    }, [])

    
    return (
        <Context.Provider value={{
            users,
            archivedUsers,
            permissionTemplates,
            createDomainUser: (data) => {
                return createUser(data)
            }
        }} >{users === null || permissionTemplates === null ? <span>Laster</span> : children}</Context.Provider>
    )
}
export default function CompanyUsers(){
    return (
        <Provider>
            <Navigation />
        </Provider>
    )
}

function Navigation(){
    

    const { users, archivedUsers } = useContext(Context)
    const { hasCompanyPermission } = useContext(CompanyContext)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const NavItem = ({ children, link}) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        
        return (
            <li><Link to={link} className={match ? 'active' : ''}>{children}</Link></li>
        )
    }

    return (
        <div className="p-md-3 p-2">
            <div className="p-md-4 p-3 box">
                <div className="row">
                    <div className="col">
                        <h3 className="bold mb-4">Medlemmer</h3>
                    </div>
                    {
                        hasCompanyPermission('adminUser') ? 
                            <div className="col-auto">
                                <button className="btn btn-primary" onClick={() => setDrawerOpen(true) }>+ Nytt medlem</button>
                            </div>
                        : null
                    }
                    
                </div>
                <div className="row">
                    <div className="col">
                        <div className="sub-nav-container">
                            <ul className="sub-nav">
                                <NavItem link="">Aktive ({users ? users.length : 0})</NavItem>
                                <NavItem link="arkiv">Arkiverte ({archivedUsers ? archivedUsers.length : 0})</NavItem>
                            </ul>
                        </div>
                    </div>
                </div>
                <Outlet />
            </div>
            <Drawer open={drawerOpen} setOpen={setDrawerOpen}>
                <NewUser open={drawerOpen} setOpen={setDrawerOpen} />
            </Drawer>
        </div>
    )
}

export function ActiveCompanyUsers(){
    const { company, reloadCompanyUsers, hasCompanyPermission } = useContext(CompanyContext)
    const { users } = useContext(Context)
    const navigate = useNavigate()

    const [filteredUsers, setFilteredUsers] = useState(users)
    const [search, setSearch] = useState('')



    useEffect(() => {
        if(users){
            setFilteredUsers(
                users.filter(p => p.fullname.match(new RegExp(search, 'i')))
            )
        }
    }, [search, users])

    if(!company){
        return (<div></div>)
    }

    return (
        <div className="">
            <div className="">
                

                <div className="row align-items-center gx-3 mb-4">
                    <div className="col">
                        <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form-control" placeholder="Søk i navn" />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => reloadCompanyUsers()}>
                        <FontAwesomeIcon size="lg" icon={['far', 'filter']} />
                        </button>
                    </div>
                </div>

                {
                    users === null ? (
                        <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FontAwesomeIcon spin size="2x" icon={['fad', 'spinner-third']} className="text-primary" />
                            </div>
                            <div className="text-center">
                                <h6 className="bold text-primary">Henter medlemmer..</h6>
                                <p>Vent litt mens vi henter medlemmer i bedriften.</p>
                            </div>
                        </div>
                    ) : (
                        <Table
                            id="company-users"
                            gridTemplateColumns={["repeat(4, 1fr)", "repeat(4, 1fr)", "repeat(4, 1fr)"]}
                            gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                            headers={[
                                { title: 'Navn', key: 'fullname', sort: 'string'},
                                { title: 'Stillingstittel', key: 'role', sort: 'string'},
                                { title: 'Fødselsdato', key: 'dob', sort: 'number'},
                                { title: 'Sist endret' },
                                { title: ""}
                            ]}
                            perPage={10}
                            rows={filteredUsers}
                            showResult
                            unit="medlemmer"
                            onRowClick={(e) => {
                                console.log(e)
                                if(hasCompanyPermission('adminUser')) navigate(`${e.id}/detaljer`)
                            }}
                            columns={[
                                { 
                                    grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 4"],
                                    keys: ['photo', 'fullname'],
                                    display: (e) => {
                                        
                                        return (

                                            <div className="row gx-2 align-items-center">
                                                <div className="col-auto">
                                                    <UserPhoto photo={e.photo} name={e.fullname} size={[40, 30, 30]} />
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="bold m-0">{e.fullname}</h6>
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "1 / 4 / 2 / 6"],
                                    keys: ['role'],
                                    display: (e) => {
                                        return (
                                            <span>{e.role}</span>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 4 / 3 / 6"],
                                    keys: ['dob'],
                                    display: (e) => {
                                        return (
                                            <div>
                                                <span>{hasCompanyPermission('adminUser') ? (e.dob ? moment(e.dob).format('DD.MM.YYYY') : 'Ikke satt' ) : '-' }</span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 1 / 3 / 4"],
                                    keys: ['id', 'lastEdited'],
                                    display: (e) => {
                                        return (
                                            <div>
                                                <span>{hasCompanyPermission('adminUser') ? (e.lastEdited ? moment(e.lastEdited).format('DD.MM.YYYY') : 'Ikke endret' ) : '-' }</span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                                    keys: ['id'],
                                    display: (e) => {
                                        return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                                    }
                                }     
                            ]}
                        />
                    )
                }
            </div>
        </div>
    )
}

export function ArchivedCompanyUsers(){
    const { company, reloadCompanyUsers, hasCompanyPermission } = useContext(CompanyContext)
    const { archivedUsers } = useContext(Context)
    const navigate = useNavigate()

    const [filteredUsers, setFilteredUsers] = useState(archivedUsers)
    const [search, setSearch] = useState('')



    useEffect(() => {
        if(archivedUsers){
            setFilteredUsers(
                archivedUsers.filter(p => p.fullname.match(new RegExp(search, 'i')))
            )
        }
    }, [search, archivedUsers])

    if(!company){
        return (<div></div>)
    }

    return (
        <div className="">
            <div className="">
                

                <div className="row align-items-center gx-3 mb-4">
                    <div className="col">
                        <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form-control" placeholder="Søk i navn" />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => reloadCompanyUsers()}>
                        <FontAwesomeIcon size="lg" icon={['far', 'filter']} />
                        </button>
                    </div>
                </div>

                {
                    archivedUsers === null ? (
                        <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FontAwesomeIcon spin size="2x" icon={['fad', 'spinner-third']} className="text-primary" />
                            </div>
                            <div className="text-center">
                                <h6 className="bold text-primary">Henter medlemmer..</h6>
                                <p>Vent litt mens vi henter medlemmer i bedriften.</p>
                            </div>
                        </div>
                    ) : (
                        <Table
                            id="archived-company-users"
                            gridTemplateColumns={["repeat(4, 1fr)", "repeat(4, 1fr)", "repeat(4, 1fr)"]}
                            gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                            headers={[
                                { title: 'Navn', key: 'fullname', sort: 'string'},
                                { title: 'Stillingstittel', key: 'role', sort: 'string'},
                                { title: 'Fødselsdato', key: 'dob', sort: 'number'},
                                { title: 'Sist endret' },
                                { title: ""}
                            ]}
                            perPage={10}
                            rows={filteredUsers}
                            showResult
                            unit="medlemmer"
                            onRowClick={(e) => {
                                console.log(e)
                                if(hasCompanyPermission('adminUser')) navigate(`../${e.id}/detaljer`)
                            }}
                            columns={[
                                { 
                                    grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 4"],
                                    keys: ['photo', 'fullname'],
                                    display: (e) => {
                                        
                                        return (

                                            <div className="row gx-2 align-items-center">
                                                <div className="col-auto">
                                                    <UserPhoto photo={e.photo} name={e.fullname} size={[40, 30, 30]} />
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="bold m-0">{e.fullname}</h6>
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "1 / 4 / 2 / 6"],
                                    keys: ['role'],
                                    display: (e) => {
                                        return (
                                            <span>{e.role}</span>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 4 / 3 / 6"],
                                    keys: ['dob'],
                                    display: (e) => {
                                        return (
                                            <div>
                                                <span>{hasCompanyPermission('adminUser') ? (e.dob ? moment(e.dob).format('DD.MM.YYYY') : 'Ikke satt' ) : '-' }</span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 1 / 3 / 4"],
                                    keys: ['id', 'lastEdited'],
                                    display: (e) => {
                                        return (
                                            <div>
                                                <span>{hasCompanyPermission('adminUser') ? (e.lastEdited ? moment(e.lastEdited).format('DD.MM.YYYY') : 'Ikke endret' ) : '-' }</span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                                    keys: ['id'],
                                    display: (e) => {
                                        return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                                    }
                                }     
                            ]}
                        />
                    )
                }
            </div>
        </div>
    )
}

function NewUser({ open, setOpen }){
    
    const { permissionTemplates, createDomainUser } = useContext(Context)
    const [permissionOptions, setPermissionOptions] = useState([])

    let schema = yup.object().shape({
        firstname: yup.string().required("Fornavn er påkrevd"),
        lastname: yup.string().required("Etternavn er påkrevd"),
        phone: yup.number().typeError("Må være tall").required("Telefonnummer er påkrevd"),
        role: yup.string().required("Stillingstittel / rolle er påkrevd"),
        email: yup.string().email("Må være en gyldig e-postadresse").required("e-postadresse er påkrevd"),
        permissionTemplate: yup.object().typeError("Rolle er påkrevd").nullable(false).required('Rolle er påkrevd'),
        active: yup.boolean()
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            role: '',
            email: '',
            permissionTemplate: null,
            active: false
        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            const status = await createDomainUser({
                firstname: values.firstname,
                lastname: values.lastname,
                phone: values.phone,
                role: values.role,
                email: values.email,
                permissionTemplate: values.permissionTemplate,
                active: values.active
            })

            if(status){
                setOpen(false)
            }
        },
    });

    useEffect(() => {
        let temp = []
        permissionTemplates.map((e) => {
            temp.push({label: e.name, value: e.id})
        })
        setPermissionOptions(temp)
    }, [])
    

    return (
        <div className="px-3 pt-3">
            <div className="row mb-4 justify-content-between pointer" onClick={() => setOpen(false)}>
                <div className="col-auto">
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                        </div>
                        <div className="col-auto">
                            <span className="bold">Tilbake</span>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fas', 'xmark']} />
                </div>
            </div>
            <h2 className="bold mb-4">Legg til bruker</h2>
            <hr className="hint-text" />
            <form onSubmit={formik.handleSubmit}>
            <div className="row mb-5">
                <div className="col-lg-6">
                    <Input
                        name="firstname"
                        label="Fornavn"
                        type="text"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                        touched={formik.touched.firstname}
                        helperText={formik.touched.firstname && formik.errors.firstname}
                        placeholder={"Medlemmets fornavn"}
                    />
                </div>
                <div className="col-lg-6">
                    <Input
                        name="lastname"
                        label="Etternavn"
                        type="text"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        touched={formik.touched.lastname}
                        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                        helperText={formik.touched.lastname && formik.errors.lastname}
                        placeholder={"Medlemmets etternavn"}
                    />
                </div>
                <div className="col-lg-6">
                    <Input
                        name="role"
                        label="Stillingstittel / rolle"
                        type="text"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        error={formik.touched.role && Boolean(formik.errors.role)}
                        touched={formik.touched.role}
                        helperText={formik.touched.role && formik.errors.role}
                        placeholder={"Stillingstittel / rolle"}
                    />
                </div>
                <div className="col-lg-6">
                    <Input
                        name="phone"
                        label="Telefonnummer"
                        type="text"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        touched={formik.touched.phone}
                        helperText={formik.touched.phone && formik.errors.phone}
                        placeholder={"Fyll inn telefonnummer (8 siffer)"}
                    />  
                </div>
                <div className="col-lg-6">
                    <Input
                        name="email"
                        label="e-postadresse"
                        type="text"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        touched={formik.touched.email}
                        helperText={formik.touched.email && formik.errors.email}
                        placeholder={"Fyll inn medlemmets e-postadresse"}
                    />  
                </div>
                <div className="col-lg-6">
                <div className={"form-group mb-3 "}>
                        <label>Rolle</label>
                        <DropDown
                            name="permissionTemplate"
                            error={formik.touched.permissionTemplate && Boolean(formik.errors.permissionTemplate)}
                            options={permissionOptions}
                            value={formik.values.permissionTemplate}
                            placeholder="Velg en rolle"
                            onChange={(e) => formik.setFieldValue("permissionTemplate", e)}
                            touched={formik.touched.permissionTemplate}
                            helperText={formik.touched.permissionTemplate && formik.errors.permissionTemplate}

                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <CheckBox
                            name="active"
                            label="Aktiver medlem ved opprettelse"
                            value={formik.values.active}
                            classes="checkbox-success"
                            onChange={(e) => formik.setFieldValue("active", e)}
                            description="Aktivering av medlemmet gir umiddelbar invitasjon og tilgang til systemet"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <button type="button" className="btn btn-border btn-block w-100" onClick={() => setOpen(false)}>Avbryt og lukk</button>
                </div>
                <div className="col-lg-6">
                    <button className="btn btn-success w-100" type="submit">Legg til bruker</button>
                </div>
            </div>
            </form>
        </div>
    )
}