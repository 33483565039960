/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios'
import { AuthContext } from "./AuthProvider";
import { AccessContext } from "./AccessProvider";
export const ConsultantContext = React.createContext({
    consultants: [],
    users: [],
    teams: [],
    companies: [],
    permissions: null,
    loadCompanies: () => {}
})

export const ConsultantProvider = ({children}) => {
    const { enterprise } = useContext(AccessContext)
    const { apiCall } = useContext(AuthContext)

    const [consultants, setConsultants] = useState([])
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [companies, setCompanies] = useState([])
    const [permissions, setPermissions] = useState(null)
    
    

    const getPermissions = async () => {
        const { data } = await apiCall({action: 'get-permissions'})
        console.log('Trying to get permissions', data)
        if(data.status === 1){
            
            setPermissions(data.data.permissions)
        }
    }

    useEffect(() => {
        getPermissions()
    }, [enterprise])

    useEffect(() => {
        
    }, [permissions])

    useEffect(async () => {
        await loadCompanies()
        loadUsers()
        loadTeams()
    }, [])

    useEffect(() => {
        
        if(teams && teams.length){
            teams.forEach((team) => {
                setConsultants(team.consultants)
            })
        }
    }, [teams])

    useEffect(() => {
        
    }, [consultants])

    useEffect(() => {
        
        let companyUsers = []
        if(companies && companies.length > 0){
            companies.forEach((company) => {
                let users = []
                
                if(company.users){
                company.users.forEach((user) => {
                    users = [...users, {...user, company: company}]
                })
                companyUsers = [...companyUsers, ...users]
            }
            })


            setUsers([...users, ...companyUsers])
        }
    }, [companies])

  
    
    const loadCompanies = async () => {
        const status = await apiCall({action: 'api-get-consultant-companies'})

        if(status.data.status === 1){
            setCompanies(status.data.data)
        }
    }

    const loadTeams = async () => {
        const status = await apiCall({action: 'get-consultant-teams'})
        
        if(status.data.status === 1){
            
            setTeams(status.data.data)
        }
    }

    const loadUsers = async () => {
        const { data } = await apiCall({action: 'get-enterprise-users'})
        if(data && data.status === 1){
            setUsers(data.data)
        }
    }
    

    return <ConsultantContext.Provider value={{
        consultants,
        users,
        teams,
        companies,
        permissions
    }}>{
        !permissions ? <NoAccess /> : children 
    }</ConsultantContext.Provider>
}

function NoAccess({ message }){
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height: '100vh'}}>
            <h1>Her har du ikke tilgang</h1>
            <h3>Du har ikke tilgang til denne rådgiverbedriften</h3>
        </div>
        
    )
}