/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { offset } from "@popperjs/core";
import React, { useRef, useState, useEffect, useContext } from "react";
import { usePopper } from "react-popper";
import { CSSTransition } from "react-transition-group";
import { ConfigContext } from "../providers/ConfigProvider";
import ImageStack from "./ImageStack";
import { Portal } from "./Portal";
import UserPhoto from "./UserPhoto";
export default function PersonPicker({ items, selected, setSelected, handleManually, text, hideOutput, label, disableSearch, disabled, teams, selectedTeams, setSelectedTeams }){

    const ref = useRef(null)

    const [referenceElement, setReferenceElement] = useState()
    const [popperElement, setPopperElement] = useState()
    const [selectedOutput, setSelectedOutput] = useState([])

    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        offset: 10
    })

    const [search, setSearch] = useState('')
    const [showDropdown, setShowDropdown] = useState(false)
    const [view, setView] = useState('users')


    const update = (item) => {
        if(handleManually){
            setSelected(item)
            return
        }
        if(selected.filter(e => e.id === item.id).length > 0){
            const removed = selected.filter(e => e.id !== item.id)
            setSelected(removed)
        }else{
            setSelected([...selected, item])
        }
    }

    const updateTeam = (item) => {
        if(handleManually){
            setSelectedTeams(item)
            return
        }
        if(selectedTeams.filter(e => e.id === item.id).length > 0){
            const removed = selectedTeams.filter(e => e.id !== item.id)
            setSelectedTeams(removed)
        }else{
            setSelectedTeams([...selectedTeams, item])
        }
    }

    const setPosition = (ref) => {
        let pos = ref.current.getBoundingClientRect();

        let {width, height, left,top} = pos;

        let offsetFromRight =  window.innerWidth - (width + left);

        if (offsetFromRight < 0){
            ref.current.style.left = (offsetFromRight - 40) + "px";

        }

        return;
    }

    useEffect(() => {
        if(showDropdown){
            setPosition(ref);
        }
    }, [showDropdown])

    useOnClickOutside(ref, () => setShowDropdown(false))

    useEffect(() => {
        let output

        output = [...selected]

        if(selectedTeams){
            output = [...output, ...selectedTeams.map(team => {
                return {
                    fullname: team.name,
                    photo: null
                }
            })]
        }
        setSelectedOutput(output)
    }, [selected, selectedTeams])

    return (
        <div className="person-picker-container">
            <div className="row gx-2">
                <div className="col-auto">
                    {
                        text ? 
                        (
                            disabled ? null : 
                            <button ref={setReferenceElement} type="button" className="btn btn-dashed" onClick={() => setShowDropdown(true)}>
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <FontAwesomeIcon icon={['fal', 'plus']} />
                                    </div>
                                    <div className="col">
                                        <span>{text}</span>
                                    </div>
                                </div>
                            </button>
                        )
                        :
                        disabled ? null : 
                        <button ref={setReferenceElement} type="button" className="person-picker-button" onClick={() => setShowDropdown(true)}>
                            <FontAwesomeIcon icon={['fal', 'plus']} />
                        </button>
                    }
                </div>
                {
                    hideOutput ? null : 
                        <div className="col-auto">
                            <ImageStack users={selectedOutput} />
                        </div>
                }
            </div>
            <Portal className="person-picker-portal">
            { showDropdown ?
            <CSSTransition  in={showDropdown} timeout={200} classNames="person-picker-dropdown-wrapper">
                <div 
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="popper"
                >
                <div className="person-picker-dropdown" ref={ref}>
                    {
                        !disableSearch ? (
                            <div className="person-picker-search">
                                <div className="row align-items-center gx-2">
                                    <div className="col-auto">
                                        <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                                    </div>
                                    <div className="col">
                                        <input type="text" placeholder="Søk" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        ) : null 

                    }

                    {
                        label ? (
                                <div className="person-picker-search">
                                    <small className="caps hint-text">{label}</small>
                                </div>
                        ) : null
                    }

                    {
                        teams && teams.length > 0 ? (
                            <div className="person-picker-search">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <small onClick={() => setView('users')} className={`caps ${view === 'users' ? 'bold' : 'hint-text pointer'}`}>Brukere</small>
                                    </div>
                                    <div className="col-auto">
                                        <small onClick={() => setView('teams')} className={`caps ${view === 'teams' ? 'bold' : 'hint-text pointer'}`}>Grupper</small>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    
                    <div className="person-picker-wrapper">
                        {
                            view === 'users' ? 
                                items?.filter(person => !person.fullname || person.fullname.match(new RegExp(search, 'i'))).map((item, index) => {
                                    return (
                                        <Person 
                                            key={index} 
                                            setSelected={setSelected}
                                            update={update}
                                            selected={selected}
                                            item={item}
                                        />
                                    )
                                }) 
                            : 

                                teams?.map((item, index) => {
                                    return (
                                        <Team 
                                            key={index} 
                                            setSelected={setSelected}
                                            update={updateTeam}
                                            selected={selectedTeams}
                                            item={item}
                                        />
                                    )
                                })
                        }
                    </div>
                </div>
                </div>
            </CSSTransition>
            : null}
            </Portal>
        </div>
    )
}

function Person({ item, selected, setSelected, update}){
    const { id, fullname, photo, consultant } = item
    
    const active  = selected?.filter(e => e.id === id).length > 0
    return (
        <div className="person-picker-item" onClick={() => update(item)}>
            <div className="row align-items-center gx-3">
                <div className="col-auto">
                    <UserPhoto photo={photo} size={[30, 30, 30]} name={fullname} consultant={consultant} />
                </div>
                <div className="col">
                    <span className={active ? 'bold' : ''}>{fullname}</span>
                </div>
            </div>
        </div>
        
    )
}

function Team({ item, selected, setSelected, update }){
    const { id, name } = item

    console.log(selected)

    const active  = selected?.filter(e => e.id === id).length > 0
    return (
        <div className="person-picker-item" onClick={() => update(item)}>
            <div className="row align-items-center gx-3">
                <div className="col-auto">
                    <UserPhoto photo={null} size={[30, 30, 30]} name={name}  />
                </div>
                <div className="col">
                    <span className={active ? 'bold' : ''}>{name}</span>
                </div>
            </div>
        </div>
    )
}

function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }