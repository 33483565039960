/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { NewCompanyContext, NewCompanyProvider } from "../../../providers/NewCompanyProvider";
import 'react-image-crop/dist/ReactCrop.css';
import DropDown from "../../../components/DropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Drawer from "../../../components/Drawer";
import PersonModal from "../../../components/PersonModal";
import UserPhoto from "../../../components/UserPhoto";
import { ConfigContext } from "../../../providers/ConfigProvider";
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import ImageCrop from "../../../components/ImageCrop";
import { DomainContext } from "../../../providers/DomainProvider";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";

export default function DomainNewCompany(){
    return (
        <NewCompanyProvider>
            <TabNavigation />
        </NewCompanyProvider>
    )
}

function TabNavigation(){

    const { formik } = useContext(NewCompanyContext)

    const [errors, setErrors] = useState(null)

    const TabItem = ({ link, children, error }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={`tab-item ${match ? "tab-item-active" : (error ? 'text-danger' : null)}`}>
                {error && !match ? (
                    <div className="row align-items-center gx-2">
                        <div className="col-auto">{children}</div>
                        <div className="col-auto"><FontAwesomeIcon icon={['fas', 'circle-exclamation']} /></div>
                    </div>
                ) : children}
            </Link>
        )
    }

    
    const navigate = useNavigate()
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('bedriftsinformasjon')
        }
    }, [])

    useEffect(() => {
        if(formik && formik.errors && Object.keys(formik.errors).length){
            setErrors(true)
        }else{
            setErrors(false)
        }
    }, [formik])

    return (
        <div className="p-md-3 p-2">
                <div className="transparent-box">
                    <div className="tab-container">
                        <div className="row gx-0">
                            <div className="col-auto">
                                <TabItem error={errors} link="bedriftsinformasjon" className="text-danger">Bedriftsinformasjon</TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="brukere">Brukere</TabItem>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
    )
}

export function DomainNewCompanySettings(){
    

    const {
        useExistingCompany, setUseExistingCompany,
        existingCompany,
        formik

    } = useContext(NewCompanyContext)

    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        if(existingCompany && !useExistingCompany){
            setModalOpen(true)
        }
    }, [existingCompany])
      
    return (
        
            <div className="bg-white p-md-4 p-3">
                <div className="row justify-content-between mb-5">
                    <div className="col-auto">
                        <h2 className="bold">Opprett ny bedrift</h2>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-primary px-4" type="submit">Lagre</button>
                    </div>
                </div>
                
                <Information />
                <InsertLogo />
                <Admins />
                <Settings />
                <Tasks />
                <Forms />
                <Documents />
                
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <Link to="../brukere" className="btn btn-border px-5">Legg til brukere</Link>
                    </div>
                </div>

                <Modal open={modalOpen} setOpen={setModalOpen} containerStyle={{minWidth: 500}} >
                    <div className="p-3">
                        <h4 className="bold">Dette orgnummeret er allerede registrert</h4>
                        <p>Bedriften du prøver å opprette eksisterer allerede. Vil du koble denne bedriften til ditt domene?</p>
                        <div className="row">
                            <div className="col"><button type="button" className="btn btn-border w-100" onClick={() => {
                                setUseExistingCompany(false)
                                formik.setFieldValue('orgnr', '')
                                setModalOpen(false)
                            }}>Nei, la meg prøve igjen</button></div>
                            <div className="col"><button type="button" className="btn btn-success w-100" onClick={() => {
                                setUseExistingCompany(true)
                                setModalOpen(false)
                            }}>Ja, hent informasjon</button></div>
                        </div>
                    </div>
                </Modal>
            </div> 
            
                
        
    )
}

function Information(){

    const {
        useExistingCompany,
        formik
    } = useContext(NewCompanyContext)
    const { notify } = useContext(ConfigContext)

    const getBrreg = async () => {
        const orgnr = formik.values.orgnr.replace(/ /g,'')
        if(orgnr.length === 9){
            const status = await fetch('https://data.brreg.no/enhetsregisteret/api/enheter/' + orgnr)
           
            
            if(status.ok){
                const data = await status.json()

                const address = data.postadresse ? data.postadresse : data.forretningsadresse
                if(data){
                    formik.setFieldValue('name', data.navn)
                    formik.setFieldValue('country', {label: address.land, value: address.landkode})
                    formik.setFieldValue('address', address.adresse[0])
                    formik.setFieldValue('zip', address.postnummer)
                    formik.setFieldValue('city', address.poststed)
                }
            }else{
                notify(2, 'Ingen treff', 'Det var ingen treff på gitt org.nr. hos Brønnøysund')
            }
        }else{
            console.log(orgnr)
        }
        
    }
    { /** 
    if(existingCompany && useExistingCompany){
        
        return (
            <div>
            <h4 className="bold">Informasjon om bedriften</h4>
                <div className="border-lg p-md-4 p-3 mb-5">
                    <div className="row align-items-end mb-3">
                        <div className="col-md-4 mb-2 mb-md-0">
                            <Input
                                name="orgnr"
                                label="Org.nr."
                                type="text"
                                value={formik.values.orgnr}
                                onChange={formik.handleChange}
                                error={formik.touched.orgnr && Boolean(formik.errors.orgnr)}
                                touched={formik.touched.orgnr}
                                helperText={formik.touched.orgnr && formik.errors.orgnr}
                                placeholder={"Bedriftens organisasjonsnummer"}
                            />
                        </div>
                    </div>
                    <div className="row align-items-end mb-3">
                        <div className="col mb-2 mb-md-0">
                            
                            <Input
                                name="name"
                                label="Navn"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.orgnr}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={""}
                            />
                        </div>
                        <div className="col-md-4 col-12">
                            <div className={"form-group" + (validations && validations.country ? ' error' : '')}>
                                <label>Land</label>
                                <DropDown
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    helperText={formik.touched.country && formik.errors.country}
                                    value={formik.values.country}
                                    onChange={(value) => formik.setFieldValue('country', value)}
                                    options={[
                                        {label: 'Norge', value: 'NO'},
                                        {label: 'Sverige', value: 'SE'},
                                        {label: 'Danmark', value: 'DK'},
                                        {label: 'Polen', value: 'PL'},
                                    ]}
                                    placeholder="Velg land"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end mb-3 gx-2 gx-md-3">
                        <div className="col-12 col-md mb-2 mb-md-0">
                            <Input
                                name="address"
                                label="Navn"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                touched={formik.touched.address}
                                helperText={formik.touched.address && formik.errors.address}
                                placeholder={""}
                            />                        </div>
                        <div className="col-md-2 col-4">
                            <div className={"form-group"}>
                                <label>Postnr</label>
                                <input value={existingCompany.payload.zip} disabled type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-3 col">
                            <div className={"form-group"}>
                                <label>Poststed</label>
                                <input value={existingCompany.payload.city} disabled type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
    */}
   

    return (
        <div>
        <h4 className="bold">Informasjon om bedriften</h4>
            <div className="border-lg p-md-4 p-3 mb-5">
                <div className="row align-items-center mb-3">
                    <div className="col-md-4 mb-2 mb-md-0">
                        <Input
                            name="orgnr"
                            label="Org.nr."
                            type="text"
                            value={formik.values.orgnr}
                            onChange={formik.handleChange}
                            error={formik.touched.orgnr && Boolean(formik.errors.orgnr)}
                            touched={formik.touched.orgnr}
                            helperText={formik.touched.orgnr && formik.errors.orgnr}
                            placeholder={"Bedriftens organisasjonsnummer"}
                        />
                    </div>
                    <div className="col-md-auto col">
                        <button type="button" className="btn btn-primary px-4" onClick={() => getBrreg()}>Hent fra Brønnøysund</button>
                    </div>
                </div>
                <div className="row align-items-end mb-3">
                    <div className="col mb-2 mb-md-0">
                        <Input
                            name="name"
                            label="Navn"
                            type="text"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            touched={formik.touched.name}
                            helperText={formik.touched.name && formik.errors.name}
                            placeholder={"Bedriftens navn"}
                            disabled={useExistingCompany}
                        />
                    </div>
                    <div className="col-md-4 col-12">
                        <div className={"form-group mb-3"}>
                            <label>Land</label>
                            <DropDown
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                value={formik.values.country}
                                onChange={(e) => formik.setFieldValue("country", e)}
                                touched={formik.touched.country}
                                helperText={formik.touched.country && formik.errors.country}
                                options={[
                                    {label: 'Norge', value: 'NO'},
                                    {label: 'Sverige', value: 'SE'},
                                    {label: 'Danmark', value: 'DK'},
                                    {label: 'Polen', value: 'PL'},
                                ]}
                                placeholder="Velg land"
                                disabled={useExistingCompany}
                            />
                        </div>
                    </div>
                </div>
                <div className="row align-items-end mb-3 gx-2 gx-md-3">
                    <div className="col-12 col-md mb-2 mb-md-0">
                        <Input
                            name="address"
                            label="Gateadresse"
                            type="text"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            touched={formik.touched.address}
                            helperText={formik.touched.address && formik.errors.address}
                            placeholder={"Gateadresse"}
                            disabled={useExistingCompany}
                        />
                    </div>
                    <div className="col-md-2 col-4">
                        <Input
                            name="zip"
                            label="Postnr"
                            type="text"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                            touched={formik.touched.zip}
                            helperText={formik.touched.zip && formik.errors.zip}
                            placeholder={"Postnr."}
                            
                            disabled={useExistingCompany}
                        />
                    </div>
                    <div className="col-md-3 col">
                        <Input
                            name="city"
                            label="Poststed"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.zip)}
                            touched={formik.touched.city}
                            helperText={formik.touched.city && formik.errors.city}
                            placeholder={"Poststed"}
                            
                            disabled={useExistingCompany}
                        />
                    </div>
                </div>
            </div>
        </div>  
    )
}

function InsertLogo(){

    const {
        useExistingCompany,
        formik
    } = useContext(NewCompanyContext)


    if(useExistingCompany) return <div></div>

    return (
        <div>
            <h4 className="bold">Bedriftens logo</h4>
                <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                            <div className="py-1">
                                <ImageCrop 
                                    img={formik.values.logo}
                                    setImg={(logo) => formik.setFieldValue("logo", logo)}
                                    height={150}
                                    aspect={1}
                                    unit={'logo'}
                                    error={formik.touched.logo && Boolean(formik.errors.logo)}
                                    helperText={formik.touched.logo && formik.errors.logo}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col mb-md-0 mb-5">
                        <h6 className="bold">Bedriftens logo</h6>
                        <p>Administrator i bedriften kan selv legge inn og endre egen logo i etterkant av opprettelse.</p>
                    </div>
                </div>
        </div>
    )
}

function Settings(){

    const { formik, templateTags, templateTypes } = useContext(NewCompanyContext)

    return (
        <div>
            <h4 className="bold">Oppsett av området</h4>
                <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                            <div className="form-group mb-3">
                                <label>Type selskap</label>
                                <DropDown
                                    name="type"
                                    error={formik.touched.type && Boolean(formik.errors.type)}
                                    value={formik.values.type}
                                    onChange={(value) => {
                                        const selectedType =  templateTypes.filter(t => t.id === value.value)[0]
                                        if(selectedType.SagaTemplateDocument){
                                            const arr = []
                                            selectedType.SagaTemplateDocument.map((document) => {
                                                
                                                if(formik.values.documents.filter(t => t.value === document.id).length === 0){
                                                    arr.push({label: document.name, value: document.id})
                                                }
                                            })
                                            formik.setFieldValue("documents", [...formik.values.documents, ...arr])
                                        }

                                        if(selectedType.SagaTemplateTask){
                                            const arr = []
                                            selectedType.SagaTemplateTask.map((task) => {
                                                if(formik.values.tasks.filter(t => t.value === task.id).length === 0){
                                                    arr.push({label: task.name, value: task.id})
                                                }
                                            })
                                            
                                            formik.setFieldValue("tasks", [...arr, ...formik.values.tasks])
                                        }

                                        if(selectedType.SagaTemplateForm){
                                            const arr = []
                                            selectedType.SagaTemplateForm.map((form) => {
                                                if(formik.values.forms.filter(t => t.value === form.id).length === 0){
                                                    arr.push({label: form.name, value: form.id})
                                                }
                                            })
                                            
                                            formik.setFieldValue("forms", [...arr, ...formik.values.forms])
                                        }
                                        formik.setFieldValue("type", value)
                                    }}
                                    options={templateTypes.map(item => {
                                        return {label: item.name, value: item.id}
                                    })}
                                    placeholder="Velg selskapstype"
                                    touched={formik.touched.type}
                                    helperText={formik.touched.type && formik.errors.type}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Knagger</label>
                                <DropDown
                                    name="tags"
                                    error={formik.touched.tags && Boolean(formik.errors.tags)}
                                    value={formik.values.tags}
                                    onChange={(value) => {
                                        
                                        if(formik.values.tags.filter(t => t.value === value.value).length === 0){

                                            const selectedTag = templateTags.filter(t => t.id === value.value)[0]

                                            
                                            if(selectedTag){
                                                if(selectedTag.SagaTemplateDocument){
                                                    const arr = []
                                                    selectedTag.SagaTemplateDocument.map((document) => {
                                                        
                                                        if(formik.values.documents.filter(t => t.value === document.id).length === 0){
                                                            arr.push({label: document.name, value: document.id})
                                                        }
                                                    })
                                                    formik.setFieldValue("documents", [...formik.values.documents, ...arr])
                                                }

                                                if(selectedTag.SagaTemplateTask){
                                                    const arr = []
                                                    selectedTag.SagaTemplateTask.map((task) => {
                                                        if(formik.values.tasks.filter(t => t.value === task.id).length === 0){
                                                            arr.push({label: task.name, value: task.id})
                                                        }
                                                    })
                                                    
                                                    formik.setFieldValue("tasks", [...arr, ...formik.values.tasks])
                                                }

                                                if(selectedTag.SagaTemplateForm){
                                                    const arr = []
                                                    selectedTag.SagaTemplateForm.map((form) => {
                                                        if(formik.values.forms.filter(t => t.value === form.id).length === 0){
                                                            arr.push({label: form.name, value: form.id})
                                                        }
                                                    })
                                                    
                                                    formik.setFieldValue("forms", [...arr, ...formik.values.forms])
                                                }
                                            }
                                            formik.setFieldValue("tags", [...formik.values.tags, value])
                                        }else{
                                            const selectedTag = templateTags.filter(t => t.id === value.value)[0]
                                            if(selectedTag){
                                                
                                                if(selectedTag.SagaTemplateTask){
                                                    const arr = []
                                                    selectedTag.SagaTemplateTask.map((task) => arr.push({label: task.name, value: task.id}))
                                                    formik.setFieldValue("tasks", formik.values.tasks.filter(t => arr.indexOf(t) > -1))
                                                }
                                                if(selectedTag.SagaTemplateForm){
                                                    const arr = []
                                                    selectedTag.SagaTemplateForm.map((form) => arr.push({label: form.name, value: form.id}))
                                                    formik.setFieldValue("forms", formik.values.forms.filter(t => arr.indexOf(t) > -1))
                                                }
                                                if(selectedTag.SagaTemplateDocument){
                                                    const arr = []
                                                    selectedTag.SagaTemplateDocument.map((document) => arr.push({label: document.name, value: document.id}))
                                                    formik.setFieldValue("documents", formik.values.documents.filter(t => arr.indexOf(t) > -1))
                                                }
                                            }
                                            formik.setFieldValue("tags", formik.values.tags.filter(t => t.value !== value.value))
                                        }
                                        
                                    }}
                                    options={templateTags.map(item => {
                                        return {label: item.name, value: item.id}
                                    })}
                                    placeholder="Velg knagger"
                                    multiple
                                />
                            </div>
                            
                            
                        </div>
                    </div>
                    <div className="col mb-md-0 mb-5">
                        <h6 className="bold">Selskapstyper og knagger</h6>
                        <p>Ved å bruke selskapstyper og knagger vil du automatisk få opp forslag til oppgave- og dokumentoppsett for bedriften som opprettes.</p>
                    </div>
                </div>
        </div>
    )
}

function Admins(){

    const { formik } = useContext(NewCompanyContext)
    const { domainUsers } = useContext(DomainContext)

    const [ open, setOpen ] = useState(false)
    return (
        <div>
            <h4 className="bold">Tildel admin for området</h4>
        
            <div className="border-lg mb-md-5 mb-2 ">
                <div className="p-md-4 p-3">
                    <p>Velg admin for bedriften som vil få tilgang til samhandling og oversikt over denne bedriften. Om ingen velges, vil du som oppretter automatisk bli admin.</p>
                    <div className="pt-4">
                        <button type="button" className="btn btn-dashed" onClick={() => setOpen(true)}>
                            <div className="row gx-1">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['fal', 'plus']} />
                                </div>
                                <div className="col">
                                    <span>Tildel admin</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                {
                    formik.values.admins.length > 0 && formik.values.admins.map((el, key) => {
                        return (
                            <div className="border-top px-3 py-2" key={key}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <UserPhoto name={el.fullname} photo={el.photo} size={[40, 40, 40]} />
                                    </div>
                                    <div className="col">
                                        <p className="m-0">{el.fullname}</p>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-link text-danger" onClick={() => formik.setFieldValue('admins', formik.values.admins.filter(c => c.id !== el.id))  }>Fjern</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <Drawer
                open={open}
                setOpen={setOpen}
            >
                <PersonModal
                    users={domainUsers}
                    responsibleUsers={formik.values.admins}
                    setResponsibleUsers={(a) => formik.setFieldValue('admins', a)}
                    setOpen={setOpen}
                />
            </Drawer>
        </div>
    )
}

function Tasks(){

    const { templateTasks, formik } = useContext(NewCompanyContext)
    return (
        <div>
            <h4 className="bold">Oppgaver</h4>
            <div className="row">
                <div className="col-md-8 col-12">
                    <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                        <div className="form-group">
                            <label>Hvilke oppgaver skal bedriften utføre?</label>
                            <DropDown
                                name="tasks"
                                error={formik.touched.tasks && Boolean(formik.errors.tasks)}
                                value={formik.values.tasks}
                                onChange={(value) => {
                                    
                                    if(formik.values.tasks.filter(t => t.value === value.value).length === 0){
                                        
                                        formik.setFieldValue('tasks', [...formik.values.tasks, value])
                                    }else{
                                        formik.setFieldValue('tasks', formik.values.tasks.filter(t => t.value !== value.value))
                                    }
                                    
                                }}
                                options={templateTasks?.map(item => {
                                    return {label: item.name, value: item.id}
                                })}
                                placeholder="Velg oppgaver"
                                multiple
                                touched={formik.touched.tasks}
                                helperText={formik.touched.tasks && formik.errors.tasks}
                            />
                        </div>
                    </div>
                </div>
                <div className="col mb-md-0 mb-5">
                    <h6 className="bold">Oppgaver</h6>
                    <p>Velg selv hvilke oppgavemaler som skal følge med bedriften. Selskapstyper og knagger vil være med på å bestemme hvilke oppgaver som er valgt. Etter opprettelse må en admin inn og tildele ansvarlige på hver oppgave.</p>
                </div>
            </div>
        </div>
    )
}

function Forms(){

    const { templateForms, formik } = useContext(NewCompanyContext)
    return (
        <div>
            <h4 className="bold">Skjemamaler</h4>
            <div className="row">
                <div className="col-md-8 col-12">
                    <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                        <div className="form-group">
                            <label>Hvilke skjemamaler skal bedriften få med?</label>
                            <DropDown
                                name="forms"
                                error={formik.touched.forms && Boolean(formik.errors.forms)}
                                value={formik.values.forms}
                                onChange={(value) => {
                                    
                                    if(formik.values.forms.filter(t => t.value === value.value).length === 0){
                                        
                                        formik.setFieldValue('forms', [...formik.values.forms, value])
                                    }else{
                                        formik.setFieldValue('forms', formik.values.forms.filter(t => t.value !== value.value))
                                    }
                                    
                                }}
                                options={templateForms?.map(item => {
                                    return {label: item.name, value: item.id}
                                })}
                                placeholder="Velg skjemamaler"
                                multiple
                                touched={formik.touched.forms}
                                helperText={formik.touched.forms && formik.errors.forms}
                            />
                        </div>
                    </div>
                </div>
                <div className="col mb-md-0 mb-5">
                    <h6 className="bold">Skjemamaler</h6>
                    <p>Velg selv hvilke skjemamaler som skal følge med bedriften. Disse skjemamalene kan brukes når medlemmer i bedriften oppretter oppgaver.</p>
                </div>
            </div>
        </div>
    )
}

function Documents(){
    const { formik, templateDocuments } = useContext(NewCompanyContext)
    return (
        <div>
            <h4 className="bold">Dokumenter</h4>
            <div className="row">
                <div className="col-md-8 col-12">
                    <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                        <div className="form-group">
                            <label>Hvilke dokumentområder skal bedriften få tilgang til?</label>
                            <DropDown
                                name="documents"
                                error={formik.touched.documents && Boolean(formik.errors.documents)}
                                value={formik.values.documents}
                                onChange={(value) => {
                                    
                                    if(formik.values.documents.filter(t => t.value === value.value).length === 0){
                                        formik.setFieldValue('documents', [...formik.values.documents, value])
                                    }else{
                                        
                                        formik.setFieldValue('documents', formik.values.documents.filter(t => t.value !== value.value))
                                    }
                                    
                                }}
                                options={templateDocuments?.map(item => {
                                    return {label: item.name, value: item.id}
                                })}
                                placeholder="Velg dokumentområder"
                                multiple
                            />
                        </div>
                    </div>
                </div>
                <div className="col mb-md-0 mb-5">
                    <h6 className="bold">Dokumenter</h6>
                    <p>Velg dokumentområder bedriften skal ha tilgang til. Hvis ingen er valgt, vil bedriften stå uten dokumenter, men alikevel ha mulighet til å få strukturere egne områder. Dokumentområder kan følge med basert på selskapstype og knagger.</p>
                </div>
            </div>
        </div>
    )
}
