import React from "react"
export default function FullscreenLoading({customText}){



    return (
        <div className="loadingcomponent-wrapper vh-100 d-flex align-items-center">
            <div className="d-block w-100">
                <div className="fullscreenloader">Laster...</div>
                {customText ? <h4 className="text-center fw-bold">{customText}</h4> : null}
            </div>
           
        </div>
    )
}