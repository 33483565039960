/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { createRef, useContext, useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";

export default function FileUpload(props){

    const { accessToken, company, domain } = useContext(AuthContext)
    const { notify, apiUrl } = useContext(ConfigContext)
    const { files, setFiles } = props
    const container = createRef()
    const [dragging, setDragging] = useState(false)
    const [mousePosition, setMousePosition] = useState({})
    const [uploading, setUploading] = useState(false)
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    
    useEffect(() => {

    }, [files])

    const handleChange = async e => {
        

        e.preventDefault();
        e.stopPropagation();
        let newFiles = files.slice()
        if (e.target.files) {
            const fileList = e.target.files
        
            
            
            
            for (var i = 0; i < fileList.length; i++) {
                if(!files.some(file => file.name === fileList[i].name)){
                    const data = await uploadFile(fileList[i])
                    
                    if(data) newFiles.push(data)
                }
            }
            console.log(newFiles)
            
        }

        setFiles(newFiles)

        setDragActive(false);
    }

    const uploadFile = async (file) => {
        setUploading(true)
        const formData = new FormData();

        formData.append('accessToken', accessToken)
        formData.append('action', 'upload-single-attachment')
        formData.append('files[]', file)
        
        if(company) formData.append('company', company.id)
        if(domain) formData.append('domain', domain.id)

        const { data } = await axios({
            method: 'post',
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        setUploading(false)
        if(data.status !== 1){
            notify(data.status, data.title, data.message)
            return null
        }

        return data.data

    }

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
      };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const fileList = e.dataTransfer.files
        
            
            
            let newFiles = files
            for (var i = 0; i < fileList.length; i++) {
                if(!files.some(file => file.name === fileList[i].name)){
                    const data = await uploadFile(fileList[i])
                    
                    if(data) newFiles.push(data)
                }
            }
            console.log(newFiles)
            setFiles(newFiles)
        }

        setDragActive(false);
    }

    const removeFile = (id) => {
        setFiles(files.filter(f => f.id !== id))
    }

    const FileIcon = ({type}) => {
        switch(type){
            case 'image/jpeg':
                return <FontAwesomeIcon icon={['fal', 'file-image']} size="2x" fixedWidth />

            default:
                return <FontAwesomeIcon icon={['fal', 'file-lines']} size="2x" fixedWidth />

        }
    }

    const FileItem = ({ id, file_name, file_type, file_size }) => {

        const { downloadAttachment } = useContext(AuthContext)
                
        return (
            
                <div className="file-item pointer">
                    <div className=" row align-items-center gx-1">
                        <div className="col-auto">
                            <FileIcon type={file_type} />
                        </div>
                        <div className="col">
                            <p className="m-0 fw-bold">{file_name}</p>
                        </div>
                        <div className="col-auto">
                            <p className="text-primary pointer mb-0 px-2"  onClick={() => downloadAttachment(id, file_name)}>Last ned</p>
                        </div>
                        <div className="col-auto">
                            <p className="text-danger pointer mb-0"  onClick={() => removeFile(id)}>Fjern</p>
                        </div>
                    </div>
                </div>
            
        )
    }
    
    
    return (
        <div className={`${uploading ? 'loading-overlay' : ''} position-relative `}>

            <form id="form-file-upload" className={`file-upload-container ${dragActive ? 'drag-active' : ''}`} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <div className="file-upload-text text-center" onClick={() => inputRef.current.click()}>
                    <div className="d-block my-3 text-primary">
                            <FontAwesomeIcon icon={['far', 'folder-arrow-up']} size="2x"  fixedWidth />
                        </div>
                        <span className="fs-5" style={{color : "#0BB5A6"}}>Dra og slipp, eller trykk her for å laste opp</span>
                    </div>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>

            { files.length > 0 ? 
                (<div className="file-wrapper mt-2">
                    {
                        files.map((el, index) => {
                            return (
                                <FileItem 
                                    key={index}
                                    {...el}
                                />)
                            })  
                    }
            
                </div>)
            : null }
        </div>
    )
}
