import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useOnClickOutside } from "./Functions";

export default function RightDrawer({open, setOpen, children}){
    const ref = useRef()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(open)
        }, 50)
    }, [open])

    useOnClickOutside(ref, () => setOpen(false))

    return (
        <CSSTransition in={open} timeout={250} classNames="notification-center-wrapper" unmountOnExit>
            <div className="notification-center-wrapper">
                <CSSTransition in={isOpen} timeout={500} classNames="notification-center-container" unmountOnExit>
                    <div className="notification-center-container p-md-5 p-4" ref={ref}>
                        {children}
                    </div>
                </CSSTransition>
            </div>
        </CSSTransition>
    )
}

function Notification(){

}