import React, { useContext, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import PersonPicker from "../../components/PersonPicker"
import UserPhoto from '../../components/UserPhoto'
import { AuthContext } from '../../providers/AuthProvider'
import { CompanyContext } from '../../providers/CompanyProvider'
import { ConfigContext } from '../../providers/ConfigProvider'
import { DomainContext } from '../../providers/DomainProvider'
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from '../../components/Input'
import DropDown from '../../components/DropDown'
import ImageCrop from '../../components/ImageCrop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { formatDate } from '../../components/Functions'
import RadioGroup from '../../components/RadioGroup'
import Modal from '../../components/Modal'

export default function CompanySettings(){
    const { hasCompanyPermission } = useContext(CompanyContext)
    const { company } = useContext(AuthContext)
    const TabItem = ({ link, children }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={"tab-item " + (match ? "tab-item-active" : "")}>{children}</Link>
        )
    }

    
    const navigate = useNavigate()
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('detaljer')
        }
    }, [])

    return (
        <div className="p-md-3 p-2 h-100">
                <div className="transparent-box h-100 tab-wrapper">
                    
                    <div className="tab-container">
                        <div className="row gx-0 justify-content-between">
                            <div className="col">
                                <div className="row gx-0">
                                    <div className="col-auto">
                                        <TabItem link="detaljer">Detaljer</TabItem>
                                    </div>
                                    <div className="col-auto">
                                        {company.companyType == 1 ? <TabItem link="administratorer">Administratorer</TabItem> : null}
                                    </div>
                                    { hasCompanyPermission(['adminCompany']) ?
                                        <div className="col-auto">
                                            <TabItem link="maler">Maler</TabItem>
                                        </div>
                                    : null }

                                </div>
                            </div>
                            { hasCompanyPermission(['adminCompany']) ?
                                <div className="col-auto align-self-end">
                                    <TabItem link="rediger">Rediger detaljer <FontAwesomeIcon style={{marginLeft: 10}} icon={['fal', 'pencil']} /></TabItem>
                                </div>
                            : null }
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
    )
}
export function CompanyInformation(){
    const { company, uploadFile, apiCall } = useContext(AuthContext)

    if(company?.companyType === 2){
        return <RoomInformation />
    }
    
    return (
        
            <div className="p-3 p-md-4 bg-white h-100" style={{overflowY: 'scroll'}}>
                <div className="pb-3">
                    <h3 className="bold mb-3">Bedriftsdetaljer</h3>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <UserPhoto photo={company.image} name={company.name} size={[80, 70, 50]} />
                        </div>
                        <div className="col">
                            <h3 className="bold m-0">{company.name}</h3>
                            <h6 className="m-0">Org.nr. {company.organization_number}</h6>
                        </div>
                    </div>
                </div>
                <div className="pb-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="bold">Bedriftsopplysninger</h4>
                            <p>Bedriften personopplysninger som oppholssted, navn kontaktopplysninger og mer.</p>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Organisasjonsnummer</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.organization_number}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Navn</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.name}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Gateadresse</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.payload.address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Postnummer</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.payload.zip}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Poststed</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.payload.city}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Land</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.country?.label}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Opprettet</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">
                                            {company.created ? 
                                                <span>{moment(company.created.on).format('D.M.Y')} av {company.created.by.fullname}</span>
                                            : <i>Mangler info</i>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Aktiv bedrift</label>

                                </div>
                                <div className="col-6">
                                    <label className="bold">{company.deleted ? 'Nei' : 'Ja'}</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Sist endret</label>

                                </div>
                                <div className="col-6">
                                <p className="m-0">
                                            {company.edited ? 
                                                <span>{formatDate(company.edited.on)} av {company.edited.by.fullname}</span>
                                            : <i>Mangler info</i>}
                                        </p>
                                    <label className="bold"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Shortcuts />
            </div>
        
    )
}

export function RoomInformation(){
    const { company, uploadFile, apiCall } = useContext(AuthContext)

    return (
        
            <div className="p-3 p-md-4 bg-white h-100">
                <div className="pb-3">
                    <h3 className="bold mb-3">Romdetaljer</h3>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <UserPhoto photo={company.image} name={company.name} size={[80, 70, 50]} />
                        </div>
                        <div className="col">
                            <h3 className="bold m-0">{company.name}</h3>
                        </div>
                    </div>
                </div>
                <div className="pb-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="bold">Bedriftsopplysninger</h4>
                            <p>Brukerens personopplysninger som oppholssted, navn kontaktopplysninger og mer.</p>
                            
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Navn</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">{company.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Opprettet</label>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0">
                                            {company.created ? 
                                                <span>{moment(company.created.on).format('D M Y')} av {company.created.by.fullname}</span>
                                            : <i>Mangler info</i>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="bold">Romprofil</h4>
                            <p>Informasjon om romprofil, som siste endring og om brukeren er aktiv i systemet.</p>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Aktiv bedrift</label>

                                </div>
                                <div className="col-6">
                                    <label className="bold">{company.deleted ? 'Nei' : 'Ja'}</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Sist endret</label>

                                </div>
                                <div className="col-6">
                                <p className="m-0">
                                            {company.edited ? 
                                                <span>{formatDate(company.edited.on)} av {company.edited.by.fullname}</span>
                                            : <i>Ikke endret</i>}
                                        </p>
                                    <label className="bold"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Shortcuts />
            </div>
        
    )
}


function Shortcuts(){
    const { getTagableObjects, apiCall } = useContext(AuthContext)
    const { companyShortcuts, reloadCompanyProperties } = useContext(CompanyContext)
    const [shortcuts, setShortcuts] = useState(companyShortcuts)
    const [shortcutModalOpen, setShortcutModalOpen] = useState(false)
    const [tagable, setTagable] = useState(null)

    useEffect(async () => {
        const { data, status } = await getTagableObjects()

        if(status === 1){
            const tags = data.map(item => {
                return {
                    label: `${item.title}`,
                    icon: findIcon(item.type),
                    value: item,
                    description: `${getType(item.type)}${item.company ? `, ${item.company?.name}` : ''} `
                }
            })
            setTagable(tags)
        }
    }, [])

    useEffect(() => {
        setShortcuts(companyShortcuts)
    }, [companyShortcuts])

    useEffect(() => {
        reloadCompanyProperties()
    }, [])

    const saveShortcuts = async (shortcuts) => {
        const { data } = await apiCall({action: 'save-company-shortcuts', shortcuts: shortcuts})

        console.log(data)
        if(data.status === 1) reloadCompanyProperties()
    }

    const deleteShortcut = (shortcut) => {
        const newShortcuts = shortcuts.filter(s => s !== shortcut)
        setShortcuts(newShortcuts)
        saveShortcuts(newShortcuts)
    }

    const findIcon = (t) => {
        switch(t){
            case 'SagaNewTask':
                return ['far', 'chart-simple']
            case 'SagaDocument':
                return ['far', 'file-lines']
            case 'SagaChannel':
                return ['far', 'messages']
            default:
                return ['far', 'file']
        }
    }

    const getType = (t) => {
        switch(t){
            case 'SagaNewTask':
                return 'Oppgave'
            case 'SagaDocument':
                return 'Dokumentområde'
            case 'SagaChannel':
                return 'Kanal'
            default:
                return null
        }
    }

    return (
        <div className='mb-5'>
            <div className="row">
                <div className="col">
                    <h3 className="bold">Snarveier</h3>
                    <p>Her kan du opprette og redigere snarveier som vises på oversikten til alle medlemmer av domenet.</p>
                </div>
                
            </div>
            <div className="row gx-2">
                {
                    shortcuts.length ? shortcuts.map((item, key) => <Shortcut key={key} item={item} deleteShortcut={deleteShortcut} />) : null
                }
                <div className="col-md-2" onClick={() => setShortcutModalOpen(true)}>
                    <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary">
                        <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <FontAwesomeIcon fixedWidth size="3x" icon={['far', 'plus']} className="text-primary" />
                        </div>
                        <div className="text-center">
                            <h6 className="bold">Legg til ny snarvei</h6>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={shortcutModalOpen} setOpen={setShortcutModalOpen}>
                <NewShortcut setOpen={setShortcutModalOpen} tagable={tagable} setShortcuts={setShortcuts} shortcuts={shortcuts} saveShortcuts={saveShortcuts} />
            </Modal>
        </div>
    )
}

function Shortcut({ item, deleteShortcut }){
    const { type, externalLink, internalLink, title } = item
    const drawShortcutIcon = () => {
        if(type === 'external') return ['far', 'arrow-up-right-from-square']
        if(internalLink && internalLink.icon) return internalLink.icon
        return ['far', 'file']
    }

    return (
        
        <div className="col-md-2 mb-2 h-100">
            <div className="box hover-primary py-2 px-3 h-100">
                <div className="row justify-content-end gx-0"><div className="col-auto"><FontAwesomeIcon icon={['fas', 'xmark']} size="sm" className="pointer" onClick={() => deleteShortcut(item)} /></div></div>
                <div className="py-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold">{title}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NewShortcut({ setOpen, tagable, setShortcuts, shortcuts, saveShortcuts }){
    
    let schema = yup.object().shape({
        title: yup.string().required("Tittel er påkrevd"),
        type: yup.string(),
        internalLink: yup.object().nullable().when('type', {
            is: 'internal',
            then: yup.object().typeError("Snarvei er påkrevd").required(),
        }),
        externalLink: yup.string().when('type', {
            is: 'external',
            then: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Skriv inn en korrekt lenke'
            )
            .required('Lenke er påkrevd'),
        }),
    });


    const formik = useFormik({
        initialValues: {
            title: '',
            type: 'internal',
            internalLink: null,
            externalLink: ''

        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            setShortcuts([...shortcuts, values])
            saveShortcuts([...shortcuts, values])
            setOpen(false)
        },
    });
    
    const drawShortcutIcon = () => {
        if(formik.values.type === 'external') return ['far', 'arrow-up-right-from-square']
        if(formik.values.internalLink && formik.values.internalLink.icon) return formik.values.internalLink.icon
        return ['far', 'file']
    }

    return (
        <div className="p-3 d-flex" style={{minWidth: 600, maxWidth: '90vw', minHeight: '50vh', overflow: 'visible'}}>
            <form className="d-flex flex-column flex-grow-1" onSubmit={formik.handleSubmit}>
                <div className="flex-grow-1">
                    <h3 className="bold">Lag ny snarvei</h3>
                    <div className="form-group mb-2">
                        
                        <RadioGroup
                            items={[
                                {label: 'Intern snarvei', value: 'internal'},
                                {label: 'Ekstern snarvei', value: 'external'}
                            ]}
                            selected={formik.values.type}
                            select={(value) => formik.setFieldValue('type', value)}
                            large
                        />
                    </div>
                    {
                        formik.values.type === 'internal' ? 
                            <div className="form-group mb-3">
                                <label>Snarvei til</label>
                                {
                                    tagable ? 
                                    <DropDown
                                        options={tagable}
                                        placeholder="Velg snarvei"
                                        error={formik.touched.internalLink && Boolean(formik.errors.internalLink)}
                                        touched={formik.touched.internalLink}
                                        helperText={formik.touched.internalLink && formik.errors.internalLink}
                                        value={formik.values.internalLink}
                                        onChange={(value) => {
                                            formik.setFieldValue('internalLink', value)
                                            formik.setFieldValue('title', value.label)
                                        }}
                                    />
                                    : null
                                }
                            </div>

                        :
                        <div className="form-group mb-3">
                            <Input
                                name="externalLink"
                                label="Lenke til ekstern adresse"
                                type="text"
                                value={formik.values.externalLink}
                                onChange={formik.handleChange}
                                error={formik.touched.externalLink && Boolean(formik.errors.externalLink)}
                                touched={formik.touched.externalLink}
                                helperText={formik.touched.externalLink && formik.errors.externalLink}
                                placeholder={"Eks. https://www.vg.no/"}
                            />
                        </div>     
                    }
                    
                    <div className="form-group mb-3">
                        <Input
                            name="title"
                            label="Tittel på snarvei"
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            touched={formik.touched.title}
                            helperText={formik.touched.title && formik.errors.title}
                            placeholder={"Tittel på snarvei"}
                        />
                    </div>

                    <div className="row justify-content-center mt-3">
                        <div className="col-6">
                            <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary">
                                <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                                </div>
                                <div className="text-center">
                                    <h6 className="bold">{formik.values.title}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" className="btn btn-border w-100" onClick={() => setOpen(false)}>Avbryt</button>
                    </div>
                    <div className="col">
                        <button type="submit" className="btn btn-success w-100">Lagre snarvei</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export function CompanyEdit(){
    const { company, uploadFile, apiCall, loadCompanyByUrl, domain } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { isArchived } = useContext(CompanyContext)
    const { domainAccess } = useContext(DomainContext)

    const navigate = useNavigate()
    
    let schema = yup.object().shape({
        orgnr: yup.string().matches(/^[0-9]{9}$/, 'Org.nr. må være 9 siffer').required("Org.nr. er påkrevd"),
        name: yup.string().required("Bedriftsnavn er påkrevd"),
        country: yup.object().typeError("Land er påkrevd").required(),
        address: yup.string().required("Adresse er påkrevd"),
        zip: yup.string().matches(/^[0-9]{4}$/, 'Postnummer må være 4 siffer').required("Postnummer er påkrevd"),
        city: yup.string().required("Poststed er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            orgnr: company.organization_number ? company.organization_number : '',
            name: company.name ? company.name : '',
            country: company.country ? company.country : null,
            address: company.payload?.address ? company.payload.address : '',
            zip: company.payload?.zip ? company.payload.zip : '',
            city: company.payload?.city ? company.payload.city : '',
            logo: company.image ? company.image : null,
            type: null,
            tags: [],
        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            save(values)
        },
    });

    const save = async (values) => {
        const imageData = values.logo instanceof File ? await uploadFile(values.logo) : null

        //console.log(imageData)

        const { data } = await apiCall({
            action: 'edit-company-info',
            orgnr: values.orgnr,
            name: values.name,
            country: values.country,
            address: values.address,
            zip: values.zip,
            city: values.city,
            logo: imageData,
            type: null,
            tags: [],
            image: imageData
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            loadCompanyByUrl()
        }

    }

    const archiveCompany = async () => {
        const { data } = await apiCall({
            action: 'archive-domain-company-relation',
            id: company.id,
        })

        notify(data.status, data.title, data.message)
        if(data.status === 1){
            navigate(`/${domain.hash}/kunder`)
        }

    }

    if(company?.companyType === 2) return <RoomEdit archiveCompany={archiveCompany}/>
    return (
        
            <div className="p-3 p-md-4 bg-white h-100">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">Rediger bedrift</h3>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-success px-5">Lagre endringer</button>
                        </div>
                    </div>
                    <div className="pt-3 pb-4">
                        <ImageCrop
                            img={formik.values.logo}
                            setImg={(logo) => formik.setFieldValue("logo", logo)}
                            height={120}
                            aspect={1}
                            unit={'logo'}
                            error={formik.touched.logo && Boolean(formik.errors.logo)}
                            helperText={formik.touched.logo && formik.errors.logo}
                        />
                    </div>
                    
                    <h4 className="bold">Bedriftsopplysninger</h4>
                    <p>Bedriftens opplysninger</p>
                    <div className="row gx-3 align-items-center">
                        <div className="col-4">
                            <Input
                                name="orgnr"
                                label="Org.nr."
                                type="text"
                                value={formik.values.orgnr}
                                onChange={formik.handleChange}
                                error={formik.touched.orgnr && Boolean(formik.errors.orgnr)}
                                touched={formik.touched.orgnr}
                                helperText={formik.touched.orgnr && formik.errors.orgnr}
                                placeholder={"Bedriftens organisasjonsnummer"}
                            />
                        </div>
                           
                        <div className="col-xl col-lg-12 mb-2 mb-md-0">
                            <Input
                                name="name"
                                label="Navn"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Bedriftens navn"}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-12 col-12">
                            <div className={"form-group mb-3"}>
                                <label>Land</label>
                                <DropDown
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    value={formik.values.country}
                                    onChange={(e) => formik.setFieldValue("country", e)}
                                    touched={formik.touched.country}
                                    helperText={formik.touched.country && formik.errors.country}
                                    options={[
                                        {label: 'Norge', value: 'NO'},
                                        {label: 'Sverige', value: 'SE'},
                                        {label: 'Danmark', value: 'DK'},
                                        {label: 'Polen', value: 'PL'},
                                    ]}
                                    placeholder="Velg land"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end mb-3 gx-2 gx-md-3">
                        <div className="col-12 col-md mb-2 mb-md-0">
                            <Input
                                name="address"
                                label="Gateadresse"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                touched={formik.touched.address}
                                helperText={formik.touched.address && formik.errors.address}
                                placeholder={"Gateadresse"}
                            />
                        </div>
                        <div className="col-md-2 col-4">
                            <Input
                                name="zip"
                                label="Postnr"
                                type="text"
                                value={formik.values.zip}
                                onChange={formik.handleChange}
                                error={formik.touched.zip && Boolean(formik.errors.zip)}
                                touched={formik.touched.zip}
                                helperText={formik.touched.zip && formik.errors.zip}
                                placeholder={"Postnr."}
                            />
                        </div>
                        <div className="col-md-3 col">
                            <Input
                                name="city"
                                label="Poststed"
                                type="text"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.zip)}
                                touched={formik.touched.city}
                                helperText={formik.touched.city && formik.errors.city}
                                placeholder={"Poststed"}
                            />
                        </div>
                    </div>
                
                </form>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <button className='btn btn-danger' onClick={() => archiveCompany()}>
                            <div className="row gx-2">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['far', 'box-archive']} />
                                </div>
                                <div className="col-auto">
                                    {isArchived ? 'Reaktiver bedrift' : 'Arkiver bedrift'}
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        
    )
}

export function RoomEdit({ archiveCompany }){
    const { company, uploadFile, apiCall, loadCompanyByUrl } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { isArchived } = useContext(CompanyContext)
    
    
    let schema = yup.object().shape({
        name: yup.string().required("Romnavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            
            name: company.name ? company.name : '',
            logo: company.image ? company.image : null,
            tags: [],
        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            save(values)
        },
    });

    const save = async (values) => {
        const imageData = values.logo instanceof File ? await uploadFile(values.logo) : null

        //console.log(imageData)

        const { data } = await apiCall({
            action: 'edit-room-info',
            name: values.name,
            logo: imageData,
            tags: [],
            image: imageData
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            loadCompanyByUrl()
        }

    }
    return (
        
            <div className="p-3 p-md-4 bg-white h-100">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">Rediger bedrift</h3>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-success px-5">Lagre endringer</button>
                        </div>
                    </div>
                    <div className="pt-3 pb-4">
                        <ImageCrop
                            img={formik.values.logo}
                            setImg={(logo) => formik.setFieldValue("logo", logo)}
                            height={120}
                            aspect={1}
                            unit={'logo'}
                            error={formik.touched.logo && Boolean(formik.errors.logo)}
                            helperText={formik.touched.logo && formik.errors.logo}
                        />
                    </div>
                    
                    <h4 className="bold">Romopplysninger</h4>
                    <p>Rommets opplysninger</p>
                    <div className="row gx-3 align-items-center">
                        
                           
                        <div className="col-xl col-lg-12 mb-2 mb-md-0">
                            <Input
                                name="name"
                                label="Navn"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Romnavn"}
                            />
                        </div>
                        
                    </div>
                    <div className="row align-items-end mb-3 gx-2 gx-md-3">
                        <div className="col-12 col-md mb-2 mb-md-0">
                           
                        </div>
                        <div className="col-md-2 col-4">
                            
                        </div>
                        <div className="col-md-3 col">
                            
                        </div>
                    </div>
                
                </form>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <button className='btn btn-danger' onClick={() => archiveCompany()}>
                            <div className="row gx-2">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['far', 'box-archive']} />
                                </div>
                                <div className="col-auto">
                                    {isArchived ? 'Reaktiver rom' : 'Arkiver rom'}
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        
    )
}

export function CompanyAdmins(){
    const { consultants, reloadAdmins } = useContext(CompanyContext)
    const { domainUsers } = useContext(DomainContext)
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const [activeConsultants, setActiveConsultants] = useState(consultants)

    console.log(consultants)

    const updateMembers = (id) => {
        
        updateAdmin(id)
    }

    const updateAdmin = async(id) => {
        const { data } = await apiCall({
            action: 'edit-company-admin',
            user: id
        })

        reloadAdmins()

        notify(data.status, data.title, data.message)
    }

    

    const SelectedUser = ({ id, fullname, photo }) => {
        return (
            <div className="p-3 border-bottom-not-last">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <UserPhoto name={fullname} photo={photo} size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                {fullname}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-danger" onClick={() => updateMembers(id)}>Fjern</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        
        <div className="p-3 p-md-4 bg-white h-100" style={{overflow:'visible'}}>
            <h3 className="bold mb-3">Administratorer</h3>
            <div className="border mb-2">
                {
                    consultants && consultants.length ? consultants.map((item, index) => {
                        return <SelectedUser key={index} {...item} />
                    }) : 
                    <div className="p-3">
                        <p className="m-0">Ingen administratorer valgt</p>
                    </div>
                }
            
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={domainUsers}
                    selected={consultants}
                    setSelected={(val) => updateMembers(val.id)}
                    handleManually
                    hideOutput
                    text="Legg til administrator"
                    label="Administratorer"
                    disableSearch
                />
            </div>
        </div>
        
    )
}

export function CompanyTemplates(){
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const [templateTasks, setTemplateTasks] = useState(null)
    const [templateForms, setTemplateForms] = useState(null)
    const [templateDocuments, setTemplateDocuments] = useState(null)

    const [tasks, setTasks] = useState([])
    const [forms, setForms] = useState([])
    const [documents, setDocuments] = useState([])

    const getDomainTemplateTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-template-tasks'});
        if(data.status === 1) setTemplateTasks(data.data)
    }

    const getDomainTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-domain-template-forms'});
        if(data.status === 1) setTemplateForms(data.data)
    }

    const getDomainTemplateDocuments = async () => {
        const { data } = await apiCall({action: 'get-domain-template-documents'});
        if(data.status === 1) setTemplateDocuments(data.data)
    }

    const getCompanyTemplates = async () => {
        const { data } = await apiCall({action: 'get-company-templates'});
        
        if(data.status === 1){
            if(data.data.SagaTemplateForm){
                setForms(data.data.SagaTemplateForm.map(item => {
                    return {label: item.name, value: item.id}
                }))
            }

            if(data.data.SagaTemplateTask){
                setTasks(data.data.SagaTemplateTask.map(item => {
                    return {label: item.name, value: item.id}
                }))
            }

            if(data.data.SagaTemplateDocument){
                setDocuments(data.data.SagaTemplateDocument.map(item => {
                    return {label: item.name, value: item.id}
                }))
            }
        }
    }

    useEffect(() => {
        getDomainTemplateTasks()
        getDomainTemplateForms()
        getDomainTemplateDocuments()
        getCompanyTemplates()
    }, [])

    const updateTemplateForm = async (id) => {
        const { data } = await apiCall({
            action: 'update-company-template-form',
            id: id
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1) getCompanyTemplates()
    }

    const updateTemplateDocument = async (id) => {
        const { data } = await apiCall({
            action: 'update-company-template-document',
            id: id
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1) getCompanyTemplates()
    }

    const updateTemplateTask = async (id) => {
        const { data } = await apiCall({
            action: 'update-company-template-task',
            id: id
        })

        notify(data.status, data.title, data.message)

        console.log(data)
        if(data.status === 1) getCompanyTemplates()
    }

    return (
        <div className="p-3 p-md-4 bg-white h-100" style={{overflow:'visible'}}>
            <h3 className="bold mb-3">Maler</h3>
            <div className='form-group'>
                <label>Oppgavemaler som er tilgjengelig for området</label>
                <DropDown
                    value={tasks}
                    onChange={(v) => {
                        updateTemplateTask(v.value)
                    }}
                    options={templateTasks?.map(item => {
                        return {label: item.name, value: item.id}
                    })}
                    placeholder="Velg oppgavemaler"
                    multiple
                />

            </div>
            <div className='form-group'>
                <label>Skjemamaler som er tilgjengelig for området</label>
                <DropDown
                    value={forms}
                    onChange={(v) => {
                        updateTemplateForm(v.value)
                    }}
                    options={templateForms?.map(item => {
                        return {label: item.name, value: item.id}
                    })}
                    placeholder="Velg skjemamaler"
                    multiple
                />

            </div>
            <div className='form-group'>
                <label>Dokumentmaler tildelt som dokumentområde</label>
                <DropDown
                    value={documents}
                    onChange={(v) => {
                        //console.log(v)
                        updateTemplateDocument(v.value)
                    }}
                    options={templateDocuments?.map(item => {
                        return {label: item.name, value: item.id}
                    })}
                    placeholder="Legg til dokumentområder"
                    multiple
                />

            </div>
        </div>
        
    )
}