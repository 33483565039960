import React from "react"
export default function Input(props){
    const { 
        name,
        label,
        value,
        onChange,
        inputClass,
        error,
        type,
        placeholder,
        helperText,
        touched,
        disabled
    } = props

    return (
        <div className={`form-group mb-3 ${error ? 'error' : null} ${touched && !error ? 'valid' : null}`}>
            {label ? <label>{label}</label> : null}
            <input disabled={disabled ? disabled : ''}  type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} className={`form-control ${inputClass}`} />
            {error && helperText ? <small className="text-danger">{helperText}</small> : null}
        </div>
    )
}