
import React, { useContext, useState } from "react";
import DraftEditor from "../components/DraftEditor";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { ConsultantContext } from "../providers/ConsultantProvider";
import { AuthContext } from "../providers/AuthProvider";

export default function UnitTesting(){
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const { consultants} = useContext(ConsultantContext)
    const { apiCall } = useContext(AuthContext)
    return (
        <div className="px-3">
            <div className="box p-4">
                <h2 className="bold">Test</h2>
                <DraftEditor 
                    editorState={editorState} 
                    placeholder="Beskriv oppgaven"
                    enableTags
                    mentions={consultants}
                />
            </div>
            <button onClick={async () => {
                const status = await apiCall({action: 'get-enterprise-users'})
                console.log(status.data)
            }}>Test</button>
        </div>
        
    )
}