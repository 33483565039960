/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Outlet, useLocation, useNavigate, useParams, useMatch,  Link, useResolvedPath  } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

/** Template Components */
import Header from './components/Header'

/** Pages */
import {default as ConsultantChannels} from './pages/consultant/channel/Channels'
import DomainDashboard from "./pages/domain/DomainDashboard";
import Companies from "./pages/Companies";
import UnitTesting from "./main/UnitTesting";
import DomainTasks, { ActiveDomainTasks, ArchivedDomainTasks } from "./pages/consultant/task/DomainTasks";
import TaskBatch from "./pages/consultant/task/TaskBatch";
import SubTaskDetails from "./pages/consultant/task/SubTaskDetails";
import TaskSubmissionDetails from "./pages/TaskSubmissionDetails";
import { DomainNewCompanySettings } from "./pages/domain/area/DomainNewCompany";
import ChannelConversation, { ChannelArchive, ChannelSettings, ConversationFiles, Conversations } from "./components/ChannelConversation";
import DomainTeams from "./pages/domain/team/DomainTeams";
import DomainTeamUser, { DomainTeamUserConversations, DomainTeamUserSettings, DomainTeamUserTasks } from "./pages/domain/team/DomainTeamUser";
import CompanyDashboard from "./pages/company/dashboard/CompanyDashboard";
import AllCompanyTasks, { ActiveCompanyTasks, ArchivedCompanyTasks } from "./pages/company/task/AllCompanyTasks";
import NewCompanyTask from "./pages/company/task/NewCompanyTask";
import Channels from "./pages/company/channel/Channels";
import CompanyUser, { CompanyUserDetails, CompanyUserEdit } from "./pages/company/employees/CompanyUserDetails";
import { CompanyArticleSignatures, CompanyDocumentFolder, EditCompanyArticle, NewCompanyDocumentFile } from "./pages/company/documents/CompanyDocuments";
import { AuthContext, AuthProvider } from "./providers/AuthProvider";
import { AccessProvider } from "./providers/AccessProvider";
import NotificationCenter from "./components/NotificationCenter";
import moment from "moment";
import { ConfigContext } from "./providers/ConfigProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import { DomainProvider } from "./providers/DomainProvider";
import DomainSidebar from "./pages/domain/DomainSidebar";
import { CompanyProvider } from "./providers/CompanyProvider";
import ConsultantCompanySidebar from "./components/ConsultantCompanySidebar";
import DomainTaskDetails from "./pages/consultant/task/DomainTaskDetails";
import DomainUsers, { ActiveDomainUsers, ArchivedDomainUsers } from "./pages/domain/users/DomainUsers";
import DomainUser, { DomainUserDetails, DomainUserEdit } from "./pages/domain/users/DomainUserDetails";
import DomainTemplateContainer, { DomainDocumentTemplates, DomainFormTemplates, DomainTaskTemplates, DomainTemplateSettings } from "./pages/domain/template/DomainTemplateContainer";
import DomainTaskTemplateNew from "./pages/domain/template/DomainTaskTemplateNew";
import CompanyUsers, { ActiveCompanyUsers, ArchivedCompanyUsers } from "./pages/company/employees/CompanyUsers";
import DomainTemplateSettingNew from "./pages/domain/template/DomainTemplateSettingNew";
import DomainDocumentTemplateNew, { DomainTemplateDocumentFolder, EditTemplateArticle, NewTemplateDocumentFile } from "./pages/domain/template/DomainDocumentTemplateNew";
import DomainDocumentTemplateEdit, { DomainTemplateDocumentFolderEdit, EditTemplateEditArticle, NewTemplateEditDocumentFile } from "./pages/domain/template/DomainDocumentTemplateEdit";
import DomainFormTemplateNew from "./pages/domain/template/DomainFormTemplateNew";
import DomainFormTemplateEdit from "./pages/domain/template/DomainFormTemplateEdit";
import DomainTemplateSettingEdit from "./pages/domain/template/DomainTemplateSettingEdit";
import DomainTaskTemplateEdit from "./pages/domain/template/DomainTaskTemplateEdit";
import DomainSettings from "./pages/domain/settings/DomainSettings";
import NewDomain, { NewDomainInformation, NewDomainUsers } from "./pages/domain/settings/NewDomain";
import DomainNewCompany from "./pages/domain/area/DomainNewCompany";
import DomainNewCompanyUsers from "./pages/domain/area/DomainNewCompanyUsers";
import CompanyDocuments from "./pages/company/documents/CompanyDocuments";
import DomainDocuments, { ArticleSignatures, DomainDocumentFolder, EditArticle, NewDocumentFile } from "./pages/consultant/documents/DomainDocuments";
import DomainNewTask from "./pages/consultant/task/DomainNewTask";
import CompanyTaskDetails from "./pages/company/task/CompanyTaskDetails";
import TaskDetails from "./pages/consultant/task/TaskDetails";
import DomainTaskEdit from "./pages/consultant/task/DomainTaskEdit";
import CompanySettings, { CompanyAdmins, CompanyEdit, CompanyInformation, CompanyTemplates } from "./pages/company/CompanySettings";
import CompanyTaskEdit from "./pages/company/task/CompanyTaskEdit";
import MessageCenter from "./components/MessageCenter";
import Profile, { EditPassword, EditProfile } from "./pages/EditProfile";
import DomainNewRoom, { DomainNewRoomInformation } from "./pages/domain/area/DomainNewRoom";
import axios from 'axios'
import CompanyTeams from "./pages/company/CompanyTeams";
import CompanyTeamUser, { CompanyTeamUserConversations, CompanyTeamUserSettings, CompanyTeamUserTasks } from "./pages/company/CompanyTeamUser";
import DomainNewRoomUsers from "./pages/domain/area/DomainNewRoomUsers";
import DomainEdit from "./pages/domain/settings/DomainEdit";

library.add(fas, far, fal, fat, fad)
moment.locale('nb')

function pnAvailable() {
    
    var bAvailable = false;
    if (window.isSecureContext) {
        // running in secure context - check for available Push-API
        bAvailable = (('serviceWorker' in navigator) && 
                      ('PushManager' in window) && 
                      ('Notification' in window)); 
    } else {
        console.log('site have to run in secure context!');
    }

    return bAvailable;
}
    
async function pnSubscribe(accessToken, apiUrl, getNotifications) {
    if (pnAvailable()) {
        // if not granted or denied so far...
        
        const status  = await window.Notification.requestPermission();
        
        if (status === 'granted') {
            // register service worker
            await pnRegisterSW(accessToken, apiUrl, getNotifications);
        }
    }
}

async function pnRegisterSW(accessToken, apiUrl, getNotifications) {
    
    
    const publicKey = 'BHJ9RY86gZ3FL3-YE8H5DXd9Plg3rSQ6haY6XXbhme2zuvhyRSyqbse6jAYCjFyjTDGRgfVSANIGcibG8egxPwQ'
    navigator.serviceWorker.register('/PNServiceWorker.js')
        .then((swReg) => {
            swReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: publicKey
            }).then(async sub => {
                const formData = new FormData();

                formData.append('accessToken', accessToken)
                formData.append('action', 'registerPushNotification')
                formData.append("sub", JSON.stringify(sub))

                

                const { data } = await axios({
                    method: 'post',
                    url: apiUrl,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" }
                })

            })

            

            

            // registration worked
            console.log('Registration succeeded. Scope is ' + swReg.scope);
        }).catch((e) => {
            // registration failed
            console.log('Registration failed with ' + e);
        });
}

export default function App(){
    
    return (
		<BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />}>
                    
                    <Route path=":domain" element={<Domain />}>
                        <Route path="profil" element={<Profile />}>
                            <Route path="rediger" element={<EditProfile />} />
                            <Route path="endre-passord" element={<EditPassword />} />
                        </Route>
                        <Route path="oversikt" element={<DomainDashboard />} />
                        <Route path="unit-testing" element={<UnitTesting />} />
                        
                        {/* Oppgaver */}
                        <Route path="oppgaver" element={<DomainTasks />}>
                            <Route index element={<ActiveDomainTasks />} />
                            <Route path="arkiv" element={<ArchivedDomainTasks />} />
                        </Route>
                        <Route path="oppgaver/samling/:batch" element={<TaskBatch />} />
                        <Route path="oppgaver/samling/:batch/:id" element={<DomainTaskDetails />} />
                        <Route path="oppgaver/:id" element={<TaskDetails />} >
                            
                            <Route path=":subtask" element={<SubTaskDetails />} />
                            

                        </Route>
                        <Route path="oppgaver/arkiv/:id" element={<TaskDetails />} >
                            
                            <Route path=":subtask" element={<SubTaskDetails />} />
                            

                        </Route>
                        <Route path="oppgaver/:id/rediger" element={<DomainTaskEdit />} />
                        <Route path="oppgaver/:id/utforelse/:submission" element={<TaskSubmissionDetails />} />
                        <Route path="oppgaver/ny" element={<DomainNewTask />} />

                        {/* Kunder */}
                        <Route path="kunder" element={<Companies />} />
                        <Route path="kunder/ny-bedrift" element={<DomainNewCompany />}>
                            <Route path="bedriftsinformasjon" element={<DomainNewCompanySettings />} />
                            <Route path="brukere" element={<DomainNewCompanyUsers />} />
                        </Route>

                        <Route path="kunder/nytt-rom" element={<DomainNewRoom />}>
                            <Route path="informasjon" element={<DomainNewRoomInformation />} />
                            <Route path="brukere" element={<DomainNewRoomUsers />} />
                        </Route>

                        {/* Medlemmer */}
                        <Route path="medlemmer" element={<DomainUsers />}>
                            <Route index element={<ActiveDomainUsers />} />
                            <Route path="arkiv" element={<ArchivedDomainUsers />}/>
                        </Route>
                        <Route path="medlemmer/:userid" element={<DomainUser />}>
                            <Route path="detaljer" element={<DomainUserDetails/>} />
                            <Route path="rediger" element={<DomainUserEdit/>} />
                        </Route>

                        {/* Kanaler */}
                        <Route path="kanaler" element={<ConsultantChannels />}>
                            <Route path=":id" element={<ChannelConversation  />}>
                                <Route path="samtaler" element={<Conversations />} />
                                <Route path="filer" element={<ConversationFiles />} />
                                <Route path="innstillinger" element={<ChannelSettings />} />
                                <Route path="arkiv" element={<ChannelArchive />} />
                            </Route>
                        </Route>

                        {/* Grupper */}
                        <Route path="grupper" element={<DomainTeams />} >
                            <Route path=":consultant" element={<DomainTeamUser />}>
                                <Route path="oppgaver" element={<DomainTeamUserTasks />} />
                                <Route path="samtaler" element={<DomainTeamUserConversations />} />
                                <Route path="innstillinger" element={<DomainTeamUserSettings />} />
                            </Route>
                        </Route>

                        {/* Dokumenter */}
                        <Route path="dokumenter" element={<DomainDocuments />} >
                            <Route path=":folder/ny-artikkel" element={<NewDocumentFile />} />
                            <Route path=":folder/:folder1/ny-artikkel" element={<NewDocumentFile />} />
                            <Route path=":folder/:folder1/:folder2/ny-artikkel" element={<NewDocumentFile />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/ny-artikkel" element={<NewDocumentFile />} />

                            <Route path=":folder/:folder1/rediger" element={<EditArticle />} />
                            <Route path=":folder/:folder1/:folder2/rediger" element={<EditArticle />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/rediger" element={<EditArticle />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/:folder4/rediger" element={<EditArticle />} />

                            <Route path=":folder/:folder1/signeringer" element={<ArticleSignatures />} />
                            <Route path=":folder/:folder1/:folder2/signeringer" element={<ArticleSignatures />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/signeringer" element={<ArticleSignatures />} />

                            <Route path=":folder" element={<DomainDocumentFolder />} >
                                <Route path=":folder1" element={<DomainDocumentFolder />} >
                                    <Route path=":folder2" element={<DomainDocumentFolder />} >
                                        <Route path=":folder3" element={<DomainDocumentFolder />} >
                                            <Route path=":folder4" element={<DomainDocumentFolder />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                            
                        </Route>
                        

                        {/* Maler */}
                        <Route path="maler" element={<DomainTemplateContainer />}>
                            <Route path="oppgavemaler" element={<DomainTaskTemplates />}></Route>
                            <Route path="dokumentmaler" element={<DomainDocumentTemplates />}></Route>
                            <Route path="skjemamaler" element={<DomainFormTemplates />}></Route>
                            <Route path="maloppsett" element={<DomainTemplateSettings />} />
                            
                        </Route>

                        <Route path="maler/oppgavemaler/ny" element={<DomainTaskTemplateNew />}></Route>
                        <Route path="maler/oppgavemaler/rediger/:taskId" element={<DomainTaskTemplateEdit />}></Route>

                        <Route path="maler/dokumentmaler/ny/" element={<DomainDocumentTemplateNew />}>
                            <Route path="ny-artikkel" element={<NewTemplateDocumentFile />} />
                            <Route path=":folder/ny-artikkel" element={<NewTemplateDocumentFile />} />
                            <Route path=":folder/:folder1/ny-artikkel" element={<NewTemplateDocumentFile />} />
                            <Route path=":folder/:folder1/:folder2/ny-artikkel" element={<NewTemplateDocumentFile />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/ny-artikkel" element={<NewTemplateDocumentFile />} />

                            <Route path=":folder/rediger" element={<EditTemplateArticle />} />
                            <Route path=":folder/:folder1/rediger" element={<EditTemplateArticle />} />
                            <Route path=":folder/:folder1/:folder2/rediger" element={<EditTemplateArticle />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/rediger" element={<EditTemplateArticle />} />

                            <Route path=":folder" element={<DomainTemplateDocumentFolder />}>
                                <Route path=":folder1" element={<DomainTemplateDocumentFolder />}>
                                    <Route path=":folder2" element={<DomainTemplateDocumentFolder />} />
                                        <Route path=":folder3" element={<DomainTemplateDocumentFolder />} >
                                            <Route path=":folder4" element={<DomainTemplateDocumentFolder />} />
                                        </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="maler/dokumentmaler/rediger/:templateId" element={<DomainDocumentTemplateEdit />}>

                            <Route path="ny-artikkel" element={<NewTemplateEditDocumentFile />} />
                            <Route path=":folder/ny-artikkel" element={<NewTemplateEditDocumentFile />} />
                            <Route path=":folder/:folder1/ny-artikkel" element={<NewTemplateEditDocumentFile />} />
                            <Route path=":folder/:folder1/:folder2/ny-artikkel" element={<NewTemplateEditDocumentFile />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/ny-artikkel" element={<NewTemplateEditDocumentFile />} />

                            <Route path=":folder/rediger" element={<EditTemplateEditArticle />} />
                            <Route path=":folder/:folder1/rediger" element={<EditTemplateEditArticle />} />
                            <Route path=":folder/:folder1/:folder2/rediger" element={<EditTemplateEditArticle />} />
                            <Route path=":folder/:folder1/:folder2/:folder3/rediger" element={<EditTemplateEditArticle />} />

                            <Route path=":folder" element={<DomainTemplateDocumentFolderEdit />}>
                                <Route path=":folder1" element={<DomainTemplateDocumentFolderEdit />}>
                                    <Route path=":folder2" element={<DomainTemplateDocumentFolderEdit />} />
                                        <Route path=":folder3" element={<DomainTemplateDocumentFolderEdit />} >
                                            <Route path=":folder4" element={<DomainTemplateDocumentFolderEdit />} />
                                        </Route>
                                </Route>
                            </Route>
                        </Route>

                        <Route path="maler/skjemamaler/ny" element={<DomainFormTemplateNew />} />
                        <Route path="maler/skjemamaler/rediger/:formId" element={<DomainFormTemplateEdit />} />


                        <Route path="maler/maloppsett/ny" element={<DomainTemplateSettingNew />} />
                        <Route path="maler/maloppsett/rediger/:settingId" element={<DomainTemplateSettingEdit />} />

                        {/** Innstillinger */}
                        <Route path="innstillinger" element={<DomainSettings />} />
                        <Route path="innstillinger/nytt-domene" element={<NewDomain />}>
                            <Route path="domeneinformasjon" element={<NewDomainInformation />} />
                            <Route path="brukere" element={<NewDomainUsers />} />
                        </Route>
                        <Route path="innstillinger/rediger" element={<DomainEdit />}></Route>
                        
                        <Route path=":company" element={<CompanyApp />}>
                            <Route path="profil" element={<Profile />}>
                                <Route path="rediger" element={<EditProfile />} />
                                <Route path="endre-passord" element={<EditPassword />} />
                            </Route>
                            <Route path="oversikt" element={<CompanyDashboard />} />

                            <Route path="oppgaver" element={<AllCompanyTasks />}>
                                <Route index element={<ActiveCompanyTasks />} />
                                <Route path="arkiv" element={<ArchivedCompanyTasks />} />
                            </Route>
                            <Route path="oppgaver/:id" element={<TaskDetails />} >
                                <Route path=":subtask" element={<SubTaskDetails />} />
                            </Route>
                            <Route path="oppgaver/:id/rediger" element={<CompanyTaskEdit />} />
                            <Route path="oppgaver/:id/utforelse/:submission" element={<TaskSubmissionDetails />} />
                            <Route path="oppgaver/ny" element={<NewCompanyTask />} />

                            <Route path="kanaler" element={<Channels />}>
                                <Route path=":id" element={<ChannelConversation  />} >
                                    <Route path="samtaler" element={<Conversations />} />
                                    <Route path="filer" element={<ConversationFiles />} />
                                    <Route path="innstillinger" element={<ChannelSettings />} />
                                    <Route path="arkiv" element={<ChannelArchive />} />
                                    </Route>
                            </Route>

                            <Route path="grupper" element={<CompanyTeams />} >
                                <Route path=":consultant" element={<CompanyTeamUser />}>
                                    <Route path="oppgaver" element={<CompanyTeamUserTasks />} />
                                    <Route path="samtaler" element={<CompanyTeamUserConversations />} />
                                    <Route path="innstillinger" element={<CompanyTeamUserSettings />} />
                                </Route>
                            </Route>

                            <Route path="medlemmer" element={<CompanyUsers /> }>
                                <Route index element={<ActiveCompanyUsers />} />
                                <Route path="arkiv" element={<ArchivedCompanyUsers />}/>
                            </Route>
                            <Route path="medlemmer/:userid" element={<CompanyUser />}>
                                <Route path="detaljer" element={<CompanyUserDetails/>} />
                                <Route path="rediger" element={<CompanyUserEdit/>} />
                            </Route>

                            <Route path="dokumenter" element={<CompanyDocuments />}>
                                <Route path=":folder/ny-artikkel" element={<NewCompanyDocumentFile />} />
                                <Route path=":folder/:folder2/ny-artikkel" element={<NewCompanyDocumentFile />} />
                                <Route path=":folder/:folder2/:folder3/ny-artikkel" element={<NewCompanyDocumentFile />} />

                                <Route path=":folder/:folder1/rediger" element={<EditCompanyArticle />} />
                                <Route path=":folder/:folder1/:folder2/rediger" element={<EditCompanyArticle />} />
                                <Route path=":folder/:folder1/:folder2/:folder3/rediger" element={<EditCompanyArticle />} />

                                <Route path=":folder/:folder1/signeringer" element={<CompanyArticleSignatures />} />
                                <Route path=":folder/:folder1/:folder2/signeringer" element={<CompanyArticleSignatures />} />
                                <Route path=":folder/:folder1/:folder2/:folder3/signeringer" element={<CompanyArticleSignatures />} />

                                <Route path=":folder" element={<CompanyDocumentFolder />} >
                                    <Route path=":folder1" element={<CompanyDocumentFolder />} >
                                        <Route path=":folder2" element={<CompanyDocumentFolder />} >
                                            <Route path=":folder3" element={<CompanyDocumentFolder />}  />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path="innstillinger" element={<CompanySettings />}>
                                <Route path="detaljer" element={<CompanyInformation />} />
                                <Route path="administratorer" element={<CompanyAdmins />} />
                                <Route path="maler" element={<CompanyTemplates />} />
                                <Route path="rediger" element={<CompanyEdit />} />
                            </Route>
                        </Route>
                        
                    </Route>
                </Route>
            </Routes>
		</BrowserRouter>
	)
}

function Main(){
    return (
        <AuthProvider>
            <div className="container-fluid p-0">
                <div className="row gx-0 ">
                    <Outlet />
                </div>
                <NotificationCenter />
                <MessageCenter />
            </div>
            <Notification />
        </AuthProvider>
    )
}

function Domain(){

    const { accessToken, getNotifications } = useContext(AuthContext)
    const { apiUrl } = useContext(ConfigContext)

    useEffect(() => {
        const broadcast = new BroadcastChannel('notification-channel');

        // Listen to the response
        broadcast.onmessage = (event) => {
            console.log('Boardcast from PNServiceWorker: ', event.data);
            if(event.data.payload){
                switch(event.data.payload){
                    case 'notification':
                        getNotifications();
                        break
                }
            }
        };
    }, [])
    

    useEffect(() => {
        pnSubscribe(accessToken, apiUrl, getNotifications)
    }, [accessToken, apiUrl])
    return <DomainApp />

}

function DomainApp(){
    const navigate = useNavigate()
    const params = useParams()
    

    
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('oversikt')
        }
    }, [])


	return (
		<DomainProvider>
            {
                params.company ? <Outlet />
                : (
                    <>
                        <DomainSidebar />
                        <div className="col">
                            <div className="main">
                                <Header />
                                <div className="main-content">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            
        </DomainProvider>
	)
}
function CompanyApp(){
    const navigate = useNavigate()
    const params = useParams()
    

    
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('oversikt')
        }
    }, [])
    
    return (
		<CompanyProvider>
            <ConsultantCompanySidebar />
            <div className="col">
                <div className="main">
                    <Header />
                    <div className="main-content">
                        <Outlet />
                    </div>
                </div>
            </div>
		</CompanyProvider>
	)
}

function NoAccess({ message }){
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height: '100vh'}}>
            <h1>Her har du ikke tilgang</h1>
            <h3>{message}</h3>
            <Link to="/">Gå hjem</Link>
        </div>
        
    )
}


function ReAuth(){
	const { mustRelog, user, login } = useContext(AuthContext)

	useEffect(() => {
		if(mustRelog){
			setShow(true)
		}else{
			setShow(false)
		}
	}, [mustRelog])

	const [show, setShow] = useState(false)
	const [email, setEmail] = useState(user ? user.email : '')
	const [password, setPassword] = useState('')

	return (
		<CSSTransition in={show} unmountOnExit timeout={200} classNames="relog">
			<div className="relog-container">
				<div className="relog-box box p-4" style={{minWidth: '400px'}}>
					<h3>Logg inn</h3>
					<p>Du har vært inaktiv for lenge og må logge deg inn på nytt.</p>
					<div className="form-group mb-3">
						<label>E-postadresse</label>
						<input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
					</div>
					<div className="form-group mb-3">
						<label>Passord</label>
						<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
					</div>
					<button className="btn btn-primary w-100" onClick={() => login(email, password)}>Logg inn</button>
				</div>
			</div>
		</CSSTransition>
	)
}

function Notification(){
	const { notification } = useContext(ConfigContext)

	const [showNotification, setShowNotification] = useState(false)
	const [icon, setIcon] = useState(['fal', 'check-circle']);
	const [className, setClassName] = useState('notification-success')
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');

	useEffect(() => {
		if(notification){
			switch(notification.type){
				case 1:
					setIcon(['fal', 'circle-check'])
					setClassName('notification-success')
					break;
				case 2:
					setIcon(['fas', 'circle-info'])
					setClassName('notification-info')
					break;
				case 3:
					setIcon(['fas', 'triangle-exclamation'])
					setClassName('notification-warning')
					break;
				case 4:
					setIcon(['fas', 'triangle-exclamation'])
					setClassName('notification-danger')
					break;
				default:
					setIcon(['fal', 'circle-check'])
					setClassName('notification-success')
			}

			setTitle(notification.title)
			setMessage(notification.message)
			setShowNotification(true)
		}else{
			setShowNotification(false)
		}
	}, [notification])

	

	return (
		<CSSTransition in={showNotification} unmountOnExit timeout={200} classNames="notification">
			<div className={"notification " + className}>
				<div className="notification-icon">
					<FontAwesomeIcon icon={icon} />
				</div>
				<div className="notification-content">
					{ title ? <h5 className="m-0">{title}</h5> : null }
					
					<p className="m-0">{message}</p>
				</div>
			</div>
		</CSSTransition>
	)

}