import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DropDown from "../../../components/DropDown"
import RadioGroup from "../../../components/RadioGroup"
import { AuthContext } from "../../../providers/AuthProvider"
import { ConfigContext } from "../../../providers/ConfigProvider"

export default function DomainTemplateSettingEdit(){
    
    const { apiCall, domain } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const navigate = useNavigate()
    const params = useParams()

    const [id, setId] = useState(null)
    const [name, setName] = useState('')
    const [type, setType] = useState(1)

    const [tasks, setTasks] = useState([])
    const [taskTemplates, setTaskTemplates] = useState([])

    const [documents, setDocuments] = useState([])
    const [documentTemplates, setDocumentTemplates] = useState([])

    const [forms, setForms] = useState([])
    const [formTemplates, setFormTemplates] = useState([])

    useEffect(async () => {
        
        await getTemplateTasks()
        await getTemplateDocuments()
        await getTemplateForms()
        loadTemplateSetting()
    }, [])

    const loadTemplateSetting = async () => {
        const { data } = await apiCall({
            action: 'load-domain-template-setting',
            id: params.settingId
        });

        if(data.status === 1){
            setId(data.data.id)
            setName(data.data.name)
            setType(data.data.type)

            let tempDocuments = []
            data.data.SagaTemplateDocument.map((item) => {
                tempDocuments.push({label: item.name, value: item.id})
            })
            setDocuments(tempDocuments)

            let tempTasks = []
            data.data.SagaTemplateTask.map((item) => {
                tempTasks.push({label: item.name, value: item.id})
            })
            setTasks(tempTasks)

            let tempForms = []
            data.data.SagaTemplateForm.map((item) => {
                tempForms.push({label: item.name, value: item.id})
            })
            setForms(tempForms)
        }
        
        console.log(data)
    }

    const getTemplateTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-template-tasks'});
        
        if(data.status === 1){
            let temp = []
            data.data.map(item => {
                temp.push({ label: item.name, value: item.id})
            })
            setTaskTemplates(temp)

            console.log(temp)
        }
    }

    const getTemplateDocuments = async () => {
        const { data } = await apiCall({action: 'get-domain-template-documents'});

        if(data.status === 1){
            let temp = []
            data.data.map(item => {
                temp.push({ label: item.name, value: item.id})
            })
            setDocumentTemplates(temp)
        }
    }

    const getTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-domain-template-forms'});

        if(data.status === 1){
            let temp = []
            data.data.map(item => {
                temp.push({ label: item.name, value: item.id})
            })
            setFormTemplates(temp)
        }
    }
    
    const save = async () => {
        const { data } = await apiCall({
            action: 'edit-domain-template-setting',
            id: id,
            name: name,
            type: type,
            tasks: tasks,
            documents: documents,
            forms: forms
        })

        notify(data.status, data.title, data.message)
        navigate(`/${domain.hash}/maler/maloppsett`)
    }

    return (
        <div className="p-3">
            <div className="box p-4">
                <button className="btn btn-link" onClick={() => navigate(`/${domain.hash}/maler/maloppsett`)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>
                <div className="row justify-content-between mt-4">
                    <div className="col-auto">
                        <h2 className="bold">Opprett et maloppsett</h2>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-success" onClick={() => save()}>Lagre mal</button>
                    </div>
                </div>
                <hr />
                <div className="row py-3 gx-5">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Navn på oppsett</label>
                            <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" placeholder="Navn" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Type oppsett</label>
                            <p>Type bestemmer hvordan oppsettet kan brukes. Når man oppretter en bedrift kan man velge én bransje, men flere knagger.</p>
                            <RadioGroup 
                                select={setType}
                                selected={type}
                                items={[
                                    {label: 'Bransje', value: 2},
                                    {label: 'Knagg', value: 1}
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <hr/>

                <div className="py-3 gx-5">
                    <h5 className="bold">Oppgaver</h5>
                    <div className="row gx-5">
                        <DropDown
                            label="Tilhørende oppgavemaler"
                            error={null}
                            value={tasks ? tasks : ''}
                            onChange={(value) => {
                                
                                if(tasks.filter(t => t.value === value.value).length === 0){
                                    setTasks([...tasks, value])
                                }else{
                                    setTasks(tasks.filter(t => t.value !== value.value))
                                }
                                
                            }}
                            options={taskTemplates}
                            placeholder="Velg oppgaver"
                            multiple
                            sideFlex="Valgte oppgaver"
                        />
                    </div>
                </div>
                <hr/>
                <div className="py-3 gx-5">
                    <h5 className="bold">Skjema</h5>
                    <div className="row gx-5">
                       
                    
                    <DropDown
                        label="Tilhørende skjemamaler"
                        error={null}
                        value={forms ? forms : ''}
                        onChange={(value) => {
                            
                            if(forms.filter(t => t.value === value.value).length === 0){
                                setForms([...forms, value])
                            }else{
                                setForms(forms.filter(t => t.value !== value.value))
                            }
                            
                        }}
                        options={formTemplates}
                        placeholder="Velg skjemamaler"
                        multiple
                        sideFlex="Valgte skjemaer"
                    />
                    </div>
                </div>

                <hr/>
                <div className="py-3 gx-5">
                    <h5 className="bold">Dokumentmaler</h5>
                    <div className="row gx-5">
                       
                    
                    <DropDown
                        label="Tilhørende dokumentområder"
                        error={null}
                        value={documents ? documents : ''}
                        onChange={(value) => {
                            
                            if(documents.filter(t => t.value === value.value).length === 0){
                                setDocuments([...documents, value])
                            }else{
                                setDocuments(documents.filter(t => t.value !== value.value))
                            }
                            
                        }}
                        options={documentTemplates}
                        placeholder="Velg dokumentmaler"
                        multiple
                        sideFlex="Valgte dokumenter"
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}