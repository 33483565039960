import React, { createContext, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "./AuthProvider"
import { ConfigContext } from "./ConfigProvider"
import ArchivedTaskDetails from "../components/ArchivedTaskDetails";
import { DrawerContext } from "../components/Drawer";
import { TaskDetailsContext } from "./TaskDetailsProvider";
import { getNextDeadline } from "../components/Functions";
let timeout

export const SubTaskContext = createContext({

})

export const SubTaskProvider = ({ children }) => {
    const { apiCall, company } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { setOpen } = useContext(DrawerContext)
    const { fetchTask: reloadMainTask } = useContext(TaskDetailsContext)

    const [data, setData] = useState(null)
    const [responsibles, setResponsibles] = useState([])
    const [log, setLog] = useState([])
    const [submission, setSubmission] = useState({})
    const [submissionFetched, setSubmissionFetched] = useState(false)
    const [formValidation, setFormValidation] = useState(null)
    const [selectedSubmission, setSelectedSubmission] = useState(null)
    const [isArchived, setIsArchived] = useState(false)
    const [isSigned, setIsSigned] = useState(false)
    const [deadline, setDeadline] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        fetchTask()
        
        fetchSubmission()
    }, [])

    useEffect(() => {
        if(data){
            getLog()
            setResponsibles(data.responsibles)
            const deadline = getNextDeadline({deadline: data.deadline, subtasks: data.subtasks, lastSubmission: data.lastSubmission, submissions: data.submissions})
            setDeadline(deadline)
            setTaskSigned()
        }
    }, [data])

    const setTaskSigned = () => {
        if(data.signature === 2){
            let bool = true
            
            submission?.signatures && submission.signatures?.length && submission.signatures.forEach((signature) => {
                if(!signature.signed) bool = false
            })
            setIsSigned(bool)
        }else{
            setIsSigned(true)
        }
    }

    const saveSubmission = async (submissionData) => {
        console.log('saveSubmission')
        const status = await apiCall({action: 'save-task-submission', id: data.id, submission: submissionData, deadline: deadline?.timestamp})
        console.log('Saved!')
        return status
    }

    const fetchTask = async () => {
        if(params.subtask){
            const status = await apiCall({action: 'get-task-details', id: params.subtask})
            if(status.data.status === 1){
                
                setData(status.data.data)
                if(status.data.data.archivedon) setIsArchived(true)
            }
        }
    }

    const fetchSubmission = async () => {
        if(params.subtask){
            const status = await apiCall({action: 'get-active-task-submission', id: params.subtask})
            
            if(status.data.status === 1){
                setSubmission(status.data.data)
            }
            setSubmissionFetched(true)
        }
    }

    const getLog = async () => {
        let logData = []
        const log_status = await apiCall({action: 'get-log', class_type: 'SagaNewTask', class_id: data.id })
        if(log_status.data.status === 1){
            log_status.data.data.map((item, index) => {
                logData.push({
                    type: 'event',
                    ...item
                })
            })
        }

        if(data.messages){
            data.messages.map((item, index) => {
                logData.push({
                    type: 'message',
                    ...item
                })
            })
        }

        setLog(logData.sort((a, b) => {
            return moment(a['createdon']).format('X') - moment(b['createdon']).format('X')
        }))

        
    }

    const addMessage = async (content) => {
        const status = await apiCall({action: 'add-task-message', id: data.id, message: content})
        
        if(status.data.status === 1){
            fetchTask()
        }
    }

    const doCompleteTask = async (values) => {
        clearTimeout(timeout)
        const status = await apiCall({action: 'archive-task-submission', id: data.id, deadline: deadline?.timestamp, submission: values})
        if(status.data.status === 1){
            notify(status.data.status, status.data.title, status.data.message)
            reloadMainTask()
            setOpen(false)
        }
    }

    const archiveTask = async () => {
        const status = await apiCall({action: 'archive-task', id: data.id})
        notify(status.data.status, status.data.title, status.data.message)
        if(status.data.status === 1){
            fetchTask()
        }
    }

    return <SubTaskContext.Provider value={{
        data,
        submission,
        setSubmission,
        submissionFetched,
        formValidation,
        selectedSubmission,
        isArchived,
        isSigned,
        log,
        responsibles,
        fetchTask,
        fetchSubmission,
        addMessage,
        archiveTask,
        signTask: async () => {
            const status = await apiCall({action: 'sign-task', id: data.id, submission: submission})
            if(status.data.status === 1){
                notify(status.data.status, status.data.title, status.data.message)
                fetchTask()
                fetchSubmission()
            }
        },
        completeTask: (values) => {
            doCompleteTask(values)
        },
        changeSubmission: (id) => {
            if(data.submissions.filter(s => s.id === id).length){
                setSelectedSubmission(data.submissions.filter(s => s.id === id)[0])
            }
        },
        updateSubmission: (data) => {
            //setSubmission(data)
            console.log('updateSubmission')
            clearTimeout(timeout)
            if(submissionFetched){
                timeout = setTimeout(() => {
                    saveSubmission(data)
                }, 3000) 
            }
        },
        updateForm: (formData) => {
            saveSubmission({
                ...data.form,
                form: formData
            })
        },
        submitForm: (formData) => {
            clearTimeout(timeout)
            
            doCompleteTask({
                ...data.form,
                form: formData
            })
        },
        deadline
    }}>
        {data && submissionFetched ? (isArchived || selectedSubmission ?  <ArchivedTaskDetails Context={SubTaskContext} /> : children) : <Skeleton />}
    </SubTaskContext.Provider>
}

function Skeleton(){
    return (
        <div className="p-3">
            <div className="row gx-2 mb-3">
                <div className="col-lg-6">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-5">
                            <div className="col">
                                
                            </div>
                            <div className="col-auto">
                                <i>Frist: </i>
                            </div>
                            
                            <div className="col-auto">
                                <div className="icon-check-container checked-danger">
                                    <div className="icon-check-button ">
                                        <FontAwesomeIcon icon={['fas', 'flag']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="bold"></h3>
                        
                        
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                
                            </div>
                        
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="h-100 box  d-flex flex-column">
                        <div className="p-4 col">
                            
                        </div>
                        <div className="p-3 border-top">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link">
                                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}