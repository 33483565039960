/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
export default function RadioGroup({ selected, select, items, containerClass, classes, large }){

    const random = Math.floor(Math.random() * 10000) + 1
    return (
        <div className={"radio-group" + (containerClass ? ' ' + containerClass : '') + (large ? ' ' + 'radio-large' : '') }>
            {
                items.map((item, key) => {
                    return (<RadioButton 
                        key={key}
                        selected={selected}
                        select={select}
                        classes={classes}
                        {...item}
                    />)
                })
            }
        </div>
    )
}

function RadioButton({ selected, select, classes, label, value }){
    const random = Math.floor(Math.random() * 10000) + 1
    return (
        <div className={"radio-button" + (classes ? ' ' + classes : '') }>
            <input  type="radio" checked={selected === value ? 'checked' : ''} id={'radio-' + random} onChange={(e) => select(value)} />
            <label htmlFor={'radio-' + random}>
                <span>
                    <span>
                        <FontAwesomeIcon icon={['fal', 'check']} />
                    </span>
                </span>
                {label}
            </label>
        </div>
    )
}