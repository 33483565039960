/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState, useContext, useMemo, useCallback, useRef, useEffect, createRef } from "react";
import { Link, Outlet, useMatch, useNavigate, useParams, useResolvedPath, } from "react-router-dom";
import { ConfigContext } from "../providers/ConfigProvider";
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention'
import {EditorState, convertToRaw, Modifier, RichUtils} from 'draft-js';
import Editor from '@draft-js-plugins/editor'
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css'

import { Entry, styleBlockedText } from "./Functions";
import { ChannelContext as CompanyChannelContext } from "../pages/company/channel/Channels";
import { ChannelContext as ConsultantChannelContext } from "../pages/consultant/channel/Channels";

import ImageStack from "./ImageStack";
import Tooltip from "./Tooltip";
import axios from "axios";
import { DomainContext } from "../providers/DomainProvider";
import { AuthContext } from "../providers/AuthProvider";
import UserPhoto from "./UserPhoto";
import PersonPicker from "./PersonPicker";
import { CompanyContext } from "../providers/CompanyProvider";
import ChannelEditor from "./ChannelEditor";
import Modal from "./Modal";

export default function ChannelConversation(){

    const { company, domain, contextMessages, setNotificationRead } = useContext(AuthContext)
    
    const params = useParams();

    const { currentChannel, channels, getChannel } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)

    const { screenSize } = useContext(ConfigContext)
    const { hasCompanyPermission } = useContext(CompanyContext)

    const [members, setMembers] = useState([])

    useEffect(() => {
        if(currentChannel){
            contextMessages.map(notification => {
                if(!notification.isRead){
                    if(notification.actions[0].id === currentChannel.id){
                        setNotificationRead(notification.id)
                    }
                }
            })

            let teams = currentChannel.teams.map(team => {
                return {
                    fullname: team.name,
                    photo: null,
                }
            })

            setMembers([...currentChannel.members, ...teams])
        }
    }, [currentChannel])

    useEffect(() => {
        if(channels){
            const channel = channels.filter(c => c.alias === params.id)[0]
            if(channel){
                getChannel(channels.filter(c => c.alias === params.id)[0].id)
            }
        }
    }, [channels, params])


    if(currentChannel === null){
        return (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <FontAwesomeIcon spin size="3x" className="mb-3"  icon={['fad', 'spinner-third']} />
                <div className="text-center">
                    <h3 className="bold">Laster..</h3>
                </div>
            </div>
        )
    }

    const NavItem = ({ link, title, children, icon }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: false });

        

        return screenSize === 2 ? <Link to={link}><FontAwesomeIcon size="lg" icon={[match ? 'fad' : 'fal', icon]} /></Link> : <Link to={link}><span  className={match ? 'active' : ''}>{title}{children}</span></Link>
    }
    
    return (
        <div className="d-flex flex-column h-100">
            <div className="p-3">
            {
                    screenSize === 2 ? 
                    <div className="mb-2">
                        <Link to={`/${domain.hash}${company ? `/${company.hash}` : ''}/kanaler`} className="btn btn-link">
                            <div className="row gx-2">
                                <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                                <div className="col-auto">Tilbake</div>
                            </div>
                        </Link>
                    </div> : null
                }
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="bold p-md-2">{currentChannel.title}</h3>
                </div>
                <div className="col-auto">
                    {
                        currentChannel.members ? (
                            <div className="row align-items-center gx-2">
                                <div className="col-auto">
                                    <small className="pe-1 d-md-block d-none">{members.length} medlem{members.length > 1 && 'mer'}</small>
                                </div>
                                <div className="col-auto">
                                    <ImageStack users={members} />
                                </div>
                            </div>
                        )
                    : null }
                    
                </div>
            </div>
            </div>
            <div className="channel-body">
                <div className="channel-tabs px-3 ">
                    <div className="row mb-md-0 mb-2">
                        <div className="col">
                            <div className="row">
                                <div className="col-auto">
                                    <NavItem link={'samtaler'} title="Samtaler" icon={'messages'} />
                                </div>
                                <div className="col-auto">
                                    <NavItem link={'filer'} title="Filer" icon={'files'} />
                                </div>
                                <div className="col-auto">
                                    <NavItem link={'arkiv'} title="Arkiverte samtaler" icon={'message-check'} />
                                </div>
                            </div>
                        </div>
                        {
                            !company || company && hasCompanyPermission('adminChannel') ? 
                                <div className="col-auto">
                                    <NavItem link={'innstillinger'} title="Innstillinger" icon={'gear'}>
                                        <FontAwesomeIcon  icon={['far', 'gear']} className="d-inline-block ms-1" />
                                    </NavItem>
                                </div>
                            : null
                        }
                        
                    </div>
                </div>
                <Outlet />
                {
                    /*
                    
                    tab === 'files' ? 
                    <ConversationFiles files={attachments} /> : (tab === 'conversation' ? <Conversations conversations={currentChannel.conversations} /> : <ChannelSettings /> )
                    
                */}
                
            </div>
        </div>
        
    )
    
}

export function Conversations(){
    
    const { company } = useContext(AuthContext)
    const { addMessage, mentionable, currentChannel } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)
    const { conversations } = currentChannel 
    const [showEditor, setShowEditor] = useState(false)
    const [editorState, setEditorState] = useState(null)
    const [attachments, setAttachments] = useState([])
    useEffect(() => {
        setShowEditor(false)
    }, [currentChannel])

    const sendMessage = () => {
        setEditorState(null)
        setShowEditor(false)
        addMessage(editorState, null, attachments)
    }

    const scrollRef = createRef()

    useEffect(() => {
        // Chatloggen skal alltid være scrollet til bunn
        scrollToBottom()
    }, [scrollRef])

    const scrollToBottom = () => {
        if(scrollRef.current){
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
        }
    }

    return (
        <div className="h-100">
            <div className="d-flex flex-column h-100 ">
                <div className="channel-conversation-messages p-3 scrollinside" ref={scrollRef}>
                    {
                        conversations && conversations.length > 0 && conversations.map((item, key) => {
                        
                            return (
                                <ConversationItem
                                    key={key}
                                    index={key}
                                    {...item}
                                />
                            )
                        })
                    }
                    {
                        conversations.length === 0 ?
                            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                <FontAwesomeIcon size="3x" className="mb-3 text-success"  icon={['fad', 'pencil']} />
                                <div className="text-center">
                                    <h3 className="bold">Ingen samtaler</h3>
                                    <p>Kom igang ved å skrive den første samtalen</p>
                                    <FontAwesomeIcon size="2x" className="mb-3 text-success"  icon={['fas', 'circle-arrow-down']} />
                                </div>
                            </div>
                        : null
                    }
                </div>
                { showEditor ? 
                        (
                            <ChannelEditor 
                                value={editorState}
                                setValue={setEditorState}
                                mentionableUsers={mentionable}
                                submit={sendMessage}
                                attachments={attachments} 
                                setAttachments={setAttachments}
                            />
                        )
                : <button className="btn btn-primary mx-3 mb-3" onClick={() => setShowEditor(true)}>Start en ny samtale</button> }
            </div>
        </div>
    )
}

function ConversationItem({ index, messages }){
   
    const { company } = useContext(AuthContext)
    const { mentionable, addMessage } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)


    const [showEditor, setShowEditor] = useState(false)
    const [editorState, setEditorState] = useState(null)
    const [attachments, setAttachments] = useState([])

    const sendMessage = async () => {
        setEditorState(null)
        setShowEditor(false)
        await addMessage(editorState, index, attachments)
    }

    return (
        <div className="mb-4">
            {
                messages && messages.length > 0 && messages.map((item, key) => {
                
                    return (
                        <Message 
                            key={key}
                            index={key}
                            conversation={index}
                            {...item}
                        />
                    )
                })
            }
            <div className="row col">
                <div className="col offset-1">
                    {
                        showEditor ? 
                        (
                            <ChannelEditor 
                                value={editorState}
                                setValue={setEditorState}
                                mentionableUsers={mentionable}
                                submit={sendMessage}
                                attachments={attachments} 
                                setAttachments={setAttachments}
                            />
                        )
                        :
                        <button onClick={() => setShowEditor(true)} className="btn btn-link text-primary">Svar</button>
                    }
                </div>
            </div>
        </div>
    )
}

function Message({ createdby, createdon, messageRaw, index, conversation, attachments }){
    
    
    const { company, user } = useContext(AuthContext)
    const { archiveConversation, deleteMessage } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)
    const { hasCompanyPermission } = useContext(CompanyContext)
    const { hasPermission } = useContext(DomainContext)

    

    return (
        <div className="row gx-2  mb-md-3 mb-2">
            { index !== 0 ? <div className="col-1"></div> : null}
            <div className="col-auto">
                <div className="d-md-block d-none">
                <UserPhoto photo={createdby.photo} name={createdby.fullname} size={[50, 40, 30]} />
                </div>
            </div>
            <div className="col">
                <div className="py-2 px-3 bg-grey " style={{borderRadius: 10}}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <strong>{createdby.fullname}</strong>
                        </div>
                        <div className="col">
                            <span className="hint-text d-md-block d-none">{moment(createdon).format('DD. MMMM YYYY')}</span>
                        </div>
                        {
                            index === 0 ? 
                            <div className="col-auto">
                                { (!company && hasPermission('adminChannel')) || (company && hasCompanyPermission('adminChannel') || createdby.id === user.id) ? (
                                    <Tooltip content="Arkiver samtale" placement="left">
                                        <button className="btn btn-link" onClick={() => archiveConversation(conversation)}>
                                            <FontAwesomeIcon icon={['far', 'box-archive']} />
                                        </button>
                                    </Tooltip>
                                    )
                                : null}
                            </div>
                        : null}
                        {
                            createdby.id === user.id ?
                            <div className="col-auto">
                                <button className="btn btn-link" onClick={() => deleteMessage(index, conversation)}>
                                    <FontAwesomeIcon icon={['far', 'trash']} />
                                </button>
                            </div> 
                            : null
                        }
                    </div>
                    <div className="mb-2">
                        <div dangerouslySetInnerHTML={{__html: styleBlockedText(messageRaw) }}></div>
                    </div>
                    <div className="">
                        {
                            attachments && attachments.length ? <MessageAttachments files={attachments} /> : null
                        }
                    </div>
                    {
                        messageRaw.entityMap && Object.keys(messageRaw.entityMap).map((item, key) => {
                            if(messageRaw.entityMap[key].type === '#mention'){
                                return <MessageTag key={key} {...messageRaw.entityMap[key].data.mention} />
                                
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function MessageTag(props){
    const { type, id, title, alias, description } = props
    const navigate = useNavigate()
    const { domain, company } = useContext(AuthContext)

    const go = () => {

        let baseUrl = `/${domain.hash}/`
        if(company){
            baseUrl = baseUrl + `${company.hash}/`
        }

        if(props.company){
            baseUrl = baseUrl + `${props.company.hash}/`
        }

        
        switch(type){
            case 'SagaNewTask': navigate(`${baseUrl}oppgaver/${id}`);break;
            case 'SagaDocument': navigate(`${baseUrl}dokumenter/${alias}`);break;
            case 'SagaTemplateTask': navigate(`${baseUrl}maler/${alias}`);break;
            case 'SagaTemplateDocument': navigate(`${baseUrl}maler/${alias}`);break;
            case 'SagaTemplateForm': navigate(`${baseUrl}maler/${alias}`);break;
            case 'SagaTemplateSetting': navigate(`${baseUrl}maler/${alias}`);break;
        }
        
    }

    const Icon = () => {
        let icon = 'file'

        switch(type){
            case 'SagaNewTask': icon = 'chart-simple'; break;
            case 'SagaDocument': icon = 'file-lines'; break
            case 'SagaTemplateTask': 
            case 'SagaTemplateDocument':
            case 'SagaTemplateForm':
            case 'SagaTemplateSetting':
            icon = 'pen-to-square'; break
        }

        return (
            <FontAwesomeIcon className="text-primary" icon={['fas', icon]} />
        )
    }
    return (
                                    
        <div className="row mb-2 pointer" onClick={() => go()}>
            <div className="col-auto">
                <div className="d-flex align-items-center justify-content-center message-tag">
                    <Icon />
                </div>
            </div>
            <div className="col">
                <h6 className="m-0 bold">{title}</h6>
                {description ? <small style={{lineHeight: '10px'}}><i>{description}</i></small> : null }
            </div>
        </div>
       
    )
}

function MessageAttachments({ files }){
    const { downloadAttachment } = useContext(AuthContext)
    const { apiUrl } = useContext(ConfigContext)

    const Attachment = ({ id, file_name }) => {
            
        return (
            <div className="col-auto mb-2 pointer"  onClick={() => downloadAttachment(id, file_name)}>
                <div className="row gx-0" style={{padding: '5px 10px', backgroundColor: 'var(--primary)', color: '#FFF', borderRadius: '10px'}}>
                    <div className="col-auto">
                        <FontAwesomeIcon style={{marginRight: 10}} icon={['fal', 'paperclip-vertical']} />
                    </div>
                    <div className="col message-attachment-text">
                        {file_name}
                    </div>
                    
                </div>
            </div>
        )
    }

    if(!files || files.length === 0){
        return (<div></div>)
    }

    return (
        <div className="row gx-1">
            {
                files.map((item, key) => {
                    return (
                        <Attachment 
                            key={key}
                            {...item}
                        />
                    )
                })
            }
        </div>
    )
    
}

export function ConversationFiles(){
    
    const { downloadAttachment, company } = useContext(AuthContext)
    const { addMessage, mentionable, currentChannel } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)
    const [files, setFiles] = useState([])

    useEffect(() => {
        if(currentChannel){
            let f = []
            currentChannel.conversations.map((conversation) => {
                conversation.messages.map((message) => {
                    if(message.attachments && message.attachments.length){
                        message.attachments.map((attachment) => {
                            f = [...f, attachment]
                        })
                    }
                })
            })
            setFiles(f)

        }
    }, [currentChannel])

    if(files.length === 0){
        return (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon size="2x"   icon={['far', 'paperclip']} className="text-primary" />
                </div>
                <div className="text-center">
                    <h6 className="bold text-primary">Ingen filer</h6>
                    <p>Her kommer filer som er blitt lagt til i meldinger.</p>
                </div>
            </div>
        )
    }

    

    return (
        <div className="">
            {
                files.map((file, key) => {
                    let icon
                    switch(file.file_type){
                        case 'image/jpeg':
                        case 'image/png':
                        case 'image/jpg':
                            icon = 'file-image'
                            break
                        default:
                            icon = 'file'
                    }
                    return (
                        <div className="px-2 py-3 border-bottom hover-primary pointer" key={key} onClick={() => downloadAttachment(file.id, file.file_name)}>

                            <div className="row align-items-center">
                            <div className="col-auto">
                                    <FontAwesomeIcon icon={['fad', icon]} className="text-primary" size="2x" />
                                </div>
                                <div className="col">
                                    {file.file_name}
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['far', 'download']} size="lg" />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export function ChannelArchive(){
    const { company } = useContext(AuthContext)
    const { addMessage, mentionable, currentChannel } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)
    const { archived } = currentChannel 


    const scrollRef = createRef()

    useEffect(() => {
        // Chatloggen skal alltid være scrollet til bunn
        scrollToBottom()
    }, [scrollRef])

    const scrollToBottom = () => {
        if(scrollRef.current){
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
        }
    }

    const ArchivedConversation = ({ index, messages }) => {
   
        const [showMessages, setShowMessages] = useState(false)
    
        const Message = ({ createdby, createdon, messageRaw, index, conversation, attachments }) => {
    
    
            const { company, user } = useContext(AuthContext)
            const { archiveConversation } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)
            const { hasCompanyPermission } = useContext(CompanyContext)
            const { hasPermission } = useContext(DomainContext)
        
            if(index !== 0 && !showMessages) return <div></div>
        
            return (
                <div className="row gx-2  mb-3">
                    { index !== 0 ? <div className="col-1"></div> : null}
                    <div className="col-auto">
                        <UserPhoto photo={createdby.photo} name={createdby.fullname} size={[50, 50, 50]} />
                    </div>
                    <div className="col">
                        <div className="py-2 px-3 bg-grey " style={{borderRadius: 10}}>
                            <div className="row gx-2">
                                <div className="col-auto">
                                    <strong>{createdby.fullname}</strong>
                                </div>
                                <div className="col">
                                    <span className="hint-text">{moment(createdon).format('DD. MMMM YYYY')}</span>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div dangerouslySetInnerHTML={{__html: styleBlockedText(messageRaw) }}></div>
                            </div>
                            <div className="">
                                {
                                    attachments && attachments.length ? <MessageAttachments files={attachments} /> : null
                                }
                            </div>
                            {
                                messageRaw.entityMap && Object.keys(messageRaw.entityMap).map((item, key) => {
                                    if(messageRaw.entityMap[key].type === '#mention'){
                                        return <MessageTag key={key} {...messageRaw.entityMap[key].data.mention} />
                                        
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        }
    
        return (
            <div className="mb-4">
                {
                    messages && messages.length > 0 && messages.map((item, key) => {
                    
                        return (
                            <Message 
                                key={key}
                                index={key}
                                conversation={index}
                                {...item}
                            />
                        )
                    })
                }
                { messages && messages.length > 1 ? <div className="d-flex justify-content-end"><button className="btn btn-link" onClick={() => setShowMessages(!showMessages)}>{!showMessages ? 'Vis samtale' : 'Skjul samtale'}</button></div> : null}
                
            </div>
        )
    }

    return (
        <div className="h-100">
            <div className="d-flex flex-column h-100 ">
                <div className="channel-conversation-messages p-md-3 p-2 scrollinside" ref={scrollRef}>
                    {
                        archived && archived.length > 0 && archived.map((item, key) => {
                        
                            return (
                                <ArchivedConversation
                                    key={key}
                                    index={key}
                                    {...item}
                                />
                            )
                        })
                    }
                    {
                        archived.length === 0 ?
                            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                <FontAwesomeIcon size="3x" className="mb-3 text-success"  icon={['fad', 'box-archive']} />
                                <div className="text-center">
                                    <h3 className="bold">Ingen arkiverte samtaler</h3>
                                    <p>Alle arkiverte samtaler vises her</p>
                                </div>
                            </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export function ChannelSettings(){
    const { company, domain } = useContext(AuthContext)
    const { domainUsers, domainTeams } = useContext(DomainContext)
    const { companyUsers, companyTeams, consultants, hasCompanyPermission } = useContext(CompanyContext)
    const { currentChannel, addMember, removeMember, addTeam, removeTeam, deleteChannel, loadChannels } = useContext(company ? CompanyChannelContext : ConsultantChannelContext)

    const [members, setMembers] = useState(currentChannel.members ? currentChannel.members : [])
    const [teams, setTeams] = useState(currentChannel.teams ? currentChannel.teams : [])

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setMembers(currentChannel.members ? currentChannel.members : [])
        setTeams(currentChannel.teams ? currentChannel.teams : [])
    }, [currentChannel])

    const SelectedUser = ({ id, fullname, photo }) => {
        return (
            <div className="p-3 border-bottom-not-last">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <UserPhoto name={fullname} photo={photo} size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                {fullname}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-danger" onClick={() => removeMember(id)}>Fjern</button>
                    </div>
                </div>
            </div>
        )
    }

    const SelectedTeam = ({ id, name }) => {
        
        return (
            <div className="p-3 border-bottom-not-last">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <UserPhoto name={name} size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                {name}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-danger" onClick={() => removeTeam(id)}>Fjern</button>
                    </div>
                </div>
            </div>
        )
    }


    const updateMembers = (item) => {
        const team = teams.filter(e => e.id === item.id)
        if(team.length > 0){
            const removed = teams.filter(e => e.id === item.id)
            removeMember(removed[0].id)
            
        }else{
            addMember(item.id)
        }
    }

    const updateTeams = (item) => {
        const member = members.filter(e => e.id === item.id)
        if(member.length > 0){
            const removed = members.filter(e => e.id === item.id)
            removeTeam(removed[0].id)
            
        }else{
            addTeam(item.id)
        }
    }

    const deleteChannelClick = async () => {
        const status = await deleteChannel()
        
        if(status.status === 1) {
            loadChannels()
            navigate(`../../`)
        }
    }
    
    if(company && !hasCompanyPermission('adminChannel')){
        return(
            <h1>Ingen tilgang</h1>
        )
    }
    return (
        <div className="p-3">
            <h5 className="bold">Medlemmer</h5>
            <div className="border mb-2">
                {
                    members && members.length ? members.map((item, index) => {
                        return <SelectedUser key={index} {...item} />
                    }) : null
                }

                {
                    teams && teams.length ? teams.map((item, index) => {
                        return <SelectedTeam key={index} {...item} />
                    }) : null
                }
            
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={company ? [...companyUsers, ...consultants] : domainUsers}
                    teams={company ? companyTeams : domainTeams}
                    selected={members}
                    selectedTeams={teams}
                    setSelected={(val) => updateMembers(val)}
                    setSelectedTeams={(val) => updateTeams(val)}
                    handleManually
                    hideOutput
                    text="Legg til medlem"
                    disableSearch
                />
            </div>
            <div className="row justify-content-end">
                <div className="col-auto">
                    <button className="btn text-danger btn-border" onClick={() => setDeleteModalOpen(true)}>
                        <div className="row gx-2">
                            <div className="col-auto">Slett kanal</div>
                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'trash']} /></div>
                        </div>
                    </button>
                </div>
            </div>

            <Modal setOpen={setDeleteModalOpen} open={deleteModalOpen}>
                <div className="p-4 bg-white">
                    <h3>Slette?</h3>
                    <p>Er du sikker på at du vil slette kanal? Alle aktive og arkiverte samtaler vil forsvinne.</p>
                    <div className="row justify-content-end">
                        <div className="col-auto"><button className="btn btn-danger" onClick={() => deleteChannelClick()}>Slett kanal</button></div>
                        <div className="col-auto"><button className="btn btn-border" onClick={() => setDeleteModalOpen(false)}>Nei</button></div>
                    </div>
                </div>
            </Modal>
            
        </div>
    )
}