import React from 'react'
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { ConfigContext } from "../providers/ConfigProvider"
import UserPhoto from "./UserPhoto"
import Tooltip from './Tooltip'
import { AuthContext } from '../providers/AuthProvider'
import Modal from './Modal'
import ReactCodeInput from 'react-code-input'

export default function Signatures({ users, signatures, onSign }){

    if(!signatures || signatures.length === 0){
        return <p><i>Signaturer blir sendt ut når oppgaven er fullført</i></p>
    }

    return (
        <div className="row gx-2">
            {
                signatures && signatures.length ? signatures.map((item, key) => {
                    return <Signature 
                        key={key}
                        person={users.filter(user => user.id === item.requiredBy)}
                        onSign={onSign}
                        {...item}
                    />
                }) : <p><i>Ansvarlig(e) må velges for at oppgaven skal kunne signeres</i></p>
            }
        </div>
    )
}


function Signature({ signed, person, onSign, token, signedWith }){
    const { id, photo, fullname } = person[0]
    const { user, apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const [me, setMe] = useState(false)
    const [signModalOpen, setSignModalOpen] = useState(false)

    useEffect(() => { 
        setMe(id === user.id)
    }, [])

    const sign = async (code) => {
        
        const { data } = await apiCall({
            action: 'sign-signature',
            token: token,
            code: code
        })

        notify(data.status, data.title, data.message)
        setSignModalOpen(false)
        onSign()
    }

    const SignModal = () => {
        const [code, setCode] = useState('')
        const [disabled, setDisabled] = useState(false)

        useEffect(() => {
            if(code.length === 6){
                setDisabled(true)
                sign(code)
            }
        }, [code])

        return (
            <div className="p-4" style={{maxWidth: 400}}>
                <h4 className="bold">Signering av oppgave</h4>
                <p>Ved å signere denne oppgaven bekrefter du at <strong>utførelsen er korrekt</strong>.</p>
                <p>Vennligst verifiser din signatur med to-faktor koden fra din autentiserings-app under.</p>
                <form className="text-center" onSubmit={(e) => {
                    e.preventDefault()
                    sign()
                }}>
                    <ReactCodeInput
                        type='text' 
                        fields={6} 
                        value={code}
                        onChange={val => setCode(val)}
                        style={{textAlign: 'center'}}
                        autoFocus
                        inputMode="numeric"
                        inputStyle={{random: 0}}
                        className="mb-4"
                        disabled={disabled}
                    />
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-black-outline w-100" onClick={() => setSignModalOpen(false)}>Avbryt og lukk</button>
                        </div>
                        <div className="col-6">
                            <button type="submit" className="btn btn-primary w-100" disabled={disabled}>Signér</button>
                        </div>
                    </div>
                
                </form>
                
            </div>
        )
    }
    
    return (
        <div className="col-auto">
            <Tooltip content={signed ? <div>{signedWith ? <p className='m-0'>Signert med <strong>{signedWith}</strong></p> : null}<p className='m-0'>ID #{token}</p></div> : <span>Ikke signert enda</span>} placement="top">
                <div className={"signature-item" + (signed ? ' signature-item-signed' : ' signature-item-not-signed') }>
                        
                    <div className="signature-photo">
                        <UserPhoto photo={photo} name={fullname} size={[50, 50, 50]} />
                    </div>
                    <div className="signature-content">
                        <small className="m-0 d-block">{me ? 'Deg' : fullname}</small>
                        { signed ? <small className="m-0 text-success">{moment(signed.signed).format('DD. MMM YYYY')}</small> : <small className="m-0 text-danger">Ikke signert</small>}
                    </div>
                                        
                    <div className="signature-button">
                        {me && !signed ?<button onClick={() => setSignModalOpen(true)}>Signér</button>: null }
                    </div>
                    

                </div>
            </Tooltip>
            {
                me && !signed ?
                <Modal
                    open={signModalOpen}
                    setOpen={setSignModalOpen}
                >
                <SignModal setOpen={setSignModalOpen} />
            </Modal>
            :null
            }
        </div>
    )
}