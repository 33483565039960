import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import ContextMenu, { ContextMenuItem } from "../../../components/ContextMenu";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import { AuthContext } from "../../../providers/AuthProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import { DomainContext } from "../../../providers/DomainProvider";


const Context = createContext({
    tasks: null,
    documents: null,
    forms: null,
    settings: null
})

const Provider = ({children}) => {

    const { apiCall } = useContext(AuthContext)
    
    const [tasks, setTasks] = useState(null)
    const [documents, setDocuments] = useState([])
    const [forms, setForms] = useState([])
    const [settings, setSettings] = useState([])

    useEffect(() => {
        getTemplateTasks()
        getTemplateDocuments()
        getTemplateForms()
        getTemplateSettings()
    }, [])

    const getTemplateTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-template-tasks'});
        
        if(data.status === 1){
            setTasks(data.data)
        }
    }

    const getTemplateDocuments = async () => {
        const { data } = await apiCall({action: 'get-domain-template-documents'});

        if(data.status === 1){
            setDocuments(data.data)
        }
    }

    const getTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-domain-template-forms'});

        if(data.status === 1){
            setForms(data.data)
        }
    }

    const getTemplateSettings = async () => {
        const { data } = await apiCall({action: 'get-domain-template-settings'});

        if(data.status === 1){
            setSettings(data.data)
        }
    }


    return <Context.Provider value={{
        tasks,
        documents,
        forms,
        settings,
        getTemplateTasks,
        getTemplateDocuments,
        getTemplateForms,
        getTemplateSettings
    }}>{children}</Context.Provider>
}
export default function DomainTemplateContainer(){

    const TabItem = ({ link, children }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={"tab-item " + (match ? "tab-item-active" : "")}>{children}</Link>
        )
    }

    
    const navigate = useNavigate()
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('oppgavemaler')
        }
    }, [])

    return (
        <div className="p-md-3 p-2">
                <div className="transparent-box">
                    <div className="tab-container">
                        <div className="row gx-0">
                            <div className="col-auto">
                                <TabItem link="oppgavemaler">Oppgavemaler</TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="dokumentmaler">Dokumentmaler</TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="skjemamaler">Skjemamaler</TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="maloppsett">Maloppsett</TabItem>
                            </div>
                        </div>
                    </div>
                    <Provider>
                        <Outlet />
                    </Provider>
                </div>
            </div>
    )
}

export function DomainTaskTemplates(){

    const { user, apiCall } = useContext(AuthContext)
    const { hasPermission } = useContext(DomainContext)
    const { tasks, getTemplateTasks } = useContext(Context)
    const { notify } = useContext(ConfigContext)
    const navigate = useNavigate()

    const deleteTemplate = async (id) => {
        const { data } = await apiCall({
            action: 'delete-domain-template-task',
            id: id
        })

        if(data.status === 1) getTemplateTasks()
        notify(data.status, data.title, data.message)
    }

    return (
        <div className="p-md-4 p-3 bg-white">
            <div className="row justify-content-between mb-5">
                <div className="col-auto">
                    <h2 className="bold">Oppgavemaler</h2>
                </div>
                <div className="col-auto">
                    <Link to="ny" className="btn btn-success">Opprett mal</Link>
                </div>
            </div>

            <Table
                gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                headers={[
                    { title: 'Navn', key: 'name', sort: 'string'},
                    { title: 'Visningsnavn', key: 'display_name', sort: 'string'},
                    { title: '', key: '', sort: 'number'},
                    { title: 'Offentlig mal', sort: 'boolean'},
                    { title: ''}
                ]}
                perPage={10}
                rows={tasks}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 3"],
                        keys: ['name'],
                        display: (e) => {
                            
                            return (

                                <h6 className="bold m-0">{e.name}</h6>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "1 / 3 / 2 / 4"],
                        keys: ['display_name'],
                        display: (e) => {
                            return (
                                <span>{e.display_name}</span>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 2"],
                        keys: ['priority', 'form', 'subtasks'],
                        display: (e) => {
                            return (
                                <div className="row gx-1">
                                    {
                                        e.priority ? (
                                            <div className="col-auto">
                                                <Tooltip content="Prioritert oppgave" placement="bottom">
                                                    <div className="bordered-color danger py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        e.form ? (
                                            <div className="col-auto">
                                                <Tooltip content="Skjema" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        e.subtasks && e.subtasks.length > 0 ? (
                                            <div className="col-auto">
                                                <Tooltip content="Underoppgaver" placement="bottom">
                                                    <div className="bordered-color success py-1 px-2">
                                                        <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : null
                                    }
                    
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 2 / 3 / 3"],
                        keys: ['isPublic'],
                        display: (e) => {
                            return (
                                e.isPublic ? 
                                <div style={{height: 30, width: 30, borderRadius: '50%', backgroundColor: e.isPublic ? 'var(--success-tint)' : 'var(--danger-tint)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={['far', e.isPublic ? 'check' : 'xmark']} style={{color: e.isPublic ? 'var(--success)' : 'var(--danger)'}} />
                                </div>
                                : <div></div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "2 / 3 / 3 / 4"],
                        keys: ['id', 'createdby'],
                        display: (e) => {
                            if(hasPermission(['adminTemplate']) || e.createdby.id === user.id){
                                return (
                                    <div className="d-flex justify-content-end">
                                    <ContextMenu 
                                        buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                    >
                                        <ContextMenuItem 
                                            label="Rediger"
                                            icon={['far', 'pencil']}
                                            onClick={() => navigate(`rediger/${e.id}`)}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Slett"
                                            icon={['far', 'trash']}
                                            onClick={() => deleteTemplate(e.id)}
                                            colorClass="text-danger"
                                        />
                                    </ContextMenu>
                                    </div>
                                )
                            }
                        }
                    }
                ]}
                ifEmpty={
                    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                        <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className="fa-stack fa-lg">
                            
                                <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'list-check']} className="fa-stack-1x text-secondary" />
                            </span>
                        </div>
                        <div className="text-center">
                            <h6 className="bold text-primary">Ingen oppgavemaler</h6>
                            <p>Det finnes ingen oppgavemaler enda</p>
                            <Link to="ny" className="btn btn-success">Opprett mal</Link>
                        </div>
                    </div>
                }
            />
        </div>
        
    )
}

export function DomainDocumentTemplates(){
    const { user, apiCall } = useContext(AuthContext)
    const { documents, getTemplateDocuments } = useContext(Context)
    const { hasPermission } = useContext(DomainContext)
    const { notify } = useContext(ConfigContext)
    const navigate = useNavigate()

    const deleteTemplate = async (id) => {
        const { data } = await apiCall({
            action: 'delete-domain-template-document',
            id: id
        })

        if(data.status === 1) getTemplateDocuments()
        notify(data.status, data.title, data.message)
    }


    return (
        <div className="p-md-4 p-3 bg-white">
            <div className="row justify-content-between mb-5">
                <div className="col-auto">
                    <h2 className="bold">Dokumentmaler</h2>
                </div>
                <div className="col-auto">
                    <Link to="ny" className="btn btn-success">Opprett mal</Link>
                </div>
            </div>
            <Table
                gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                headers={[
                    { title: 'Navn', key: 'name', sort: 'string'},
                    { title: 'Visningsnavn', key: 'display_name', sort: 'string'},
                    { title: 'Antall dokumenter', key: 'count', sort: 'number'},
                    { title: 'Offentlig mal', sort: 'boolean'},
                    { title: ''}
                ]}
                perPage={10}
                rows={documents}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 3"],
                        keys: ['name'],
                        display: (e) => {
                            
                            return (
                                <h6 className="bold m-0">{e.name}</h6>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "2 / 1 / 3 / 2"],
                        keys: ['display_name'],
                        display: (e) => {
                            return (
                                <span>{e.display_name}</span>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 3 / 2 / 4"],
                        keys: ['count'],
                        display: (e) => {
                            return (
                                <div className="d-flex justify-content-md-start justify-content-end">
                                    <span>{e.count} dokumenter</span>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 2 / 3 / 3"],
                        keys: ['public'],
                        display: (e) => {
                            return (
                                e.public ? 
                                <div style={{height: 30, width: 30, borderRadius: '50%', backgroundColor: e.public ? 'var(--success-tint)' : 'var(--danger-tint)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={['far', e.public ? 'check' : 'xmark']} style={{color: e.public ? 'var(--success)' : 'var(--danger)'}} />
                                </div>
                                : <div></div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "2 / 3 / 3 / 4"],
                        keys: ['id', 'createdby'],
                        display: (e) => {
                            if(hasPermission(['adminTemplate']) || e.createdby.id === user.id){
                                return (
                                    <div className="d-flex justify-content-end">
                                    <ContextMenu 
                                        buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                    >
                                        <ContextMenuItem 
                                            label="Rediger"
                                            icon={['far', 'pencil']}
                                            onClick={() => navigate(`rediger/${e.id}`)}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Slett"
                                            icon={['far', 'trash']}
                                            onClick={() => deleteTemplate(e.id)}
                                            colorClass="text-danger"
                                        />
                                    </ContextMenu>
                                    </div>
                                )
                            }
                        }
                    }
                ]}
                ifEmpty={
                    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                        <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className="fa-stack fa-lg">
                            
                                <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'list-check']} className="fa-stack-1x text-secondary" />
                            </span>
                        </div>
                        <div className="text-center">
                            <h6 className="bold text-primary">Ingen dokumentmaler</h6>
                            <p>Det finnes ingen dokumentmaler enda</p>
                            <Link to="ny" className="btn btn-success">Opprett mal</Link>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export function DomainFormTemplates(){
    const { user, apiCall } = useContext(AuthContext)
    const { hasPermission } = useContext(DomainContext)
    const { forms, getTemplateForms } = useContext(Context)
    const { notify } = useContext(ConfigContext)
    const navigate = useNavigate()

    const deleteTemplate = async (id) => {
        const { data } = await apiCall({
            action: 'delete-domain-template-form',
            id: id
        })

        if(data.status === 1) getTemplateForms()
        notify(data.status, data.title, data.message)
    }

    return (
        <div className="p-md-4 p-3 bg-white">
            <div className="row justify-content-between mb-5">
                <div className="col-auto">
                    <h2 className="bold">Skjemamaler</h2>
                </div>
                <div className="col-auto">
                    <Link to="ny" className="btn btn-success">Opprett mal</Link>
                </div>
            </div>
            <Table
                gridTemplateColumns={["repeat(4, 1fr)", "repeat(4, 1fr)", "repeat(2, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                headers={[
                    { title: 'Navn', key: 'name', sort: 'string'},
                    { title: 'Visningsnavn', key: 'display_name', sort: 'string'},
                    { title: 'Offentlig mal', sort: 'boolean'},
                    { title: ''}
                ]}
                perPage={10}
                rows={forms}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                        keys: ['name'],
                        display: (e) => {
                            
                            return (
                                <h6 className="bold m-0">{e.name}</h6>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "1 / 2 / 2 / 3"],
                        keys: ['display_name'],
                        display: (e) => {
                            return (
                                <span>{e.display_name}</span>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 2"],
                        keys: ['isPublic'],
                        display: (e) => {
                            return (
                                e.isPublic ? 
                                <div style={{height: 30, width: 30, borderRadius: '50%', backgroundColor: e.isPublic ? 'var(--success-tint)' : 'var(--danger-tint)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={['far', e.isPublic ? 'check' : 'xmark']} style={{color: e.isPublic ? 'var(--success)' : 'var(--danger)'}} />
                                </div>
                                : <div></div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 2 / 3 / 3"],
                        keys: ['id', 'createdby'],
                        display: (e) => {
                            if(hasPermission(['adminTemplate']) || e.createdby.id === user.id){
                                return (
                                    <div className="d-flex justify-content-end">
                                    <ContextMenu 
                                        buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                    >
                                        <ContextMenuItem 
                                            label="Rediger"
                                            icon={['far', 'pencil']}
                                            onClick={() => navigate(`rediger/${e.id}`)}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Slett"
                                            icon={['far', 'trash']}
                                            onClick={() => deleteTemplate(e.id)}
                                            colorClass="text-danger"
                                        />
                                    </ContextMenu>
                                    </div>
                                )
                            }
                        }
                    }
                ]}
                ifEmpty={
                    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                        <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className="fa-stack fa-lg">
                            
                                <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'list-check']} className="fa-stack-1x text-secondary" />
                            </span>
                        </div>
                        <div className="text-center">
                            <h6 className="bold text-primary">Ingen skjemamaler</h6>
                            <p>Det finnes ingen skjemamaler enda</p>
                            <Link to="ny" className="btn btn-success">Opprett mal</Link>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export function DomainTemplateSettings(){

    const { user, apiCall } = useContext(AuthContext)
    const { hasPermission } = useContext(DomainContext)
    const { settings, getTemplateSettings } = useContext(Context)
    const { notify } = useContext(ConfigContext)
    const navigate = useNavigate()

    const deleteTemplate = async (id) => {
        const { data } = await apiCall({
            action: 'delete-domain-template-setting',
            id: id
        })

        if(data.status === 1) getTemplateSettings()
        notify(data.status, data.title, data.message)
    }

    return (
        <div className="p-4 bg-white">
            <div className="row justify-content-between mb-5">
                <div className="col-auto">
                    <h2 className="bold">Maloppsett</h2>
                </div>
                <div className="col-auto">
                    <Link to="ny" className="btn btn-success">Opprett nytt oppsett</Link>
                </div>
            </div>

            <Table
                gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                headers={[
                    { title: 'Navn', key: 'name', sort: 'string'},
                    { title: 'Antall dokumenter', key: 'SagaTemplateDocument', sort: 'array'},
                    { title: 'Antall oppgaver', key: 'SagaTemplateTask', sort: 'array'},
                    { title: 'Type', sort: 'number'},
                    { title: ''}
                ]}
                perPage={10}
                rows={settings}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 3"],
                        keys: ['name'],
                        display: (e) => {
                            return (<h6 className="bold m-0">{e.name}</h6>)
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "2 / 2 / 3 / 3"],
                        keys: ['SagaTemplateDocument'],
                        display: (e) => {
                            return (
                                <span>{e.SagaTemplateDocument.length} dokumenter</span>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 2"],
                        keys: ['SagaTemplateTask'],
                        display: (e) => {
                            return (
                                <span>{e.SagaTemplateTask.length} oppgaver</span>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "1 / 3 / 2 / 4"],
                        keys: ['typeName'],
                        display: (e) => {
                            return (
                                <span>{e.typeName}</span>
                            )
                        }
                    },
                    {
                        grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 3 / 3 / 4"],
                        keys: ['id', 'createdby'],
                        display: (e) => {
                            if(hasPermission(['adminTemplate']) || (e.createdby && e.createdby.id === user.id)){
                                return (
                                    <div className="d-flex justify-content-end">
                                    <ContextMenu 
                                        buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                    >
                                        <ContextMenuItem 
                                            label="Rediger"
                                            icon={['far', 'pencil']}
                                            onClick={() => navigate(`rediger/${e.id}`)}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Slett"
                                            icon={['far', 'trash']}
                                            onClick={() => deleteTemplate(e.id)}
                                            colorClass="text-danger"
                                        />
                                    </ContextMenu>
                                    </div>
                                )
                            }
                        }
                    }
                ]}
                ifEmpty={
                    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                        <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className="fa-stack fa-lg">
                            
                                <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'list-check']} className="fa-stack-1x text-secondary" />
                            </span>
                        </div>
                        <div className="text-center">
                            <h6 className="bold text-primary">Ingen maloppsett</h6>
                            <p>Det finnes ingen maloppsett enda</p>
                            <Link to="ny" className="btn btn-success">Opprett mal</Link>
                        </div>
                    </div>
                }
            />
        </div>
        
    )
}