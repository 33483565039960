import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "./Functions"
import { CSSTransition } from "react-transition-group"

const Context = createContext({
    show: false,
    setShow: () => {}
})

const Provider = ({children}) => {
    const [show, setShow] = useState()
    return <Context.Provider value={{
        show,
        setShow,
    }}> 
        {children}
    </Context.Provider>}

export default function ContextMenu({children, buttonEl}){
    return (
        <Provider>
            <MenuWrapper children={children} buttonEl={buttonEl} />
        </Provider>
    )
}

function MenuWrapper({children, buttonEl}){
    
    const { show, setShow } = useContext(Context)

    const ref = useRef()
    useOnClickOutside(ref, () => setShow(false))

    return (
        <div className="context-menu-wrapper" ref={ref}>
            <button type="button" className="btn btn-link" onClick={() => setShow(true)}>
                {buttonEl}
            </button>
            <CSSTransition in={show} timeout={500} classNames="notification-center-wrapper" unmountOnExit>
                <div className="context-menu-container">
                    <ul>
                        {children}
                    </ul>
                </div>
            </CSSTransition>
        </div>
    )
}

export function ContextMenuItem({ label, icon, onClick, colorClass }){
    const { setShow, show } = useContext(Context)
    return (
        <li onClick={() => {
            setShow(false)
            onClick()
            

        }} className={colorClass}>
            <div className="row gx-2">
                {
                    icon ? (
                        <div className="col-auto">
                            <FontAwesomeIcon icon={icon} fixedWidth />
                        </div>
                    ) : null
                }
                <div className="col-auto">
                    <span className="semibold">{label}</span>
                </div>
            </div>
        </li>
    )
}