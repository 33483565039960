/* eslint-disable */
import React, { createContext, createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {EditorState, RichUtils, convertToRaw} from 'draft-js';
import Editor from '@draft-js-plugins/editor'
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css'
import Attachments from "../../../components/Attachments";
import PersonPicker from "../../../components/PersonPicker";
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import { ConfigContext } from "../../../providers/ConfigProvider";
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention'
import { CheckForm, CommentForm, ConfirmForm, RangeForm } from "../../../components/FormComponents";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { canDoDask, Entry, formatDate, getNextDeadline, styleBlockedText, styleText } from "../../../components/Functions";
import { CompanyContext } from "../../../providers/CompanyProvider";
import UserPhoto from "../../../components/UserPhoto";
import ImageStack from "../../../components/ImageStack";
import Drawer from "../../../components/Drawer";
import Signatures from "../../../components/Signatures";
import Modal from "../../../components/Modal";
import RightDrawer from "../../../components/RightDrawer";
import { DomainContext } from "../../../providers/DomainProvider";
import TaskLog from "../../../components/TaskLog";
moment.locale("nb")

export const Context = createContext({
    data: null,
    submission: {},
    submissionFetched: false,
    formValidation: null,
    selectedSubmission: null,
    getTask: () => {},
    getActiveSubmission: () => {},
    updateSubmission: () => {},
    validateForm: () => {},
    completeTask: () => {},
    signTask: () => {}
})

let timeout

const Provider = () => {
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const { company } = useContext(CompanyContext)

    const [data, setData] = useState(null)
    const [responsibles, setResponsibles] = useState([])
    const [log, setLog] = useState([])
    const [submission, setSubmission] = useState({})
    const [submissionFetched, setSubmissionFetched] = useState(false)
    const [formValidation, setFormValidation] = useState(null)
    const [selectedSubmission, setSelectedSubmission] = useState(null)
    const [isArchived, setIsArchived] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        fetchTask()
        fetchSubmission()
    }, [])

    useEffect(() => {
        if(data){
            getLog()
            setResponsibles(data.responsibles)
        }
    }, [data])

    const saveSubmission = async () => {
        const status = await apiCall({action: 'save-task-submission', id: data.id, submission: JSON.stringify(submission)})
        
        console.log('Saved..')
        return status
    }

    const fetchTask = async () => {
        if(params.id){
            const status = await apiCall({action: 'get-task-details', id: params.id})
            if(status.data.status === 1){
                setData(status.data.data)
            }
        }
    }

    const fetchSubmission = async () => {
        if(params.id){
            const status = await apiCall({action: 'get-active-task-submission', id: params.id})
            console.log(status)
            if(status.data.status === 1){
                setSubmission(status.data.data)
            }
            setSubmissionFetched(true)
        }
    }

    useEffect(() => {
        validateForm()
    }, [submission])

    const validateForm = async (force) => {
        if(data && data.form){
            let validations
            console.log(data.form)
            Object.keys(data.form.items).map((key) => {
                const sub = submission && submission.form && submission.form[key]
                const formType = data.form.items[key].type.val
                
                
                if(formType === 1){
                    if(!sub) validations = {...validations, [key]: {error: force ? true : false, message: 'Velg noe'}}
                    if(sub && sub.value !== 'danger')  validations = {...validations, [key]: {error: false}}
                    if(sub && !sub.value) validations = {...validations, [key]: {error: true, message: 'Velg noe'}}
                    if(sub && sub.value === 'danger' && (!sub.comment || sub.comment.length === 0)) validations = {...validations, [key]: {error: true, message: 'Ved avvik må kommentar fylles inn'}}
                }
                if(formType === 2){
                    if(!sub) validations = {...validations, [key]: {error: force ? true : false, message: 'Kommentar må fylles ut'}}
                    if(sub && sub.comment && sub.comment.length > 0) validations = {...validations, [key]: {error: false}}
                    if(sub && (!sub.comment || sub.comment.length === 0)) validations = {...validations, [key]: {error: true, message: 'Kommentar må fylles ut'}}
                }
                if(formType === 3){
                    if(!sub) validations = {...validations, [key]: {error: force ? true : false, message: 'Må bekreftes altså'}}
                    if(sub && sub.value) validations = {...validations, [key]: {error: false}}
                    if(sub && !sub.value) validations = {...validations, [key]: {error: true, message: 'Må bekreftes'}}
                }
                
                                
            })
            setFormValidation(validations)

            if(force){
                console.log(validations)
                clearTimeout(timeout)
                let valid = true;
                if(validations){
                    Object.keys(validations).map((key) => {
                        if(validations[key].error === true){
                            valid = false
                        }
                    })
                }

                if(valid){
                    const status = await apiCall({action: 'archive-task-submission', id: data.id, submission: JSON.stringify(submission)})
                    if(status.data.status === 1){
                        notify(status.data.status, status.data.title, status.data.message)
                        fetchTask()
                        fetchSubmission()
                    }
                }
            }
        }
    }

    const getLog = async () => {
        let logData = []
        const log_status = await apiCall({action: 'get-log', class_type: 'SagaNewTask', class_id: data.id })
        if(log_status.data.status === 1){
            log_status.data.data.map((item, index) => {
                logData.push({
                    type: 'event',
                    ...item
                })
            })
        }

        if(data.messages){
            data.messages.map((item, index) => {
                logData.push({
                    type: 'message',
                    ...item
                })
            })
        }

        setLog(logData.sort((a, b) => {
            return moment(a['createdon']).format('X') - moment(b['createdon']).format('X')
        }))

        
    }

    
    return <Context.Provider value={{
        data,
        submission,
        submissionFetched,
        formValidation,
        selectedSubmission,
        isArchived,
        log,
        responsibles,
        setResponsibles,
        getTask: () => {
            fetchTask()
        },
        changeSubmission: (id) => {

            if(data.submissions.filter(s => s.id === id).length){
                setSelectedSubmission(data.submissions.filter(s => s.id === id)[0])
            }
        },
        updateSubmission: (data) => {
            
            clearTimeout(timeout)
            setSubmission(data)

            
            if(submissionFetched){
                timeout = setTimeout(() => {
                    saveSubmission()
                }, 2000) 
            }
        },
        validateForm: () => {
            validateForm(true)
        },
        completeTask: async () => {
            clearTimeout(timeout)
            const status = await apiCall({action: 'archive-task-submission', id: data.id, submission: JSON.stringify(submission)})
            if(status.data.status === 1){
                notify(status.data.status, status.data.title, status.data.message)
                navigate(-1)
            }
        },
        signTask: async () => {
            const status = await apiCall({action: 'sign-task', id: data.id, submission: JSON.stringify(submission)})
    
            
            if(status.data.status === 1){
                notify(status.data.status, status.data.title, status.data.message)
                fetchTask()
                fetchSubmission()
            }
        }
    }}>{
        data ? (
            <>
                {
                    data.archivedon !== null || selectedSubmission ? <ArchivedDetails /> : <Details />
                }
            </>
        ) : <Skeleton />
    }</Context.Provider>
}

export default function DomainTaskDetails(){
    return (
        <Provider>
            <Details />
        </Provider>
    )
}



function Details(){

    const { apiCall, user } = useContext(AuthContext)
    const { domainUsers } = useContext(DomainContext)
    const { data, submission, getTask, validateForm, submissionFetched, updateSubmission, completeTask, signTask, responsibles, setResponsibles } = useContext(Context)
    const logRef = createRef()

    

    const [company, setCompany] = useState(null)
    const [subtaskDrawerOpen, setSubtaskDrawerOpen] = useState(false)
    const [signModalOpen, setSignModalOpen] = useState(false)

    const [submissionDrawerOpen, setSubmissionDrawerOpen] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(params.subtask){
            setSubtaskDrawerOpen(true)
        }
    }, [params])

    useEffect(() => {
        if(!subtaskDrawerOpen && params.subtask){
            //navigate(-1)
        }
    }, [subtaskDrawerOpen, params])

    useEffect(() => {
        
    }, [company])


    useEffect(async () => {
        if(data){
            if(data.company){
                const status = await apiCall({action: 'get-company', id: data.company.id})
                
                setCompany(status.data.data)
            }

            
        }
    }, [data])

    useEffect(() => {
        // Chatloggen skal alltid være scrollet til bunn
        if(logRef.current){
            logRef.current.scrollTop = logRef.current.scrollHeight - logRef.current.clientHeight;
        }
    }, [logRef])
    

    const updateResponsible = async (item) => {
        
        if(responsibles.filter(e => e.id === item.id).length > 0){
            const removed = responsibles.filter(e => e.id !== item.id)
            const status = await apiCall({action: 'remove-task-responsible', id: data.id, user: item.modUser})
            if(status.data.status === 1){
                setResponsibles(removed)
                getTask()
            }
        }else{
            const api = await apiCall({action: 'add-task-responsible', id: data.id, user: item.modUser})
            
            if(api.data.status === 1){
                setResponsibles([...responsibles, item])
                getTask()
            }
        }
    }

    const drawSubmitButton = () => {
        if(!deadline.available){
            return <button className="btn btn-primary w-100" disabled="disabled">Utførelse av oppgaven åpner senere</button>
        }
        if(data.signature === 2){

            //Hvis du er ansvarlig og skal signere
            if(data.responsibles.filter(r => r.id === user.id).length){
                //Hvis du ikke har signert
                if(!data.signatures.filter(s => s.requiredBy === user.modUser).length){
                    return <button className="btn btn-primary w-100" onClick={() => setSignModalOpen(true)}>Signer oppgave</button>
                }
                
                if(data.signatures.length === data.responsibles.length){
                    if(data.form){
                        return (<button onClick={() => validateForm()} className="btn btn-primary w-100">Send inn skjema</button>)
                    }   
            
                    
                    return (<button onClick={() => completeTask()} className="btn btn-primary w-100">Fullfør oppgave</button>)
                }
            }

            return <button className="btn btn-primary w-100" disabled="disabled">Oppgaven må signeres av alle ansvarlige</button>

        }

        if(data.form){
            return (<button onClick={() => validateForm()} className="btn btn-primary w-100">Send inn skjema</button>)
        }   

        
        return (<button onClick={() => completeTask()} className="btn btn-primary w-100">Fullfør oppgave</button>)
        
        
    }

    

    if(!data){
        return (
            <Skeleton />
        )
    }

    const deadline = getNextDeadline({deadline: data.deadline, subtasks: data.subtasks, lastSubmission: data.lastSubmission})

    return (
        <div className="p-md-3 p-2">
            { data.lastSubmission ? (
                <div className="box mb-md-3 mb-2 px-3 py-2 pointer" onClick={() => setSubmissionDrawerOpen(true)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['far', 'repeat']} />
                        </div>
                        <div className="col">
                            <p className="m-0">Dette er en gjentagende oppgave. Det er registrert flere utførelser av oppgaven.</p>
                        </div>
                        <div className="col-auto">
                            <div className="row align-items-center gx-1">
                                <div className="col-auto"><button className="btn btn-link text-primary">Se alle utførelser</button></div>
                                <div className="col-auto"><FontAwesomeIcon className="text-primary" icon={['far', 'arrow-right']} /></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="row gx-2">
                <div className="col-lg-6 mb-md-3 mb-2">
                    <div className="box p-md-4 p-3 h-100">
                        <div className="row justify-content-between align-items-center mb-2">
                            <div className="col">
                                <button className="btn btn-link" onClick={() => navigate(-1)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                                        <div className="col">Tilbake</div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-auto">
                                <i>{deadline.text}</i>
                            </div>
                            
                            { data.priority ? 
                                <div className="col-auto">
                                    <div className="icon-check-container checked checked-danger">
                                        <div className="icon-check-button ">
                                            <FontAwesomeIcon icon={['fas', 'flag']} />
                                        </div>
                                    </div>
                                </div>
                            : null }
                        </div>
                        <div className="mb-2">
                            <PersonPicker 
                                items={domainUsers}
                                selected={responsibles}
                                setSelected={(val) => updateResponsible(val)}
                                handleManually
                            />  
                        </div>
                        <h3 className="bold">{data.name}</h3>
                        { data.description ? <div dangerouslySetInnerHTML={{__html: styleText(data.description.blocks)}}></div> : null }
                        
                        { data.attachments && data.attachments.length > 0 ? (
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                <Attachments
                                    files={data.attachments}
                                />
                            </div>
                        ) : null}
                        { data.subtasks && data.subtasks.length > 0 ? (
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                {
                                    data.subtasks && data.subtasks.map((item, key) => {
                                       
                                        const nextDeadline = getNextDeadline({deadline: item.deadline})
                                        return (
                                            
                                            <div key={key} className="py-2 px-2 bottom-border" >
                                                <div className="row align-items-center gx-2">
                                                    <div className="col-auto">
                                                        <ImageStack users={item.responsibles} />
                                                    </div>
                                                    <div className="col" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                        <Link to={`${item.id}`}><span className="bold">{item.name}</span></Link>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span>Frist: {nextDeadline.text}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        ): null}
                    </div>
                </div>
                <div className="col-lg-6 mb-md-3 mb-2">
                    <div className="h-100 box  d-flex flex-column py-2" >
                        <TaskLog Context={Context} />
                    </div>
                </div>
                
            </div>
            {
                data.signature === 2 ? (
                    <div className="signatures py-3">
                        <h5 className="bold">Signaturer</h5>
                        <Signatures users={responsibles} signatures={data.signatures} />
                    </div>
                ) : null
            }
            
            { submissionFetched ? <Form form={data.form} /> : null }
            
            {
                !data.form ?
                    (
                        <div className="box p-3 mb-3">
                            <h4 className="bold">Kommentar til utførelse (valgfritt)</h4>
                            <textarea placeholder="Skriv eventuell kommentar her.." value={submission.comment ? submission.comment : ''} onChange={(e) => updateSubmission({comment: e.target.value})} className="form-control" rows="3"></textarea>
                        </div>
                    )
                : null
            }

            {drawSubmitButton()}

            <Drawer open={subtaskDrawerOpen} setOpen={setSubtaskDrawerOpen} classNames={"bg-grey"}>
                <Outlet />
            </Drawer>

            <Modal
                open={signModalOpen}
                setOpen={setSignModalOpen}
            >
                <div className="p-4" style={{maxWidth: 400}}>
                    <h4 className="bold">Signering av oppgave</h4>
                    <p>Denne oppgaven fullføres ved at nødvendige parter signerer. Når alle har signert vil oppgaven <strong>fullføres og arkiveres</strong>.</p>
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-black-outline w-100" onClick={() => setSignModalOpen(false)}>Avbryt og lukk</button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-primary w-100" onClick={() => {
                                signTask()
                                setSignModalOpen()
                            }}>Signer oppgave</button>
                        </div>
                    </div>
                </div>
            </Modal>
            
            <RightDrawer
                open={submissionDrawerOpen}
                setOpen={setSubmissionDrawerOpen}
            >
                <Submissions setOpen={setSubmissionDrawerOpen} />
            </RightDrawer>
        </div>
    )
}

function Submissions({ setOpen }){

    const { data, changeSubmission } = useContext(Context)
    const { apiCall } = useContext(AuthContext)
    const [submissions, setSubmissions] = useState(data.submissions)


    const SubmissionItem = ({ id, archivedon, archivedby }) => {
        return (
            <div className="submission-drawer-item">
                <div className="row gx-2">
                    <div className="col-auto">
                        <div className="submission-drawer-dot">
                            <div className="dot"></div>
                        </div>
                    </div>
                    <div className="col">
                        <span className="hint-text mb-2 d-block">{formatDate(archivedon, 'DD. MMMM YYYY')}</span>
                        {archivedby ? (
                            <div className="row gx-2 align-items-center pb-4 mr-5">
                                <div className="col-auto">
                                    <UserPhoto photo={archivedby.photo} name={archivedby.fullname} size={[40, 40, 40]} />
                                </div>
                                <div className="col">
                                    Utført av {archivedby.fullname}
                                </div>
                            </div>
                        ) : null}
                        
                    </div>
                    <div className="col-auto align-self-center">
                        <button onClick={() => {
                            changeSubmission(id)
                            setOpen(false)    
                        }} className="btn btn-primary btn-sm" style={{marginLeft: 30}}>Se oppgave ({id})</button>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div>
            <div style={{borderBottom: '1px solid #000'}}>
                <div className="row pb-2">
                    <div className="col">
                        <h6 className="bold">Utførelser av {data.name}</h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => setOpen(false)}>
                            <FontAwesomeIcon icon={['fas','xmark']} />
                        </button>
                    </div>
                </div>
            </div>
            {submissions === null ? 
                (
                    <div className="d-flex justify-content-center py-5">
                        <FontAwesomeIcon spin size="2x" icon={['fad', 'spinner-third']} />
                    </div>
                )
                :
                (
                    <div className="py-4">
                    {
                        submissions.length > 0 ? submissions.map((el, key) => {
                            return <SubmissionItem key={key} {...el} />
                        }) : null
                    }
                    </div>
                )
            }
            
            
        </div>
    )
}

function Form({ form }){
    if(!form) return (<div></div>)
    const { categoryOrder, categories } = form
    
    return (
        <div className="form">
            {categoryOrder && categoryOrder.map(categoryId => {
                const category = categories[categoryId];
                
                const items = category.items.map(
                    itemId => form.items[itemId],
                );

                return <Category 
                    key={category.id} 
                    category={category} 
                    items={items} 
                    index={categoryOrder.findIndex(e => e === categoryId)}
                />;
            })}
        </div>
    )   
}

function Category({ category, items, index }){
    
    
    const [collapsed, setCollapsed] = useState(index === 0 ? true : false)
    return(
        <div className="border  mb-2 bg-white">
            <div className="p-3">
                <div className="row align-items-center">
                    <div className="col-auto pointer" onClick={() => setCollapsed(!collapsed)}>
                        <FontAwesomeIcon icon={['far', collapsed ? 'chevron-down' : 'chevron-up']} />
                    </div>
                    <div className="col">
                        <h5 className="mb-0 bold">{category.title}</h5>
                    </div>
                    <div className="col-auto">
                        <small>{Object.keys(items).length} spørsmål</small>
                    </div>
                </div>
            </div>
            <div style={{display: collapsed ? 'block' : 'none'}}>
                <div className="p-3 border-top">
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">{category.title}</h3>
                            <div dangerouslySetInnerHTML={{__html: styleText(category.description.blocks)}}></div>
                            
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    {   
                        items.map((item, key) =>{
                            return (
                                <FormItem
                                    key={key}
                                    {...item}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function FormItem(props){
    const { updateSubmission, submission, data, formValidation, validateForm } = useContext(Context)
    const { id, type } = props

    const onChange = (value) => {
        
        const newState = {
            ...submission,
            ...data.form,
            form: {
                ...submission.form,
                [id]: value
            }
        }

        updateSubmission(newState)
    }
    let switchType = ''
    if(type.value) switchType = type.value
    if(type.val) switchType = type.val
    switch(switchType){
        case 1:
            return <CheckForm validateForm={validateForm} error={formValidation && formValidation[id] ? formValidation[id] : null} value={submission.form && submission.form[id] ? submission.form[id] : null} onChange={(e) => onChange(e)} {...props} />
        case 2:
            return <CommentForm validateForm={validateForm} error={formValidation && formValidation[id] ? formValidation[id] : null} value={submission.form && submission.form[id] ? submission.form[id] : null} onChange={(e) => onChange(e)} {...props} /> 
        case 3:
            return <ConfirmForm validateForm={validateForm} error={formValidation && formValidation[id] ? formValidation[id] : null} value={submission.form && submission.form[id] ? submission.form[id] : null} onChange={(e) => onChange(e)} {...props} />
        case 4:
            return <RangeForm validateForm={validateForm} error={formValidation && formValidation[id] ? formValidation[id] : null} value={submission.form && submission.form[id] ? submission.form[id] : null} onChange={(e) => onChange(e)} {...props} />
    }

    return (
        <div></div>
    )

}


function ArchivedDetails(){
    const { apiCall, user } = useContext(AuthContext)
    const { data, submission, getTask, submissionFetched, updateSubmission, changeSubmission, selectedSubmission } = useContext(Context)
    const logRef = createRef()
  
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [log, setLog] = useState([])
    const [company, setCompany] = useState(null)
    const [suggestions, setSuggestions] = useState(data.responsibles)
    const [open, setOpen] = useState(false);
    const [subtaskDrawerOpen, setSubtaskDrawerOpen] = useState(false)
    const [submissionDrawerOpen, setSubmissionDrawerOpen] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(!selectedSubmission) changeSubmission(data.submissions[0].id)
    }, [])

    useEffect(() => {
        if(params.subtask) setSubtaskDrawerOpen(true)
    }, [params])

    useEffect(() => {
        if(!subtaskDrawerOpen && params.subtask){
            //navigate(-1)
        }
    }, [subtaskDrawerOpen, params])


    useEffect(async () => {
        if(data){
            if(data.company){
                const status = await apiCall({action: 'get-company', id: data.company.id})
                setCompany(status.data.data)
            }

            let logData = []
            if(data.id){
                const log_status = await apiCall({action: 'get-log', class_type: 'SagaNewTask', class_id: data.id })
                if(log_status.data.status === 1){
                    log_status.data.data.map((item, index) => {
                        logData.push({
                            type: 'event',
                            ...item
                        })
                    })
                }
            }

            if(data.messages){
                data.messages.map((item, index) => {
                    logData.push({
                        type: 'message',
                        ...item
                    })
                })
            }
            
            setLog(logData.sort((a, b) => {
                return moment(a['createdon']).format('X') - moment(b['createdon']).format('X')
            }))

            
        }
    }, [data])

    useEffect(() => {
        // Chatloggen skal alltid være scrollet til bunn
        if(logRef.current){
            logRef.current.scrollTop = logRef.current.scrollHeight - logRef.current.clientHeight;
        }
    }, [logRef])
    

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionComponent(mentionProps) {
                
                return (
                    <span
                      className={mentionProps.className}
                      // eslint-disable-next-line no-alert
                    >
                      {mentionProps.mention.fullname}
                    </span>
                  );
            }
        });
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
      }, []);
    
    const editor = useRef(null)

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ trigger, value }) => {
        setSuggestions(defaultSuggestionsFilter(value, data.responsibles, trigger));
    }, []);

    const addMessage = async () => {
        const status = await apiCall({action: 'add-task-message', id: data.id, message: JSON.stringify(convertToRaw(editorState.getCurrentContent()))})
        
        if(status.data.status === 1){
            getTask()
        }
        setEditorState(() => EditorState.createEmpty())
    }

    if(!selectedSubmission){
        return <Skeleton />
    }

    return (
        <div className="p-md-3 p-2">
            <div className="box mb-3 px-md-3 px-2 py-2 bg-warning-tint">
                <div className="row gx-2">
                    <div className="col-auto">
                        <FontAwesomeIcon icon={['far', 'box-archive']} />
                    </div>
                    <div className="col">
                        <p className="m-0">Denne oppgaven ble utført <span className="bold">{formatDate(moment(selectedSubmission.archivedon), 'DD. MMM YYYY')}</span> og er arkivert.</p>
                    </div>
                </div>
            </div>
            { data.submissions.length > 1 ? (
                <div className="box mb-3 px-3 py-2 pointer" onClick={() => setSubmissionDrawerOpen(true)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['far', 'repeat']} />
                        </div>
                        <div className="col">
                            <p className="m-0">Dette er en gjentagende oppgave. Det er registrert flere utførelser av oppgaven.</p>
                        </div>
                        <div className="col-auto">
                            <div className="row align-items-center gx-1">
                                <div className="col-auto"><button className="btn btn-link text-primary">Se alle utførelser</button></div>
                                <div className="col-auto"><FontAwesomeIcon className="text-primary" icon={['far', 'arrow-right']} /></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="row gx-2">
                <div className="col-lg-6 mb-3">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-2">
                            <div className="col">
                                <button className="btn btn-link" onClick={() => navigate(-1)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                                        <div className="col">Tilbake</div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-auto">
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <FontAwesomeIcon icon={['fas', 'check']} className="text-primary" />
                                    </div>
                                    <div className="col-auto">
                                        <p className="m-0 bold">Utført {formatDate(moment(selectedSubmission.archivedon), 'DD. MMM YYYY')}</p>
                                    
                                    </div>
                                </div>
                                
                            </div>
                            
                            { data.priority ? 
                                <div className="col-auto">
                                    <div className="icon-check-container checked checked-danger">
                                        <div className="icon-check-button ">
                                            <FontAwesomeIcon icon={['fas', 'flag']} />
                                        </div>
                                    </div>
                                </div>
                            : null }
                        </div>
                        <div className="mb-2">
                            <ImageStack users={data.responsibles} size={[40, 40, 40]} />
                        </div>
                        <h3 className="bold">{data.name}</h3>
                        { data.description ? <div dangerouslySetInnerHTML={{__html: styleText(data.description.blocks)}}></div> : null }
                        
                        { data.attachments && data.attachments.length > 0 ? (
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                <Attachments
                                    files={data.attachments}
                                />
                            </div>
                        ) : null}
                        { data.subtasks && data.subtasks.length > 0 ? (
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                {
                                    data.subtasks && data.subtasks.map((item, key) => {
                                       
                                        const nextDeadline = getNextDeadline({deadline: item.deadline})
                                        return (
                                            
                                            <div key={key} className="py-2 px-2 bottom-border" >
                                                <div className="row align-items-center gx-2">
                                                    <div className="col-auto">
                                                        <ImageStack users={item.responsibles} />
                                                    </div>
                                                    <div className="col" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                        <Link to={`${item.id}`}><span className="bold">{item.name}</span></Link>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span>Frist: {nextDeadline.text}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        ): null}
                    </div>
                </div>
                <div className="col-lg-6 mb-3">
                    <div className="h-100 box d-flex flex-column py-2" >
                        <div className="p-4 col task-chat-log" ref={logRef}>
                            {
                                log.map((item, key) => {
                                    return <Log key={key}{...item} />
                                })
                            }
                        </div>
                        <div className="p-3 border-top task-chat-editor">
                            <div className="row align-items-center">
                                <div className="col">
                                    <Editor 
                                        editorState={editorState} 
                                        onChange={setEditorState} 
                                        placeholder="Skriv en kommentar" 
                                        ref={editor}
                                        plugins={plugins}
                                    />
                                    
                                    <MentionSuggestions
                                        open={open}
                                        onOpenChange={onOpenChange}
                                        suggestions={data.responsibles}
                                        onSearchChange={onSearchChange}
                                        entryComponent={Entry}
                                        onAddMention={() => {
                                            // get the mention object selected
                                        }}
                                    />
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link" onClick={() => addMessage()}>
                                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
            {
                data.signature === 2 ? (
                    <div className="signatures py-3">
                        <h5 className="bold">Signaturer</h5>
                        <Signatures users={data.responsibles} signatures={data.signatures} />
                    </div>
                ) : null
            }
            { selectedSubmission && (     
                <>
                    {
                        selectedSubmission.payload.form ?
                            <ArchivedFormSubmission /> :
                            (
                                <div className="box p-3 mb-3">
                                    <h4 className="bold">Kommentar til utførelse (valgfritt)</h4>
                                    <div className="rte">
                                        {selectedSubmission.payload.comment ? selectedSubmission.payload.comment : <i>Ingen kommentar utfylt</i>}
                                    </div>
                                </div>
                            )
                    }
                </>
            )}

            <Drawer open={subtaskDrawerOpen} setOpen={setSubtaskDrawerOpen} classNames={"bg-grey"}>
                <Outlet />
            </Drawer>
            
            <RightDrawer
                open={submissionDrawerOpen}
                setOpen={setSubmissionDrawerOpen}
            >
                <Submissions setOpen={setSubmissionDrawerOpen} />
            </RightDrawer>
        </div>
    )
}

function ArchivedFormSubmission(){

    const { selectedSubmission } = useContext(Context)

    const { categoryOrder, categories, items } = selectedSubmission.payload

    const Category = ({ category, items, index }) => {
        const [collapsed, setCollapsed] = useState(true)

        const FormItem = (props) => {
            
            const { id, type } = props

            console.log(selectedSubmission)
            
            let switchType = ''
            if(type.value) switchType = type.value
            if(type.val) switchType = type.val
            switch(switchType){
                case 1:
                    return <CheckForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} />
                case 2:
                    return <CommentForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} /> 
                case 3:
                    return <ConfirmForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} />
                case 4:
                    return <RangeForm disabled {...props} value={selectedSubmission && selectedSubmission.payload && selectedSubmission.payload.form[id] ? selectedSubmission.payload.form[id] : null} />
            }

            return (
                <div></div>
            )
        }

        return(
            <div className="border  mb-2 bg-white">
                <div className="p-3">
                    <div className="row align-items-center">
                        <div className="col-auto pointer" onClick={() => setCollapsed(!collapsed)}>
                            <FontAwesomeIcon icon={['far', collapsed ? 'chevron-down' : 'chevron-up']} />
                        </div>
                        <div className="col">
                            <h5 className="mb-0 bold">{category.title}</h5>
                        </div>
                        <div className="col-auto">
                            <small>{Object.keys(items).length} spørsmål</small>
                        </div>
                    </div>
                </div>
                <div style={{display: collapsed ? 'block' : 'none'}}>
                    <div className="p-3 border-top">
                        <div className="row">
                            <div className="col">
                                <h3 className="bold">{category.title}</h3>
                                <div dangerouslySetInnerHTML={{__html: styleText(category.description.blocks)}}></div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="p-3">
                    {   
                            items.map((item, key) =>{
                                return (
                                    <FormItem
                                        key={key}
                                        {...item}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="form">
            {categoryOrder && categoryOrder.map(categoryId => {
                const category = categories[categoryId];
                
                const items = category.items.map(
                    itemId => selectedSubmission.payload.items[itemId],
                );

                return <Category 
                    key={category.id} 
                    category={category} 
                    items={items} 
                    index={categoryOrder.findIndex(e => e === categoryId)}
                />;
            })}
        </div>
    ) 
}
function Skeleton(){
    return (
        <div className="p-3">
            <div className="row gx-2 mb-3">
                <div className="col-lg-6">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-5">
                            <div className="col">
                                
                            </div>
                            <div className="col-auto">
                                <i>Frist: </i>
                            </div>
                            
                            <div className="col-auto">
                                <div className="icon-check-container checked-danger">
                                    <div className="icon-check-button ">
                                        <FontAwesomeIcon icon={['fas', 'flag']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="bold"></h3>
                        
                        
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                
                            </div>
                        
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="h-100 box  d-flex flex-column">
                        <div className="p-4 col">
                            
                        </div>
                        <div className="p-3 border-top">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link">
                                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}