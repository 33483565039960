import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { useOnClickOutside } from "./Functions"

export default function Modal({ open, setOpen, children, containerStyle }){
    const ref = useRef()

    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setModalOpen(open)
      }, 50)
  }, [open])

    useOnClickOutside(ref, () => setOpen(false))
    return(
        <CSSTransition in={open} timeout={500} classNames="notification-center-wrapper" unmountOnExit>
            <div className="modal-wrapper">
              <CSSTransition in={modalOpen} timeout={500} classNames="modal-container" unmountOnExit>
                <div className="modal-container" ref={ref} style={containerStyle}>
                    {children}
                </div>
                </CSSTransition>
            </div>
        </CSSTransition>
    )
}

