/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Dropdown } from "react-bootstrap";
import moment from 'moment'
import {  createContext, useContext, useState } from "react";
import React, { useEffect } from "react";
//import GridLayout from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { Link, Outlet, useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Submenu from "../../components/Submenu";
import { AuthContext } from "../../providers/AuthProvider";
import Table from "../../components/Table";
import { formatDate, getDeadlineType, getNextDeadline } from "../../components/Functions";
import Tooltip from "../../components/Tooltip";
import ImageStack from "../../components/ImageStack";
import UserPhoto from "../../components/UserPhoto";
import { DomainContext } from "../../providers/DomainProvider";

const Context = createContext({

})

const Provider = ({children}) => {
    const { apiCall, mustRelog, user, domain } = useContext(AuthContext)
    

    const [tasksRequiringAttention, setTasksRequiringAttention] = useState(0)

    const [tasks, setTasks] = useState(null)
    useEffect(() => {
        setTasks(null)
        getTasks()
    }, [domain])

    const getTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-my-tasks'})
        
        
        
        var tempArray = [];
        var attention = 0;
        if(data.status === 1){
            let allTasks = data.data
            
            allTasks.forEach((item, index, object) => {
                if(item.parent) return
                if(item.archivedon) return
                if(item.batch){
                    if(allTasks.filter(i => i.batch === item.batch).length > 1){
                        
                        tempArray = [...tempArray, {
                            batch: item.batch,
                            name: item.name,
                            subtasks: allTasks.filter(i => i.parent === item.id),
                            deadline: item.deadline,
                            form: item.form,
                            count: allTasks.filter(i => i.batch === item.batch).length,
                            responsibles: item.responsibles
                        }]
                        
                        

                        allTasks.forEach((item2, index) => {
                            if(object[index].batch === item.batch){
                                object.splice(index, 1)
                            }
                        })
                    }else{
                        
                        tempArray = [...tempArray, {
                            ...item
                        }]
                    }
                }else{
                    tempArray = [...tempArray, item]
                }


                
                let deadline = getNextDeadline({deadline: item.deadline, subtasks: item.subtasks, lastSubmission: item.lastSubmission})
                
                if((deadline.daysUntil !== null && deadline.daysUntil <= 7) || !item.responsibles.length){
                    attention++
                }
            })

            setTasksRequiringAttention(attention)
            setTasks(tempArray)
        }
    }

    return (
        <Context.Provider value={{
            tasks,
            tasksRequiringAttention
        }}>
            {children}
        </Context.Provider>
    )
}


export default function DomainDashboard(){
    return (
        <Provider>
            <Container />
        </Provider>
    )
    
    

    
}

function Container(){
    const { user } = useContext(AuthContext)
    const { tasksRequiringAttention } = useContext(Context)
    const { unreadMessages } = useContext(DomainContext)
    return (
        <div>
            <div>
                <div className="p-md-3 p-lg-4 px-3 py-2">
                    <h2 className="bold">Velkommen tilbake {user.fullname}</h2>
                    {
                        tasksRequiringAttention !== 0 ? 
                            <div className="py-2 px-3 mb-1 border bg-warning-tint border-warning text-success">
                                <p className="m-0">Du har <strong>{tasksRequiringAttention} oppgaver</strong> til oppfølging.</p>
                            </div>
                        : null
                    }
                </div>
                <div className="row px-md-3 px-2 gx-3">
                    <div className="col-12 mb-md-3 mb-2">
                        <DashboardTasks />
                    </div>
                    <div className="col-md-6 mb-md-3 mb-2">
                        <Conversations />
                    </div>
                    <Shortcuts />
                </div>
                        
                    
                
            </div>
        </div>
    )
}

export function ApiTest(){
    const { apiCall } = useContext(AuthContext)

    return (
        <div className="py-5">
            <h1>Testing av API</h1>
            <button className="btn btn-primary" onClick={() => apiCall({action: 'api-unit-testing', username: 'fail'})}>Lorem ipsum</button>
        </div>
    )
}

export function DashboardTasks(){

    const { apiCall, mustRelog, user, domain } = useContext(AuthContext)
    const { tasks } = useContext(Context)

    const navigate = useNavigate()

    return (
        <div className="p-md-4 p-3 box">
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <h3 className="bold">Dine oppgaver</h3>
                    </div>
                    <div className="col-auto">
                        <Link to="../oppgaver/ny" className="btn btn-primary"><FontAwesomeIcon icon={['fal', 'plus']} /> Ny oppgave</Link>
                    </div>
                </div>
                {
                    <Table
                        id="domain-dashboard-tasks"
                        gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "2fr 1fr 2fr 1fr"]}
                        gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                        perPage={5}
                        rows={tasks}
                        showResult
                        unit="oppgaver"
                        onRowClick={(e) => {
                            
                            if(e.count && e.count > 1){
                                navigate(`../oppgaver/samling/${e.batch}`)
                            }else{
                                navigate(`../oppgaver/${e.id}`)
                            }
                            
                        }}
                        customSort={(rows) => {
                            if(!rows) return false
                            return rows.sort((a, b) => {
                                const a1 =  getNextDeadline({deadline: a.deadline, subtasks: a.subtasks, lastSubmission: a.lastSubmission})
                                const b1 = getNextDeadline({deadline: b.deadline, subtasks: b.subtasks, lastSubmission: b.lastSubmission})
                                return a1.timestamp - b1.timestamp
                            })
                        }}
                        columns={[
                            {
                                grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                                keys: ['name', 'companies', 'count'],
                                display: (e) => {
                                    
                                    return (
                                        <div>
                                            <div className="row gx-2">
                                                { e.count > 1 ? 
                                                    <div className="col-auto">
                                                        <FontAwesomeIcon icon={['fal', 'layer-group']} />
                                                    </div> 
                                                : null }
                                                <div className="col">
                                                <span className="bold d-block taskname">{e.name}</span>
                                                </div>
                                            </div>
                                            
                                            {
                                                e.count > 1 ? 
                                                <span className="hint-text">{`${e.count} bedrifter`}</span> 
                                            : null 
                                            }
                                            {
                                                e.companies && e.companies.length && e.companies.map((company) => {
                                                    return <span className="hint-text">{company.name}</span>
                                                })
                                            }
                                        </div>
                                    )
                                }
                            },
                            {
                                grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                                keys: ['deadline', 'subtasks', 'lastSubmission'],
                                display: (e) => {
                                    let deadlineClass = ''
                                    const deadline = getNextDeadline({deadline: e.deadline, subtasks: e.subtasks, lastSubmission: e.lastSubmission})
                                    
                                    if(deadline.daysUntil !== null){
                                        switch(true){
                                            case deadline.daysUntil < 0:
                                                deadlineClass = 'text-danger'
                                                break
                                            case deadline.daysUntil <= 7:
                                                deadlineClass = 'text-warning'
                                                break;
                                        }
                                    }
                                    return (
                                        <div style={{lineHeight: 1}}>
                                            <span className={"d-block mb-1 " + deadlineClass}>{deadline.text}</span>
                                            <small>
                                                <FontAwesomeIcon size="sm" icon={['fal', 'repeat']} style={{marginRight: 5}} />
                                                <i>{getDeadlineType({deadline: e.deadline})}</i>
                                            </small>   
                                        </div>
                                    ) 
                                }
                            },
                            {
                                grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                                keys: ['priority', 'form', 'subtasks'],
                                display: (e) => {
                                    return (
                                        <div className="row gx-1">
                                            {
                                                e.priority ? (
                                                    <div className="col-auto">
                                                        <Tooltip content="Prioritert oppgave" placement="bottom">
                                                            <div className="bordered-color danger py-1 px-2">
                                                                <FontAwesomeIcon fixedWidth icon={['fas', 'flag']} />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                e.form ? (
                                                    <div className="col-auto">
                                                        <Tooltip content="Skjema" placement="bottom">
                                                            <div className="bordered-color success py-1 px-2">
                                                                <FontAwesomeIcon fixedWidth icon={['fas', 'clipboard']} />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                e.subtasks && e.subtasks.length > 0 ? (
                                                    <div className="col-auto">
                                                        <Tooltip content="Underoppgaver" placement="bottom">
                                                            <div className="bordered-color success py-1 px-2">
                                                                <FontAwesomeIcon fixedWidth icon={['fas', 'network-wired']} />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                ) : null
                                            }
                            
                                        </div>
                                    )
                                }
                            },
                            {
                                grid: ["1 / 5 / 2 / 6", "1 / 5 / 2 / 6", "1 / 4 / 2 / 6"],
                                keys: ['responsibles', 'subtasks'],
                                display: (e) => {
                                    var responsibles = []
                                    if(e.subtasks && e.subtasks.length){
                                        e.subtasks.map((item) => {
                                            item.responsibles && item.responsibles.map((i) => {
                                                responsibles.push(i)
                                            })
                                            
                                        })
                                    }
                                    if(e.responsibles && e.responsibles.length){
                                        e.responsibles.map((i) => {
                                            responsibles.push(i)
                                        })
                                        
                                    }
                                    
                                    
                                    return (responsibles.length ? <ImageStack users={responsibles} /> : <Tooltip content="Mangler ansvarlig" placement="left"><FontAwesomeIcon size="2x" icon={['fas', 'circle-exclamation']} style={{color: 'var(--danger-tint)'}} /></Tooltip>)
                                }
                            },
                            {
                                grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                                keys: ['pinned'],
                                display: (e) => {
                                    return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                                }
                            }      
                        ]}
                    />
                
                }
            </div>
        </div>
    )
}

export function DashboardTeams(){
    return (
        <h1>Teams</h1>
    )
}

function Conversations(){
    const { domain } = useContext(AuthContext)
    const { unreadMessages } = useContext(DomainContext)
    const navigate = useNavigate()

    
    if(unreadMessages.length === 0){
        
        return (
            <div className="p-md-4 p-3 h-100 d-flex flex-column box">
                <div className="row align-items-center mb-4 px-1">
                    <div className="col">
                        <h2 className="bold">Samtaler</h2>
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                    </div>
                </div>
                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon size="2x" icon={['fad', 'comments']} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold text-primary">Ingen samtaler</h6>
                        <p>Det er ingen samtaler å følge opp!</p>
                    </div>
                </div>
            </div>
        )
        
    }

    return (
        <div className="p-md-4 p-3 box">
            <div className="row align-items-center mb-md-4 mb-2 px-md-1">
                <div className="col">
                    <h2 className="bold">Samtaler</h2>
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                </div>
            </div>
            <Table
                gridTemplateColumns={["min-content 1fr 1fr", "fit-content(8ch) 1fr 1fr", "fit-content(8ch) 1fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                grid="0fr 1fr 1fr 0fr"
                perPage={5}
                rows={unreadMessages}
                showResult
                unit="samtaler"
                onRowClick={(e) => {
                    
                    if(e.company){
                        navigate(`/${domain.hash}/${e.company.hash}/kanaler/${e.alias}/samtaler`)
                    }else{
                        navigate(`/${domain.hash}/kanaler/${e. alias}/samtaler`)
                    }
                    
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                        keys: ['conversations'],
                        display: (e) => {
                            const lastMessage = e.conversations[e.conversations.length - 1]
                            
                            return (
                                <div style={{marginRight: '1rem'}}>
                                    <UserPhoto photo={lastMessage?.message.createdby.photo} name={lastMessage?.message.createdby.fullname} size={[50, 40, 30]} />
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 2", "1 / 2 / 2 / 2", "1 / 2 / 2 / 2"],
                        keys: ['message', 'conversations'],
                        display: (e) => {
                            
                            const lastMessage = e.conversations[e.conversations.length - 1]

                            let dateString = formatDate(lastMessage.message.createdon, 'diff')
                            

                            return (
                                <div>
                                    <h6 className="bold">{lastMessage?.message.createdby.fullname}</h6>
                                    <div className="row gx-3 hint-text align-items-center">
                                        <div className="col-auto">
                                            <span className="hint-text">{dateString}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row gx-1 align-items-center">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['far', 'message-lines']} />
                                                </div>
                                                <div className="col-auto">
                                                    {e.conversations.length}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 3 / 2 / 4"],
                        keys: ['company', 'title'],
                        display: (e) => {
                            return (
                                <div>
                                    <span className="d-block bold">{e.title}</span>
                                    <span>{e.company?.name}</span>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5"],
                        keys: ['channel', 'message'],
                        display: (e) => {
                            return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                        }
                    }      
                ]}
            />
            {/** 
            <Table
                gridTemplateColumns={["min-content 1fr min-content", "min-content 1fr min-content", "min-content 1fr min-content"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={5}
                rows={unreadMessages}
                onRowClick={(e) => {
                    console.log(e)
                    if(e.company){
                        navigate(`/${domain.hash}/${e.company.hash}/kanaler/${e.channel.alias}`)
                    }else{
                        navigate(`/${domain.hash}/kanaler/${e.channel.alias}`)
                    }
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                        keys: ['message'],
                        display: (e) => {
                            return (
                                <UserPhoto photo={e.message.createdby.photo} name={e.message.createdby.fullname} size={[45, 45, 45]} tooltip />
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 6", "1 / 2 / 2 / 6", "1 / 2 / 2 / 6"],
                        keys: ['message', 'messages', 'company', 'channel'],
                        display: (e) => {
                            let dateString
                            const date = moment(e.message.createdon)
                            dateString = 'I dag'
                            if(moment().diff(date, 'days') > 0){
                                dateString = moment().diff(date, 'days') === 1 ? `${moment().diff(date, 'days')} dag siden` : `${moment().diff(date, 'days')} dager siden` 
                            }
                            return (
                                <div className="px-3">
                                    <div className="row gx-2 align-items-center mb-2">
                                        <div className="col-auto">
                                            <h6 className="bold mb-0">{e.company ? e.company.name : e.channel.title}</h6>
                                        </div>
                                    </div>
                                    <div className="row align-items-center text-grey">
                                    <div className="col-auto">
                                            <small className="hint-text">{e.message.createdby.fullname}</small>
                                        </div>
                                        <div className="col-auto">
                                            <small className="hint-text">{dateString}</small>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row gx-1">
                                                <div className="col-auto">
                                                    <FontAwesomeIcon icon={['fal', 'comment']} />
                                                </div>
                                                <div className="col-auto">
                                                    {e.messages}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    },
                    
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 3 / 2 / 4"],
                        keys: ['message'],
                        display: (e) => {
                            return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                        }
                    }      
                ]}
            />
            */}
        </div>
    )
}

function Shortcuts(){
    const { domain } = useContext(AuthContext)
    const { domainShortcuts } = useContext(DomainContext)
    const navigate = useNavigate()

    function Shortcut({ type, externalLink, internalLink, title }){

        const drawShortcutIcon = () => {
            if(type === 'external') return ['far', 'arrow-up-right-from-square']
            if(internalLink && internalLink.icon) return internalLink.icon
            return ['far', 'file']
        }

        const onClick = () => {
            if(type === 'external'){
                window.open(externalLink)
            }else{
                let url = ''
                switch(internalLink.value.type){
                    case 'SagaNewTask':
                        url = `oppgaver/${internalLink.value.id}`
                        break;
                    case 'SagaChannel':
                        url = `kanaler/${internalLink.value.alias}/samtaler`
                        break
                    case 'SagaDocument':
                        url = `dokumenter/${internalLink.value.alias}`
                        break
                }
        
                if(internalLink.value.company){
                    navigate(`/${domain.hash}/${internalLink.value.company.hash}/${url}`)
                }else{
                    navigate(`/${domain.hash}/${url}`)
                }
            }

        }

        return (
            
            <div className="col-md-2 col-6  mb-md-3 mb-2">
                <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary h-100" onClick={onClick}>
                    <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold">{title}</h6>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                domainShortcuts.map((item, key) => <Shortcut key={key} {...item} />)
            }
        </>
    )
    return (
        <div className="p-md-4 p-3 box">
            <div className="row align-items-center mb-md-4 mb-2 px-md-1">
                <div className="col">
                    <h2 className="bold">Snarveier</h2>
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                </div>
            </div>
        </div>
    )
}
