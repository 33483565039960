import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React, { createContext, useContext, useEffect, useState } from "react"
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import CheckBox from "../../../components/CheckBox"
import ContextMenu, { ContextMenuItem } from "../../../components/ContextMenu"
import Drawer from "../../../components/Drawer"
import DropDown from "../../../components/DropDown"
import ImageCrop from "../../../components/ImageCrop"
import Modal from "../../../components/Modal"
import UserPhoto from "../../../components/UserPhoto"
import { AuthContext } from "../../../providers/AuthProvider"
import { ConfigContext } from "../../../providers/ConfigProvider"
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../../components/Input"
import PersonPicker from "../../../components/PersonPicker"

const Context = createContext({
    logo: null, setLogo: () => {},
    logoSmall: null, setLogoSmall: () => {},
    orgNr: null, setOrgNr: () => {},
    name: null, setName: () => {},
    country: null, setCountry: () => {},
    address: null, setAddress: () => {},
    zip: null, setZip: () => {},
    city: null, setCity: () => {},
    users: null, setUsers: () => {},
    teams: null, setTeams: () => {},
    permissionTemplates: null,
    save: () => {}
})

const Provider = ({children}) => {

    const navigate = useNavigate()

    const { notify, apiUrl } = useContext(ConfigContext)
    const { apiCall, getAvailablePaths, uploadFile } = useContext(AuthContext)

    const [logo, setLogo] = useState(null)
    const [logoSmall, setLogoSmall] = useState(null)
    const [orgNr, setOrgNr] = useState(null)
    const [name, setName] = useState(null)
    const [country, setCountry] = useState(null)
    const [address, setAddress] = useState(null)
    const [zip, setZip] = useState(null)
    const [city, setCity] = useState(null)
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])

    const [modalOpen, setModalOpen] = useState(false)
    const [permissionTemplates, setPermissionTemplates] = useState([])

    const [userDrawerOpen, setUserDrawerOpen] = useState(false)
    const [editUser, setEditUser] = useState(null)
    const [editTeam, setEditTeam] = useState(null)
    const [teamModalOpen, setTeamModalOpen] = useState(false)

    useEffect(() => {
        
        if(editUser !== null){
            setUserDrawerOpen(true)
        }
    }, [editUser])

    let schema = yup.object().shape({
        orgnr: yup.string().matches(/^[0-9]{9}$/, 'Org.nr. må være 9 siffer').required("Org.nr. er påkrevd"),
        name: yup.string().required("Bedriftsnavn er påkrevd"),
        country: yup.object().typeError("Land er påkrevd").required(),
        address: yup.string().required("Adresse er påkrevd"),
        zip: yup.string().matches(/^[0-9]{4}$/, 'Postnummer må være 4 siffer').required("Postnummer er påkrevd"),
        city: yup.string().required("Poststed er påkrevd"),
        logo: yup.mixed().nullable(false).required("Logo påkrevd"),
        logoSmall: yup.mixed().nullable(false).required("Logo påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            orgnr: '',
            name: '',
            country: null,
            address: '',
            zip: '',
            city: '',
            logo: null,
            logoSmall: null,
            users: [],
            teams: []
        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            save(values)
        },
    })

    useEffect(() => {
        loadPermissionTemplates()
    }, [])

    const loadPermissionTemplates = async () => {
        const status = await apiCall({action: 'get-general-permission-templates'})
        if(status.data.status === 1){
            setPermissionTemplates(status.data.data)
        }else{
            setPermissionTemplates(false)
        }
    }
    const save = async (values) => {
        if(formik.values.users.length === 0){
            setModalOpen(true)
            return
        }
        saveDomain(values)
    }

    const modalAddUsers = () => {
        setModalOpen(false)
        navigate('brukere')
    }

    const saveDomain = async (values) => {
        
        const logoData = await uploadFile(values.logo)
        const logoSmallData = await uploadFile(values.logoSmall)
        
        const { data } = await apiCall({
            action: 'create-domain',
            logo: logoData,
            logoSmall: logoSmallData,
            orgNr: values.orgnr,
            name: values.name,
            country: values.country,
            address: values.address,
            zip: values.zip,
            city: values.city,
            users: values.users,
            teams: values.teams,
            permissionTemplates: permissionTemplates
        })
        
        
        notify(data.status, data.title, data.message)

        if(data.status === 1){
            getAvailablePaths()
            navigate(`/${data.data.hash}/oversikt`)
        }
    }

    return <Context.Provider value={{
        logo, setLogo,
        logoSmall, setLogoSmall,
        orgNr, setOrgNr,
        name, setName,
        country, setCountry,
        address, setAddress,
        zip, setZip,
        city, setCity,
        users, setUsers,
        teams, setTeams,
        permissionTemplates,
        formik,
        editUser,
        setEditUser,
        setUserDrawerOpen,
        setTeamModalOpen,
        editTeam, setEditTeam,
        save
    }}>
        <>
            <form onSubmit={formik.handleSubmit}>
                {children}
            </form>
            {/** Legger modals utenfor, slik at de kan ha eget form */}
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
            >  
                <div className="p-3" style={{maxWidth: 500}}>
                    <h4 className="bold">Ingen brukere</h4>
                    <p>Du har ikke lagt til noen brukere. Er du sikker på at du vil opprette nytt domene uten brukere? Du kan enkelt legge inn brukere senere.</p>
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-warning w-100" type="submit" >Ja, opprett uten brukere</button>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success w-100" onClick={() => modalAddUsers()}>Legg til brukere</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Drawer
                open={userDrawerOpen}
                setOpen={setUserDrawerOpen}
            >
                <AddUser
                    setUserDrawerOpen={setUserDrawerOpen}
                />
            </Drawer>
        </>
    </Context.Provider>
}
export default function NewDomain(){
    return (
        <Provider>
            <TabNavigation />
        </Provider>

    )
}
function TabNavigation(){

    const { formik } = useContext(Context)

    const [errors, setErrors] = useState(null)

    const TabItem = ({ link, children }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={"tab-item " + (match ? "tab-item-active" : "")}>{children}</Link>
        )
    }

    
    const navigate = useNavigate()
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('domeneinformasjon')
        }
    }, [])

    return (
        <div className="p-3">
                <div className="transparent-box">
                    <div className="tab-container">
                        <div className="row gx-0">
                            <div className="col-auto">
                                <TabItem link="domeneinformasjon">Domeneinformasjon</TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="brukere">Brukere</TabItem>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
    )
}

export function NewDomainInformation(){

    const { formik } = useContext(Context)
    const { notify } = useContext(ConfigContext)

    const getBrreg = async () => {
        if(formik.values.orgnr.length === 9){
            const status = await fetch('https://data.brreg.no/enhetsregisteret/api/enheter/' + formik.values.orgnr)
           
            
            if(status.ok){
                const data = await status.json()
                
                const address = data.postadresse ? data.postadresse : data.forretningsadresse
                if(data){
                    formik.setFieldValue('name', data.navn)
                    formik.setFieldValue('country', {label: address.land, value: address.landkode})
                    formik.setFieldValue('address', address.adresse[0])
                    formik.setFieldValue('zip', address.postnummer)
                    formik.setFieldValue('city', address.poststed)
                }
            }else{
                notify(2, 'Ingen treff', 'Det var ingen treff på gitt org.nr. hos Brønnøysund')
            }
        }
    }
       

    return (
        <div className="p-4 bg-white">
            <div className="row justify-content-between mb-3">
                <div className="col-auto">
                    <h2 className="bold">Administrer domeneinformasjon</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-success px-5" type="submit">Lagre</button>
                </div>
            </div>
            
            <div className="row">
                <div className="col-lg-6">
                    <div className="py-3">
                        <ImageCrop 
                            img={formik.values.logo}
                            setImg={(logo) => formik.setFieldValue("logo", logo)}
                            height={150}
                            aspect={2}
                            unit={'logo'}
                            error={formik.touched.logo && Boolean(formik.errors.logo)}
                            helperText={formik.touched.logo && formik.errors.logo}
                        />
                        
                        <div className="mt-3">
                            <p className="hint-text">Denne logoen vil vises i sin helhet i menyen for alle områder under dette domenet. Et horisontalt utsnitt med hvit bakgrunn anbefales</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="py-3">
                        <ImageCrop 
                            img={formik.values.logoSmall}
                            setImg={(logo) => formik.setFieldValue("logoSmall", logo)}
                            height={150}
                            aspect={1}
                            unit={'logo'}
                            error={formik.touched.logoSmall && Boolean(formik.errors.logoSmall)}
                            helperText={formik.touched.logoSmall && formik.errors.logoSmall}
                        />
                        <div className="mt-3">
                            <p className="hint-text">Denne logoen vil vises i et sirkulært utsnitt. Utsnittet er fast. Bruk gjerne et logosymbol og ikke tekst til dette formatet. Brukes der hvor det henvises til området.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            


            <div className="border p-md-4 p-3 mb-5">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-4 mb-2 mb-md-0">
                            <Input
                                name="orgnr"
                                label="Org.nr."
                                type="text"
                                value={formik.values.orgnr}
                                onChange={formik.handleChange}
                                error={formik.touched.orgnr && Boolean(formik.errors.orgnr)}
                                touched={formik.touched.orgnr}
                                helperText={formik.touched.orgnr && formik.errors.orgnr}
                                placeholder={"Domenets organisasjonsnummer"}
                            />
                        </div>
                        <div className="col-md-auto col">
                            <button className="btn btn-border px-4" onClick={() => getBrreg()}>Hent fra Brønnøysund</button>
                        </div>
                    </div>
                    <div className="row align-items-end mb-3">
                        <div className="col mb-2 mb-md-0">
                            <Input
                                name="name"
                                label="Navn."
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Domenets navn"}
                            />
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="form-group mb-2 mb-md-0">
                                <label>Land</label>
                                <DropDown
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    value={formik.values.country}
                                    onChange={(e) => formik.setFieldValue("country", e)}
                                    touched={formik.touched.country}
                                    helperText={formik.touched.country && formik.errors.country}
                                    options={[
                                        {label: 'Norge', value: 'NO'},
                                        {label: 'Sverige', value: 'SE'},
                                        {label: 'Danmark', value: 'DK'},
                                        {label: 'Polen', value: 'PL'},
                                    ]}
                                    placeholder="Velg land"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end mb-3">
                        <div className="col-12 col-md mb-2 mb-md-0">
                            <Input
                                name="address"
                                label="Gateadresse"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                touched={formik.touched.address}
                                helperText={formik.touched.address && formik.errors.address}
                                placeholder={"Domenets adresse"}
                            />
                        </div>
                        <div className="col-md-2 col-4">
                            <Input
                                name="zip"
                                label="Postnr"
                                type="text"
                                value={formik.values.zip}
                                onChange={formik.handleChange}
                                error={formik.touched.zip && Boolean(formik.errors.zip)}
                                touched={formik.touched.zip}
                                helperText={formik.touched.zip && formik.errors.zip}
                                placeholder={"Postnr"}
                            />
                        </div>
                        <div className="col-md-3 col">
                            <Input
                                name="city"
                                label="Poststed"
                                type="text"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                touched={formik.touched.city}
                                helperText={formik.touched.city && formik.errors.city}
                                placeholder={"Poststed"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <Link to="../brukere" className="btn btn-border">Legg til brukere</Link>
                    </div>
                </div>
        </div>
    )
}

export function NewDomainUsers(){
    const {
        setUserDrawerOpen,
        formik,
        setEditUser,
        setEditTeam,
        editTeam
    } = useContext(Context)

    const [groupDrawerOpen, setGroupDrawerOpen] = useState(false)


    useEffect(() => {
        if(editTeam !== null){
            setGroupDrawerOpen(true)
        }
    }, [editTeam])

    const removeUser = (el) => {
        formik.setFieldValue("users", formik.values.users.filter(u => u !== el))
    }

    const removeTeam = (el) => {
        formik.setFieldValue('teams', formik.values.teams.filter(t => t !== el))
    }

    return (
        <div className="p-4 bg-white">
            <div className="row justify-content-between mb-3">
                <div className="col-auto">
                    <h2 className="bold">Administrer brukere</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-success px-5" type="submit">Lagre</button>
                </div>
            </div>

            <h4 className="bold">Opprett brukere</h4>
                
            <div className="border mb-md-5 mb-2">
                <div className="p-md-4 p-3">
                    Opprett aktive administratorer for domenet
                    <div className="pt-4">
                        <button className="btn btn-dashed" type="button" onClick={() => setUserDrawerOpen(true)}>
                            <div className="row gx-1">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['fal', 'plus']} />
                                </div>
                                <div className="col">
                                    <span>Legg til brukere</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                {
                    formik.values.users.map((el, key) => {
                        
                        return (
                            <div className="border-top px-3 py-2" key={key}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <UserPhoto name={el.firstname} size={[40, 40, 40]} />
                                    </div>
                                    <div className="col-4">
                                        <p className="m-0">{el.firstname} {el.lastname}</p>
                                    </div>
                                    <div className="col">
                                        <p className="m-0">{el.permissionTemplate ? el.permissionTemplate.label : <span className="text-danger">Ingen brukerrolle valgt!</span>}</p>
                                    </div>
                                    <div className="col-auto">
                                        <ContextMenu
                                            buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                        >
                                            <ContextMenuItem
                                                label="Rediger"
                                                icon={['far', 'pencil']}
                                                onClick={() => setEditUser(key)}
                                            />
                                            <hr style={{margin: 0}} />
                                            <ContextMenuItem 
                                                label="Fjern"
                                                icon={['far', 'trash']}
                                                onClick={() => removeUser(el)}
                                                colorClass="text-danger"
                                            />
                                        </ContextMenu>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <h4 className="bold">Opprett grupper</h4>
                
            <div className="border mb-md-5 mb-2">
                <div className="p-md-4 p-3">
                    <p>Opprett eller administrer grupper med medlemmene i domenet. Grupper har en gruppeleder som vil få oversikt over sin gruppes aktiviteter. Grupper kan også tilegnes oppgaver m.m.</p>
                    <div className="pt-4">
                        <button className="btn btn-dashed" type="button" onClick={() => setGroupDrawerOpen(true)}>
                            <div className="row gx-1">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['fal', 'plus']} />
                                </div>
                                <div className="col">
                                    <span>Legg til en gruppe</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                {
                    formik.values.teams.map((el, key) => {
                        
                        return (
                            <div className="border-top px-3 py-2" key={key}>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <p className="m-0">{el.name}</p>
                                    </div>
                                    <div className="col-auto">
                                        
                                    </div>
                                    
                                    <div className="col">
                                        <p className="m-0">{parseInt(el.leaders.length) + parseInt(el.members.length)} medlemmer</p>
                                    </div>
                                    <div className="col-auto">
                                        <ContextMenu
                                            buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                        >
                                            <ContextMenuItem
                                                label="Rediger"
                                                icon={['far', 'pencil']}
                                                onClick={() => setEditTeam(key)}
                                            />
                                            <hr style={{margin: 0}} />
                                            <ContextMenuItem 
                                                label="Fjern"
                                                icon={['far', 'trash']}
                                                onClick={() => removeTeam(el)}
                                                colorClass="text-danger"
                                            />
                                        </ContextMenu>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <Modal
                open={groupDrawerOpen}
                setOpen={setGroupDrawerOpen}
            >
                <AddGroup 
                    setOpen={setGroupDrawerOpen}
                />
            </Modal>
        </div>
    )
}

function AddGroup({ setOpen }){

    const { formik, editTeam, setEditTeam } = useContext(Context)
    const [name, setName] = useState('')
    const [leaders, setLeaders] = useState([])
    const [members, setMembers] = useState([])

    useEffect(() => {
        if(editTeam !== null){
            console.log(formik.values.teams[editTeam])
            if(formik.values.teams[editTeam]){
                setName(formik.values.teams[editTeam].name)
                setLeaders(formik.values.teams[editTeam].leaders)
                setMembers(formik.values.teams[editTeam].members)
            }
        }
    }, [])

    const updateLeaders = (item) => {
        if(leaders.filter(e => e.id === item.id).length > 0){
            const removed = leaders.filter(e => e.id !== item.id)
            setLeaders(removed)
        }else{
            setLeaders([...leaders, item])
        }
    }

    const updateMembers = (item) => {
        if(members.filter(e => e.id === item.id).length > 0){
            const removed = members.filter(e => e.id !== item.id)
            setMembers(removed)
        }else{
            setMembers([...members, item])
        }
    }

    const SelectedUser = ({ id, fullname, onRemove }) => {
        return (
            <div className="p-3 border-bottom-not-last">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row align-items-center gx-2">
                            <div className="col-auto">
                                <UserPhoto name={fullname} size={[40, 40, 40]} />
                            </div>
                            <div className="col">
                                {fullname}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-danger" onClick={() => onRemove(id)}>Fjern</button>
                    </div>
                </div>
            </div>
        )
    }

    const removeLeader = (id) => {
        if(leaders.filter(e => e.id === id).length > 0){
            const removed = leaders.filter(e => e.id !== id)
            setLeaders(removed)
        }
    }

    const removeMember = (id) => {
        if(members.filter(e => e.id === id).length > 0){
            const removed = members.filter(e => e.id !== id)
            setMembers(removed)
        }
    }

    const saveTeam = () => {
        if(editTeam !== null){
            const newTeam = [...formik.values.teams]
            newTeam[editTeam] = {
                name: name,
                leaders: leaders,
                members: members
            }
            formik.setFieldValue('teams', newTeam)
            setEditTeam(null)
        }else{
            formik.setFieldValue('teams', [...formik.values.teams, {
                name: name,
                leaders: leaders,
                members: members
            }])
        }
        

        setOpen(false)
    }

    return (
        <div className="p-3" style={{minWidth: 600, maxWidth: '90vw', overflow: 'visible'}}>
            <h3 className="bold">Opprett gruppe</h3>
            <div className="form-group mb-3">
                <label>Gruppenavn</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="border-lg mb-2">
                <div className="px-3 py-2">
                    <label className="m-0 p-0 bold">Gruppeleder</label>
                    
                </div>
                <hr className="m-0" />
                {
                    leaders && leaders.length === 0 ?
                        <div className="p-3">
                            <p className="hint-text m-0">Legg til en eller flere gruppeledere</p>
                        </div>
                    : leaders && leaders.map((item, index) => {
                        return <SelectedUser key={index} {...item} onRemove={removeLeader} />
                    })
                }
                
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={formik.values.users.map((u, i) => {
                        return {
                            id: i,
                            fullname: `${u.firstname} ${u.lastname}`,
                            photo: null
                        }
                    })}
                    selected={leaders}
                    setSelected={(val) => updateLeaders(val)}
                    handleManually
                    hideOutput
                    text="Legg til en gruppeleder"
                    label="Brukere"
                    disableSearch
                />
            </div>

            <div className="border-lg mb-2">
                <div className="px-3 py-2">
                    <label className="m-0 p-0 bold">Gruppemedlemmer</label>
                    
                </div>
                <hr className="m-0" />
                {
                    members && members.length === 0 ?
                        <div className="p-3">
                            <p className="hint-text m-0">Legg til en eller flere gruppemedlemmer</p>
                        </div>
                    : members && members.map((item, index) => {
                        return <SelectedUser key={index} {...item} onRemove={removeMember} />
                    })
                }
                
            </div>
            <div className="pt-1">
                <PersonPicker
                    items={formik.values.users.map((u, i) => {
                        return {
                            id: i,
                            fullname: `${u.firstname} ${u.lastname}`,
                            photo: null
                        }
                    })}
                    selected={members}
                    setSelected={(val) => updateMembers(val)}
                    handleManually
                    hideOutput
                    text="Legg til et gruppemedlem"
                    label="Brukere"
                    disableSearch
                /> 
                 
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-border w-100" type="button" onClick={() => setOpen(false)}>Avbryt</button>
                </div>
                <div className="col">
                    <button className="btn btn-success w-100" type="button" onClick={() => saveTeam()} >Lagre gruppe</button>
                </div>
            </div>
        </div>
    )
}

function AddUser(){

    const { permissionTemplates, setUserDrawerOpen, formik, editUser, setEditUser } = useContext(Context)

    let userSchema = yup.object().shape({
        firstname: yup.string().required("Fornavn er påkrevd"),
        lastname: yup.string().required("Etternavn er påkrevd"),
        phone: yup.string().matches(/^[0-9]{8}$/, 'Telefonnummer må være 8 siffer').required("Telefonnummer er påkrevd"),
        role: yup.string().required("Stillingstittel / rolle er påkrevd"),
        email: yup.string().email("Må være en gyldig e-postadresse").required("e-postadresse er påkrevd"),
        permissionTemplate: yup.object().typeError("Rolle er påkrevd").nullable(false).required('Rolle er påkrevd'),
        active: yup.boolean()
    });

    const [editUserData, setEditUserData] = useState(null)

    useEffect(() => {
        if(editUser !== null){
            if(formik.values.users[editUser]){
                setEditUserData(formik.values.users[editUser])
                
            }
        }
    }, [])

    useEffect(() => {
        if(editUserData){
            Object.keys(editUserData).map((e) => {
                console.log('Setting', e, 'to', editUserData[e])
                userFormik.setFieldValue(e, editUserData[e])
            })
        }
    }, [editUserData])

    const userFormik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            role: '',
            email: '',
            permissionTemplate: null,
            active: false
        },
        validationSchema: userSchema,
        onSubmit: async (values) => {   
            if(editUserData){
                const newUsers = [...formik.values.users]
                newUsers[editUser] = {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    phone: values.phone,
                    role: values.role,
                    email: values.email,
                    permissionTemplate: values.permissionTemplate,
                    active: values.active
                }
                formik.setFieldValue('users', newUsers)
                setEditUser(null)
            }else{
                formik.setFieldValue('users', [...formik.values.users, {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    phone: values.phone,
                    role: values.role,
                    email: values.email,
                    permissionTemplate: values.permissionTemplate,
                    active: values.active
                }])
            }      
            
            setUserDrawerOpen(false)
        },
    });

    

    return (
        <div className="py-3 d-flex flex-column h-100">
            <div className="col">
                <div className="row justify-content-between mb-4">
                    <div className="col-auto pointer" onClick={() => setUserDrawerOpen(false)}>
                        <div className="row">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                            </div>
                            <div className="col-auto">
                                <h6 className="bold">Opprett ny kunde</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto pointer" onClick={() => setUserDrawerOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'xmark']} />
                    </div>
                </div>
                <h3 className="bold">Legg til bruker</h3>
                <hr className="mb-4" style={{opacity: '.1'}} />
            
                <form onSubmit={userFormik.handleSubmit}>
                    <div className="row pb-3 mb-3 border-bottom border-dashed">
                        <div className="col-md-6 mb-3">
                            <Input
                                name="firstname"
                                label="Fornavn"
                                type="text"
                                value={userFormik.values.firstname}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.firstname && Boolean(userFormik.errors.firstname)}
                                touched={userFormik.touched.firstname}
                                helperText={userFormik.touched.firstname && userFormik.errors.firstname}
                                placeholder={"Medlemmets fornavn"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="lastname"
                                label="Etternavn"
                                type="text"
                                value={userFormik.values.lastname}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.lastname && Boolean(userFormik.errors.lastname)}
                                touched={userFormik.touched.lastname}
                                helperText={userFormik.touched.lastname && userFormik.errors.lastname}
                                placeholder={"Medlemmets etternavn"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="role"
                                label="Stillingstittel / rolle"
                                type="text"
                                value={userFormik.values.role}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.role && Boolean(userFormik.errors.role)}
                                touched={userFormik.touched.role}
                                helperText={userFormik.touched.role && userFormik.errors.role}
                                placeholder={"Stillingstittel / rolle"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="phone"
                                label="Telefonnummer"
                                type="text"
                                value={userFormik.values.phone}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.phone && Boolean(userFormik.errors.phone)}
                                touched={userFormik.touched.phone}
                                helperText={userFormik.touched.phone && userFormik.errors.phone}
                                placeholder={"Fyll inn telefonnummer (8 siffer)"}
                            />  
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="email"
                                label="e-postadresse"
                                type="text"
                                value={userFormik.values.email}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.email && Boolean(userFormik.errors.email)}
                                touched={userFormik.touched.email}
                                helperText={userFormik.touched.email && userFormik.errors.email}
                                placeholder={"Fyll inn medlemmets e-postadresse"}
                            /> 
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Brukertilgang</label>
                                <DropDown
                                    name="permissionTemplate"
                                    error={userFormik.touched.permissionTemplate && Boolean(userFormik.errors.permissionTemplate)}
                                    options={permissionTemplates.map(item => {
                                        return {label: item.name, value: item.id}
                                    })}
                                    value={userFormik.values.permissionTemplate}
                                    placeholder="Velg en rolle"
                                    onChange={(e) => userFormik.setFieldValue("permissionTemplate", e)}
                                    touched={userFormik.touched.permissionTemplate}
                                    helperText={userFormik.touched.permissionTemplate && userFormik.errors.permissionTemplate}

                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Send tilgang med en gang</label>
                                <CheckBox 
                                    name="active"
                                    label="Aktiver medlem ved opprettelse"
                                    value={userFormik.values.active}
                                    classes="checkbox-success"
                                    onChange={(e) => userFormik.setFieldValue("active", e)}
                                    description="Aktivering av medlemmet gir umiddelbar invitasjon og tilgang til systemet"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <button className="btn btn-border btn-block w-100" type="button" onClick={() => setUserDrawerOpen(false)}>Avbryt og lukk</button>
                        </div>
                        <div className="col-lg-6">
                            <button className="btn btn-success w-100" type="submit">Legg til bruker</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}