import data from '../versions.json'
import moment from 'moment'
import React, { useEffect } from 'react'
export default function VersionNews() {
    
    useEffect(() => {
        localStorage.setItem('tudu.lastSeenVersion', moment().format('Y-MM-DD'))
    }, [])
    
    return (
        <div className="p-2">
            <h5 className="bold">Nytt i Samhandlingsportalen</h5>
            {
                data.map((item, key) => {
                    return (
                        <div key={key}>
                            <h6 className="bold">{moment(item.date).format('D. MMM')}</h6>
                            <ul>
                                {
                                    item.items.map((i, k) => {
                                        return (
                                            <li key={k}>{i}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                })
            }
        </div>
    )
}