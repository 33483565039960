import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { formatDate, getNextDeadline, parseLogText, styleBlockedText } from "../../../components/Functions"
import ImageStack from "../../../components/ImageStack"
import Modal from "../../../components/Modal"
import Switch from "../../../components/Switch"
import Table from "../../../components/Table"
import Tooltip from "../../../components/Tooltip"
import UserPhoto from "../../../components/UserPhoto"
import { AuthContext } from "../../../providers/AuthProvider"
import { CompanyContext } from "../../../providers/CompanyProvider"
import { ConfigContext } from "../../../providers/ConfigProvider"
import { ConsultantContext } from "../../../providers/ConsultantProvider"

export default function CompanyConsultantDashboard(){
    
    const { company, companyTags, companyType, companyUsers, isArchived } = useContext(CompanyContext)
    const { baseUrl } = useContext(ConfigContext)
    const { teamleader } = useContext(AuthContext)
    

    const toggleTypes = [
        {
            value: "me",
            label: "Meg",
            icon: ['fal', 'user']
        },
        {
            value: 'team',
            label: "Mitt team",
            icon: ['fal', 'users']
        }
    ]

    const [toggle, setToggle] = useState('me')

    if(!company) return (<div></div>)

    const Tag = ({ name }) => {
        return (
            <div className="col-auto">
                <div className="py-2 px-3 border border-success bg-success-tint round text-success">
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['fal', 'tag']} size="lg" />
                        </div>
                        <div className="col-auto">
                            <span>{name}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const CompanyBrreg = () => {

        const { updateBrregInfo } = useContext(CompanyContext)
        const { loadCompanyByUrl } = useContext(AuthContext)

        const [loading, setLoading] = useState(false)
        const getBrreg = async () => {
            setLoading(true)
            await updateBrregInfo()
            await loadCompanyByUrl()
            setLoading(false)
        }

        const BrregInfo = () => {

            const { 
                navn, 
                organisasjonsnummer, 
                organisasjonsform, 
                roles, 
                naeringskode1, 
                forretningsadresse, 
                postadresse, 
                registrertIMvaregisteret, 
                registrertIForetaksregisteret,
                registreringsdatoEnhetsregisteret,
                antallAnsatte,
                sisteInnsendteAarsregnskap
            } = company.properties.brreg

            const getDagligLeder = () => {
                let dagligLedere = []
                const result = roles.rollegrupper.filter(r => r.type.kode === 'DAGL')
                if(result && result[0]){
                    
                    
                    result[0].roller.forEach((person) => {
                        dagligLedere.push(`${person.person.navn.fornavn} ${person.person.navn.etternavn}`)
                    })
                }
                return dagligLedere.join(', ')
            }

            return (
                <div className="row">
                    <div className="col-mb-6">
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Juridisk navn</div>
                            <div className="col-6 text-right">{navn}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Org.nr.</div>
                            <div className="col-6 text-right">{organisasjonsnummer}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Selskapsform</div>
                            <div className="col-6 text-right">{organisasjonsform?.beskrivelse}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Daglig leder</div>
                            <div className="col-6 text-right">{getDagligLeder()}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">NACE-bransje</div>
                            <div className="col-6 text-right">{naeringskode1.beskrivelse} ({naeringskode1.kode})</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Antall medlemmer</div>
                            <div className="col-6 text-right">{antallAnsatte}</div>
                        </div>
                    </div>
                    <div className="col-mb-6">
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Forretningsadresse</div>
                            <div className="col-6 text-right">{forretningsadresse ? `${forretningsadresse.adresse[0]} ${forretningsadresse.postnummer} ${forretningsadresse.poststed}` : '...'}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Postadresse</div>
                            <div className="col-6 text-right">{postadresse ? `${postadresse.adresse[0]} ${postadresse.postnummer} ${postadresse.poststed}` : '...'}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Registrert i foretaksregisteret</div>
                            <div className="col-6 text-right">{registrertIForetaksregisteret ? 'Ja' : 'Nei'}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Registrert i MVA</div>
                            <div className="col-6 text-right">{registrertIMvaregisteret ? 'Ja' : 'Nei'}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Registreringsdato</div>
                            <div className="col-6 text-right">{moment(registreringsdatoEnhetsregisteret).format('DD.MM.Y')}</div>
                        </div>
                        <div className="row gx-5 mb-2">
                            <div className="col-6">Siste innsendt årsregnskap</div>
                            <div className="col-6 text-right">{sisteInnsendteAarsregnskap}</div>
                        </div>
                    </div>
                </div>
            )
        }
        
        return (
            <div>
                <h4>Opplysninger fra Brønnøysund</h4>
                <hr />
                {
                    company.properties?.brreg ? <BrregInfo /> : <p>Opplysninger fra Brønnøysund er ikke hentet enda</p>
                }
                <div className="row align-items-center">
                    <div className="col-auto">
                        <button className="btn btn-primary" onClick={() => getBrreg()} disabled={loading}>
                            <div className="row">
                                <div className="col-auto">Oppdater opplysninger</div>
                                {loading ? <div className="col-auto"><FontAwesomeIcon icon={['fad', 'spinner-third']} size="lg" spin /></div> : null}
                                
                            </div>
                        </button>
                    </div>
                    { company.properties?.brreg?.lastUpdated ? 
                    <div className="col-auto">
                        <span className="hint-text">Sist oppdatert {formatDate(company.properties?.brreg?.lastUpdated)}</span>
                    </div>
                    : null }
                </div>
                
            </div>
        )
    }
    
    return (
        <div className="p-md-3 p-2">
            <div className="box p-md-4 p-3 mb-md-3 mb-2">
                <div className="row mb-3">
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <h2 className="bold">{company.name}</h2>
                            </div>
                            {isArchived ? 
                                <div className="col-auto">
                                    <small className="bg-danger px-3 py-1 rounded text-white caps">Arkivert</small>
                                </div>
                            : null}
                            <div className="col-auto">
                                <small>{companyType ? companyType.name : null}</small>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-auto">
                        {
                            companyUsers && companyUsers.length ? (
                                <div className="row align-items-center gx-2">
                                    <div className="col-auto">
                                        <small style={{opacity: '.8'}}>{companyUsers.filter(u => !u.archived).length} medlemmer</small>
                                    </div>
                                    <div className="col-auto">
                                        <ImageStack users={companyUsers.filter(u => !u.archived)} />
                                    </div>
                                </div>
                            )
                        : null }
                    </div>
                </div>
                <div className="row gx-2 mb-4">
                    {
                        companyTags && companyTags.map((item, key) => {
                            return <Tag key={key} {...item} />
                        })
                    }
                </div>
                <CompanyBrreg /> 
                
            </div>
            <div className="box p-md-4 p-3 mb-3">
                <div className="row">
                    <div className="col">
                        <h3 className="fw-bold">Nåværende status</h3>
                    </div>
                    { teamleader ? 
                    <div className="col-auto">
                        <Switch 
                            value={toggle}
                            setValue={setToggle}
                            data={toggleTypes}
                        />
                    </div>
                    : null }
                </div>
                <hr style={{opacity: .1}} />
                <div className="row gx-5">
                    <div className="col-md-6">
                        <h5 className="fw-semi my-3">Oppgaver nær eller over utløpsdato</h5>
                        <Tasks toggle={toggle} />
                    </div>
                    <Conversations toggle={toggle} />
                </div>
            </div>
            <div className="row gx-3">
                <Activities />
                <Shortcuts />
            </div>
        </div>
    )
}

function Activities(){

    const { apiCall, company } = useContext(AuthContext)

    const [log, setLog] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const getLog = async () => {
        const { data } = await apiCall({action: 'get-latest-company-log'})

        if(data.status === 1) setLog(data.data.sort((a, b) => {
            return moment(b.createdon).format('X') - moment(a.createdon).format('X')
        }))
    }

    useEffect(() => {
        getLog()
    }, [])
    
    const Activity = ({ content, createdon, properties}) => {
        const { icon } = properties
        return (
            <div className="row gx-3 mb-4">
                <div className="col-auto">
                    <div className="" style={{width: 30, height: 30, borderRadius: '100%', boxShadow: '0 0 5px rgba(0,0,0,.2)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FontAwesomeIcon icon={['far', icon ? icon : 'message']} className="text-primary" size="1x" />
                    </div>
                </div>
                <div className="col">
                    <p className="m-0">{parseLogText(content)}</p>
                    <small className="hint-text">{formatDate(createdon)}</small>
                </div>
            </div>
        )
    }

    const EmptyLog = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center py-5">
                    <FontAwesomeIcon  size="5x" icon={['fad', 'clipboard-list']} className="text-primary mb-4" />
                    <h5 className="bold">Loggen er tom</h5>
                    <p>Her vil alle aktiviteter på området loggføres</p>                
                </div>
            </div>
        )
    }

    const Loading = () => {
        return (
            <div className="py-3 text-center">
                <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="3x" className="text-primary" />
            </div>
        )
    }

    return (
        <div className="col-md-12 col-lg-6 mb-2 mb-md-3">
            <div className="box p-md-4 p-3">
                <h3 className="bold mb-3">Aktivitet hos {company.name}</h3>
                {
                    log && log.length ? log.map((item, key) => {
                        return <Activity key={key} {...item} />
                    }) : !log ? <Loading /> : <EmptyLog />
                }
                {
                    !log || log.length === 0 ? null : 
                    <div className="text-center">
                        <button className="btn btn-link text-primary" onClick={() => setModalOpen(true)}>Se full aktivitetslogg</button>
                    </div>
                }
                
            </div>
            <Modal open={modalOpen} setOpen={setModalOpen}>
                <AllActivitiesModal />
            </Modal>
        </div>
    )
}

function AllActivitiesModal(){

    const { apiCall } = useContext(AuthContext)

    const [log, setLog] = useState(null)
    const getLog = async () => {
        const { data } = await apiCall({action: 'get-company-log'})
        console.log(data.data)
        if(data.status === 1) setLog(data.data.sort((a, b) => {
            return moment(b.createdon).format('X') - moment(a.createdon).format('X')
        }))
    }

    useEffect(() => {
        getLog()
    }, [])
    
    const Activity = ({ content, createdon, properties}) => {
        const { icon } = properties
        return (
            <div className="row gx-3 mb-4">
                <div className="col-auto">
                    <div className="" style={{width: 30, height: 30, borderRadius: '100%', boxShadow: '0 0 5px rgba(0,0,0,.2)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FontAwesomeIcon icon={['far', icon ? icon : 'message']} className="text-primary" size="1x" />
                    </div>
                </div>
                <div className="col">
                    <p className="m-0">{parseLogText(content)}</p>
                    <small className="hint-text">{formatDate(createdon)}</small>
                </div>
            </div>
        )
    }

    const EmptyLog = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center py-5">
                    <FontAwesomeIcon  size="5x" icon={['fad', 'clipboard-list']} className="text-primary mb-4" />
                    <h5 className="bold">Loggen er tom</h5>
                    <p>Her vil alle aktiviteter på området loggføres</p>                
                </div>
            </div>
        )
    }

    const Loading = () => {
        return (
            <div className="py-3 text-center">
                <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="3x" className="text-primary" />
            </div>
        )
    }

    return (
        <div className="p-4">
            {
                log && log.length ? log.map((item, key) => {
                    return <Activity key={key} {...item} />
                }) : !log ? <Loading /> : <EmptyLog />
            }
        </div>
    )
}

function Conversations({ toggle }){
    const navigate = useNavigate()
    const { company, unreadMessages } = useContext(CompanyContext)
    const { domain } = useContext(AuthContext)

    return (
        <div className="col-md-6">
            <h5 className="fw-semi my-3">Uleste meldinger</h5>
            {
                unreadMessages.length > 0 ?
                (
                    <Table
                        gridTemplateColumns={["min-content 1fr 1fr min-content", "fit-content(8ch) 1fr 1fr min-content", "fit-content(8ch) 1fr min-content"]}
                        gridTemplateRows={["1fr", "1fr", "1fr"]}
                        grid="0fr 1fr 1fr 0fr"
                        perPage={5}
                        rows={unreadMessages}
                        showResult
                        unit="samtaler"
                        onRowClick={(e) => {
                            
                            navigate(`/${domain.hash}/${company.hash}/kanaler/${e.alias}/samtaler`)
                            
                        }}
                        columns={[
                            { 
                                grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 2"],
                                keys: ['conversations'],
                                display: (e) => {
                                    const lastMessage = e.conversations[e.conversations.length - 1]
                                    
                                    return (
                                        <div style={{marginRight: '1rem'}}>
                                            <UserPhoto photo={lastMessage?.message.createdby.photo} name={lastMessage?.message.createdby.fullname} size={[50, 40, 30]} />
                                        </div>
                                    )
                                }
                            },
                            {
                                grid: ["1 / 2 / 2 / 2", "1 / 2 / 2 / 2", "1 / 2 / 2 / 2"],
                                keys: ['message', 'conversations'],
                                display: (e) => {
                                    
                                    const lastMessage = e.conversations[e.conversations.length - 1]

                                    let dateString = formatDate(lastMessage.message.createdon, 'diff')
                                    

                                    return (
                                        <div>
                                            <h6 className="bold">{lastMessage?.message.createdby.fullname}</h6>
                                            <div className="row gx-3 hint-text align-items-center">
                                                <div className="col-auto">
                                                    <span className="hint-text">{dateString}</span>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="row gx-1 align-items-center">
                                                        <div className="col-auto">
                                                            <FontAwesomeIcon icon={['far', 'message-lines']} />
                                                        </div>
                                                        <div className="col-auto">
                                                            {e.conversations.length}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )
                                }
                            },
                            {
                                grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "1 / 3 / 2 / 4"],
                                keys: ['company', 'title'],
                                display: (e) => {
                                    return (
                                        <div>
                                            <span className="d-block bold">{e.title}</span>
                                            <span>{e.company?.name}</span>
                                        </div>
                                    )
                                }
                            },
                            {
                                grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5"],
                                keys: ['channel', 'message'],
                                display: (e) => {
                                    return (<FontAwesomeIcon  size="lg" icon={['far', 'arrow-right']} />)
                                }
                            }      
                        ]}
                    />
                )
                :
                (
                    <div className="bg-success-tint p-3" style={{borderRadius: 10}}>
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <FontAwesomeIcon className="text-success" icon={['fad', 'circle-check']} size="2x" />
                            </div>
                            <div className="col">
                                <p className="text-success m-0">Det er for tiden ingen samtaler med behov for oppfølging</p>
                            </div>
                        </div>
                    </div>
                )
            }
            
        </div>
    )
}

function Tasks({ toggle }){

    const { apiCall, user } = useContext(AuthContext)
    const { company } = useContext(CompanyContext)
    const { consultants } = useContext(ConsultantContext)
    const navigate = useNavigate()

    const [tasks, setTasks] = useState(null)
    const [filteredTasks, setFilteredTasks] = useState(null)

    useEffect(() => {
        setTasks(null)
        getTasks()
    }, [])

    useEffect(() => {
        filterTasks()
    }, [tasks, toggle])

    const filterTasks = () => {
        let tempArray = []
        if(!tasks) return
        tasks.forEach((item, index, object) => {
            if(item.parent) return
            console.log('taskitem', item)
            const t = getNextDeadline({deadline: item.deadline, subtasks: item.subtasks, lastSubmission: item.lastSubmission, submissions: item.submissions})
            
            if(t.daysUntil <= 7){
                tempArray.push(item)
            }
        })

        setFilteredTasks(tempArray)
    }

    const getTasks = async () => {
        const { data } = await apiCall({action: 'get-company-tasks-requiring-attention', company: company.id})
        console.log('getting attention tasks', data)
        if(data.status === 1){
            setTasks(data.data)
            
        }
    }

    if(!filteredTasks){
        return (
            
                <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                    <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span className="fa-stack fa-lg">
                            
                            <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'clipboard-list-check']} className="fa-stack-1x text-secondary" />
                            <FontAwesomeIcon fixedWidth spin size="2x" icon={['fad', 'spinner-third']} className="fa-stack-1x text-primary" />
                        </span>
                    </div>
                    <div className="text-center">
                        <h6 className="bold text-primary">Henter oppgaver</h6>
                    </div>
                </div>
            
        )
    }

    if(filteredTasks.length === 0){
        return (
            
                <div className="bg-success-tint p-3" style={{borderRadius: 10}}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <FontAwesomeIcon className="text-success" icon={['fad', 'circle-check']} size="2x" />
                        </div>
                        <div className="col">
                            <p className="text-success m-0">Det er for tiden ingen oppgaver med behov for oppfølging</p>
                        </div>
                    </div>
                </div>
           
        )
    }

    return (
        
            <Table
                gridTemplateColumns={["2fr 0fr", "2fr 0fr", "2fr 0fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredTasks}
                showResult
                unit="oppgaver"
                onRowClick={(e) => {
                    
                    
                    navigate(`../oppgaver/${e.id}`)
                    
                    
                }}
                columns={[
                    { 
                        grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 3"],
                        keys: ['name', 'companies', 'deadline', 'subtasks', 'lastSubmission', 'submissions'],
                        display: (e) => {
                            const deadline = getNextDeadline({deadline: e.deadline, subtasks: e.subtasks, lastSubmission: e.lastSubmission, submissions: e.submissions})
                            return (
                                <div>
                                    <span className="bold d-block">{e.name}</span>
                                    <span>{deadline?.text}</span>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "1 / 3 / 2 / 4"],
                        keys: ['responsibles'],
                        display: (e) => {
                            return (e.responsibles && e.responsibles.length ? <ImageStack users={e.responsibles} /> : <Tooltip content="Mangler ansvarlig" placement="left"><FontAwesomeIcon size="2x" icon={['fas', 'circle-exclamation']} style={{color: 'var(--danger-tint)'}} /></Tooltip>)
                        }
                    }     
                ]}
            />
        
    )

    
}

function Shortcuts(){
    const { company, domain } = useContext(AuthContext)
    const { companyShortcuts } = useContext(CompanyContext)
    const navigate = useNavigate()

    function Shortcut({ type, externalLink, internalLink, title }){

        const drawShortcutIcon = () => {
            if(type === 'external') return ['far', 'arrow-up-right-from-square']
            if(internalLink && internalLink.icon) return internalLink.icon
            return ['far', 'file']
        }

        const onClick = () => {
            if(type === 'external'){
                window.open(externalLink)
            }else{
                let url = ''
                switch(internalLink.value.type){
                    case 'SagaNewTask':
                        url = `oppgaver/${internalLink.value.id}`
                        break;
                    case 'SagaChannel':
                        url = `kanaler/${internalLink.value.alias}/samtaler`
                        break
                    case 'SagaDocument':
                        url = `dokumenter/${internalLink.value.alias}`
                        break
                }
        
                
                navigate(`/${domain.hash}/${company.hash}/${url}`)
                
            }

        }

        return (
            
            <div className="col-md-2 col-6  mb-md-3 mb-2">
                <div className="py-5 d-flex flex-column justify-content-center align-items-center box  pointer hover-primary h-100" onClick={onClick}>
                    <div className="bg-success-tint mb-3" style={{width: 100, height: 100, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon fixedWidth size="3x" icon={drawShortcutIcon()} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold">{title}</h6>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                companyShortcuts.map((item, key) => <Shortcut key={key} {...item} />)
            }
        </>
    )
    return (
        <div className="p-md-4 p-3 box">
            <div className="row align-items-center mb-md-4 mb-2 px-md-1">
                <div className="col">
                    <h2 className="bold">Snarveier</h2>
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                </div>
            </div>
        </div>
    )
}