import React, { useCallback, useMemo, useState } from 'react'
import { ReactDOM, Component } from 'react';
import {Editor, EditorState, RichUtils, Modifier} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention'
import '@draft-js-plugins/mention/lib/plugin.css'
import { Entry, styleBlockedText } from "./Functions";

const mentionPlugin = createMentionPlugin({
	mentionComponent(mentionProps) {
		
		return (
			<span
			className={mentionProps.className}
			// eslint-disable-next-line no-alert
			>
			{mentionProps.mention.fullname}
			</span>
		);
	}
});
const hashtagPlugin = createMentionPlugin({mentionTrigger: ['#']})
const plugins = [mentionPlugin, hashtagPlugin]

const RANDOM_TAGS = [
	{ id: 1, name: "Fredagsstell", description: "Fredagsstell innebærer blant annet å sjekke alle lysene i lokalet, tømme søppel, og rydde unna alt som skal ut før neste uke. Nedenfor er et skjema hvor det...", type: "task"},
	{ id: 2, name: "En kontrakt", description: "Bare en kontraktstest", type: "contract"},
	{ id: 3, name: "Tilfeldig oppgave", description: "Helt tilfeldig oppgave for testing", type: "task"},
]

export default class DraftEditor extends Component {
    constructor(props) {
      	super(props);

      	this.state = {
			editorState: props.editorState,
			hashtagOpen: false,
			hashtags: [],
			mentions: props.mentions ? props.mentions : [],
			mentionOpen: false
		};

		this.focus = () => this.refs.editor.focus();
		this.onChange = (editorState) => this.setState({editorState});

		this.handleKeyCommand = (command) => this._handleKeyCommand(command);
		this.onTab = (e) => this._onTab(e);
		this.toggleBlockType = (type) => this._toggleBlockType(type);
		this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
		this.insert = (symbol) => this._insert(symbol)
		this.onSearchHashtagChange = (object) => this._onSearchHashtagChange(object)
		this.onSearchMentionChange = (object) => this._onSearchMentionChange(object)
		this.onOpenHashtagChange = (open) => this._onOpenHashtagChange(open)
		this.onOpenMentionChange = (open) => this._onOpenMentionChange(open)
    }

	

	_insert(symbol){

		
		const {editorState} = this.state;

		const newContentState = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            symbol,
        );
      
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-fragment',
        );

		if (newEditorState) {
			this.onChange(newEditorState);
			return true;
		}
		return false;
	}

    _handleKeyCommand(command) {
      const {editorState} = this.state;
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
        return true;
      }
      return false;
    }

    _onTab(e) {
      const maxDepth = 4;
      this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
      this.onChange(
        RichUtils.toggleBlockType(
          this.state.editorState,
          blockType
        )
      );
    }

    _toggleInlineStyle(inlineStyle) {
      this.onChange(
        RichUtils.toggleInlineStyle(
          this.state.editorState,
          inlineStyle
        )
      );
    }

	_onOpenMentionChange(){
		this.setState({
			mentionOpen: true
		})
	}

	_onSearchMentionChange({ value }) {
		this.setState({
			mentions: defaultSuggestionsFilter(value, RANDOM_TAGS)
		})
	}
	
	_onOpenHashtagChange(){
		this.setState({
			hashtagOpen: true
		})
	}
	
	_onSearchHashtagChange({ value }) {
		this.setState({
			hashtags: defaultSuggestionsFilter(value, RANDOM_TAGS)
		})
	}

    render() {
		const {editorState, hashtagOpen, hashtags, mentionOpen, mentions} = this.state;
		const HashtagSuggestions = hashtagPlugin.MentionSuggestions
		const MentionSuggestions = mentionPlugin.MentionSuggestions

		console.log(mentionPlugin)

		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		let className = 'RichEditor-editor';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		

		return (
			<div className="draft-editor-container">
				<div className={className} onClick={this.focus}>
					<Editor
						blockStyleFn={getBlockStyle}
						customStyleMap={styleMap}
						editorState={editorState}
						handleKeyCommand={this.handleKeyCommand}
						onChange={this.onChange}
						onTab={this.onTab}
						placeholder={this.props.placeholder ? this.props.placeholder : ''}
						ref="editor"
						spellCheck={true}
						plugins={plugins}
					/>
					<MentionSuggestions
						open={mentionOpen}
						onOpenChange={this.onOpenMentionChange}
						suggestions={mentions}
						onSearchChange={this.onSearchMentionChange}
						entryComponent={Entry}
					/>
					<HashtagSuggestions 
						open={hashtagOpen}
						onOpenChange={this.onOpenHashtagChange}
						suggestions={hashtags}
						onSearchChange={this.onSearchHashtagChange}
					/>
				</div>
				<div className="draft-editor-buttons">
					<div className="draft-editor-buttons-section">
						<BlockStyleControls
							editorState={editorState}
							onToggle={this.toggleBlockType}
						/>
						<InlineStyleControls
							editorState={editorState}
							onToggle={this.toggleInlineStyle}
						/>
						<ListControl 
							editorState={editorState}
							onToggle={this.toggleBlockType}
						/>
						<InsertControl 
							editorState={editorState}
							insertSymbol={this.insert}
						/>
					</div>
				</div>
			</div>
      	);
    }
}


  	// Custom overrides for "code" style.
	const styleMap = {
		CODE: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
		},
	};

	function getBlockStyle(block) {
		switch (block.getType()) {
			case 'blockquote': return 'RichEditor-blockquote';
			default: return null;
		}
	}

	class InsertButton extends React.Component {
		constructor() {
			super();
			this.onToggle = (e) => {
				e.preventDefault();
				this.props.onToggle(this.props.symbol);
			};
		}

		render() {

		let className = 'RichEditor-styleButton';
		

		return (
			<div className="col-auto">
				<span className={className} onMouseDown={this.onToggle}>
				{
					this.props.icon ? <FontAwesomeIcon icon={this.props.icon} /> : this.props.label
				}
				</span>
			</div>
		);
		}
	}

	class StyleButton extends React.Component {
		constructor() {
			super();
			this.onToggle = (e) => {
				e.preventDefault();
				this.props.onToggle(this.props.style);
			};
		}

		render() {

		let className = 'RichEditor-styleButton';
		if (this.props.active) {
			className += ' RichEditor-activeButton';
		}

		return (
			<div className="col-auto">
				<span className={className} onMouseDown={this.onToggle}>
				{
					this.props.icon ? <FontAwesomeIcon icon={this.props.icon} /> : this.props.label
				}
				</span>
			</div>
		);
		}
	}

	class StyleOption extends React.Component {
		constructor() {
		super();
		this.onToggle = (e) => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
		}

		render() {

		let className = 'RichEditor-styleButton';
		if (this.props.active) {
			className += ' RichEditor-activeButton';
		}

		return (
			<option value={this.props.style}>{this.props.label}</option>
		)
		}
	}

  	const BLOCK_TYPES = [
      	{label: 'Paragraf', style: 'paragraph'},
		{label: 'H1', style: 'header-one'},
		{label: 'H2', style: 'header-two'},
		{label: 'H3', style: 'header-three'},
		{label: 'H4', style: 'header-four'},
		{label: 'H5', style: 'header-five'},
		{label: 'H6', style: 'header-six'},
  	];
	  

	const BlockStyleControls = (props) => {
		const {editorState} = props;
		const selection = editorState.getSelection();
		const blockType = editorState
			.getCurrentContent()
			.getBlockForKey(selection.getStartKey())
			.getType();


		const toggle = (value) => {
			props.onToggle(value)
		}

		return (
		<div className="RichEditor-controls col-auto">
			<select className='' value={blockType} onChange={(e) => props.onToggle(e.target.value)}>
				{BLOCK_TYPES.map((type) =>
					<StyleOption
						key={type.label}
						active={type.style === blockType}
						label={type.label}
						onToggle={toggle}
						style={type.style}
					/>
				)}
			</select>
		</div>
		);
	};

	

	var INLINE_STYLES = [
		{label: 'Bold', style: 'BOLD', icon: ['fas', 'bold']},
		{label: 'Italic', style: 'ITALIC', icon: ['fal', 'italic']},
		{label: 'Underline', style: 'UNDERLINE', icon: ['far', 'underline']},
	];

  	const InlineStyleControls = (props) => {
    	var currentStyle = props.editorState.getCurrentInlineStyle();
    	return (
			<div className="RichEditor-controls col-auto">
				<div className="row gx-1">
					{INLINE_STYLES.map(type =>
					<StyleButton
						key={type.label}
						active={currentStyle.has(type.style)}
						label={type.label}
						onToggle={props.onToggle}
						style={type.style}
						icon={type.icon}
					/>
					)}
				</div>
			</div>
		)
	}

	var LIST_STYLES = [
		{label: 'OL', style: 'ordered-list-item', icon: ['fas', 'list-ol']},
		{label: 'UL', style: 'unordered-list-item', icon: ['fal', 'list-ul']},
	];

	const ListControl = (props) => {
    	var currentStyle = props.editorState.getCurrentInlineStyle();
    	return (
			<div className="RichEditor-controls col-auto">
				<div className="row gx-1">
					{LIST_STYLES.map(type =>
					<StyleButton
						key={type.label}
						active={currentStyle.has(type.style)}
						label={type.label}
						onToggle={props.onToggle}
						style={type.style}
						icon={type.icon}
					/>
					)}
				</div>
			</div>
		)
	}

	const SYMBOLS = [
		{label: '#', symbol: '#', icon: ['fal', 'hashtag']},
		{label: '@', symbol: '@', icon: ['fal', 'at']},

	]

	const InsertControl = (props) => {
		
		return (
			<div className="RichEditor-controls col-auto">
				<div className="row gx-1">
					{SYMBOLS.map(type =>
						<InsertButton
							key={type.label}
							label={type.label}
							onToggle={props.insertSymbol}
							symbol={type.symbol}
							icon={type.icon}
						/>
					)}
				</div>
			</div>
		)
	}
