import React, { createContext, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "./AuthProvider"
import { ConfigContext } from "./ConfigProvider"
import ArchivedTaskDetails from "../components/ArchivedTaskDetails";
import { getNextDeadline } from "../components/Functions";

var timeout = undefined

export const TaskDetailsContext = createContext({

})

export const TaskDetailsProvider = ({ children }) => {
    const { apiCall, company } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const [data, setData] = useState(null)
    const [responsibles, setResponsibles] = useState([])
    const [log, setLog] = useState([])
    const [submission, setSubmission] = useState(null)
    const [submissionFetched, setSubmissionFetched] = useState(false)
    const [formValidation, setFormValidation] = useState(null)
    const [selectedSubmission, setSelectedSubmission] = useState(null)
    const [isArchived, setIsArchived] = useState(false)
    const [isSigned, setIsSigned] = useState(true)
    const [deadline, setDeadline] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    

    useEffect(() => {
        fetchTask()
        fetchSubmission()
    }, [])

    useEffect(() => {
        if(data){
            getLog()
            setResponsibles(data.responsibles)
            const deadline = getNextDeadline({deadline: data.deadline, subtasks: data.subtasks, lastSubmission: data.lastSubmission, submissions: data.submissions})
            setDeadline(deadline)
        }
    }, [data])

    useEffect(() => {
        console.log('deadline', deadline)
    }, [deadline])

    useEffect(() => {
        setTaskSigned()
    }, [data, submission])

    const setTaskSigned = () => {
        if(data?.signature === 2){
            let bool = true
            
            submission?.signatures && submission.signatures?.length && submission.signatures.forEach((signature) => {
                if(!signature.signed) bool = false
            })
            setIsSigned(bool)
        }else{
            setIsSigned(true)
        }
    }

    const saveSubmission = async (submissionData) => {
        const status = await apiCall({action: 'save-task-submission', id: data.id, submission: submissionData})
        return status
    }

    const fetchTask = async () => {
        if(params.id){
            const status = await apiCall({action: 'get-task-details', id: params.id})
            if(status.data.status === 1){
                
                setData(status.data.data)
                if(status.data.data.archivedon) setIsArchived(true)
            }
        }
    }

    const fetchSubmission = async () => {
        if(params.id){
            const status = await apiCall({action: 'get-active-task-submission', id: params.id})
           
            if(status.data.status === 1){
                setSubmission(status.data.data)
            }
            setSubmissionFetched(true)
        }
    }

    const getLog = async () => {
        let logData = []
        
        if(data.messages){
            data.messages.map((item, index) => {
                logData.push(item)
            })
        }

        setLog(logData.sort((a, b) => {
            return moment(a['createdon']).format('X') - moment(b['createdon']).format('X')
        }))

        
    }

    const addMessage = async (content) => {
        const status = await apiCall({action: 'add-task-message', id: data.id, message: content})
        
        if(status.data.status === 1){
            fetchTask()
        }
    }

    const doCompleteTask = async (sub) => {
        clearTimeout(timeout)
        const status = await apiCall({action: 'archive-task-submission', id: data.id, submission: sub, deadline: deadline?.timestamp})
        notify(status.data.status, status.data.title, status.data.message)
        if(status.data.status === 1){
            
            navigate(-1)
        }
    }

    const archiveTask = async () => {
        const status = await apiCall({action: 'archive-task', id: data.id})
        notify(status.data.status, status.data.title, status.data.message)
        if(status.data.status === 1){
            fetchTask()
        }
    }

    const deleteTask = async () => {
        const status = await apiCall({action: 'delete-task', id: data.id})
        notify(status.data.status, status.data.title, status.data.message)
        if(status.data.status === 1) navigate(-1)
    }

    return <TaskDetailsContext.Provider value={{
        data,
        submission,
        setSubmission,
        submissionFetched,
        formValidation,
        selectedSubmission,
        isArchived,
        isSigned,
        log,
        responsibles,
        timeout,
        deadline,
        fetchTask,
        addMessage,
        archiveTask,
        deleteTask,
        fetchSubmission,
        signTask: async () => {
            const status = await apiCall({action: 'sign-task', id: data.id, submission: submission})
            if(status.data.status === 1){
                notify(status.data.status, status.data.title, status.data.message)
                fetchTask()
                fetchSubmission()
            }
        },
        completeTask: (values) => {
            doCompleteTask(values)
        },
        changeSubmission: (id) => {
            
            if(data.submissions.filter(s => s.id === id).length){
                setSelectedSubmission(data.submissions.filter(s => s.id === id)[0])
            }
        },
        updateSubmission: (data) => {
            //setSubmission(data)
            clearTimeout(timeout)
            if(submissionFetched){
                console.log('Waiting to save', data)
                timeout = setTimeout(() => {
                    saveSubmission(data)
                }, 3000) 
            }
        },
        updateForm: (formData) => {
            
            if(submissionFetched){
                saveSubmission({
                    ...data.form,
                    form: formData
                })
            }
       
        },
        submitForm: (formData) => {
            clearTimeout(timeout)
            
            doCompleteTask({
                ...data.form,
                form: formData
            })
            
        }

    }}>
        {data ? (isArchived || selectedSubmission ?  <ArchivedTaskDetails Context={TaskDetailsContext} /> : children) : <Skeleton />}
    </TaskDetailsContext.Provider>
}

function Skeleton(){
    return (
        <div className="p-3">
            <div className="row gx-2 mb-3">
                <div className="col-lg-6">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-5">
                            <div className="col">
                                
                            </div>
                            <div className="col-auto">
                                <i>Frist: </i>
                            </div>
                            
                            <div className="col-auto">
                                <div className="icon-check-container checked-danger">
                                    <div className="icon-check-button ">
                                        <FontAwesomeIcon icon={['fas', 'flag']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="bold"></h3>
                        
                        
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                
                            </div>
                        
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="h-100 box  d-flex flex-column">
                        <div className="p-4 col">
                            
                        </div>
                        <div className="p-3 border-top">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link">
                                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}