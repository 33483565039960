import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import CheckBox from "../components/CheckBox";
import DropDown from "../components/DropDown";
import Tooltip from "../components/Tooltip";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";

export default function Login(){

    const formRef = useRef()

    const [step, setStep] = useState(0)

    const [finalEmail, setFinalEmail] = useState('')
    const [finalPassword, setFinalPassword] = useState('')
    const [finalRememberMe, setFinalRememberMe] = useState(false)
    const [userData, setUserData] = useState(null)
    const [qrUrl, setQrUrl] = useState(null)
    const [loginStatus, setLoginStatus] = useState(null)

    const { getLoginState, accessToken, apiCall, loginSuccess } = useContext(AuthContext)
    const { dev, API_LIST, baseUrl, setBaseUrl } = useContext(ConfigContext)
    

    useEffect(() => {
        if(accessToken){
            getLoginState()
        }
    }, [accessToken])

    useEffect(() => {
        if(formRef.current){
            formRef.current.addEventListener('submit', (e) => {
                e.preventDefault();
                //console.log('submit')
            })
        }
    }, [formRef])

    

    

    const EmailForm =  () => {

        const [email, setEmail] = useState(finalEmail)

        const storeEmail = (email) => {
            setFinalEmail(email)
            setStep(1)
        }


        return (
            <div className="mb-5">
                <div className="login-text text-center">
                    <h2 className="fw-bold">Logg inn</h2>
                    <p className="hint-text">Samhandlingsportalen hvor man kan samarbeide - kunde og rådgiver</p>
                </div>
                <form ref={formRef} onSubmit={() => storeEmail(email)}>                    
                    <div className="form-group my-5">
                        <label>E-post</label>
                        <input autoFocus type="email" className="form-control big-text" placeholder="Fyll inn e-postadresse" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Neste</button>
                </form>
            </div>
        )
    }

    const PasswordForm = () => {
        
        const [password, setPassword] = useState('')
        const [rememberMe, setRememberMe] = useState(false)

        const validatePassword = async (password) => {
            const { data } = await apiCall({
                action: 'validate-email-password',
                email: finalEmail,
                password: password
            })

    
            if(data.status === 3){
                setLoginStatus(data)
            }

            setFinalPassword(password)

            if(data.status === 2){
                setQrUrl(data.qr_code)
                setStep(3)
            }

            if(data.status == 1){
                setStep(4)
            }

            if(data.status === 4){
                loginSuccess(data.user_data, data.access_token)
            }

        }

        return (
            <div>
                <div className="login-text mb-5 text-center">
                    <h2 className="fw-bold">Skriv inn passordet ditt</h2>
                    <p className="hint-text">Samhandlingsportalen hvor man kan samarbeide - kunde og rådgiver</p>
                </div>
                <form className="mb-5" ref={formRef} onSubmit={(e) => {
                    e.preventDefault()
                    validatePassword(password, rememberMe)
                }}>
                    <div className="form-group mb-2">
                        <label>Passord</label>
                        <input autoFocus={true} type="password" className="form-control big-text" placeholder="Fyll inn ditt passord" value={password} onChange={(e) => setPassword(e.target.value)} />
                        
                    </div>
                    
                    <button type="button" className="btn btn-link text-primary" onClick={() => setStep(2)}>Glemt passord? Trykk her</button>
                    
                    <div className="my-4">
                        <CheckBox
                            name="rememberme"
                            label="Husk meg"
                            value={rememberMe}
                            classes="checkbox-success"
                            onChange={(e) => setRememberMe(e)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Neste</button>
                </form>
            </div>
        )
    }

    const ResetPassword = () => {

        const [email, setEmail] = useState(finalEmail)

        const reset = async () => {
            const { data } = await apiCall({
                action: 'forgot-password',
                email: email
            })

            setLoginStatus(data)
            setStep(1)
        }

        return (
            <div>
                <div className="login-text mb-5 text-center">
                    <h2 className="fw-bold">Har du glemt passordet?</h2>
                    <p className="hint-text">Hvis du har glemt passordet ditt kan du fylle inn e-postadressen din under, så sender vi deg et nytt!</p>
                </div>
                <form className="mb-5" ref={formRef} onSubmit={(e) => {
                    e.preventDefault()
                    reset()
                }}>
                    <div className="form-group mb-3">
                        <label>E-post</label>
                        <input type="email" className="form-control big-text" placeholder="Fyll inn e-postadresse" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Send nytt passord</button>
                </form>
            </div>
        )
    }

    const ScanQRCode = () => {
        return (
            <div>
                <div className="login-text mb-5 text-center">
                    <h2 className="fw-bold">Registrer to-faktor</h2>
                    <p className="hint-text">Scan koden under i med din <Tooltip content="eks. Google Authenticator eller Microsoft Authenticator" placement="top"><u>autentiserings-app<sup>?</sup></u></Tooltip></p>
                </div>
                <div className="mb-5 text-center">                    
                    { qrUrl ? <img src={qrUrl} width={200} height={200} className="mb-4" /> : <p className="text-center">Auda, det oppstod en feil</p>}
                    <button type="button" className="btn btn-primary w-100" onClick={() => setStep(4)}>Jeg har scannet QR-koden</button>
                </div>
            </div>
        )
    }

    const ValidateCode = () => {

        const [code, setCode] = useState('')
        const [disabled, setDisabled] = useState(false)

        useEffect(() => {
            if(code.length === 6){
                validateCode()
            }
        }, [code])

        const validateCode = async () => {
            setDisabled(true)
            
            const { data } = await apiCall({
                action: 'validate-login-code',
                code: code,
                email: finalEmail,
                password: finalPassword
            })

            console.log(data)

            if(data.status === 3){
                setLoginStatus(data)
            }

            if(data.status === 1){
                loginSuccess(data.user_data, data.access_token)
            }

            setDisabled(false)
        }
        return (
            <div>
                <div className="login-text mb-5 text-center">
                    <h2 className="fw-bold">Verifiser deg med to-faktor</h2>
                    <p className="hint-text">Fyll inn koden du ser via autentiserings-appen din under</p>
                </div>
                <form className="text-center mb-5" onSubmit={(e) => {
                    e.preventDefault()
                    validateCode()
                }}>
                    <ReactCodeInput 
                        type='text' 
                        fields={6} 
                        value={code}
                        onChange={val => setCode(val)}
                        style={{textAlign: 'center'}}
                        autoFocus
                        inputMode="numeric"
                        inputStyle={{random: 0}}
                        className="mb-4"
                        disabled={disabled}
                    />
                    <button type="submit" className="btn btn-primary w-100" disabled={disabled}>Verifiser</button>
                </form>
            </div>
        )
    }

    const render = () => {
        switch(step){
            case 0: return <EmailForm />
            case 1: return <PasswordForm />
            case 2: return <ResetPassword />
            case 3: return <ScanQRCode />
            case 4: return <ValidateCode />
            default: return <EmailForm />
        }
    }

    const BackButton = () => {
        if(step === 0) return null

        const onClick = () => {
            console.log('click?', step)
            switch(step){
                case 1: setStep(0); break;
                case 2: setStep(0); break;
                case 3: setStep(1); break;
                case 4: setStep(1); break;
            }
        }

        return (
            <button className="btn btn-link" type="button" onClick={() => onClick()}>
                <FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" />
            </button>
        )
    }
    

    return (
        <div className="d-flex vh-100 align-items-center justify-content-center gx-0 position-relative p-md-0 p-3" style={{backgroundColor: 'rgba(0,0,0,.025)'}}>
            <div className="position-absolute w-100 h-100 bg-login">
                <img className="w-100 h-100 coverimg multiply" src={process.env.PUBLIC_URL + "/images/loginbg.jpg"}/>
            </div>
            <div className="loginbox bg-white rounded px-3">
                <div className="px-5 py-4 w-90 mx-auto">
                    <div className="row align-items-center">
                        <div className="col-2">
                            <BackButton />
                        </div>
                    
                        <div className="col">
                            <div className="text-center">
                                <div className=" py-4 mb-2">
                                    <img src={process.env.PUBLIC_URL + "/gfx/tudu-logo-ny.svg"} height="70"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-2"></div>
                    </div>
                    {
                        dev ? 
                        <div className="mb-3">
                            <DropDown
                                value={API_LIST.filter(item => item.value === baseUrl)[0]}
                                options={API_LIST}
                                onChange={(e) => setBaseUrl(e.value)}
                            />
                        </div> : null
                    }
                    
                    { loginStatus ? <div className="text-center"><h6 className="bold text-danger">{loginStatus.title}</h6><p className={loginStatus.status === 3 ? 'text-danger' : ( loginStatus.status === 2 ? 'text-warning' : 'text-success')}>{loginStatus.message}</p></div> : null}
                    {render()}
                    <div className="text-center opacity-50">
                        <span>Tudu AS All rights reserved</span>
                    </div>
                </div>
            </div>
            
        </div>
    )
}