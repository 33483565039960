import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import RadioGroup from "./RadioGroup";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AuthContext } from "../providers/AuthProvider";
import Table from "./Table";
import DropDown from "./DropDown";
import Draft from "./Draft";
import { rteMarkup, styleBlockedText, styleText } from "./Functions";
import { DrawerContext } from "./Drawer";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "./Input";
import DraftWysiwyg from "./DraftWysiwyg";

const Context = createContext({
    formItems: {},
    setFormItems: () => {},
    saveForm: () => {},
    handleAddFormItem: () => {},
    editCategory: () => {},
    editFormItem: () => {},
    deleteFormItem: () => {}
})

export default function FormBuilderProvider({children, currentContext, template}){
    const { form, setForm, templateForms } = useContext(currentContext)
    const [ formItems, setFormItems ] = useState(form ? form : {})
    const { setOpen, setDirty, forceClose } = useContext(DrawerContext)

    useEffect(() => {
        console.log(formItems)
    }, [formItems])

    useEffect(() => {
        setFormItems(form)
    }, [form])

    useEffect(() => {
        
        
        if(template){
            setForm(formItems)
        }else{
            if(form !== formItems){
                setDirty(true)
            }
        }
    }, [formItems])

    return <Context.Provider value={{
        template,
        formItems,
        templateForms,
        setFormItems: (formItems) => {
            setFormItems(formItems)
        },
        saveForm: () => {
            if(!template){
                setDirty(false)
            }
            setForm(formItems)
            forceClose()
        },
        handleAddFormItem: (item, category) => {
            //const id = formItems.items ? `item-${Object.keys(formItems.items).length + 1}` : 'item-1'
            const newCategory = formItems.categories[category]
            console.log(formItems.items)
            //Sjekker om denne ID-en allerede eksisterer (hvis andre er slettet)

            let key = `item-1`
            let usedKeys = []
            if(formItems.items){
                
                Object.keys(formItems.items).forEach(k => {
                    usedKeys.push(k)
                })
            }
            
            let i = 1;
            let bool = false
            while(!bool){
                key = `item-${i}`
                if(!usedKeys.includes(key)){
                    bool = true;
                }
                i++;
            }
            

            


            newCategory.items.push(key)
    
            const newItems = Object.assign({}, formItems.items)
            newItems[key] = {
                id: key,
                ...item
            }
            
            const newState = {
                ...formItems,
                items: newItems,
                categories: {
                    ...formItems.categories,
                    [category]: newCategory,
                },
            };
            setFormItems(newState);
            
            
        },
        editCategory: (obj, id) => {
            
            

            setFormItems({
                ...formItems,
                categories: {
                    ...formItems.categories,
                    [id]: {
                        ...formItems.categories[id],
                        title: obj.title,
                        description: obj.description,
                        
                    }
                }
            })
            
        },
        editFormItem: (obj, id) => {
            setFormItems({
                ...formItems,
                items: {
                    ...formItems.items,
                    [id]: {
                        id: id,
                        ...obj
                    }
                }
            })
        },
        deleteFormItem: (id) => {
            const newItems = formItems.items
            const newCategories = formItems.categories

            for (const [key, category] of Object.entries(newCategories)){
                newCategories[key].items = category.items.filter(i => i !== id)
            }

            if(newItems[id]){
                delete newItems[id]
            }

            setFormItems({
                ...formItems,
                items: newItems,
                categories: newCategories
            })
        }
    }}><Main /></Context.Provider>
}

function Main(){
    const [formType, setFormType] = useState(1)
    const { setOpen } = useContext(DrawerContext)
    const { template } = useContext(Context)

    if(template) return <Builder />
    return (
        <div className="p-3">
            <div className="row justify-content-end">
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['fas', 'xmark']} />
                    </button>
                </div>
            </div>
            <h3 className="bold mb-5">Opprett skjema</h3>
                <div className="form-group">
                    <RadioGroup
                        items={[
                            {label: 'Bygg skjema selv', value: 1},
                            {label: 'Bruk et ferdig oppsett', value: 2}
                        ]}
                        selected={formType}
                        select={setFormType}
                        large
                    />
                </div>
                <hr />

                {
                    formType === 1 ? <Builder /> : <ChooseTemplateForm setFormType={setFormType} />
                }
            </div>
    )
}

function Builder(){
    const {formItems, setFormItems, saveForm, template } = useContext(Context)

    const [showErrors, setShowErrors] = useState(false)
    const { setDirty } = useContext(DrawerContext)
    const [modalOpen, setModalOpen] = useState(false)
    


    const save = () => {
        
        let errors = false
        Object.keys(formItems.categories).forEach(category => {
            if(formItems.categories[category].items.length === 0){
                errors = true
                setShowErrors(true)
                return
            }
        })
        if(errors) return
        
        if(!template){
            setDirty(false)
        }
        
        saveForm()
    }
    const onDragEnd = ({ destination, source, draggableId }) => {
       
       

        if (!destination) return

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )  return

        if(destination.droppableId === 'main' && source.droppableId === 'main'){
            const newOrder = Array.from(formItems.categoryOrder)
            newOrder.splice(source.index, 1);
            newOrder.splice(destination.index, 0, draggableId);

            const newState = {
                ...formItems,
                categoryOrder: newOrder
            }
            setFormItems(newState)
            return
        }

        const start = formItems.categories[source.droppableId];
        const finish = formItems.categories[destination.droppableId];

        if (start === finish) {
            
            const newItems = Array.from(start.items);
            newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, draggableId);

            const newCategory = {
                ...start,
                items: newItems,
            };

            const newState = {
                ...formItems,
                categories: {
                    ...formItems.categories,
                    [newCategory.id]: newCategory,
                },
            };

            setFormItems(newState);
            return;
        }

        // Moving from one list to another
        const startItemIds = Array.from(start.items);
        startItemIds.splice(source.index, 1);
        const newStart = {
            ...start,
            items: startItemIds,
        };

        const finishItemIds = Array.from(finish.items);
        finishItemIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            items: finishItemIds,
        };

        const newState = {
            ...formItems,
            categories: {
                ...formItems.categories,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };
        setFormItems(newState);
    }

    return (
        <div>
            <div>
                <button className="btn btn-dashed" onClick={() => setModalOpen(true)}>
                    <div className="row gx-1">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['fal', 'plus']} />
                        </div>
                        <div className="col">
                            <span>Legg til kategori</span>
                        </div>
                    </div>
                </button>
            </div>
            {
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'main'} type="CATEGORY">
                    {(provided, snapshot) => (
                        <div className='p-3 border-top'
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            
                            
                        {formItems && formItems.categoryOrder && formItems.categoryOrder.map(categoryId => {
                            const category = formItems.categories[categoryId];
                            
                            const items = category.items.map(
                                itemId => formItems.items[itemId],
                            );

                            return <Category 
                                key={category.id} 
                                category={category} 
                                items={items} 
                                index={formItems.categoryOrder.findIndex(e => e === categoryId)}
                                showErrors={showErrors}
                            />;
                        })}
                    
                        {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                </DragDropContext>
            }
            {!template ? 
            <>
                
                <div className="row mt-5 justify-content-center">
                    <div className="col-md-5 col-12">
                        <div className="d-grid">
                            {showErrors ? <h6 className="text-danger text-center">Kategorier må inneholde minst ett spørsmål</h6> : <div></div>}
                            
                            <button type="button" onClick={() => save()} className="btn btn-primary d-block">Lagre skjema</button>
                        </div>
                    </div>
                </div>
            </>
            : null}
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
            >
                <NewCategory setOpen={setModalOpen} />
            </Modal>
        </div>
    )
}

function NewCategory({ setOpen }){
    const {formItems, setFormItems, saveForm } = useContext(Context)

    let schema = yup.object().shape({
        name: yup.string().required("Kategorinavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: null,
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
                     
            saveCategory(values)
        },
    });

    const saveCategory = (values) => {
        const id = formItems && formItems.categories ? `category-${Object.keys(formItems.categories).length + 1}` : 'category-1'

        const newState = {
            ...formItems,
            categories: {
                ...formItems.categories,
                [id]: {
                    id: id,
                    title: values.name,
                    description: values.description,
                    items: []
                },
            },
            categoryOrder: formItems.categoryOrder ? [...formItems.categoryOrder, id] : [id]
        };

        setFormItems(newState);
        setOpen(false)
    }

    return (
        <div className="p-2" style={{minWidth: '50vw', maxWidth: '95%'}}>
            <form onSubmit={formik.handleSubmit}>
                <h6 className="bold">Legg til kategori</h6>
                <p>Du kan bruke kategorier til å gruppere spørsmålene under grupper. Fyll inn feltene og trykk lagre for å opprette en kategori til dette skjemaet.</p>
                <Input
                    name="name"
                    label="Navn"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    touched={formik.touched.name}
                    helperText={formik.touched.name && formik.errors.name}
                    placeholder={"Kategorinavn"}
                />
                <div className="form-group mb-2">
                    <label>Beskrivelse</label>
                    
                    <DraftWysiwyg
                        value={formik.values.description}
                        setValue={(e) => formik.setFieldValue('description', e)}
                    />
                    
                </div>
                <div className="row justify-content-end mt-3">
                    <div className="col-6">
                        <button type="button" className="btn btn-black-outline bold w-100" onClick={() => setOpen(false)}>
                            Avbryt og lukk
                        </button>
                    </div>
                    <div className="col-6">
                        <button type="submit" className="btn btn-primary bold w-100">
                            Lagre kategori
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

function Category({ category, items, index, showErrors }){
    const { handleAddFormItem, editCategory } = useContext(Context)
    const [collapsed, setCollapsed] = useState(true)
    const [editMode, setEditMode] = useState(false)
    const [newItem, setNewItem] = useState(false)

    const [title, setTitle] = useState(category.title)
    const [description, setDescription] = useState(category && category.description ? category.description : null)

    const addFormItem = (obj) => {
        handleAddFormItem(obj, category.id)
        setNewItem(false)
    }

    useEffect(() => {
        if(collapsed){
            setNewItem(false)
        }
    }, [collapsed])
    return (
        <Draggable 
            type="CATEGORY"
            draggableId={category.id} 
            index={index}
        >
            {(provided) => (
                <div className="border my-3" ref={provided.innerRef} {...provided.draggableProps} >
                    <div className="p-3" {...provided.dragHandleProps}>
                        <div className="row align-items-center">
                            <div className="col-auto pointer" onClick={() => setCollapsed(!collapsed)}>
                                <FontAwesomeIcon icon={['far', collapsed ? 'chevron-down' : 'chevron-up']} />
                            </div>
                            <div className="col">
                                <h5 className="mb-0 bold">{category.title}</h5>
                            </div>
                            <div className="col-auto">
                                <small className={showErrors && Object.keys(items).length === 0 ? 'text-danger' : ''}>{Object.keys(items).length} spørsmål</small>
                            </div>
                        </div>
                    </div>
            
                    <div style={{display: collapsed ? 'block' : 'none'}}>
                        {
                            !editMode ? (
                                <div className="p-3 border-top">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="bold">{category.title}</h3>
                                            <div dangerouslySetInnerHTML={{__html: rteMarkup(category.description)}}></div>
                                            
                                        </div>
                                        <div className="col-auto">
                                            <button onClick={() => setEditMode(true)} className="btn btn-link">
                                                <FontAwesomeIcon icon={['far', 'pencil']} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-3 border-top">
                                    <div className="form-group">
                                        <label>Navn på kategori</label>
                                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Beskrivelse</label>
                                        
                                        <DraftWysiwyg
                                            value={description}
                                            setValue={setDescription}
                                        />
                                        
                                        
                                    </div>
                                    <button onClick={() => {
                                        editCategory({title: title, description: description}, category.id)
                                        setEditMode(false)
                                    }} className="btn btn-primary w-100">Lagre</button>
                                </div>
                            )
                        }
                        <Droppable droppableId={category.id}>
                            {(provided, snapshot) => (
                                <div className='p-3'
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {items.map((item, index) => (
                                        <FormItem key={item.id} index={index} {...item} />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <div className="px-3 pb-3">
                            {
                                newItem ? 
                                <NewFormItem handleAddFormItem={addFormItem} /> : 
                                <button onClick={() => setNewItem(true)} className="w-100 btn btn-primary">Legg til spørsmål</button>
                            }
                        </div>
                    </div>
                </div>
        
            )}
        </Draggable>
    )
    
}

function FormItem({ id, title, type, description, index, prefix }){
    const [editMode, setEditMode] = useState(false)
    const { editFormItem, deleteFormItem } = useContext(Context)

    return (
        <Draggable 
            draggableId={id} 
            index={index}
        >
            {(provided) => (
                <div className="box p-3 mb-2" ref={provided.innerRef} {...provided.draggableProps} >
                    {
                        !editMode ? 
                        (
                            <div className="row align-items-center">
                                <div className="col-auto" {...provided.dragHandleProps}>
                                    <FontAwesomeIcon className="hint-text" icon={['fas', 'grip-vertical']} size="2x" />
                                </div>
                                <div className="col">
                                    <h5 className="m-0 bold">{title}</h5>
                                    <small className="m-0 p-0">{description}</small>
                                </div>
                                <div className="col-auto">
                                    <div className="border py-1 px-3">
                                        <div className="row gx-1">
                                            <div className="col-auto">
                                                <span>{type.label}</span>
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={type.icon} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-auto">
                                    <div className="row gx-3">
                                        <div className="col-auto">
                                            <button onClick={() => setEditMode(true)} className="btn btn-link">
                                                <FontAwesomeIcon icon={['far', 'pencil']} />
                                            </button>
                                        </div>
                                        <div className="col-auto">
                                            <button onClick={() => deleteFormItem(id)} className="btn btn-link">
                                                <FontAwesomeIcon className="text-danger" icon={['far', 'trash']} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    ) :
                    (
                        <div>
                            <div className="row align-items-center">
                                <div className="col-auto" {...provided.dragHandleProps}>
                                    <FontAwesomeIcon className="hint-text" icon={['fas', 'grip-vertical']} size="2x" />
                                </div>
                                <div className="col">
                                    <h5 className="m-0 bold">{title}</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="border py-1 px-3">
                                        <div className="row gx-1">
                                            <div className="col-auto">
                                                <span>{type.label}</span>
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={type.icon} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <NewFormItem 
                                    handleAddFormItem={(e) => {
                                        editFormItem(e, id)
                                        setEditMode(false)
                                    }}
                                    object={{ id: id, title: title, type: type, description: description}}
                                />
                            </div>
                        </div>
                    )
                }
                </div>
            )}
        </Draggable>
        
    )
}

function NewFormItem({ handleAddFormItem, object }){


    const add = (values) => {
        handleAddFormItem({title: values.title, description: values.description, type: values.type})
    }

    let schema = yup.object().shape({
        title: yup.string().required("Spørsmålstekst er påkrevd"),
        type: yup.object().typeError("Spørsmålstype er påkrevd").required(),
    });

    const formik = useFormik({
        initialValues: {
            title: object ? object.title : '',
            type: object ? object.type : null,
            description: object ? object.description : '',
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
                     
            add(values)
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
            <div className="row mb-4">
                <div className="col-lg-6">
                    
                    <Input
                        name="title"
                        label="Spørsmålstekst"
                        type="text"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        touched={formik.touched.title}
                        helperText={formik.touched.title && formik.errors.title}
                        placeholder={"Skriv spørsmålstekst her.."}
                    />
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-2">
                        <label>Spørsmålstype</label>
                        <DropDown
                            error={formik.touched.type && Boolean(formik.errors.type)}
                            touched={formik.touched.type}
                            helperText={formik.touched.type && formik.errors.type}
                            value={formik.values.type}
                            onChange={(value) => formik.setFieldValue('type', value)}
                            options={[
                                {label: 'Avsjekk', value: 1, icon: ['fal', 'check']},
                                {label: 'Kommentar', value: 2, icon: ['fal', 'comment']},
                                {label: 'Bekreftelse', value: 3, icon: ['fal', 'stamp']},
                                {label: 'Rangering', value: 4, icon: ['fal', 'slider']},
                            ]}
                            placeholder="Velg skjematype"
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>Tilleggsinformasjon (valgfritt)</label>
                        <textarea 
                            placeholder="Skriv tilleggsinformasjonen her.." 
                            value={formik.values.description} 
                            onChange={(e) => formik.setFieldValue('description', e.target.value)} 
                            className="form-control" 
                            rows="5"></textarea>
                    </div>
                </div>
                
            </div>
            <button type="submit" className='btn btn-primary px-5'>Lagre spørsmål</button>
        </div>
        </form>
    )
}

function ChooseTemplateForm({ setFormType }){
    const { formItems, setFormItems, templateForms } = useContext(Context)

    console.log('TemplateForms?', templateForms)
    const [modalOpen, setModalOpen] = useState(false)
    const [chosenTemplate, setChosenTemplate] = useState(null)

    const setTemplate = (template) => {
        setFormItems(template ? template : chosenTemplate)
        setFormType(1)
    }

    return (
        <>
            <Table
                rows={templateForms}
                perPage={5}
                gridTemplateColumns={["2fr 2fr 1fr", "2fr 2fr 1fr", "2fr 2fr 1fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                headers={[
                    { title: 'Navn', key: 'name', sort: 'string'},
                    { title: 'Antall felter', key: 'number_of_fields', sort: 'number'},
                    { title: null }
                ]}
                columns={[
                    {
                        grid: ["1 / 1 / 2 / 3", "1 / 1 / 2 / 3", "1 / 1 / 2 / 4"],
                        keys: ['name'],
                        display: (e) => {
                            return(<span>{e.name}</span>)
                        }
                    },
                    {
                        grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 1 / 3 / 4"],
                        keys: ['number_of_fields'],
                        display: (e) => {
                            return(<span className="text-success medium">{e.number_of_fields}</span>)
                        }
                    },
                    {
                        grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 4 / 3 / 6"],
                        keys: ['form'],
                        display: (e) => {
                            return (
                                <button className="btn btn-primary" onClick={() => {
                                    
                                    if(Object.keys(formItems).length){
                                        setChosenTemplate(e.form)
                                        setModalOpen(true)
                                    }else{
                                        setTemplate(e.form)
                                    }
                                }} >Bruk mal</button>
                        )
                        }
                    }      
                ]}
                ifEmpty={(
                    <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
                        <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <FontAwesomeIcon size="2x"   icon={['far', 'clipboard-list']} className="text-primary" />
                        </div>
                        <div className="text-center">
                            <h6 className="bold text-primary">Du har ingen skjemamaler enda</h6>
                        </div>
                    </div>
                )}
            />
            <Modal open={modalOpen} setOpen={setModalOpen} containerStyle={{maxWidth: 600}} >
                <div className="p-3">
                    <h6 className="bold">Informasjon vil bli slettet</h6>
                    <p>Du har allerede påbegynt et nytt skjema. Dersom du ønsker å bruke en mal vil dette overskrive det du har laget. Vil du fortsette?</p>
                    <div className="row">
                        <div className="col"><button className="btn btn-border w-100" onClick={() => setModalOpen(false)}>Avbryt og lukk</button></div>
                        <div className="col"><button className="btn btn-success w-100" onClick={() => setTemplate()}>Ja, fortsett med mal</button></div>
                    </div>
                </div>
            </Modal>
        </>
    )
}