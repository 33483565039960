import React, { createRef, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGameConsoleHandheld } from "@fortawesome/pro-duotone-svg-icons";

export default function Table({ 
    id,
    gridTemplateColumns, 
    gridTemplateRows,
    grid, 
    headers, 
    columns, 
    rows, 
    defaultSort, 
    rowPadding, 
    perPage, 
    showResult, 
    unit, 
    onRowClick, 
    ifEmpty, 
    customSort,
    loading
}){


    const tableRef = useRef()

    const [actualRows, setActualRows] = useState(rows)
    const [currentRows, setCurrentRows] = useState(rows) 
    const [sort, setSort] = useState()
    const [sortDirection, setSortDirection] = useState(true)
    const [pagination, setPagination] = useState(1)
    const [paginationSteps, setPaginationSteps] = useState(null)
    const [pageLimit, setPageLimit] = useState(perPage)

    const [windowKey, setWindowKey] = useState(0)
    const [style, setStyle] = useState({
        header: {
            gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
            gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null
        },
        row: {
            gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
            gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null,
        }
    })

    useEffect(() => {
        if(id){
            loadSettings()
        }
    }, [])

    const saveSettings = () => {
        if(id){
            let store = JSON.parse(localStorage.getItem('tudu.tableSettings'))
            if(!store){
                store = {}
            }

            store[id] = {
                pageLimit: pageLimit,
                pagination: pagination
            }

            localStorage.setItem('tudu.tableSettings', JSON.stringify(store))
        }
    }

    const loadSettings = () => {
        let store = JSON.parse(localStorage.getItem('tudu.tableSettings'))

        if(store && store[id]){
            setPageLimit(store[id].pageLimit)
            setPagination(paginationSteps >= store[id].pagination ? store[id].pagination : 1)
        }
    }

    useEffect(() => {
        saveSettings()
    }, [pageLimit, pagination])

    useEffect(() => {
        setActualRows(rows)
    }, [rows])

    useEffect(() => {
        setCurrentRows(actualRows)
    }, [actualRows])

    useEffect(() => {
        if(pageLimit && actualRows && currentRows){
            setPaginationSteps(Math.ceil(actualRows.length / pageLimit))
        }
    }, [actualRows, rows, currentRows, pageLimit])

    useEffect(() => {
        if(actualRows && Array.isArray(actualRows) && pageLimit){
            setCurrentRows(
                actualRows.slice((pagination * pageLimit) - pageLimit, pageLimit * pagination)
            )
        }
    }, [actualRows, pageLimit])

    useEffect(() => {
        if(actualRows && Array.isArray(actualRows)){
            setCurrentRows(
                actualRows.slice((pagination * pageLimit) - pageLimit, pageLimit * pagination)
            )
        }
    }, [paginationSteps, pagination, actualRows, pageLimit])

    const setCustomSort = () => {
        if(customSort){
            const sortedRows = customSort(rows)
            setActualRows(sortedRows)
        }
    }

    useEffect(() => {
        setCustomSort()
    }, [rows])

    useEffect(() => {
        
        if(sort && actualRows){
            
            const sortType = headers.filter(h => h.key === sort)[0].sort
           
            setCurrentRows(
                currentRows.sort((a, b) => {
                    switch(sortType){
                        case 'number':
                            return sortDirection ? a[sort] - b[sort] : b[sort] - a[sort]
                        case 'string':
                            const fa = a[sort], fb = b[sort]
                            if (fa < fb) {
                                return sortDirection ? -1 : 1;
                            }
                            if (fa > fb) {
                                return !sortDirection ? -1 : 1;
                            }
                            return 0;
                        case 'array':
                            return sortDirection ? a[sort].length - b[sort].length : b[sort].length - a[sort].length
                            
                    }
                })
            )
        }
    }, [sort, sortDirection])

    

    useEffect(() => {
        if(headers) setSort(defaultSort ? defaultSort : headers[0].key)
        
    }, [])    

    const drawPagination = () => {
       
        const items = []

        const goBack = () => {
            if((pagination - 1) > 0) setPagination(pagination - 1)   
        }

        const goForward = () => {
            if(pagination < paginationSteps) setPagination(pagination + 1)   
        }

        const goToClicked = (event) => {
            setPagination(parseInt(event.target.dataset.index))
        }

        items.push(<span key={1} onClick={() => setPagination(1)} className={1 === pagination ? 'active' : ''}>{1}</span>)

        let i = pagination - 2
        do{
            if(i >= 2 && i <= (paginationSteps + 1) ){
                items.push(<span data-index={i} key={i} onClick={(e) => goToClicked(e)} className={i === pagination ? 'active' : ''}>{i}</span>)          
            }
            i++
            if(i >= paginationSteps) break
        }
        while(items.length <= 5)

        

        items.push(<span key={paginationSteps} onClick={() => setPagination(paginationSteps)} className={paginationSteps === pagination ? 'active' : ''}>{paginationSteps}</span>)

        
        return (
            <div className="pagination">
                <span className="disabled" disabled onClick={() => goBack()}><FontAwesomeIcon icon={['fal','chevron-left']} /></span>
                {items}
                <span onClick={() => goForward()}><FontAwesomeIcon icon={['fal','chevron-right']} /></span>
            </div>
        )
    }

    const drawCurrentVisibleRows = () => {


        const visibleItemsStart = ((pageLimit * pagination) + 1) - pageLimit;
        const visibleItemsEnd = visibleItemsStart + currentRows.length - 1;
        if(pageLimit < actualRows.length){
            return( 
                <span>{visibleItemsStart} - {visibleItemsEnd}</span>
            )
        }else{
            return( 
                <span>{visibleItemsStart} - {visibleItemsEnd}</span> 
            )
        }
    }

    function handleResize() {

        if(tableRef.current){
            switch(true){
                case tableRef.current.clientWidth > 1024:
                    setWindowKey(0)
                    break;
                case tableRef.current.clientWidth < 1024 && tableRef.current.clientWidth > 767:
                    setWindowKey(1)
                    break;
                case tableRef.current.clientWidth < 767:
                    setWindowKey(2)
                    break;
                default:

            }   
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    
        return _ => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    const updatePageLimit = (newPageLimit) => {
        if(newPageLimit > 0) setPageLimit(newPageLimit)
    }

    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        setStyle({
            header: {
                gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
                gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null
            },
            row: {
                gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
                gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null,
            }
        })
    }, [windowKey])

    const drawTableBody = () => {
       
        
        if(!currentRows){
            if(loading) return loading
            return <Loading />
        }

        if(currentRows.length === 0){
            if(ifEmpty) return ifEmpty
            return <Empty />
        }

        return currentRows.map((row, key) => {
            return (
            <div className={"table-row" + (onRowClick ? " pointer" : "")} style={style.row} key={key} onClick={() => onRowClick ? onRowClick(row) : null}>
                {
                    columns.map((column, key) => {
                        const objs = column.keys.map((key, i) => {
                            return {[key]: row[key]}
                        })
                        const obj = objs.reduce((acc, val) => {
                            return Object.assign(acc,val)
                        })
                        return <div key={key} style={column.grid && column.grid[windowKey] ? {gridArea: column.grid[windowKey]} : {display: 'none'}}>{column.display(obj)}</div>
                    })
                }
            </div>)
        })
    }

    return (
        <div>
        <div className="table-grid" ref={tableRef} >
            {
                headers ? (
                    <div className="table-header" style={style.header}>
                        {
                            headers.map((header, key) => {
                                return (
                                    <div className="row gx-1" key={key} style={columns[key].grid && columns[key].grid[windowKey] ? {gridArea: columns[key].grid[windowKey]} : {display: 'none'}} >
                                        <div className="col-auto">
                                            <span 
                                                className="pointer" 
                                                onClick={() => {
                                                    
                                                    if(sort === header.key){
                                                        setSortDirection(!sortDirection)
                                                    }else{
                                                        setSort(header.key)}
                                                    }
                                                    
                                                }
                                            >{header.title}</span>
                                        </div>
                                        <div className="col-auto">
                                            { sort === header.key ? <FontAwesomeIcon icon={['fas', sortDirection ? 'caret-down' : 'caret-up']} /> : null}
                                            
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
            ) : null
        }
            <div className={"table-body" }>
                
                    {
                        drawTableBody()
                    }
                
            </div>
            
        </div>
            
            <div className="table-footer py-3">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        {showResult && rows && currentRows ? <span>Viser <b>{drawCurrentVisibleRows()}</b> av totalt <b>{rows.length}</b> {unit}</span> : null}
                    </div>
                    <div className="col-auto">
                        <div className="pagination">
                            <span  onClick={() => updatePageLimit(pageLimit - 5)}><FontAwesomeIcon icon={['fal','chevron-left']} /></span>
                            <span >{pageLimit} per side</span>
                            <span onClick={() => updatePageLimit(pageLimit + 5)}><FontAwesomeIcon icon={['fal','chevron-right']} /></span>
                        </div>
                    </div>
                    <div className="col-auto">
                                {
                                    paginationSteps > 1 ? drawPagination() : null
                                }
                    </div>
                </div>
            </div>
                
        </div>
    )
}

function Empty(){
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center py-5">
                <FontAwesomeIcon  size="5x" icon={['fad', 'box-open']} className="text-primary mb-4" />
                <h5 className="bold">Her var det tomt!</h5>
                <p>Det ser ikke ut til at det er noe her enda!</p>                
            </div>
        </div>
    )
}

function Loading(){
    return (
        <div className="py-5 d-flex flex-column justify-content-center align-items-center">
            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className="fa-stack fa-lg">
                
                    <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'hourglass']} className="fa-stack-1x text-secondary" />
                    <FontAwesomeIcon fixedWidth spin size="2x" icon={['fad', 'spinner-third']} className="fa-stack-1x text-primary" />
                </span>
            </div>
            <div className="text-center">
                <h6 className="bold text-primary">Henter innhold...</h6>
                <p>Vent litt mens vi henter innholdet ditt</p>
            </div>
        </div>
    )
}