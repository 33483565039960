import React, { createRef, useContext, useEffect, useState } from "react";
import ReactCrop from 'react-image-crop'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfigContext } from "../providers/ConfigProvider";

export default function ImageCrop({ img, setImg, height, aspect, unit, helperText, error, touched}){

    const INITIAL_CROP_STATE = {
        unit: 'px',
        width: height * aspect,
        height: height,
        x: 0,
        y: 0,
        aspect: aspect
    }

    const { notify, baseUrl } = useContext(ConfigContext)

    let imageRef = createRef()
    const logoRef = createRef()
    const imageFileRef = createRef()
    const [croppedImage, setCroppedImage] = useState(null)
    const [fileName, setFileName] = useState('')
    const [fileType, setFileType] = useState(null)
    const [image, setImage] = useState(null)
    const [cropOpen, setCropOpen] = useState(false)
    const [imageCrop, setImageCrop] = useState(INITIAL_CROP_STATE)
    const [dragging, setDragging] = useState(false)
    const imageUnit = unit ? unit : 'bilde'


    useEffect(() => {
        if(croppedImage){
            convertImageToFile()
        }
    }, [croppedImage])

    const convertImageToFile = async () => {
        
        const logoFile = await fetch(croppedImage).then(res => res.blob()).then(blob => {
            return new File([blob], fileName, { type: fileType })
        })
        setImg(logoFile)
    }

    const onSelectFile = (e) => {
        
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setFileType(e.target.files[0].type)
                setFileName(e.target.files[0].name)
                setImage(reader.result)
                setCropOpen(true)
                
                
            })
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    useEffect(() => {
        if(imageRef.current && !croppedImage){
            makeClientCrop({
                aspect: 2,
                height: 150,
                unit: "px",
                width: 300,
                x: 0,
                y: 0
            })
        }
    }, [imageRef])

    const onImageLoaded = (image) => {
        console.log('IMage laoded?', image)
    }

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    }

    const onCropChange = (crop, percentCrop) => {
        setImageCrop(crop)
        makeClientCrop(crop)
    }

    const  makeClientCrop = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                fileName
            );
            setCroppedImage(croppedImageUrl)
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob( (blob) => {
                if (!blob) {
                    notify(2, 'Feil filtype', 'Filen du har valgt er ikke et bilde.')
                    return;
                }
                let fileUrl
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            },
            `image/${fileName.split('.').pop()}`,
            1
            );
        });
    }

    const removeLogo = () => {
        setImg(null)
        setImage(null)
        setCroppedImage(null)
        setImageCrop(INITIAL_CROP_STATE)
    }

    const onFileDrop = (e) => {
        
       
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0]

            setDragging(false)
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setFileType(file.type)
                setFileName(file.name)
                setImage(reader.result)
                setCropOpen(true)
            })
            reader.readAsDataURL(file);
            e.dataTransfer.clearData() 
        }
    }
    
    useEffect(() => {
        if(logoRef.current){
            ['dragenter', 'dragover'].forEach(eventName => {
                
                logoRef.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(true)
                    }
                }, false)
                
            });

            ['dragleave'].forEach(eventName => {
                
                logoRef.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(false)
                    }
                }, false)
                
            })

        
            logoRef.current.addEventListener('drop', (e) => {
                e.preventDefault()
                onFileDrop(e)
                
            })
        }
    }, [logoRef])

    if(img && !(img instanceof File)){
        return (
            <div>
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className={"create-logo-wrapper"} style={{height: height, width: height * aspect, borderRadius: aspect === 1 ? '50%' : 5}}>
                            <img alt="Crop" style={{ width: '100%' }} src={baseUrl + img} />
                        </div>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary px-4" onClick={() => removeLogo()}>Velg nytt bilde</button>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div className={`image-crop-container ${error ? 'error' : null} ${touched && !error ? 'valid' : null}`}>
            <input ref={imageFileRef} type="file" accept=".jpg,.png" onChange={onSelectFile} style={{display: 'none'}}  />
            <div className="row align-items-center">
                <div className="col-auto">
                    <div ref={logoRef} className={"create-logo-wrapper"  + (dragging ? ' dragging' : '')} onClick={() => imageFileRef.current.click()} style={{height: height, width: height * aspect, borderRadius: aspect === 1 ? '50%' : 5}}>
                        {croppedImage ? <img alt="Crop" style={{ width: '100%' }} src={croppedImage} /> : (dragging ? <span>Slipp meg!</span> : <span className="text-capitalize">{imageUnit}</span>)}
                    </div>
                </div>
                <div className="col">
                    <div className="row w-100">
                        {croppedImage ? (
                            <>
                                <div className="col-md-4 col-12 mb-md-0 mb-2">
                                    <button type="button" className="btn btn-primary px-4 w-100" onClick={() => setCropOpen(true)}>Endre</button>
                                </div>
                                <div className="col-md-4 col-12">
                                    <button type="button" className="btn btn-border w-100" onClick={() => removeLogo()}>
                                        <div className="gx-2 row align-items-center justify-content-center">
                                            <div className="col-auto"> <FontAwesomeIcon icon={['fas', 'trash']} className="text-danger" /> </div>
                                            <div className="col-auto">Fjern {imageUnit}</div>
                                        </div>
                                    </button>
                                </div>
                            </>
                            
                        ) : (
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary px-4" onClick={() => imageFileRef.current.click()}>Last opp</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {error && helperText ? <small className="text-danger">{helperText}</small> : null}
            
            {image && cropOpen && (
                <div className="image-crop-wrapper box p-4">
                    <ReactCrop
                        aspect={aspect}
                        crop={imageCrop}
                        ruleOfThirds
                        onImageLoaded={(e) => onImageLoaded(e)}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    >
                        <img ref={imageRef} src={image} />
                    </ReactCrop>
                    <div className="row">
                        <button type="button" className="btn btn-primary" onClick={() => setCropOpen(false)}>Lagre utsnitt</button>
                    </div>
                </div>
            )}
        </div>
    )
}