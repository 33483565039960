/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { createRef, useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { useKeyPressEvent } from "react-use"
import { useOnClickOutside } from "./Functions"

export default function DropDown(props){
    const { 
        name,
        value, 
        onChange, 
        placeholder, 
        classes, 
        disabled, 
        multiple,
        error,
        valid,
        options,
        containerClass,
        selectFirst,
        sideFlex,
        label,
        helperText,
        touched
    } = props

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState(options)

    useEffect(() => {
        setFilter(options)
    }, [options])
    
    const searchRef = createRef()
    const inputRef = createRef()

    useEffect(() => {
        if(open){
            if(searchRef.current){
                searchRef.current.focus()
            }
        }
    }, [open])

 

    const ref = useRef()

    useOnClickOutside(ref, () => setOpen(false))

    useEffect(() => {
        setFilter(options ? options.filter(option => option.label.match(new RegExp(search, 'i'))) : null)
    }, [search])

    const Option = (props) => {
        const { label, icon, description } = props
        if(multiple){
            const active = value.filter(t => t.value === props.value)
            
            return (
                <li 
                    className={`row ${active.length !== 0 ? 'active' : ''}`} 
                    onClick={() => {
                        onChange(props)
                    }}
                >
                    <span className="col">{label}</span>
                    { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                    { description ? <small className="bold">{description}</small> : null}
                </li>
            )
        }
        return (
            <li 
                className={`row ${value && value.value === props.value ? 'active' : ''}`} 
                onClick={() => {
                    setOpen(false)
                    onChange(props)
                }}
            >
                <span className="col">{label}</span>
                { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                { description ? <small className="bold">{description}</small> : null}
            </li>
        )
    }

    const SelectedOption = (props) => {
        const { label, description } = props

        return (
            <div className="col-auto mb-2">
                <div className="row gx-0" style={{padding: '5px 10px', backgroundColor: 'var(--primary)', color: '#FFF', borderRadius: '30px'}}>
                    <div className="col-auto">
                        {label}
                        { description ? <small className="bold">{description}</small> : null}
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon onClick={() => onChange(props, inputRef?.current)} className="pointer" style={{marginLeft: 10}} icon={['fal', 'xmark']} />
                    </div>
                </div>
            </div>
        )
    }

    useKeyPressEvent("Enter", () => {

        if(document.activeElement === ref.current){
            setOpen(true)
        }
    });

    return (
        <>
            <div tabIndex={0} className={`dropdown-container position-relative ${sideFlex ? 'col-lg-6' : ''} ${disabled ? 'disabled' : ''}`} ref={ref}>
                <div className={"form-group position-relative " + containerClass} onClick={() => !disabled ? setOpen(!open) : null}>
                    {label ? <label>{label}</label> : null}
                    <div className={`dropdown input-group ${touched && !error ? 'valid' : null} ${open ? 'focus ' : null} ${disabled ? 'disabled ' : null} ${valid ? 'valid ' : null} ${error ? 'error ' : null}`}>  
                        {!multiple ? 
                            (
                            <>
                                <p>{ value && value !== '' ?  (<span>{value.label}</span>)  : <span className="hint-text">{placeholder}</span> }</p>
                                <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                            </>
                            )
                        : 
                        (
                            <>
                                <p className="hint-text">{ placeholder }</p>
                                <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                            </>
                        )}
                    </div>
                </div>
                
                <CSSTransition in={open} unmountOnExit timeout={200} classNames="dropdown">
                    <div className="dropdown-options">
                        <div className="p-2">
                            <input ref={searchRef} className="form-control" placeholder="Søk" value={search} onChange={(e) => setSearch(e.target.value)} />
                        </div>
                        <ul>
                            {
                                filter && filter.map((item, key) => {
                                    return (
                                        <Option 
                                            key={key}
                                            {...item}
                                        />
                                    )
                                })
                            }
                        </ul>
                    </div>
                </CSSTransition>
                
            </div>
            <div className={sideFlex ? "position-relative col-lg-6" : "position-relative"}>
                {sideFlex ? <div className="form-group"><label>{sideFlex}</label></div> : null}
            {   
                multiple && (
                    <div className="py-2 row gx-2">
                        {
                            value && value.length ? value.map((el, key) => {
                                return (
                                    <SelectedOption key={key} {...el} />
                                )
                            }) : null
                        }
                    </div>
                )
            }
            </div>
            {error && helperText ? <small className="text-danger">{helperText}</small> : null}
        </>
    )
}