
/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import Draft from '../../../components/Draft';

import FileUpload from '../../../components/FileUpload';
import { ConfigContext } from '../../../providers/ConfigProvider';
import Drawer from '../../../components/Drawer';
import { AuthContext } from '../../../providers/AuthProvider';
import CheckBox from '../../../components/CheckBox';
import SubtaskProvider from '../../../components/Subtask';
import FormBuilderProvider from '../../../components/FormBuilder';

import TaskRepetition from '../../../components/TaskRepetition';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from '../../../components/Input';
import BoxLoading from '../../../components/BoxLoading';
import DraftWysiwyg from '../../../components/DraftWysiwyg';

const Context = createContext({
    data: null,
    form: {},
    subtasks: [],
    addSubTask: () => {},
    setForm: () => {},
    createTask: () => {},
})

const Provider = ({children}) => {
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    const params = useParams()

    const [data, setData] = useState(null) 
    const [form, setForm] = useState({})
    const [subtasks, setSubtasks] = useState([])
    const [templateForms, setTemplateForms] = useState([])

    useEffect(() => {
        loadTemplateTask()
        getTemplateForms()
    }, [])

    const loadTemplateTask = async () => {
        const { data } = await apiCall({
            action: 'load-domain-template-task',
            id: params.taskId
        })

        setForm(data.data.form ? data.data.form : {})
        setSubtasks(data.data.subtasks ? data.data.subtasks : [])
        setData(data.data)
    }

    const getTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-domain-template-forms'});
       
        if(data.status === 1){
            setTemplateForms(data.data)
        }
    }

    return <Context.Provider value={{
        data,
        form,
        subtasks,
        templateForms,
        addSubTask: (task, index) => {
            console.log('Subtask', task)
            if(index === -1){
                setSubtasks([...subtasks, task])
            }else{
                const newSubTasks = subtasks
                newSubTasks[index] = task
                setSubtasks(newSubTasks)
            }
        },
        setForm: (form) => {
            console.log('setting form', form)
            setForm(form)
        },
        createTask: async (task) => {
            
            const status = await apiCall({
                action: 'edit-domain-template-task', 
                id: data.id,
                name: task.name,
                display_name: task.displayName,
                interval: task.interval,
                isPublic: task.isPublic,
                attachments: task.attachments,
                description: task.description,
                form: form ? form : null,
                subtasks: subtasks.length ? subtasks : null,
            })


            notify(status.data.status, 'Suksess!', status.data.message)

        }
    }}>{data === null ? <BoxLoading /> : children}</Context.Provider>
}


export default function DomainTaskTemplateEdit(){
    return (
        <Provider>
            <CreateTask />
        </Provider>
    )
}

function CreateTask(){
    
    const { form, subtasks, createTask, data } = useContext(Context)
    const {domain } = useContext(AuthContext)
    
    const navigate = useNavigate()

    const [formDrawerOpen, setFormDrawerOpen] = useState(false)
    const [subTaskDrawerOpen, setSubTaskDrawerOpen] = useState(false)

    let schema = yup.object().shape({
        name: yup.string().required("Oppgavenavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            name: data.name,
            displayName: data.display_name,
            isPublic: data.isPublic,
            interval: data.interval,
            description: data.description,
            files: data.files
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            saveTask(values)
        },
    });

    const saveTask = (values) => {
        createTask({
            name: values.name,
            displayName: values.displayName,
            isPublic: values.isPublic,
            attachments: values.files,
            description: values.description,
            interval: values.interval,
            form: form,
            subtasks: subtasks
        })

        navigate(`/${domain.hash}/maler/oppgavemaler`)

        
    }
    return (
        <div className="p-3">
            <div className="box p-4">
                <button className="btn btn-link" onClick={() => navigate(-1)}>
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon size="lg" icon={['far', 'chevron-left']} />
                        </div>
                        <div className="col-auto">
                            <span>Tilbake</span>
                        </div>
                    </div>
                </button>

                <form onSubmit={formik.handleSubmit}>

                <div className="row mt-4 align-items-center">
                    <div className="col">
                        <h3 className="bold">Rediger oppgavemal</h3>
                    </div>
                    <div className="col-auto">
                        <button type="submit" className="btn btn-primary d-block">Lagre oppgave</button>
                    </div>
                </div>
                
                <hr/>
                
                <CheckBox 
                    label="Offentlig mal - tilgjengelig for alle områder under"
                    value={formik.values.isPublic}
                    onChange={(e) => formik.setFieldValue('isPublic',e)}
                    classes="mb-3"
                />
                <div className="row mb-3">
                    <div className="col-auto">
                        <div onClick={() => setFormDrawerOpen(true)} className="border p-3 pointer" style={{borderRadius: '5px'}}>
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <FontAwesomeIcon className={Object.keys(form).length ? "text-primary" : ""} size='2x' icon={['fal', 'clipboard']} />
                                </div>
                                <div className="col">
                                    <h6 className='bold m-0'>Skjema</h6>
                                    
                                    {
                                        Object.keys(form).length ? <i>Rediger skjema</i>
                                        : <i className="hint-text">Legg til skjema</i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div onClick={() => setSubTaskDrawerOpen(true)} className="border p-3 pointer" style={{borderRadius: '5px'}}>
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <FontAwesomeIcon className={subtasks.length ? "text-primary" : ""} size='2x' icon={['fal', 'network-wired']} />
                                </div>
                                <div className="col">
                                    <h6 className='bold m-0'>Underoppgaver</h6>
                                    {
                                        subtasks.length ? <i>{subtasks.length} underoppgaver</i>
                                        : <i className="hint-text">Legg til</i>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6">
                                <Input
                                    name="name"
                                    label="Navn på mal"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    touched={formik.touched.name}
                                    helperText={formik.touched.name && formik.errors.name}
                                    placeholder={"Navn på oppgavemal"}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Input
                                    name="displayName"
                                    label="Visningnavn på oppgave"
                                    type="text"
                                    value={formik.values.displayName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                                    touched={formik.touched.displayName}
                                    helperText={formik.touched.displayName && formik.errors.displayName}
                                    placeholder={"Visningnavn på oppgave"}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group mb-3">
                            <label>Beskrivelse</label>
                            <DraftWysiwyg
                                value={formik.values.description}
                                setValue={(e) => formik.setFieldValue('description', e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Vedlegg</label>
                            <FileUpload
                                files={formik.values.files}
                                setFiles={(e) => formik.setFieldValue('files', e)}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <TaskRepetition 
                            value={formik.values.interval}
                            setValue={(e) => formik.setFieldValue('interval', e)}
                        />
                    </div>
                </div>
                </form>
                <Drawer open={formDrawerOpen} setOpen={setFormDrawerOpen} >
                    <FormBuilderProvider currentContext={Context} />     
                </Drawer>
                <Drawer open={subTaskDrawerOpen} setOpen={setSubTaskDrawerOpen}>
                    <SubtaskProvider TaskContext={Context} />        
                </Drawer>
            </div>
        </div>
    )
    
}