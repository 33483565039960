import React, { useState, useEffect } from 'react'
import { DatePicker, DatePickerRange, MultiDatePicker } from './DatePicker';
import RadioGroup from "./RadioGroup";
import moment from 'moment'
import DropDown from './DropDown';

const WEEK_OPTIONS = [
    {label: "Mandag", value: 1},
    {label: "Tirsdag", value: 2},
    {label: "Onsdag", value: 3},
    {label: "Torsdag", value: 4},
    {label: "Fredag", value: 5},
    {label: "Lørdag", value: 6},
    {label: "Søndag", value: 7},
]

const DAY_OPTIONS = [
    {label: "1.", value: 1},
    {label: "2.", value: 2},
    {label: "3.", value: 3},
    {label: "4.", value: 4},
    {label: "5.", value: 5},
    {label: "6.", value: 6},
    {label: "7.", value: 7},
    {label: "8.", value: 8},
    {label: "9.", value: 9},
    {label: "10.", value: 10},
    {label: "11.", value: 11},
    {label: "12.", value: 12},
    {label: "13.", value: 13},
    {label: "14.", value: 14},
    {label: "15.", value: 15},
    {label: "16.", value: 16},
    {label: "17.", value: 17},
    {label: "18.", value: 18},
    {label: "19.", value: 19},
    {label: "20.", value: 20},
    {label: "21.", value: 21},
    {label: "22.", value: 22},
    {label: "23.", value: 23},
    {label: "24.", value: 24},
    {label: "25.", value: 25},
    {label: "26.", value: 26},
    {label: "27.", value: 27},
    {label: "28.", value: 28},
    {label: "29.", value: 29},
    {label: "30.", value: 30},
    {label: "31.", value: 31},
]

const MONTH_OPTIONS = [
    {label: "Januar", value: 1},
    {label: "Februar", value: 2},
    {label: "Mars", value: 3},
    {label: "April", value: 4},
    {label: "Mai", value: 5},
    {label: "Juni", value: 6},
    {label: "Juli", value: 7},
    {label: "August", value: 8},
    {label: "September", value: 9},
    {label: "Oktober", value: 10},
    {label: "November", value: 11},
    {label: "Desember", value: 12},
]
export default function TaskRepetition(props){

    const { value, setValue } = props

    const [repetition, setRepetition] = useState(value ? value.repetition : 1)
    const [startDate, setStartDate] = useState(value ? value.startDate : null)
    const [dates, setDates] = useState(value && value.dates ? value.dates : [])
    const [endDate, setEndDate] = useState(value ? value.endDate : null)
    const [weekDay, setWeekDay] = useState(value && value.weekDay ? value.weekDay : WEEK_OPTIONS[0])
    const [monthDay, setMonthDay] = useState(value && value.monthDay ? value.monthDay : DAY_OPTIONS[0])
    const [month, setMonth] = useState(value && value.month ? value.month : MONTH_OPTIONS[0])

    const [repetitionDescription, setRepetitionDescription] = useState('')
    const drawOptions = () => {
        switch(repetition){
            case 1: return <Single />
            case 2: return <Daily />
            case 3: return <Weekly />
            case 4: return <Monthly />
            case 5: return <Quarterly />
            case 6: return <Yearly />
            default: return <p><i>Ooouups! Her skjedde det en feil</i></p>

        }
    }

    const Single = () => {
        
        
        return (
            <div className="form-group">
                <label>Velg frist for utførelse</label>
                <MultiDatePicker 
                    dates={dates}
                    onChange={setDates}
                    minDate={moment().subtract(1, 'days')._d}
                />
            </div>
        )
    }
    
    const Daily = () => {
        return (
            <div className="form-group">
                <label>Velg fra- og til-dato</label>
                <DatePickerRange
                    startDate={startDate ? new Date(startDate) : null}
                    setStartDate={setStartDate}
                    endDate={endDate ? new Date(endDate) : null} 
                    setEndDate={setEndDate}
                    minDate={new Date()}
                />
            </div>
        )
    }

    const Weekly = () => {
        return (
            <div className="form-group">
                <label>Velg ukedag</label>
                <DropDown 
                    value={weekDay}
                    onChange={setWeekDay}
                    options={WEEK_OPTIONS}
                    selectFirst
                />
            </div>
            
        )
    }

    const Monthly = () => {
        return (
            <div className="form-group mb-3">
                <label>Velg dag i måneden</label>
                <DropDown 
                    value={monthDay}
                    onChange={setMonthDay}
                    options={DAY_OPTIONS}
                    selectFirst
                />
            </div>
        )
    }

    const Quarterly = () => {
        return (<div></div>)
        return (
            <div>
                <div className="form-group mb-3">
                    <label>Velg startmåned</label>
                    <DropDown 
                        value={month}
                        onChange={setMonth}
                        options={MONTH_OPTIONS}
                        selectFirst
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Velg dag i måneden</label>
                    <DropDown 
                        value={monthDay}
                        onChange={setMonthDay}
                        options={DAY_OPTIONS}
                        selectFirst
                    />
                </div>
            </div>
        )
    }

    const Yearly = () => {
        return (
            <div>
                <div className="form-group mb-3">
                    <label>Velg måned</label>
                    <DropDown 
                        value={month}
                        onChange={setMonth}
                        options={MONTH_OPTIONS}
                        selectFirst
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Velg dag i måneden</label>
                    <DropDown 
                        value={monthDay}
                        onChange={setMonthDay}
                        options={DAY_OPTIONS}
                        selectFirst
                    />
                </div>
            </div>
        )
    }

    //Repetition
    useEffect(() => {
        fireOnChange()
        updateRepetitionDescription()
    }, [repetition, startDate, endDate, weekDay, monthDay, month, dates])

    const fireOnChange = () => {

        const now = moment()
    
        let d = null
        let start = null
        let end = null
        switch(repetition){
            case 1:
                if(dates && dates.length){
                    if(dates.length === 1){
                        start = dates[0]
                    }else{
                        d = dates
                    }
                }
                
                
                break;
            case 2:
                start = startDate
                end = endDate
                break;
            case 3:
                start = moment().weekday(weekDay.value - 1)
                if(!start.isAfter(now)){
                    start.add(1, 'week')
                }
                break
            case 4:
                start = moment().date(monthDay.value)
                if(!start.isAfter(now)){
                    start.add(1, 'month')
                }
                break
            case 5:
                start = moment().month(2).date(31)
                break;
            case 6:
                start = moment().month(month.value - 1).date(monthDay.value)
                break;
        }
        setValue({
            repetition: repetition,
            startDate: start ? moment(start).format('YYYY-MM-DD') : null,
            endDate: end ? moment(end).format('YYYY-MM-DD') : null,
            weekDay: weekDay,
            monthDay: monthDay,
            month: month,
            dates: d && d.length ? d.map((date) => moment(date).format('YYYY-MM-DD')) : null
        })
    }

    const updateRepetitionDescription = () => {
        if(repetition){
            switch(repetition){
                case 1:
                    
                    if(!startDate && !dates || dates.length === 0){
                        setRepetitionDescription(<p>Oppgaven vil være åpen for utførelse når som helst</p>)
                    }else{
                        if(endDate && moment(startDate).format('YYYY MM DD') !== moment(endDate).format('YYYY MM DD')){
                            setRepetitionDescription(<p>Oppgaven må utføres alle dager mellom {moment(startDate).format('DD. MMMM')} og {moment(endDate).format('DD. MMMM')}</p>)
                        }else{
                            if(dates.length === 1){
                                setRepetitionDescription(<p>Oppgaven må utføres {moment(dates[0]).format('DD. MMMM')}</p>)
                            }else{
                                setRepetitionDescription(<p>Oppgaven utføres på gitte dager over</p>)
                            }
                            
                        }
                    }
                    break;
                case 2:
                    if(endDate && moment(startDate).format('YYYY MM DD') !== moment(endDate).format('YYYY MM DD')){
                        setRepetitionDescription(<p>Oppgaven må utføres alle dager mellom {moment(startDate).format('DD. MMMM')} og {moment(endDate).format('DD. MMMM')}</p>)
                    }else{
                        setRepetitionDescription(<p>Oppgaven må utføres daglig fra {moment(startDate).format('DD. MMMM')} frem til den arkiveres.</p>)
                    }
                    break;
                case 3:
                    if(weekDay){
                        setRepetitionDescription(<p>Oppgaven må utføres hver <strong>{moment().weekday(weekDay.value - 1).format('dddd')}</strong> til den arkiveres.</p>)
                    }
                    break;
                case 4:
                    if(monthDay){
                        setRepetitionDescription(<p>Oppgaven må utføres <strong>{moment().date(monthDay.value).format('DD')}. hver måned</strong> til den arkiveres</p>)
                    }else{
                        setRepetitionDescription(<p className="text-danger">Velg dag i måned</p>)
                    }
                    
                    break;
                case 5:
                    setRepetitionDescription(<p>Oppgaven må utføres <strong>siste dag i hvert kvartal</strong> til den arkiveres</p>)
                    break;
                case 6:
                    setRepetitionDescription(<p>Oppgaven må utføres <strong>{moment().date(monthDay.value).format('DD.')} {moment().month(month.value - 1).format('MMMM')} en gang i året</strong> til den arkiveres</p>)
                    break;
                default:
                    setRepetitionDescription(<p>Oppgaven vil være åpen for utførelse når som helst</p>)
            }
        }else{
            setRepetitionDescription(<p>Oppgaven vil være åpen for utførelse når som helst</p>)
        }
    }
    return (
        <div>
            <div className="form-group mb-3">
                <label>Gjentagelse</label>
                <RadioGroup
                    items={[
                        {label: 'Ingen', value: 1},
                        {label: 'Daglig', value: 2},
                        {label: 'Ukentlig', value: 3},
                        {label: 'Månedlig', value: 4},
                        {label: 'Kvartalsvis', value: 5},
                        {label: 'Årlig', value: 6}
                    ]}
                    selected={repetition}
                    select={setRepetition}
                />
            </div>
            {
                drawOptions()
            }
            {repetitionDescription}
        </div>
    )
}