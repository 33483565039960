import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { idText } from "typescript";
import FullscreenLoading from "../components/FullscreenLoading";
import { AccessContext } from "./AccessProvider";
import { AuthContext } from "./AuthProvider";
export const DomainContext = React.createContext({
    domainAccess: null,
    domainUsers: null,
    domainCompanies: null,
    domainPermissions: null,
    domainTeams: [],
    hasPermission: () => {},
    reloadDomainCompanies: () => {},
    loadTeams: () => {}
})

export const DomainProvider = ({children}) => {

    const { apiCall, domain, teams, company } = useContext(AuthContext)
    const { } = useContext(AuthContext)
    const [ready, setReady] = useState(false)

    const [domainAccess, setDomainAccess] = useState(false)
    const [domainUsers, setDomainUsers] = useState([])
    const [domainCompanies, setDomainCompanies] = useState([])
    const [domainPermissions, setDomainPermissions] = useState(null)
    const [domainTeams, setDomainTeams] = useState([])
    const [domainShortcuts, setDomainShortcuts] = useState([])
    const [unreadMessages, setUnreadMessages] = useState([])

    useEffect(() => {
        loadDomainSettings()
        loadUnreadMessages()
    }, [domain])


    const loadDomainSettings = async () => {
        setReady(false)
        const { data } = await apiCall({
            action: 'load-domain-settings',
        })
        if(data.status === 1){
            console.log('DomainSettings', domain)
            setDomainUsers(data.data.users)
            setDomainPermissions(data.data.permissions)
            setDomainCompanies(data.data.companies)
            setDomainTeams(data.data.teams)
            setDomainShortcuts(domain.properties?.shortcuts ? domain.properties?.shortcuts : [])
            setDomainAccess(true)
        }else{
            setDomainPermissions(null)
            setDomainCompanies([])
            setDomainUsers([])
            setDomainTeams([])
            setDomainAccess(false)
        }
        setTimeout(() => {
            setReady(true);
        }, 500)
    }

    const reloadDomainCompanies = async () => {
        
        const { data } = await apiCall({
            action: 'get-domain-companies',
        })
        if(data.status === 1){
            setDomainCompanies(data.data)
        }
    }

    const reloadDomainProperties = async () => {
        const { data } = await apiCall({
            action: 'get-domain-properties',
        })
        if(data.status === 1){
            if(data.data) setDomainShortcuts(data.data.shortcuts)
            
        }
    }

    const reloadDomainTeams = async () => {
        
    }

    const reloadDomainUsers = async () => {
        //Fokuser på å laste inn nye brukere når det oppdateres
        const { data } = await apiCall({action: 'get-domain-users'})
        if(data.status === 1){
            setDomainUsers(data.data)
        }
    }

    const loadUnreadMessages = async () => {
        const { data } = await apiCall({
            action: 'load-unread-domain-messages',
        })

        if(data.status === 1){
            setUnreadMessages(data.data)
        }
    }

    const loadTeams = async () => {
        const { data } = await apiCall({
            action: 'get-domain-teams',
        })

        if(data.status === 1){
            setDomainTeams(data.data)
        }
    }

    return <DomainContext.Provider value={{
        domainAccess,
        domainUsers,
        domainCompanies,
        domainPermissions,
        domainTeams,
        domainShortcuts,
        unreadMessages,
        hasPermission: (permissions) => {
            if(!domainPermissions) return false
            if(!Array.isArray(domainPermissions.permissions)) return false
            if(domainPermissions.permissions.includes('superAdmin')) return true
            return domainPermissions.permissions.some(r=> permissions.includes(r))
        },
        reloadDomainCompanies,
        loadTeams,
        reloadDomainUsers,
        reloadDomainTeams,
        reloadDomainProperties
    }}>
        {!ready ? <FullscreenLoading customText="Laster inn informasjon om området..."/> : (
            domainAccess || company ? children : <NoAccess />
        ) }
    </DomainContext.Provider>
}

function NoAccess(){
    const navigate = useNavigate()
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height: '100vh'}}>
            
            <div className="box p-4 px-5 text-center d-flex align-items-center flex-column">
                <div className="bg-danger-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span className="fa-stack fa-lg">
                        <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'xmark']} className="fa-stack-1x text-danger" />
                        <FontAwesomeIcon fixedWidth spin size="2x" icon={['fad', 'circle']} className="fa-stack-1x text-danger" />
                    </span>
                </div>
                <h4 className="bold">ÆÆÆ</h4>
                <p>Du har ikke tilgang til dette domenet</p>
                <Link to="/" className="btn btn-success">Gå tilbake</Link>
            </div>
        </div>
    )
}