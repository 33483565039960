import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import ImageCrop from "../components/ImageCrop";
import { AuthContext } from '../providers/AuthProvider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Input from '../components/Input';
import { DatePicker } from '../components/DatePicker';
import DropDown from '../components/DropDown';
import moment from 'moment'
import { ConfigContext } from '../providers/ConfigProvider';

export default function Profile(){
    const TabItem = ({ link, children }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={"tab-item " + (match ? "tab-item-active" : "")}>{children}</Link>
        )
    }

    return (
        
            <div className="p-md-3 p-2">
                <div className="transparent-box">
                    <div className="tab-container">
                        <div className="row gx-0">
                            <div className="col-auto">
                                <TabItem link="rediger">Rediger profil <FontAwesomeIcon style={{marginLeft: 10}} icon={['fal', 'user']} /></TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="endre-passord">Endre passord <FontAwesomeIcon style={{marginLeft: 10}} icon={['fal', 'lock']} /></TabItem>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
    )
}
export function EditProfile(){
    const navigate = useNavigate()
    const { user, uploadFile, apiCall, reloadUser } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)
    console.log(user)
    const save = async (values) => {
        
        const imageData = values.photo instanceof File ? await uploadFile(values.photo) : null


        const { data } = await apiCall({
            action: 'edit-profile', 
            photo: imageData,
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            role: values.role,
            dob: moment(values.dob).format('YYYY-MM-DD 12:00:00'),
            country: values.country,
        })
        
        notify(data.status, data.title, data.message)

        if(data.status === 1){
            reloadUser()
        }
    }

    const countryList = [
        {label: 'Norge', value: 'NO'},
        {label: 'Sverige', value: 'SE'},
        {label: 'Danmark', value: 'DK'}
    ]

    let schema = yup.object().shape({
        firstname: yup.string().required("Fornavn er påkrevd"),
        lastname: yup.string().required("Etternavn er påkrevd"),
        phone: yup.string().matches(/^[0-9]{8}$/, 'Telefonnummer må være 8 siffer').required("Telefonnummer er påkrevd"),
        role: yup.string().required("Stillingstittel / rolle er påkrevd"),
        email: yup.string().email("Må være en gyldig e-postadresse").required("e-postadresse er påkrevd"),
        country: yup.object().typeError("Land er påkrevd").required('Test'),
    });

    const formik = useFormik({
        initialValues: {
            firstname: user.firstname,
            lastname: user.lastname,
            phone: user.phone,
            role: user.role,
            email: user.email,
            photo: user.photo,
            dob: moment(user.dob),
            country: user.country

        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            save(values)
        },
    });

    return (
        <div className="p-md-4 p-3 bg-white">
            <button className="btn btn-link mb-3" onClick={() => navigate(-1)}>
                <div className="row gx-1">
                    <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                    <div className="col">Tilbake</div>
                </div>
            </button>
            <h3 className="bold">Rediger din profil</h3>

            <form onSubmit={formik.handleSubmit}>
                <div className="py-3">
                    <div className="row">
                        <div className="col">
                            <div className="py-3">
                                <ImageCrop
                                    img={formik.values.photo}
                                    setImg={(e) => formik.setFieldValue('photo', e)}
                                    height={120}
                                    aspect={1}
                                    unit={'profilbilde'}
                                />
                            </div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-success px-5">Lagre endringer</button>
                        </div>
                    </div>
                    
                </div>

                <div className="row mb-3">
                                <div className="col-md-6 mb-3">
                                    <Input
                                        name="firstname"
                                        label="Fornavn"
                                        type="text"
                                        value={formik.values.firstname}
                                        onChange={formik.handleChange}
                                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                        touched={formik.touched.firstname}
                                        helperText={formik.touched.firstname && formik.errors.firstname}
                                        placeholder={"Ditt fornavn"}
                                    />
                                        
                                    
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Input
                                        name="lastname"
                                        label="Etternavn"
                                        type="text"
                                        value={formik.values.lastname}
                                        onChange={formik.handleChange}
                                        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                        touched={formik.touched.lastname}
                                        helperText={formik.touched.lastname && formik.errors.lastname}
                                        placeholder={"Ditt etternavn"}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Input
                                        name="email"
                                        label="e-postadresse"
                                        type="text"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        touched={formik.touched.email}
                                        helperText={formik.touched.email && formik.errors.email}
                                        placeholder={"Din e-postadresse"}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Input
                                        name="phone"
                                        label="Telefonnummer"
                                        type="text"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        touched={formik.touched.phone}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                        placeholder={"Ditt telefonnummer"}
                                    />
                                </div>
                                
                                <div className="col-md-6 mb-3">
                                    <Input
                                        name="role"
                                        label="Stillingstittel"
                                        type="text"
                                        value={formik.values.role}
                                        onChange={formik.handleChange}
                                        error={formik.touched.role && Boolean(formik.errors.role)}
                                        touched={formik.touched.role}
                                        helperText={formik.touched.role && formik.errors.role}
                                        placeholder={"Din stillingstittel / rolle"}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Land</label>
                                        <DropDown
                                            options={countryList}
                                            value={formik.values.country}
                                            onChange={(e) => formik.setFieldValue('country', e)}
                                            placeholder="Velg land"
                                            selectFirst
                                            error={formik.touched.country && Boolean(formik.errors.country)}
                                            touched={formik.touched.country}
                                            helperText={formik.touched.country && formik.errors.country}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Fødselsdato</label>
                                        <DatePicker
                                            selected={formik.values.dob}
                                            onChange={(e) => formik.setFieldValue('dob', e)}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                                
                            </div>
            </form>
        </div>
    )
}

export function EditPassword(){
    const navigate = useNavigate()
    const { apiCall } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const save = async (values) => {
        const { data } = await apiCall({
            action: 'new-password', 
            old: values.old,
            new: values.new,
            repeat: values.repeat
        })
        
        notify(data.status, data.title, data.message)
    }

    let schema = yup.object().shape({
        old: yup.string().required("Gammelt passord påkrevd"),
        new: yup.string().required("Nytt passord påkrevd"),
        repeat: yup.string().oneOf([yup.ref('new'), null], 'Passordene må være like').required('Gjentakelse av passord er påkrevd')
    })

    const formik = useFormik({
        initialValues: {
            old: '',
            new: '',
            repeat: '',

        },
        validationSchema: schema,
        onSubmit: async (values) => {            
            save(values)
        },
    })

    return (
        <div className="bg-white p-md-4 p-3">
            <button className="btn btn-link mb-3" onClick={() => navigate(-1)}>
                <div className="row gx-1">
                    <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                    <div className="col">Tilbake</div>
                </div>
            </button>
            
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col">
                        <h3 className="bold">Endre passord</h3>
                    </div>
                    <div className="col-auto">
                        <button type="submit" className="btn btn-success px-5">Lagre endringer</button>
                    </div>
                </div>

                <Input
                    name="old"
                    label="Gammelt passord"
                    type="password"
                    value={formik.values.old}
                    onChange={formik.handleChange}
                    error={formik.touched.old && Boolean(formik.errors.old)}
                    touched={formik.touched.old}
                    helperText={formik.touched.old && formik.errors.old}
                    placeholder={"Ditt gamle passord"}
                />
                <Input
                    name="new"
                    label="Nytt passord"
                    type="password"
                    value={formik.values.new}
                    onChange={formik.handleChange}
                    error={formik.touched.new && Boolean(formik.errors.new)}
                    touched={formik.touched.new}
                    helperText={formik.touched.new && formik.errors.new}
                    placeholder={"Ditt nye passord"}
                />
                <Input
                    name="repeat"
                    label="Gjenta nytt passord"
                    type="password"
                    value={formik.values.repeat}
                    onChange={formik.handleChange}
                    error={formik.touched.repeat && Boolean(formik.errors.repeat)}
                    touched={formik.touched.repeat}
                    helperText={formik.touched.repeat && formik.errors.repeat}
                    placeholder={"Gjenta ditt nye passord"}
                />
            </form>
        </div>
    )
}