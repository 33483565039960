/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "flatpickr/dist/themes/material_green.css";
import { Norwegian } from "flatpickr/dist/l10n/no.js"
import Flatpickr from "react-flatpickr";
import React, { forwardRef, useState } from "react";
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import nb from 'date-fns/locale/nb';
import moment from 'moment'
registerLocale('nb', nb)
setDefaultLocale('nb')
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    const dateToString = () => {
        const split = value.split(' - ')

        if(split.length === 1) return moment(split[0]).format('DD. MMMM YYYY')

        if(split[1] === '') return moment(split[0]).format('DD. MMMM YYYY')

        return moment(split[0]).format('DD.MM') + ' til ' + moment(split[1]).format('DD.MM')
        
    }
    return(
    
    <div className="date-picker-container"  onClick={onClick} ref={ref}>
        <div className="row gx-2 align-items-center">
            <div className="col-auto">
                <FontAwesomeIcon icon={['far', 'calendar']} />
            </div>
            <div className="col">
                {value ?  
                    dateToString()
                : <span className="date-picker-placeholder">Velg dato</span>}
            </div>
            <div className="col-auto">
                <FontAwesomeIcon icon={['fas', 'caret-down']} />
            </div>
        </div>
    </div>
)})

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
    
    const dateToString = () => {

    

        if(Array.isArray(value)){

            if(value && !value[0]) return 'Velg datoer'
            if(value.length === 1){
                return `${moment(value[0]).format('DD.MM')} til ...`
            }else if(value.length === 2){
                return `${moment(value[0]).format('DD.MM')} til ${moment(value[1]).format('DD.MM')}`
            }
        }
        return moment(value).format('DD. MMMM YYYY')
        
    }
    return(
    
    <div className="date-picker-container"  ref={inputRef} {...props}>
        <div className="row gx-2 align-items-center">
            <div className="col-auto">
                <FontAwesomeIcon icon={['far', 'calendar']} />
            </div>
            <div className="col">
                {value ?  
                    dateToString()
                : <span className="date-picker-placeholder">Velg dato</span>}
            </div>
            <div className="col-auto">
                <FontAwesomeIcon icon={['fas', 'caret-down']} />
            </div>
        </div>
    </div>
    )
};

const MultiDateInput = ({ value, defaultValue, inputRef, ...props }) => {

    function makeString(arr) {
        if (arr.length === 1) return arr[0];
        const firsts = arr.slice(0, arr.length - 1);
        const last = arr[arr.length - 1];
        return firsts.join(', ') + ' og ' + last;
      }

    const datesToString = () => {
        let array = []

        value.forEach(element => {
            array.push(moment(element).format('DD.MM'))
        });

        return makeString(array)
    }
    return(
    
    <div className="date-picker-container"  ref={inputRef} {...props}>
        <div className="row gx-2 align-items-center">
            <div className="col-auto">
                <FontAwesomeIcon icon={['far', 'calendar']} />
            </div>
            <div className="col">
                {value.length ?  
                    datesToString()
                : <span className="date-picker-placeholder">Velg dato(er)</span>}
            </div>
            <div className="col-auto">
                <FontAwesomeIcon icon={['fas', 'caret-down']} />
            </div>
        </div>
    </div>
    )
    return <input {...props} defaultValue={defaultValue} ref={inputRef} />;
};

export function DatePicker({ selected, onChange, minDate, ...props }){
    
    return (
        <div>
            <Flatpickr
                value={selected}
                onChange={([date]) => {
                    onChange(date)
                }}
                render={
                    ({defaultValue, value, ...props}, ref) => {
                    return <CustomInput value={value} defaultValue={defaultValue} inputRef={ref} {...props} />
                }}
                options={{
                    locale: Norwegian,
                    minDate: minDate
                }}
                {...props}
            />
        </div>
    )
}

export function MultiDatePicker({ dates, onChange, minDate }){

    const [ value, setValue ] = useState(dates)


    return (
        <Flatpickr
            value={dates}
            onChange={(dates) => {
                onChange(dates)
            }}
            render={
                ({defaultValue, value, ...props}, ref) => {
                return <MultiDateInput value={value} defaultValue={defaultValue} inputRef={ref} {...props} />
            }}
            options={{
                locale: Norwegian,
                minDate: minDate,
                mode: 'multiple'
            }}
        />
    )
}

export function DatePickerRange({ startDate, setStartDate, endDate, setEndDate, minDate }){

    const [ value, setValue ] = useState([startDate, endDate])


    return (
        <Flatpickr
            value={value}
            onChange={(dates) => {
                
                setValue(dates)

                if(dates.length === 2){
                    setStartDate(dates[0])
                    setEndDate(dates[1])
                }
                
                /*
                setValue([start, end])
                if(start) setStartDate(startDate)
                if(end) setEndDate(end)
                */
            }}
            render={
                ({defaultValue, value, ...props}, ref) => {
                return <CustomInput value={value} defaultValue={defaultValue} inputRef={ref} {...props} />
            }}
            options={{
                locale: Norwegian,
                minDate: minDate,
                mode: 'range'
            }}
        />
    )
    const onChange = (dates) => {
        console.log('date changed', dates)
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    return (
        <ReactDatePicker
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          customInput={<ExampleCustomInput />}
          minDate={minDate}
          selectsRange
        />
      );
}