import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import Drawer from "../../../components/Drawer"
import PersonModal from "../../../components/PersonModal"
import UserPhoto from "../../../components/UserPhoto"
import { DomainContext } from "../../../providers/DomainProvider"
import { NewRoomContext } from "../../../providers/NewRoomProvider"

export default function DomainNewRoomUsers(){

    const { formik, setUserDrawerOpen } = useContext(NewRoomContext)
    const { domainUsers } = useContext(DomainContext)

    const [open, setOpen] = useState(false)

    const removeUser = (el) => {
        formik.setFieldValue("newUsers", formik.values.newUsers.filter(u => u !== el))
    }

    return (
        <div className="bg-white p-md-4 p-3">
            <div className="row justify-content-between mb-5">
                <div className="col-auto">
                    <h2 className="bold">Administrer medlemmer</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-4" type="submit">Lagre</button>
                </div>
            </div>
            
            <h4 className="bold">Opprett medlemmer</h4>
                
            <div className="border-lg mb-md-5 mb-2">
                <div className="p-md-4 p-3">
                    Opprett aktive medlemmer som vil ha tilgang til rommet
                    <div className="pt-4">
                        <div className="row">
                            <div className="col-auto">
                                <button type="button" className="btn btn-dashed" onClick={() => setUserDrawerOpen(true)}>
                                    <div className="row gx-1">
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['fal', 'plus']} />
                                        </div>
                                        <div className="col">
                                            <span>Nytt medlem</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-dashed" onClick={() => setOpen(true)}>
                                    <div className="row gx-1">
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['fal', 'plus']} />
                                        </div>
                                        <div className="col">
                                            <span>Legg til</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        
                        
                    </div>
                    <Drawer
                        open={open}
                        setOpen={setOpen}
                    >
                        <PersonModal
                            users={domainUsers}
                            responsibleUsers={formik.values.members}
                            setResponsibleUsers={(a) => formik.setFieldValue('members', a)}
                            setOpen={setOpen}
                        />
                    </Drawer>
                </div>
                {
                    formik.values.newUsers.map((el, key) => {
                        
                        return (
                            <div className="border-top px-3 py-2" key={key}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <UserPhoto name={el.firstname} size={[40, 40, 40]} />
                                    </div>
                                    <div className="col">
                                        <p className="m-0">{el.firstname} {el.lastname}</p>
                                    </div>
                                    <div className="col-auto">
                                        <p className="m-0">{el.permissionTemplate ? el.permissionTemplate.label : 'Ingen brukerrolle valgt'}</p>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-link text-danger" onClick={() => removeUser(el)}>Fjern</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    formik.values.members.length > 0 && formik.values.members.map((el, key) => {
                        return (
                            <div className="border-top px-3 py-2" key={key}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <UserPhoto name={el.fullname} photo={el.photo} size={[40, 40, 40]} />
                                    </div>
                                    <div className="col">
                                        <p className="m-0">{el.fullname}</p>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-link text-danger" onClick={() => formik.setFieldValue('members', formik.values.members.filter(c => c.id !== el.id))  }>Fjern</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            
        </div>
    )
}