import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import DomainNewCompany from "../pages/domain/area/DomainNewCompany"
import { AuthContext } from "../providers/AuthProvider"
import { ConfigContext } from "../providers/ConfigProvider"
import { useOnClickOutside } from "./Functions"
import UserPhoto from "./UserPhoto"

export default function ContextPicker(){
    const { paths, contexts, company, domain, notifications } = useContext(AuthContext)
    const { baseUrl } = useContext(ConfigContext)

    const navigate = useNavigate()
    
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState(contexts)

    const [activeContext, setActiveContext] = useState(null)
    
    const [selectedDomain, setSelectedDomain] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [otherNotificationCount, setOtherNotificationCount] = useState(0)

    const searchRef = useRef()
    const ref = useRef()
    useOnClickOutside(ref, () => setOpen(false))

    useEffect(() => {
        if(contexts){
            if(domain) {
                setSelectedDomain(contexts.filter(d => d.id === domain.id)[0])
            }else{
                setSelectedDomain(contexts.filter(d => d.id === 0)[0])
            }
        }
        
    }, [open])

    useEffect(() => {
        if(company && selectedDomain){
            setSelectedCompany(selectedDomain.companies.filter(c => c.id === company.id)[0])
        }
    }, [selectedDomain])

    useEffect(() => {
        if(notifications && notifications.length){
            setOtherNotificationCount(notifications?.filter(r => !r.isRead).filter(n => {
                if(activeContext?.company){
                    return n.company?.id !== activeContext.company
                }

                if(activeContext?.domain) return n.domain.id !== activeContext.domain
            }).length)
        }
    }, [notifications, domain, company])

    useEffect(() => {
        if(open){
            if(searchRef.current){
                searchRef.current.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if(contexts){
            setFilter(contexts.filter(domain => {
                //Returnerer true hvis navnet på domenet matcher
                if(domain.name.match(new RegExp(search, 'i'))) return true
                return domain.companies.filter(company => company.name.match(new RegExp(search, 'i'))).length
            
            }))
        }
    }, [search])

    
    useEffect(() => {
        setOpen(false)
        if(company && company.contextMember){
            setActiveContext({
                domain: domain.id,
                company: company.id,
                name: company.name,
                image: company.image
            })
            return
        }
        if(domain){
            setActiveContext({
                domain: domain.id,
                name: domain.name,
                image: domain.config.logoSmall
            })
            return
        }
        
    }, [company, domain])

    useEffect(() => {
        setSelectedCompany(null)
    }, [selectedDomain])


    const Domain = ({ domain }) => {
        const navigate = useNavigate()
        const [notificationCount, setNotificationCount] = useState(0)
        
        useEffect(() => {
            if(notifications && notifications.length){
                setNotificationCount(notifications.filter(n => {
                    if(!n.domain) return false
                    if(!domain) return false
                    if(n.domain.id === domain.id) return true
                }).filter(r => !r.isRead).length)
            }   
        }, [])

        const onClick = () => {
            setSelectedDomain(domain)

            if(selectedDomain === domain && domain.contextMember){
                navigate(`/${domain.hash}/oversikt`)
            }
        }


        return (
            <div className={`p-md-3 p-1 pointer mb-1 border ${selectedDomain && domain.id === selectedDomain.id ? "bg-success-tint border-success text-success" : (activeContext.domain === domain.id ? 'bg-success-tint border-transparent' : 'border-transparent hover-lightgrey')}`} style={{borderRadius: 5}} 
                onClick={() => onClick()}
            >
                <div className="row align-items-center gx-2">
                    <div className="col-auto">
                        <UserPhoto name={domain.name} photo={domain.config.logoSmall} size={[30, 30, 30]} />
                    </div>
                    <div className="col">
                        <h6 className={`m-0 ${activeContext.domain === domain.id ? 'bold' : ''}`}>{domain.name}</h6>
                    </div>
                    { notificationCount ? 
                        <div className="col-auto">
                            <div className="notification-badge" style={{height: 30, width: 30, position: 'relative', transform: 'none'}}>
                                <span style={{fontSize: 16}}>{notificationCount}</span>
                            </div>
                        </div>
                        : null
                    }
                    
                </div>
            </div>
        )
    }

    const Company = ({ company }) => {

        

        const navigate = useNavigate()
        const [notificationCount, setNotificationCount] = useState(0)
        
        useEffect(() => {
            if(notifications && notifications.length){
                setNotificationCount(notifications.filter(n => {
                    if(n.company){
                        return n.company.id === company.id
                    }
                    return false
                }).filter(r => !r.isRead).length)
            }
        }, [])

        const onClick = () => {
            setSelectedCompany(company)

            if(selectedCompany === company && company.contextMember){
                navigate(`/${selectedDomain.hash}/${company.hash}/oversikt`)
            }
        }

        return (
            <div className={`p-md-3 p-1 pointer mb-1 border ${selectedCompany && company.id === selectedCompany.id ? "bg-success-tint border-success text-success" : (activeContext.company === company.id ? 'bg-success-tint border-transparent' : 'border-transparent hover-lightgrey')}`} style={{borderRadius: 5}} 
                onClick={() => onClick()}
            >
                <div className="row align-items-center gx-2">
                    <div className="col-auto">
                        <UserPhoto name={company.name} photo={company.image} size={[30, 30, 30]} />
                    </div>
                    <div className="col">
                        <h6 className="m-0">{company.name}</h6>
                    </div>
                    { notificationCount ? 
                        <div className="col-auto">
                            <div className="notification-badge" style={{height: 30, width: 30, position: 'relative', transform: 'none'}}>
                                <span style={{fontSize: 16}}>{notificationCount}</span>
                            </div>
                        </div>
                        : null
                    }
                    
                </div>
            </div>
        )
    }
    
    return (
        <div className="company-picker " ref={ref}>
            <div className={`company-picker-container  position-relative pointer ${open ? 'picker-open' : ''} `}  onClick={() => setOpen(!open)}>
                { activeContext ? (
                <div className="row align-items-center gx-md-3 gx-0 w-100">
                    <div className="col-auto">
                        <UserPhoto name={activeContext.name} photo={activeContext.image} size={[30, 30, 30]} />
                    </div>
                    <div className="col">
                        <h6 className="m-0 pr-4 d-none d-md-block">
                            {
                                activeContext && activeContext.name ? activeContext.name : 'Velg område'
                            }
                        </h6>
                    </div>
                    <div className="col-auto">
                        <div className="d-md-flex flex-column  d-none ">
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-up']} />
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-down']} />
                        </div>
                        
                    </div>
                    {otherNotificationCount ? <div className="context-picker-notification-bubble">{otherNotificationCount}</div> : null}
                </div>) : (
                    <div className="row align-items-center gx-3">
                    
                    <div className="col">
                        <h6 className="m-0 pr-4">Velg område</h6>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex flex-column">
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-up']} />
                            <FontAwesomeIcon size="xs" icon={['fal', 'chevron-down']} />
                        </div>
                        
                    </div>
                </div>
                )}
            </div>
            <CSSTransition in={open} timeout={250} classNames="notification-center-wrapper" unmountOnExit>
                <div className="company-picker-dropdown">
                    <div className="box p-md-3 p-2">
                        <div className="position-relative faux-placeholder">
                            <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                            <input ref={searchRef} value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form-control mb-3" placeholder="Søk.."/>
                        </div>
                        <div className="row context-picker-row">
                            <div className={`${selectedDomain && selectedDomain.companies && selectedDomain.companies.length ? 'col-6' : 'col'}`}>
                                <label className="caps hint-text">Domene</label>
                                <div className="scrollinside" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                                {
                                    filter && filter.map((domain, key) => {
                                        return <Domain key={key} domain={domain} select={setSelectedDomain}  />
                                    })
                                }
                                </div>
                                {
                                    selectedDomain && selectedDomain.contextMember && activeContext.domain !== selectedDomain.id ?  <button onClick={() => navigate(`/${selectedDomain.hash}/oversikt`)} type="button" className="btn btn-primary w-100">Gå til domene</button> : null
                                }
                               
                            </div>
                            {
                                
                                selectedDomain && selectedDomain.companies && selectedDomain.companies.length ? 
                                (
                                    <div className="col-6">
                                        <label className="caps hint-text">Område</label>
                                        {
                                            selectedDomain.companies.map((company, key) => {
                                                return <Company key={key} company={company} />
                                            })
                                        }
                                        {
                                            selectedCompany && selectedCompany.contextMember && activeContext.company !== selectedCompany.id ? <button onClick={() => navigate(`/${selectedDomain.hash}/${selectedCompany.hash}/oversikt`)} type="button" className="btn btn-primary w-100">Gå til område</button> : null
                                        }
                                    </div>
                                ) : null
                            }
                            
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
    
}

