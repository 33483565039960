/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useState } from 'react';
import FileUpload from '../../../components/FileUpload';
import { useEffect } from 'react';
import RadioGroup from '../../../components/RadioGroup';
import { useContext } from 'react';
import { ConfigContext } from '../../../providers/ConfigProvider';


import IconCheck from '../../../components/IconCheck';
import Drawer from '../../../components/Drawer';
import { AuthContext } from '../../../providers/AuthProvider';
import PersonPicker from '../../../components/PersonPicker';
import { CompanyContext } from '../../../providers/CompanyProvider';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from '../../../components/Input';
import SubtaskProvider from '../../../components/Subtask';
import FormBuilderProvider from '../../../components/FormBuilder';
import TaskRepetition from '../../../components/TaskRepetition';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DraftWysiwyg from '../../../components/DraftWysiwyg';
import BoxLoading from '../../../components/BoxLoading';

const Context = createContext({
    form: null,
    subtasks: [],
    tempateForms: [],
    addSubTask: () => {},
    setForm: () => {},
    createTask: () => {},
})

const Provider = ({children}) => {
    const { apiCall } = useContext(AuthContext)
    const { hasCompanyPermission } = useContext(CompanyContext)
    const [form, setForm] = useState({})
    const [subtasks, setSubtasks] = useState([])
    const [template, setTemplate] = useState(null)
    const [allReady, setAllReady] = useState(false)
    const [templateForms, setTemplateForms] = useState([])

    const { notify } = useContext(ConfigContext)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const loadTemplate = async (id) => {
        const { data } = await apiCall({
            action: 'get-domain-template-task',
            id: id
        })
        if(data.status === 1){

            console.log(data.data)
            setTemplate(data.data)
            setSubtasks(data.data.subtasks)
            setForm(data.data.form ? data.data.form : {})
            setAllReady(true)
        }
    }

    const getTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-company-template-forms'});
        
        if(data.status === 1){
            setTemplateForms(data.data)
        }
    }

    useEffect(() => {
        getTemplateForms()
    }, [])
    
    useEffect(() => {
        if(searchParams.get('mal')){
            loadTemplate(searchParams.get('mal'))
        }else{
            setAllReady(true)
        }
    }, [searchParams])

    return <Context.Provider value={{
        form,
        subtasks,
        template,
        templateForms,
        addSubTask: (task, index) => {
            if(index === -1){
                setSubtasks([...subtasks, task])
            }else{
                const newSubTasks = subtasks
                newSubTasks[index] = task
                setSubtasks(newSubTasks)
            }
        },
        setForm: (form) => {
            console.log('setting form', form)
            setForm(form)
        },
        createTask: async (task) => {
            
            

            const status = await apiCall({
                action: 'create-company-task', 
                name: task.name,
                files: task.files,
                description: task.description,
                interval: task.interval,
                signature: task.signature,
                priority: task.priority,
                responsible: task.responsible,
                form: form ? form : null,
                subtasks: subtasks && subtasks.length ? subtasks : null

            })


            notify(status.data.status, 'Suksess!', status.data.message)

            if(status.data.status === 1) navigate(-1)

        }
    }}>{allReady ? ( hasCompanyPermission('adminTask') ? children : <NoAccess />) : <BoxLoading />}</Context.Provider>
}

function NoAccess(){

    const navigate = useNavigate()

    return (
        <div className="py-5 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="box d-flex flex-column justify-content-center align-items-center p-5">
            <div className="bg-danger mb-3" style={{width: 90, height: 90, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <FontAwesomeIcon fixedWidth size="3x" icon={['fad', 'xmark']} className="text-white" />
            </div>
            <div className="text-center">
                <h3 className="bold text-primary">Ingen tilgang</h3>
                <p>Du har ikke tilgangen som kreves for å lage oppgaver</p>
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Tilbake</button>
            </div>
            </div>
        </div>
    )
}

export default function NewCompanyTask(){
    return (
        <Provider>
            <CreateTask />
        </Provider>
    )
}

function CreateTask(){
    const { form, subtasks, template, createTask } = useContext(Context)
    const { companyUsers, consultants } = useContext(CompanyContext)

    const [formDrawerOpen, setFormDrawerOpen] = useState(false)
    const [subTaskDrawerOpen, setSubTaskDrawerOpen] = useState(false)

    let schema = yup.object().shape({
        name: yup.string().required("Oppgavenavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            name: template ? (template.display_name ? template.display_name : template.name) : '',
            priority: false,
            signature: 1,
            interval: template ? template.deadline : {
                repetition: 1,
                startDate: null,
                endDate: null
            },
            description: template ? template.description : null,
            responsible: [],
            files: template ? template.attachments : []
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            //console.log(values)          
            saveTask(values)
        },
    });




    const saveTask = (data) => {
        createTask({
            name: data.name,
            files: data.files,
            description: data.description,
            interval: data.interval,
            signature: data.signature,
            priority: data.priority,
            responsible: data.responsible,
            form: form,
            subtasks: subtasks
        })
    }
    return (
        <div className="p-3">
            <div className="box p-4">
                <h3 className="bold mb-5">Opprett oppgave</h3>
                <hr/>
                <div className="row mb-3">
                    <div className="col-auto">
                        <div onClick={() => setFormDrawerOpen(true)} className="border p-3 pointer" style={{borderRadius: '5px'}}>
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <FontAwesomeIcon className={form && Object.keys(form).length ? "text-primary" : ""} size='2x' icon={['fal', 'clipboard']} />
                                </div>
                                <div className="col">
                                    <h6 className='bold m-0'>Skjema</h6>
                                    
                                    {
                                        Object.keys(form).length ? <i>Rediger skjema</i>
                                        : <i className="hint-text">Legg til skjema</i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div onClick={() => setSubTaskDrawerOpen(true)} className="border p-3 pointer" style={{borderRadius: '5px'}}>
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <FontAwesomeIcon className={subtasks && subtasks.length ? "text-primary" : ""} size='2x' icon={['fal', 'network-wired']} />
                                </div>
                                <div className="col">
                                    <h6 className='bold m-0'>Underoppgaver</h6>
                                    {
                                        subtasks && subtasks.length ? <i>{subtasks.length} underoppgaver</i>
                                        : <i className="hint-text">Legg til</i>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-8">
                            <Input
                                name="name"
                                label="Navn"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                touched={formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={"Navn på oppgave"}
                            />
                            <div className="form-group mb-3">
                                <label>Beskrivelse</label>
                                <DraftWysiwyg
                                    value={formik.values.description}
                                    setValue={(e) => formik.setFieldValue('description', e)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Vedlegg</label>
                                <FileUpload
                                    files={formik.values.files}
                                    setFiles={(e) => formik.setFieldValue('files', e)}
                                />
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="form-group mb-3">
                                <label>Velg ansvarlig</label>
                                <PersonPicker
                                    items={[...companyUsers, ...consultants]}
                                    selected={formik.values.responsible}
                                    setSelected={(e) => formik.setFieldValue('responsible', e)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Signering</label>
                                <RadioGroup
                                    items={[
                                        {label: 'Ingen signering', value: 1},
                                        {label: 'Krev signatur av ansvarlige', value: 2},
                                    ]}
                                    selected={formik.values.signature}
                                    select={(e) => formik.setFieldValue('signature', e)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Prioritet</label>
                                <IconCheck
                                    checked={formik.values.priority}
                                    setChecked={(e) => formik.setFieldValue('priority', e)}
                                    icon="flag"
                                    color="danger"
                                    classes="mb-2"
                                />
                                <p><i>{ formik.values.priority ? 'Oppgaven er merket som prioritert' : 'Oppgaven har ingen spesiell prioritet'} </i></p>
                            </div>
                            <TaskRepetition
                                value={formik.values.interval}
                                setValue={(e) => formik.setFieldValue('interval', e)}
                            />
                        </div>
                    </div>
                    <div className="row mt-5 justify-content-center">
                        
                        <div className="col-5">
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary d-block">Lagre oppgave</button>
                            </div>
                        </div>
                    </div>
                </form>
                <Drawer open={formDrawerOpen} setOpen={setFormDrawerOpen} >
                    <FormBuilderProvider currentContext={Context} />     
                </Drawer>
                <Drawer open={subTaskDrawerOpen} setOpen={setSubTaskDrawerOpen}>
                    <SubtaskProvider TaskContext={Context} />        
                </Drawer>
            </div>
        </div>
    )
    
}