import React, { useEffect, useRef, useState } from "react"

export default function Range({ value, setValue, min, max, disabled}){

    const ref = useRef()

    
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        if(!value){
            setValue(min)
        }
    }, [])
    useEffect(() => {
        
        if(ref.current){
            setOffset(((ref.current.offsetWidth / max) * value) - (25 - value) )
        }
    }, [value, ref])

   

    return (
        <div class="range-container" ref={ref}>
            <input type="range" min={min} max={max} value={value} class="range" onChange={(e) => setValue(e.target.value)} disabled={disabled} />
            <div className="range-value" style={{left: offset}}>{value}</div>
        </div>
    )
}