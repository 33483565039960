/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { createRef, useContext, useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";

export default function ImageUpload(props){

    const { accessToken, company, domain } = useContext(AuthContext)
    const { notify, apiUrl } = useContext(ConfigContext)
    const { files, setFiles } = props
    const container = createRef()
    const [dragging, setDragging] = useState(false)
    const [mousePosition, setMousePosition] = useState({})

    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*')
    fileSelector.addEventListener('change', (e) => {
        e.preventDefault();
        handleDrop(e.path[0].files)
    })
    useEffect(() => {

    }, [files])
    
    useEffect(() => {
        if(container.current){
            ['dragenter', 'dragover'].forEach(eventName => {
                
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(true)
                        /*
                        setMousePosition({
                            position: 'fixed',
                            top: e.pageY,
                            left: e.pageX
                        })
                        */
                    }
                }, false)
                
            });

            ['dragleave'].forEach(eventName => {
                
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(false)
                    }
                }, false)
                
            })

        
            container.current.addEventListener('drop', (e) => {
                e.preventDefault()
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    setDragging(false)
                    handleDrop(e.dataTransfer.files)
                    e.dataTransfer.clearData()  
                }
                
            })
        }

        return () => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                if(container.current){
                    container.current.removeEventlistener(eventName)
                }
            })
        }
    }, [container])

    const uploadFile = async (file) => {
        const formData = new FormData();

        formData.append('accessToken', accessToken)
        formData.append('action', 'upload-single-attachment')
        formData.append('files[]', file)
        
        if(company) formData.append('company', company.id)
        if(domain) formData.append('domain', domain.id)

        const { data } = await axios({
            method: 'post',
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })

        if(data.status !== 1){
            notify(data.status, data.title, data.message)
            return null
        }

        return data.data

    }

    const handleDrop = async (fileList) => {
        let newFiles = []
        for (var i = 0; i < fileList.length; i++) {
			if(!files.some(file => file.name === fileList[i].name)){
                

                const data = await uploadFile(fileList[i])

                

                if(data){
                    newFiles = [...files, data]
                }
                
                
				
			}
		}


        setFiles(newFiles)
    }

    const removeFile = (id) => {
        setFiles(files.filter(f => f.id !== id))
    }

    const FileIcon = ({type}) => {
        switch(type){
            case 'image/jpeg':
                return <FontAwesomeIcon icon={['fal', 'file-image']} size="2x" fixedWidth />

            default:
                return <FontAwesomeIcon icon={['fal', 'file-lines']} size="2x" fixedWidth />

        }
    }

    const FileItem = ({ id, file_name, file_type, file_size }) => {
                
        return (
            
                <div className="file-item">
                    <div className=" row align-items-center gx-1">
                        <div className="col-auto">
                            <FileIcon type={file_type} />
                        </div>
                        <div className="col">
                            <h5 className="m-0">{file_name}</h5>
                        </div>
                        <div className="col-auto">
                            <small className="text-danger pointer"  onClick={() => removeFile(id)}>Fjern</small>
                        </div>
                    </div>
                </div>
            
        )
    }
    
    
    return (
        <div>
            <div  ref={container} className={"file-upload-container mb-1 " + (dragging ? 'highlight' : '')}
            onClick={(e) => {
                e.preventDefault();
                fileSelector.click();
            }}>
                
                <div className="file-upload-text text-center">
                    <div className="d-block my-3 text-primary">
                        <FontAwesomeIcon icon={['far', 'folder-arrow-up']} size="2x"  fixedWidth />
                    </div>
                    <span>Dra og slipp, eller trykk her for å laste opp</span>
                </div>
                
            </div>
            { files.length > 0 ? 
                (<div className="file-wrapper">
                    {
                        files.map((el, index) => {
                            return (
                                <FileItem 
                                    key={index}
                                    {...el}
                                />)
                            })  
                    }
            
                </div>)
            : null }
        </div>
    )
}
