/* eslint-disable */
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"

export default function Attachments({ files }){
    
    const { downloadAttachment } = useContext(AuthContext)
    const Attachment = ({file_name, id}) => {

        const download = async () => {
            downloadAttachment(id, file_name)
        }

        return (
            <div className="file-item pointer hover-primary" onClick={() => download()}>
                <div className=" row align-items-center gx-2">
                    <div className="col-auto">
                        <FontAwesomeIcon icon={['far', 'paperclip-vertical']} size="lg" />
                    </div>
                    <div className="col">
                        <h6 className="m-0">{file_name}</h6>
                    </div>
                </div>
            </div>
        )
    }

    if(!files || files.length === 0){
        return (<div></div>)
    }

    return (
        <div className="file-wrapper">
            {
                files.map((item, key) => {
                    return (
                        <Attachment 
                            key={key}
                            {...item}
                        />
                    )
                })
            }
        </div>
    )
    
}

