/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention'
import { Entry } from './Functions';
import { CompanyContext } from '../providers/CompanyProvider';


export default function TaskEditor({ value, setValue, context}){

	const { responsibles, data } = useContext(context)
    
    const [editorState, setEditorState] = useState(value ? () => EditorState.createWithContent(convertFromRaw(value)) : () => EditorState.createEmpty())
	const [open, setOpen] = useState(false);
  	const [suggestions, setSuggestions] = useState(data.responsibles);

	const [mentionOpen, setMentionOpen] = useState(false);

    useEffect(() => {
    	setValue(convertToRaw(editorState.getCurrentContent()))
		console.log(responsibles)
    }, [editorState])

	useEffect(() => {
		if(value === null){
			setEditorState(() => EditorState.createEmpty())
		}
	}, [value])
    
    const editor = useRef(null)
  
	const { MentionSuggestions, plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
            mentionComponent(mentionProps) {
                
                return (
                    <span
                      className={mentionProps.className}
                      // eslint-disable-next-line no-alert
                    >
                      @{mentionProps.mention.fullname}
                    </span>
                )
            }
        });
		// eslint-disable-next-line no-shadow
		const { MentionSuggestions } = mentionPlugin;
		// eslint-disable-next-line no-shadow
		const plugins = [mentionPlugin];
		return { plugins, MentionSuggestions };
	  }, []);


	const onOpenMentionChange = useCallback((_open) => setMentionOpen(_open), [])
	const onSearchMentionChange = useCallback(({ value }) =>  setSuggestions(defaultSuggestionsFilter(value, data.responsibles)), [])

	const onOpenChange = useCallback((_open ) => {
		setOpen(_open);
	  }, []);

	const onSearchChange = useCallback(({ trigger, value }) => {
		setSuggestions(() => {
			var v = value.toLowerCase();
			var triggerSuggestions = data.responsibles;
			var filteredSuggestions = triggerSuggestions.filter(function (suggestion) {
				return !v || suggestion.fullname.toLowerCase().indexOf(v) > -1;
			});
			var length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
			return filteredSuggestions.slice(0, length);
			  
		})
	}, []);
   
    return (
        <div className="draft-editor-container" onClick={() => editor.current.focus()}>
            <Editor 
				editorState={editorState} 
				onChange={setEditorState} 
				ref={editor} 
				plugins={plugins}
				entryComponent={Entry}
			/>
			<MentionSuggestions
				open={mentionOpen}
				onOpenChange={onOpenMentionChange}
				suggestions={suggestions}
				onSearchChange={onSearchMentionChange}
				entryComponent={Entry}
			/>
		</div>
    )
}