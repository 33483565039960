import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { ConfigContext } from "../providers/ConfigProvider";

export default function NavItem({ item, location }){
    
    const { toggleNav, navOpen } = useContext(ConfigContext)
    const { name, icon, link, children, notifications } = item
   
    let resolved = useResolvedPath(link);
    let match = useMatch({ path: resolved.pathname, end: false });
    let parentMatch = useMatch({ path: resolved.pathname, end: true })
    
    return (
        <li className={match || parentMatch ? 'active' : ''}>
            <div>
                <NavLink className="navitem" onClick={() => {
                    if(navOpen){
                        toggleNav()
                    }
                }} to={link}>
                    <div className="col">{icon && <FontAwesomeIcon fixedWidth icon={['far', icon]} style={{fontSize: '1.25rem'}} className="left-icon" />}
                <span>{name}</span>
                </div>
                {
                    notifications >= 1 ? <div className="notification-bubble"><FontAwesomeIcon icon={['fas', 'circle']} size="lg" className="text-danger" /><span>{notifications}</span></div> : null
                }</NavLink>
                
            </div>
        </li>
    )
}