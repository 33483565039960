/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { ConfigContext } from "../providers/ConfigProvider";
import Drawer from "./Drawer";
import Tooltip from "./Tooltip";
import UserPhoto from "./UserPhoto";
export default function PersonModal({ 
    users, 
    companies, 
    teams, 
    responsibleUsers,
    setResponsibleUsers,
    responsibleCompanies,
    setResponsibleCompanies,
    responsibleTeams,
    setResponsibleTeams,
    setOpen
}){

    const ref = useRef()

    const [search, setSearch] = useState('')
    const [showDropdown, setShowDropdown] = useState(false)
    const [filter, setFilter] = useState(null)
    const [filterTypes, setFilterTypes] = useState([])
    const [isAllChecked, setIsAllChecked] = useState(false)

    useEffect(() => {
        const array = []
        if(users && users.length){
            array.push({label: 'Personer', value: 'persons'})
        }
        if(teams && teams.length){
            array.push({label: 'Team', value: 'teams'})
        }
        if(companies && companies.length){
            array.push({label: 'Områder', value: 'companies'})
        }

        setFilterTypes(array)
        
    }, [companies, teams, users ])

    useEffect(() => {
        if(!filter){
            if(filterTypes[0]){
                setFilter(filterTypes[0].value)
            }
        }
    }, [filterTypes])

    useEffect(() => {
        switch(filter){
            case 'persons':
                setIsAllChecked(responsibleUsers.length === users.length)
                break;
            case 'companies':
                setIsAllChecked(responsibleCompanies.length === companies.length)
                break;
            default:
                setIsAllChecked(false)
        }
    }, [filter, responsibleUsers, responsibleCompanies])

    const checkAll = () => {
        
        switch(filter){
            case 'persons':
                setResponsibleUsers(users)
                break;
            case 'companies':
                setResponsibleCompanies(companies)
                break;
            default:
        }

        
    }

    const unCheckAll = () => {
        switch(filter){
            case 'persons':
                setResponsibleUsers([])
                break;
            case 'companies':
                setResponsibleCompanies([])
                break;
            default:
        }
    }

    const updateUsers = (item) => {
        if(responsibleUsers.filter(e => e.id === item.id).length > 0){
            setResponsibleUsers(responsibleUsers.filter(e => e.id !== item.id))
        }else{
            setResponsibleUsers([...responsibleUsers, item])
        }
    }

    const updateTeams = (item) => {
        if(responsibleTeams.filter(e => e.id === item.id).length > 0){
            setResponsibleTeams(responsibleTeams.filter(e => e.id !== item.id))
        }else{
            setResponsibleTeams([...responsibleTeams, item])
        }
    }

    const updateCompanies = (item) => {
        const { id, name, photo } = item
        if(responsibleCompanies.filter(e => e.id === item.id).length > 0){
            setResponsibleCompanies(responsibleCompanies.filter(e => e.id !== item.id))
        }else{
            setResponsibleCompanies([...responsibleCompanies, { id, name, photo }])
        }
    }

    useOnClickOutside(ref, () => setShowDropdown(false))

    const ChosenText = () => {
        const text = []
        if(responsibleUsers && responsibleUsers.length > 0){
            if(responsibleUsers.length === 1){
                text.push(`${responsibleUsers.length} person`)
            }else{
                text.push(`${responsibleUsers.length} personer`)
            }
        }



        if(responsibleCompanies && responsibleCompanies.length > 0){
            if(responsibleCompanies.length === 1){
                text.push(`${responsibleCompanies.length} område`)
            }else{
                text.push(`${responsibleCompanies.length} områder`)
            }
            
        }

        if(text.length === 0){
            return (
                <i>Velg person(er) eller område(r)</i>
            )
        }

        if(text.length === 1){
            return (<p>{text.join('')}</p>)
        }

        const last = text.pop();

        return (
            <p>{text.join(', ') + ' og ' + last}</p>
        )
    }
    return (
        <div className="person-picker-container">
                <div className="person-modal-content-container">
                    <div className="person-modal-header p-md-4 p-3">
                        <div className="row">
                            <div className="col">
                                <h5 className="bold">Velg ansvarlig(e)</h5>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-link" onClick={() => setOpen(false)}>
                                    <FontAwesomeIcon size="lg" icon={['far', 'xmark']} />
                                </button>
                            </div>
                        </div>
                        <div className="row align-items-center mb-2 mb-md-0">
                            <div className="col-lg-5 col">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon fixedWidth icon={['far', 'magnifying-glass']} />
                                    </div>
                                    <input type="text" className="form-control" placeholder="Søk" onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['fal', 'filter']} />
                            </div>
                        </div>
                    
                        <div className="row justify-content-between align-items-end">
                            <div className="col-auto">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="form-check">
                                            <input id="all" onChange={() => checkAll()} className="form-check-input" type="checkbox" checked={isAllChecked ? 'checked' : ''} />
                                            <label for="all" className="bold">Velg alle</label>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="pointer" onClick={() => unCheckAll()}>Fjern alle</div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="col-auto">
                                <div className="row">
                                {
                                    filterTypes.map((item, key) => {
                                        return (
                                            <div className="col-auto" key={key}>
                                                <div className="py-2 px-3 pointer" onClick={() => setFilter(item.value)} style={{borderRadius: '20px', background: filter === item.value ? 'var(--success)' : 'var(--light-grey)'}} >
                                                    <span style={{color: filter === item.value ? '#FFF' : 'var(--dark)'}}>{item.label}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="person-modal-person-wrapper">
                        {
                            filter === 'persons' && users.filter(person => person.fullname.match(new RegExp(search, 'i'))).map((item, index) => {
                                return (
                                    <Person 
                                        key={index} 
                                        update={updateUsers}
                                        selected={responsibleUsers}
                                        item={item}
                                    />
                                )
                            })
                        }
                        {
                            filter === 'companies' && companies.filter(company => company.name.match(new RegExp(search, 'i'))).map((item, index) => {

                                return (
                                    <Company 
                                        key={index} 
                                        update={updateCompanies}
                                        selected={responsibleCompanies}
                                        item={item}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className="person-modal-footer p-4">
                        <div className="row justify-content-between">
                            <div className="col-auto"></div>
                            <div className="col-auto">
                                <ChosenText />
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary" onClick={() => setOpen(false) }>Ferdig</button>
                            </div>
                        </div>
                    </div>
                </div>
            
            
        </div>
    )
}



function SelectedPerson({ item, update }){
    const { fullname, photo, company } = item
    const { baseUrl } = useContext(ConfigContext)
    return (
        <div className="col-auto mb-1">
            <Tooltip content={fullname} placement="top">
                <div className="x-on-hover pointer" onClick={() => {update(item)}}>
                    <FontAwesomeIcon icon={['fal', 'xmark']} />
                </div>
                { photo ? 
                    <img src={baseUrl + photo} style={{width: 40, height: 40, borderRadius: '50%',border: company ? '' : '3px solid var(--success)'}} />
                    : 
                    <div className="profile-image-replacement" style={{width: 40, height: 40, fontSize: '1.8rem',border: company ? '' : '3px solid var(--success)'}}><span>{fullname.substr(0, 1)}</span></div>
                }
            </Tooltip>
        </div>
        
    )
}
function SelectedTeam({ item, update }){
    const { name, photo } = item
    const { baseUrl } = useContext(ConfigContext)
    return (
        <div className="col-auto">
            <div className="row">
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fal', 'users']} />
                </div>
                <div className="col-auto">
                    <span>{name}</span>
                </div>
            </div>
            { photo ? 
                <img src={baseUrl + photo} style={{width: 40, height: 40, borderRadius: '50%',border: company ? '' : '3px solid var(--success)'}} />
                : 
                <div className="profile-image-replacement" style={{width: 40, height: 40, fontSize: '1.8rem',border: company ? '' : '3px solid var(--success)'}}><span>{fullname.substr(0, 1)}</span></div>
            }
        </div>
        
    )
}
function SelectedCompany({ item, update }){
    const { image, name } = item
    const { baseUrl } = useContext(ConfigContext)
    return (
        <div className="col-auto mb-1">

            <Tooltip content={name} placement="top">
                <div className="x-on-hover pointer" onClick={() => {update(item)}}>
                    <FontAwesomeIcon icon={['fal', 'xmark']} />
                </div>
                { image ? 
                    <img src={baseUrl + image} style={{width: 40, height: 40, borderRadius: '50%'}} />
                    : 
                    <div className="profile-image-replacement" style={{width: 40, height: 40, fontSize: '1.8rem'}}><span>{name.substr(0, 1)}</span></div>
                }
            </Tooltip>
        </div>
        
    )
}

function Person({ item, selected, update}){
    const { id, fullname, photo, role, company } = item
    const active  = selected.filter(e => e.id === id).length > 0
    return (
        <div className="person-modal-person-item p-3 pointer" onClick={() => update(item)}>
            <div className="row align-items-center gx-3">
                <div className="col-1">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => update(item)} checked={active ? 'checked' : ''} />
                    </div>
                </div>
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <UserPhoto photo={photo} name={fullname} size={[60, 50, 40]} />
                        </div>
                        <div className="col-auto">
                            <h6 className="bold">{fullname}</h6>
                            <h6>{role}</h6>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="col-auto">
                    {company ? 
                        <h6 className="bold m-0">{company.name}</h6> : 
                        <div className="person-modal-person-item-icon">
                            <FontAwesomeIcon icon={['fal', 'user-tie']} />
                        </div> 
                    }
                </div>
            </div>
        </div>
        
    )
}

function Company({ item, selected, update}){
    const { id, name, image } = item
    const { baseUrl } = useContext(ConfigContext)

    const active  = selected.filter(e => e.id === id).length > 0
    return (
        <div className="person-modal-person-item p-3 pointer" onClick={() => update(item)}>
            <div className="row align-items-center gx-3">
                <div className="col-1">
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => update(item)} type="checkbox" checked={active ? 'checked' : ''} />
                    </div>
                </div>
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            { image ? 
                            <img src={baseUrl + image} style={{width: 60, height: 60, borderRadius: '50%'}} />
                            : 
                                <div className="profile-image-replacement" style={{width: 60, height: 60, fontSize: '1.8rem'}}><span>{name.substr(0, 1)}</span></div>
                            }
                        </div>
                        <div className="col-auto">
                            <h6 className="bold">{name}</h6>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="col-auto">
                    
                </div>
            </div>
        </div>
        
    )
}

function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
}