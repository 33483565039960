import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../providers/ConfigProvider";
import Tooltip from "./Tooltip";

export default function UserPhoto({ photo, name, size, tooltip, consultant }){
    const { baseUrl } = useContext(ConfigContext)

    const [imageSize, setImageSize] = useState(size ? size : 0)

    useEffect(() => {
        if(size.length){
            switch(true){
                case (window.innerWidth <= 767):
                    setImageSize(size[2])
                    break;
                case (window.innerWidth >= 768 && window.innerWidth <= 1023):
                    setImageSize(size[1])
                    break;
                case (window.innerWidth >= 1024):
                    setImageSize(size[0])
                    break;
            }
        }
    }, [])

    

    return photo ? 
    (
        tooltip ? (
            <Tooltip content={name} placement="top"><img src={baseUrl + photo} style={{width: imageSize, height: imageSize, borderRadius: '50%'}} className={consultant ? "consultant-photo" : null} /></Tooltip>
        ) 
        : <img src={baseUrl + photo} width={imageSize} height={imageSize} style={{width: imageSize, height: imageSize, borderRadius: '50%'}} className={consultant ? "consultant-photo" : null} />
    ) : (
        tooltip ? (
            <Tooltip content={name} placement="top"><div className="profile-image-replacement" style={{minWidth: imageSize, width: imageSize, height: imageSize, fontSize: `${imageSize/1.5}px`}}><span>{name ? name.substr(0, 1) : 'A'}</span></div></Tooltip>
            ) 
        : <div className="profile-image-replacement" style={{minWidth: imageSize, width: imageSize, height: imageSize, fontSize: `${imageSize/1.5}px`}}><span>{name ? name.substr(0, 1) : 'A'}</span></div>
    )
}