import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import * as yup from 'yup'
import Drawer from '../../../components/Drawer'
import DropDown from '../../../components/DropDown'
import ImageCrop from '../../../components/ImageCrop'
import Input from '../../../components/Input'
import PersonModal from '../../../components/PersonModal'
import UserPhoto from '../../../components/UserPhoto'
import { AuthContext } from '../../../providers/AuthProvider'
import { ConfigContext } from '../../../providers/ConfigProvider'
import { DomainContext } from '../../../providers/DomainProvider'
import { NewRoomContext, NewRoomProvider } from '../../../providers/NewRoomProvider'

export default function DomainNewRoom(){
    return (
        <NewRoomProvider>
            <TabNavigation />
        </NewRoomProvider>
    )
}

function TabNavigation(){

    const { formik } = useContext(NewRoomContext)

    const [errors, setErrors] = useState(null)

    const TabItem = ({ link, children, error }) => {
        let resolved = useResolvedPath(link);
        let match = useMatch({ path: resolved.pathname, end: true });
        return (
            <Link to={link} className={`tab-item ${match ? "tab-item-active" : (error ? 'text-danger' : null)}`}>
                {error && !match ? (
                    <div className="row align-items-center gx-2">
                        <div className="col-auto">{children}</div>
                        <div className="col-auto"><FontAwesomeIcon icon={['fas', 'circle-exclamation']} /></div>
                    </div>
                ) : children}
            </Link>
        )
    }

    
    const navigate = useNavigate()
    let resolved = useResolvedPath('');
    let match = useMatch({ path: resolved.pathname, end: true });
    useEffect(() => {
        if(match){
            navigate('informasjon')
        }
    }, [])

    useEffect(() => {
        if(formik && formik.errors && Object.keys(formik.errors).length){
            setErrors(true)
        }else{
            setErrors(false)
        }
    }, [formik])

    return (
        <div className="p-md-3 p-2">
                <div className="transparent-box">
                    <div className="tab-container">
                        <div className="row gx-0">
                            <div className="col-auto">
                                <TabItem error={errors} link="informasjon" className="text-danger">Rominformasjon</TabItem>
                            </div>
                            <div className="col-auto">
                                <TabItem link="brukere">Brukere</TabItem>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
    )
}

export function DomainNewRoomInformation(){

    const { formik, templateTags, templateForms, templateDocuments, templateTasks } = useContext(NewRoomContext)
    const { domainUsers } = useContext(DomainContext)

    const [ open, setOpen ] = useState(false)

    return (
        <div className="p-md-4 p-3 bg-white">
            <div className="">
                
                    <div className="row mb-5">
                        <div className="col">
                            <h2 className="bold">Opprett nytt rom</h2>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary">Lagre</button>
                        </div>
                    </div>
                    <div className="mb-4">
                        <Input
                            name="name"
                            label="Navn"
                            type="text"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            touched={formik.touched.name}
                            helperText={formik.touched.name && formik.errors.name}
                            placeholder={"Rommets navn"}
                        />
                    </div>
                    <div className="row">
                        <h4 className="bold">Rommets logo</h4>
                        <div className="col-md-8 col-12">
                            <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                                <div className="py-1">
                                    <ImageCrop
                                        img={formik.values.logo}
                                        setImg={(logo) => formik.setFieldValue("logo", logo)}
                                        height={150}
                                        aspect={1}
                                        unit={'logo'}
                                        error={formik.touched.logo && Boolean(formik.errors.logo)}
                                        helperText={formik.touched.logo && formik.errors.logo}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col mb-md-0 mb-5">
                            <h6 className="bold">Rommets logo</h6>
                            <p>En logo for rom er ikke nødvendig, men hjelper til med å lettere identifisere og finne rommet. Kan legges inn i etterkant.</p>
                        </div>
                    </div>

                    <div>
                        <h4 className="bold">Tildel administrator for rommet</h4>
                    
                        <div className="border-lg mb-md-5 mb-2 ">
                            <div className="p-md-4 p-3">
                                <p>Velg administratorer som skal ha tilgang til dette rommet. En administrator er ikke påkrevd og blir ikke en del av rommets medlemmer.</p>
                                <div className="pt-4">
                                    <button type="button" className="btn btn-dashed" onClick={() => setOpen(true)}>
                                        <div className="row gx-1">
                                            <div className="col-auto">
                                                <FontAwesomeIcon icon={['fal', 'plus']} />
                                            </div>
                                            <div className="col">
                                                <span>Tildel admin</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {
                                formik.values.admins.length > 0 && formik.values.admins.map((el, key) => {
                                    return (
                                        <div className="border-top px-3 py-2" key={key}>
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <UserPhoto name={el.fullname} photo={el.photo} size={[40, 40, 40]} />
                                                </div>
                                                <div className="col">
                                                    <p className="m-0">{el.fullname}</p>
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-link text-danger" onClick={() => formik.setFieldValue('admins', formik.values.admins.filter(c => c.id !== el.id))  }>Fjern</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Drawer
                            open={open}
                            setOpen={setOpen}
                        >
                            <PersonModal
                                users={domainUsers}
                                responsibleUsers={formik.values.admins}
                                setResponsibleUsers={(a) => formik.setFieldValue('admins', a)}
                                setOpen={setOpen}
                            />
                        </Drawer>
                    </div>

                    <div>
                    <h4 className="bold">Oppsett av rom</h4>
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <div className="border-lg p-md-4 p-3 mb-md-5 mb-3">
                                <div className="form-group mb-3">
                                    <label>Knagger</label>
                                    <DropDown
                                        name="tags"
                                        error={formik.touched.tags && Boolean(formik.errors.tags)}
                                        value={formik.values.tags}
                                        onChange={(value) => {
                                            
                                            if(formik.values.tags.filter(t => t.value === value.value).length === 0){

                                                const selectedTag = templateTags.filter(t => t.id === value.value)[0]
                                                if(selectedTag){
                                                    if(selectedTag.SagaTemplateDocument){
                                                        selectedTag.SagaTemplateDocument.map((document) => {
                                                            formik.setFieldValue("documents", [...formik.values.documents, {label: document.name, value: document.id}])
                                                        })
                                                    }

                                                    if(selectedTag.SagaTemplateTask){
                                                        selectedTag.SagaTemplateTask.map((task) => {
                                                            if(formik.values.tasks.filter(t => t.value === task.id).length === 0){
                                                                formik.setFieldValue("tasks", [...formik.values.tasks, {label: task.name, value: task.id}])
                                                            }
                                                        })
                                                    }
                                                }
                                                formik.setFieldValue("tags", [...formik.values.tags, value])
                                            }else{
                                                const selectedTag = templateTags.filter(t => t.id === value.value)[0]
                                                if(selectedTag){
                                                    if(selectedTag.SagaTemplateDocument){
                                                        selectedTag.SagaTemplateDocument.map((document) => {
                                                            
                                                            formik.setFieldValue("documents", formik.values.documents.filter(t => t.value !== document.id))
                                                        })
                                                    }
                                                    if(selectedTag.SagaTemplateTask){
                                                        selectedTag.SagaTemplateTask.map((task) => {
                                                            formik.setFieldValue("tasks", formik.values.tasks.filter(t => t.value !== task.id))
                                                        })
                                                    }
                                                }
                                                formik.setFieldValue("tags", formik.values.tags.filter(t => t.value !== value.value))
                                            }
                                            
                                        }}
                                        options={templateTags && templateTags.map(item => {
                                            return {label: item.name, value: item.id}
                                        })}
                                        placeholder="Velg knagger"
                                        multiple
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Hvilke oppgaver skal rommet skal utføre?</label>
                                    <DropDown
                                        name="tasks"
                                        error={formik.touched.tasks && Boolean(formik.errors.tasks)}
                                        value={formik.values.tasks}
                                        onChange={(value) => {
                                            
                                            if(formik.values.tasks.filter(t => t.value === value.value).length === 0){
                                                
                                                formik.setFieldValue('tasks', [...formik.values.tasks, value])
                                            }else{
                                                formik.setFieldValue('tasks', formik.values.tasks.filter(t => t.value !== value.value))
                                            }
                                            
                                        }}
                                        options={templateTasks && templateTasks.map(item => {
                                            return {label: item.name, value: item.id}
                                        })}
                                        placeholder="Velg oppgaver"
                                        multiple
                                        touched={formik.touched.tasks}
                                        helperText={formik.touched.tasks && formik.errors.tasks}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Hvilke skjemamaler skal rommet få med?</label>
                                    <DropDown
                                        name="forms"
                                        error={formik.touched.forms && Boolean(formik.errors.forms)}
                                        value={formik.values.forms}
                                        onChange={(value) => {
                                            
                                            if(formik.values.forms.filter(t => t.value === value.value).length === 0){
                                                
                                                formik.setFieldValue('forms', [...formik.values.forms, value])
                                            }else{
                                                formik.setFieldValue('forms', formik.values.forms.filter(t => t.value !== value.value))
                                            }
                                            
                                        }}
                                        options={templateForms && templateForms.map(item => {
                                            return {label: item.name, value: item.id}
                                        })}
                                        placeholder="Velg skjemamaler"
                                        multiple
                                        touched={formik.touched.forms}
                                        helperText={formik.touched.forms && formik.errors.forms}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Hvilke dokumentområder skal rommet få tilgang til?</label>
                                    <DropDown
                                        name="documents"
                                        error={formik.touched.documents && Boolean(formik.errors.documents)}
                                        value={formik.values.documents}
                                        onChange={(value) => {
                                            
                                            if(formik.values.documents.filter(t => t.value === value.value).length === 0){
                                                formik.setFieldValue('documents', [...formik.values.documents, value])
                                            }else{
                                                
                                                formik.setFieldValue('documents', formik.values.documents.filter(t => t.value !== value.value))
                                            }
                                            
                                        }}
                                        options={templateDocuments?.map(item => {
                                            return {label: item.name, value: item.id}
                                        })}
                                        placeholder="Velg dokumentområder"
                                        multiple
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col mb-md-0 mb-5">
                            <h6 className="bold">Knagger, oppgaver, skjemaer og dokumentområder</h6>
                            <p>Ved å bruke knagger vil du automatisk få opp forslag til oppgave- og dokumentoppsett for rommet som opprettes.</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-auto">
                            <button className='btn btn-success px-5' type="submit">Lagre</button>
                        </div>
                    </div>
                
            </div>
        </div>

    )
}

