import axios from "axios";
import { useFormik } from "formik";
import React, { useState, createContext, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "./AuthProvider"
import { ConfigContext } from "./ConfigProvider"
import * as yup from 'yup';
import Drawer from "../components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../components/Input";
import DropDown from "../components/DropDown";
import CheckBox from "../components/CheckBox";

export const NewRoomContext = createContext({

})

export const NewRoomProvider = ({children}) => {

    const { apiCall, domain, uploadFile } = useContext(AuthContext)
    const { notify } = useContext(ConfigContext)

    const navigate = useNavigate()

    const [templateTasks, setTemplateTasks] = useState(null)
    const [templateForms, setTemplateForms] = useState(null)
    const [templateDocuments, setTemplateDocuments] = useState(null)
    const [templateTags, setTemplateTags] = useState(null)

    const [permissionTemplates, setPermissionTemplates] = useState([])
    const [userDrawerOpen, setUserDrawerOpen] = useState(false)

    useEffect(() => {
        getTemplateTasks()
        getTemplateDocuments()
        getTemplateForms()
        getTemplateSettings()
        getPermissionTemplates()
    }, [])


    const getTemplateTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-template-tasks'});
        
        if(data.status === 1){
            
            setTemplateTasks(data.data)
        }
    }

    const getTemplateDocuments = async () => {
        const { data } = await apiCall({action: 'get-domain-template-documents'});
        
        if(data.status === 1){
            setTemplateDocuments(data.data)
        }
    }

    const getTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-domain-template-forms'});

        if(data.status === 1){
            setTemplateForms(data.data)
        }
    }

    const getTemplateSettings = async () => {
        const { data } = await apiCall({action: 'get-domain-template-settings'});
        let tags = []
        if(data.status === 1){
            data.data.map((item) => {
                if(item.type === 1){
                    tags.push(item)
                }
                
            })
            setTemplateTags(tags)
        }
    }

    const getPermissionTemplates = async () => {
        const { data } = await apiCall({action: 'get-company-permission-templates'});
        if(data.status === 1){
            setPermissionTemplates(data.data)
        }
        
    }

    let schema = yup.object().shape({
        name: yup.string().required("Bedriftsnavn er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            logo: null,
            tags: [],
            tasks: [],
            forms: [],
            documents: [],
            newUsers: [],
            members: [],
            admins: []
        },
        validationSchema: schema,
        onSubmit: async (values) => {      
            save(values)      
            
        },
    });

    const save = async (values) => {
        const logoData = values.logo instanceof File ? await uploadFile(values.logo) : null

        const { data } = await apiCall({
            action: 'create-room',
            name: values.name,
            logo: logoData,
            tags: values.tags,
            tasks: values.tasks,
            forms: values.forms,
            documents: values.documents,
            newUsers: values.newUsers,
            members: values.members,
            admins: values.admins
        })

        notify(data.status, data.title, data.message)

        if(data.status === 1){
            navigate(`/${domain.hash}/${data.data.hash}/oversikt`)
        }


    }

    return (
        <NewRoomContext.Provider value={{
            formik,
            templateTasks,
            templateDocuments,
            templateForms,
            templateTags,
            permissionTemplates,
            setUserDrawerOpen,
        }}>{
            !templateTasks || !templateDocuments || !templateTags || !templateForms ? 
                <h1>Laster</h1> : 
                (
                    <>
                        <form onSubmit={formik.handleSubmit}>
                            {children}
                        </form>
                        <Drawer
                            open={userDrawerOpen}
                            setOpen={setUserDrawerOpen}
                        >
                            <AddUser setUserDrawerOpen={setUserDrawerOpen} />
                        </Drawer> 
                    </>
                )
            }</NewRoomContext.Provider>
    )
}

function AddUser(){

    const { permissionTemplates, setUserDrawerOpen, formik } = useContext(NewRoomContext)

    let userSchema = yup.object().shape({
        firstname: yup.string().required("Fornavn er påkrevd"),
        lastname: yup.string().required("Etternavn er påkrevd"),
        phone: yup.string().matches(/^[0-9]{8}$/, 'Telefonnummer må være 8 siffer').required("Telefonnummer er påkrevd"),
        role: yup.string().required("Stillingstittel / rolle er påkrevd"),
        email: yup.string().email("Må være en gyldig e-postadresse").required("e-postadresse er påkrevd"),
        permissionTemplate: yup.object().typeError("Rolle er påkrevd").nullable(false).required('Rolle er påkrevd'),
        active: yup.boolean()
    });

    const userFormik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            role: '',
            email: '',
            permissionTemplate: null,
            active: false
        },
        validationSchema: userSchema,
        onSubmit: async (values) => {            
            formik.setFieldValue('newUsers', [...formik.values.newUsers, {
                firstname: values.firstname,
                lastname: values.lastname,
                phone: values.phone,
                role: values.role,
                email: values.email,
                permissionTemplate: values.permissionTemplate,
                active: values.active
            }])
            setUserDrawerOpen(false)
        },
    });

    

    return (
        <div className="py-3 d-flex flex-column h-100">
            <div className="col">
                <div className="row justify-content-between mb-4">
                    <div className="col-auto pointer" onClick={() => setUserDrawerOpen(false)}>
                        <div className="row">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                            </div>
                            <div className="col-auto">
                                <h6 className="bold">Opprett ny kunde</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto pointer" onClick={() => setUserDrawerOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'xmark']} />
                    </div>
                </div>
                <h3 className="bold">Legg til bruker</h3>
                <hr className="mb-4" style={{opacity: '.1'}} />
            
                <form onSubmit={userFormik.handleSubmit}>
                    <div className="row pb-3 mb-3 border-bottom border-dashed">
                        <div className="col-md-6 mb-3">
                            <Input
                                name="firstname"
                                label="Fornavn"
                                type="text"
                                value={userFormik.values.firstname}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.firstname && Boolean(userFormik.errors.firstname)}
                                touched={userFormik.touched.firstname}
                                helperText={userFormik.touched.firstname && userFormik.errors.firstname}
                                placeholder={"Medlemmets fornavn"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="lastname"
                                label="Etternavn"
                                type="text"
                                value={userFormik.values.lastname}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.lastname && Boolean(userFormik.errors.lastname)}
                                touched={userFormik.touched.lastname}
                                helperText={userFormik.touched.lastname && userFormik.errors.lastname}
                                placeholder={"Medlemmets etternavn"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="role"
                                label="Stillingstittel / rolle"
                                type="text"
                                value={userFormik.values.role}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.role && Boolean(userFormik.errors.role)}
                                touched={userFormik.touched.role}
                                helperText={userFormik.touched.role && userFormik.errors.role}
                                placeholder={"Stillingstittel / rolle"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="phone"
                                label="Telefonnummer"
                                type="text"
                                value={userFormik.values.phone}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.phone && Boolean(userFormik.errors.phone)}
                                touched={userFormik.touched.phone}
                                helperText={userFormik.touched.phone && userFormik.errors.phone}
                                placeholder={"Fyll inn telefonnummer (8 siffer)"}
                            />  
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="email"
                                label="e-postadresse"
                                type="text"
                                value={userFormik.values.email}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.email && Boolean(userFormik.errors.email)}
                                touched={userFormik.touched.email}
                                helperText={userFormik.touched.email && userFormik.errors.email}
                                placeholder={"Fyll inn medlemmets e-postadresse"}
                            /> 
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Brukertilgang</label>
                                <DropDown
                                    name="permissionTemplate"
                                    error={userFormik.touched.permissionTemplate && Boolean(userFormik.errors.permissionTemplate)}
                                    options={permissionTemplates.map(item => {
                                        return {label: item.name, value: item.id}
                                    })}
                                    value={userFormik.values.permissionTemplate}
                                    placeholder="Velg en rolle"
                                    onChange={(e) => userFormik.setFieldValue("permissionTemplate", e)}
                                    touched={userFormik.touched.permissionTemplate}
                                    helperText={userFormik.touched.permissionTemplate && userFormik.errors.permissionTemplate}

                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Send tilgang med en gang</label>
                                <CheckBox
                                    name="active"
                                    label="Aktiver medlem ved opprettelse"
                                    value={userFormik.values.active}
                                    classes="checkbox-success"
                                    onChange={(e) => userFormik.setFieldValue("active", e)}
                                    description="Aktivering av medlemmet gir umiddelbar invitasjon og tilgang til systemet"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <button type="button" className="btn btn-border btn-block w-100" onClick={() => setUserDrawerOpen(false)}>Avbryt og lukk</button>
                        </div>
                        <div className="col-lg-6">
                            <button className="btn btn-success w-100" type="submit">Legg til bruker</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}