import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { createRef, useContext, useEffect, useState } from "react"
import { ConfigContext } from "../providers/ConfigProvider";
import { styleBlockedText } from "./Functions";
import TaskEditor from "./TaskEditor";
import UserPhoto from "./UserPhoto";
import { EditorState } from 'draft-js';

export default function TaskLog({ Context }){

    const { log, addMessage} = useContext(Context)

    const [value, setValue] = useState(null)

    const ref = createRef()
    useEffect(() => {
        // Chatloggen skal alltid være scrollet til bunn
        if(ref.current){
            ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight;
        }
    }, [ref])

    const saveMessage = async () => {
        
        if(value?.blocks?.length === 1){
            if(value?.blocks[0].text.length === 0){
                return
            }
        }
        await addMessage(value)
        setValue(null)
    }

    
    return (
        <div className="d-flex flex-column h-100">
            <div className="p-4 col task-chat-log" ref={ref}>
                {
                    log.map((item, key) => {
                        return <Log key={key}{...item} />
                    })
                }
            </div>
            <div className="p-3 border-top task-chat-editor">
                <div className="row align-items-center">
                    <div className="col">
                        <TaskEditor 
                            value={value}
                            setValue={setValue}
                            context={Context}
                        />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => saveMessage()}>
                            <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Log({type, createdon, createdby, user, messageRaw}){

    //return (<Event datetime={datetime} content={content} />)
    switch(type){
        case 'Log':
            return (<Event createdon={createdon} content={messageRaw} />)
        case 'Message':
            return (<Message content={messageRaw} createdby={createdby} createdon={createdon} user={user} />)
        default:
            return (<div></div>)
    }
    
}

function Event({ createdon, content }){
    return (
        <div className="row mb-2">
            <div className="col">
                <div dangerouslySetInnerHTML={{__html: styleBlockedText(content)}}></div>
            </div>
            <div className="col-auto">
                <small>{moment(createdon).format('DD.MM.YYYY')}</small>
            </div>
        </div>
    )

}

function Message({ content, createdon, createdby}){
    const { baseUrl } = useContext(ConfigContext)
    return (
        <div className="row gx-1 align-items-center mb-3">
            <div className="col-auto">
                {
                    createdby ? <UserPhoto photo={createdby.photo} name={createdby.fullname} size={50} /> : null
                }
                
            </div>
            <div className="col">
                <div className="py-2 px-3 bg-grey " style={{borderRadius: 10, transform: 'translateX(-0px)'}}>
                    <div className="mb-2">
                        <div dangerouslySetInnerHTML={{__html: styleBlockedText(content)}}></div>
                    </div>
                    <small>Skrevet av {createdby ? createdby.fullname : 'Ukjent'} {moment(createdon).format('DD.MM.YYYY')}</small>
                </div>
            </div>
        </div>
    )
}